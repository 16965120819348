module.exports = (function () {
  var sp = new (require('./AbstractSP.js'))({
    name: 'AddonConfigurationSP',
    title: 'ADDON_INFORMATION',
  });

  var orgaId;
  var addonId;

  sp.getStreams = function (req) {
    orgaId = req.params.oid;
    addonId = req.params.addonId;

    var s_addon = orgaId
      ? API.organisations._.addons._.sso.get().withParams([orgaId, addonId]).send()
      : API.self.addons._.sso.get().withParams([addonId]).send();

    return {
      s_addon: s_addon,
    };
  };

  sp.on('onload', function (req, $container, streams) {
    streams.s_addon.onValue(_.partial(sp.displayConfiguration, $container));
  });

  sp.displayConfiguration = function ($container, data) {
    var $form = $container.find('.addon-configuration form').empty().attr({
      action: data.url,
      method: 'post',
    });

    _.each(_.omit(data, 'url'), function (value, key) {
      $form.append(
        $('<input>').attr({
          type: 'hidden',
          name: key,
          value: value,
        }),
      );
    });

    $form.submit();
  };

  return sp;
})();
