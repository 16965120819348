var $ = require('jquery');
var Bacon = require('baconjs');

var $Ping = module.exports;
var fetcher;

const { sanitize } = require('@clevercloud/components/dist/lib/i18n/i18n-sanitize.js');

$Ping.fetch = function () {
  var fetch = function () {
    return Bacon.fromPromise($.get('/ping'));
  };

  var ping = function () {
    // Check the console status every 1 minute.
    return Bacon.repeat(function (i) {
      return Bacon.later(60 * 1000, null).flatMapLatest(fetch);
    }).merge(fetch());
  };

  if (!fetcher) {
    fetcher = ping().toProperty();
  }

  return fetcher;
};

$Ping.getMaintenanceState = function () {
  return $Ping.fetch().map('.maintenance').skipDuplicates().toProperty();
};

$Ping.getConsoleHash = function () {
  return $Ping
    .fetch()
    .map(({ commit, configHash }) => [commit, configHash].join('/'))
    .skipDuplicates()
    .toProperty();
};

$Ping.displayMaintenance = function (Templates, $container) {
  $container.html(Templates['maintenance']());
};

$Ping.displayRefreshPrompt = function () {
  // The refresh prompt contains a link, so we need to pass a DOM element instead of a string
  // For this we reuse the clever components' sanitizer to make sure we don't leak anything dangerous from the translation system into the DOM
  const domFragment = sanitize(T('REFRESH_PROMPT'));

  // We want the link to trigger a "reload" and it's the easiest way to implement this #sorry
  domFragment.querySelector('a').addEventListener('click', (e) => {
    e.preventDefault();
    window.location.reload();
  });

  const toaster = document.querySelector('cc-toaster');
  toaster.show({
    message: domFragment,
    intent: 'info',
    options: {
      timeout: 0,
      closeable: true,
    },
  });
};
