const { GRAFANA_ENABLED, ADDON_LOGS_ENABLED, SHARED_SOFTWARES_ENABLED, ACCESS_LOGS_ENABLED } = require('../configuration.js');

const T = require('../models/technical/translation.js');

module.exports.getRoutes = function () {
  const addonOverviewModule = require('../statepoints-wc/addon-overview-switch-statepoint.js').init();

  return [
    { id: 'home', path: '/', type: 'static', module: require('../statepoints/HomeSP.js'), name: 'HomeSP' },
    {
      id: 'goto-resource',
      path: '/goto/{resourceId:str}',
      type: 'lazy',
      module: () => import('../statepoints-wc/redirect-to-resource.sp.js'),
    },
    {
      id: 'ticket-center-choice',
      path: '/ticket-center-choice',
      type: 'lazy',
      module: () => import('../statepoints-wc/ticket-center-choice.sp.js'),
      name: 'TicketCenterChoiceModuleSP',
      title: T('TICKET_CENTER'),
    },
    {
      id: 'auth-logout',
      path: '/auth/logout',
      type: 'static',
      module: require('../statepoints/AuthLogoutSP.js'),
      name: 'AuthLogoutSP',
    },
    {
      id: 'user-overview',
      path: '/users/me',
      type: 'lazy',
      module: () => import('../statepoints-wc/orga-overview.sp.js'),
      name: 'OrgaOverviewSP',
      title: T('OVERVIEW'),
    },
    {
      id: 'user-overview',
      path: '/users/me/overview',
      type: 'lazy',
      module: () => import('../statepoints-wc/orga-overview.sp.js'),
      name: 'OrgaOverviewSP',
      title: T('OVERVIEW'),
    },
    {
      id: 'user-information',
      path: '/users/me/information',
      type: 'static',
      module: require('../statepoints/UserInformationSP.js'),
      name: 'UserInformationSP',
      title: T('INFORMATION'),
    },
    {
      id: 'user-authentication',
      path: '/users/me/authentication',
      type: 'static',
      module: require('../statepoints/UserSecuritySP.js'),
      name: 'UserSecuritySP',
      title: T('console.user-security.title'),
    },
    {
      id: 'app-domain-names',
      path: '/users/me/applications/{appId:str}/vhosts',
      type: 'lazy',
      module: () => import('../statepoints-wc/domain-names.sp.js'),
      name: 'AppDomainNamesSP',
      title: T('DOMAIN_NAMES'),
    },
    {
      id: 'user-emails',
      path: '/users/me/emails',
      type: 'lazy',
      module: () => import('../statepoints-wc/user-emails.sp.js'),
      name: 'UserEmailsSP',
      title: T('EMAILS'),
    },
    {
      id: 'user-ssh-keys',
      path: '/users/me/ssh-keys',
      type: 'lazy',
      module: () => import('../statepoints-wc/user-ssh-keys.sp.js'),
      name: 'UserSshKeysSP',
      title: T('USER_SSH_KEYS'),
    },
    {
      id: 'user-tokens',
      path: '/users/me/tokens',
      type: 'static',
      module: require('../statepoints/UserTokensSP.js'),
      name: 'UserTokensSP',
      title: T('console.user-oauth-tokens'),
    },
    {
      id: 'user-deletion',
      path: '/users/me/deletion',
      type: 'lazy',
      module: () => import('../statepoints-wc/user-deletion.sp.js'),
      name: 'UserDeletionSP',
      title: T('USER_DELETION'),
    },
    {
      id: 'user-notifications',
      path: '/users/me/notifications',
      type: 'static',
      module: require('../statepoints/OwnerNotificationsSP.es6.js'),
      name: 'OwnerNotificationsSP',
      title: T('NOTIFICATIONS'),
    },
    {
      id: 'user-payment',
      path: '/users/me/payment',
      type: 'static',
      module: require('../statepoints/PaymentSP.es6.js'),
      name: 'PaymentSP',
      title: T('console.panes.payment'),
    },
    GRAFANA_ENABLED ? {
      id: 'user-grafana',
      path: '/users/me/grafana',
      type: 'lazy',
      module: () => import('../statepoints-wc/orga-grafana.sp.js'),
      name: 'GrafanaSP',
      title: T('METRICS.grafana'),
    } : null,
    {
      id: 'user-ticket-center',
      path: '/users/me/ticket-center',
      type: 'lazy',
      module: () => import('../statepoints-wc/ticket-center.sp.js'),
      name: 'TicketCenterSP',
      title: T('TICKET_CENTER'),
    },
    {
      id: 'user-app-creation',
      path: '/users/me/applications/new',
      type: 'static',
      module: require('../statepoints/AppCreationSP.js'),
      name: 'AppCreationSP',
      title: T('APP_CREATION'),
    },
    {
      id: 'user-app-overview',
      path: '/users/me/applications/{appId:str}',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-overview.sp.js'),
      name: 'AppOverviewSP',
      title: T('OVERVIEW'),
    },
    {
      id: 'user-app-information',
      path: '/users/me/applications/{appId:str}/information',
      type: 'static',
      module: require('../statepoints/AppSP.js'),
      name: 'AppSP',
      title: T('APPLICATION_INFORMATION'),
    },
    {
      id: 'user-app-settings',
      path: '/users/me/applications/{appId:str}/settings',
      type: 'static',
      module: require('../statepoints/AppConfigurationSP.js'),
      name: 'AppConfigurationSP',
      title: T('SCALABILITY'),
    },
    {
      id: 'user-app-tcp-redirections',
      path: '/users/me/applications/{appId:str}/tcp-redirections',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-tcp-redirections.sp.js'),
      name: 'AppTcpRedirectionsSP',
      title: T('TCP_REDIRECTIONS'),
    },
    {
      id: 'user-app-services-dependencies',
      path: '/users/me/applications/{appId:str}/services-dependencies',
      type: 'static',
      module: require('../statepoints/AppServicesDependenciesSP.es6.js'),
      name: 'AppServicesDependenciesSP',
      title: T('SERVICES_DEPENDENCIES'),
    },
    {
      id: 'user-app-env-vars',
      path: '/users/me/applications/{appId:str}/variables',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-env-vars.sp.js'),
      name: 'AppEnvVariablesSP',
      title: T('ENVIRONMENT_VARS'),
    },
    {
      id: 'user-app-exposed-vars',
      path: '/users/me/applications/{appId:str}/exposed-variables',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-exposed-variables.sp.js'),
      name: 'AppExposedVariablesSP',
      title: T('EXPOSED_VARIABLES'),
    },
    {
      id: 'user-app-logs',
      path: '/users/me/applications/{appId:str}/logs',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-logs.sp.js'),
      name: 'AppLogsSP',
      title: T('LOGS'),
    },
    ACCESS_LOGS_ENABLED ? {
      id: 'user-app-access-logs',
      path: '/users/me/applications/{appId:str}/app-access-logs',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-access-logs.sp.js'),
      name: 'AppAccessLogsSP',
      title: T('ACCESS_LOGS'),
    } : null,
    {
      id: 'user-app-deployments',
      path: '/users/me/applications/{appId:str}/deployments',
      type: 'static',
      module: require('../statepoints/AppDeploymentsSP.js'),
      name: 'AppDeploymentsSP',
      title: T('DEPLOYMENTS_ACTIVITY'),
    },
    {
      id: 'user-app-metrics',
      path: '/users/me/applications/{appId:str}/metrics',
      type: 'static',
      module: require('../statepoints/Metrics.es6.js'),
      name: 'MetricsSP',
      title: T('METRICS'),
    },
    {
      id: 'user-app-metrics-type',
      path: '/users/me/applications/{appId:str}/metrics/{type:str}',
      type: 'static',
      module: require('../statepoints/Metrics.es6.js'),
      name: 'MetricsSP',
      title: T('METRICS'),
    },
    {
      id: 'user-addon-creation',
      path: '/users/me/addons/new',
      type: 'static',
      module: require('../statepoints/AddonCreationSP.js'),
      name: 'AddonCreationSP',
      title: T('ADDON_CREATION'),
    },
    {
      id: 'user-addon-overview',
      path: '/users/me/addons/{addonId:str}',
      type: 'static',
      module: addonOverviewModule,
      name: 'AddonConfigurationSP',
      title: T('console.panes.addon-settings.dashboard'),
    },
    {
      id: 'user-addon-backups',
      path: '/users/me/addons/{addonId:str}/backups',
      type: 'lazy',
      module: () => import('../statepoints-wc/addon-backups.sp.js'),
      name: 'AddonBackupsSP',
      title: T('ADDON_BACKUPS'),
    },
    {
      id: 'user-addon-information',
      path: '/users/me/addons/{addonId:str}/informations',
      type: 'static',
      module: require('../statepoints/AddonSP.js'),
      name: 'AddonSP',
      title: T('ADDON_OVERVIEW'),
    },
    {
      id: 'user-addon-services-dependencies',
      path: '/users/me/addons/{addonId:str}/services-dependencies',
      type: 'lazy',
      module: () => import('../statepoints-wc/addon-service-dependencies.sp.js'),
      name: 'AddonServicesDependenciesSP',
      title: T('SERVICES_DEPENDENCIES'),
    },
    {
      id: 'user-addon-migrations',
      path: '/users/me/addons/{addonId:str}/migrations',
      type: 'static',
      module: require('../statepoints/AddonMigrationSP.es6.js'),
      name: 'AddonMigrationSP',
      title: T('ADDON_MIGRATION'),
    },
    {
      id: 'user-addon-migration-details',
      path: '/users/me/addons/{addonId:str}/migrations/{migrationId:str}',
      type: 'static',
      module: require('../statepoints/AddonMigrationSP.es6.js'),
      name: 'AddonMigrationSP',
      title: T('ADDON_MIGRATION'),
    },
    ADDON_LOGS_ENABLED
      ? {
          id: 'user-addon-logs',
          path: '/users/me/addons/{addonId:str}/logs',
          type: 'static',
          module: require('../statepoints/AddonLogsSP.es6.js'),
          name: 'LogsSP',
          title: T('LOGS'),
        }
      : null,
    {
      id: 'user-addon-metrics',
      path: '/users/me/addons/{addonId:str}/metrics',
      type: 'static',
      module: require('../statepoints/Metrics.es6.js'),
      name: 'MetricsSP',
      title: T('METRICS'),
    },
    {
      id: 'user-addon-metrics-type',
      path: '/users/me/addons/{addonId:str}/metrics/{type:str}',
      type: 'static',
      module: require('../statepoints/Metrics.es6.js'),
      name: 'MetricsSP',
      title: T('METRICS'),
    },
    {
      id: 'user-addon-redis-explorer',
      path: '/users/me/addons/{addonId:str}/redis-explorer',
      type: 'lazy',
      module:  () => import('../statepoints-wc/addon-redis-explorer.js'),
      name: 'AddonRedisExplorerSP',
      title: T('console.panes.addon-settings.kv-explorer'),
    },
    {
      id: 'user-addon-materia-explorer',
      path: '/users/me/addons/{addonId:str}/materia-explorer',
      type: 'lazy',
      module:  () => import('../statepoints-wc/addon-materia-explorer.js'),
      name: 'AddonMateriaExplorerSP',
      title: T('console.panes.addon-settings.kv-explorer'),
    },
    {
      id: 'user-invoices',
      path: '/users/me/invoices',
      type: 'lazy',
      module: () => import('../statepoints-wc/invoice-list.sp.js'),
      name: 'BillsSP',
      title: T('INVOICES'),
    },
    {
      id: 'user-invoice-details',
      path: '/users/me/invoices/{bid:str}',
      type: 'static',
      module: require('../statepoints/BillSP.js'),
      name: 'BillSP',
      title: T('BILL'),
    },
    {
      id: 'user-oauth-consumer-creation',
      path: '/users/me/oauth-consumers/new',
      type: 'static',
      module: require('../statepoints/OauthConsumerCreationSP.es6.js'),
      name: 'OauthConsumerCreationSP',
      title: T('console.oauth-consumers.new-consumer'),
    },
    {
      id: 'user-oauth-consumer-details',
      path: '/users/me/oauth-consumers/{consumerKey:str}',
      type: 'static',
      module: require('../statepoints/OauthConsumerSP.es6.js'),
      name: 'OauthConsumerSP',
      title: T('OAUTH_CONSUMER_INFORMATION'),
    },
    {
      id: 'user-oauth-consumer-edit',
      path: '/users/me/oauth-consumers/{consumerKey:str}/edit',
      type: 'static',
      module: require('../statepoints/OauthConsumerEditSP.es6.js'),
      name: 'OauthConsumerEditSP',
      title: T('console.oauth-consumers.edit-consumer'),
    },
    {
      id: 'orga-creation',
      path: '/organisations/new',
      type: 'static',
      module: require('../statepoints/OrgaCreationSP.js'),
      name: 'OrgaCreationSP',
      title: T('ORGA_CREATION'),
    },
    {
      id: 'orga-overview',
      path: '/organisations/{oid:str}',
      type: 'lazy',
      module: () => import('../statepoints-wc/orga-overview.sp.js'),
      name: 'OrgaOverviewSP',
      title: T('OVERVIEW'),
    },
    {
      id: 'orga-overview-alt',
      path: '/organisations/{oid:str}/overview',
      type: 'lazy',
      module: () => import('../statepoints-wc/orga-overview.sp.js'),
      name: 'OrgaOverviewSP',
      title: T('OVERVIEW'),
    },
    {
      id: 'orga-information',
      path: '/organisations/{oid:str}/information',
      type: 'static',
      module: require('../statepoints/OrgaSP.es6.js'),
      name: 'OrgaSP',
      title: T('INFORMATION'),
    },
    {
      id: 'orga-members',
      path: '/organisations/{oid:str}/members',
      type: 'lazy',
      module: () => import('../statepoints-wc/orga-members.sp.js'),
      name: 'OrgaMembersSP',
      title: T('ORGA_MEMBERS'),
    },
    {
      id: 'orga-join',
      path: '/organisations/{oid:str}/join',
      type: 'static',
      module: require('../statepoints/OrgaJoinSP.js'),
      name: 'OrgaJoinSP',
    },
    {
      id: 'orga-notifications',
      path: '/organisations/{oid:str}/notifications',
      type: 'static',
      module: require('../statepoints/OwnerNotificationsSP.es6.js'),
      name: 'OwnerNotificationsSP',
      title: T('NOTIFICATIONS'),
    },
    {
      id: 'orga-payment',
      path: '/organisations/{oid:str}/payment',
      type: 'static',
      module: require('../statepoints/PaymentSP.es6.js'),
      name: 'PaymentSP',
      title: T('console.panes.payment'),
    },
    SHARED_SOFTWARES_ENABLED ? {
      id: 'orga-saas',
      path: '/organisations/{oid:str}/saas',
      type: 'lazy',
      module: () => import('../statepoints-wc/orga-saas.sp.js'),
      name: 'OrgaSaaSSP',
      title: T('SHARED_SOFTWARES'),
    } : null,
    GRAFANA_ENABLED ? {
      id: 'orga-grafana',
      path: '/organisations/{oid:str}/grafana',
      type: 'lazy',
      module: () => import('../statepoints-wc/orga-grafana.sp.js'),
      name: 'GrafanaSP',
      title: T('METRICS.grafana'),
    } : null,
    {
      id: 'orga-ticket-center',
      path: '/organisations/{oid:str}/ticket-center',
      type: 'lazy',
      module: () => import('../statepoints-wc/ticket-center.sp.js'),
      name: 'TicketCenterSP',
      title: T('TICKET_CENTER'),
    },
    {
      id: 'orga-app-creation',
      path: '/organisations/{oid:str}/applications/new',
      type: 'static',
      module: require('../statepoints/AppCreationSP.js'),
      name: 'AppCreationSP',
      title: T('APP_CREATION'),
    },
    {
      id: 'orga-creation-tunnel',
      path: '/organisations/{oid:str}/create',
      type: 'lazy',
      module: () => import('../statepoints-wc/creation-tunnel-step-one.sp.js'),
      name: 'CreationTunnelStepOneSP',
      title: T('CREATE'),
    },
    {
      id: 'orga-creation-tunnel-cellar',
      path: '/organisations/{oid:str}/create/cellar',
      type: 'lazy',
      module: () => import('../creation-tunnel/cellar/configure-cellar.sp.js'),
      name: 'CreationTunnelConfigureCellarSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-cellar-onboarding',
      path: '/organisations/{oid:str}/cellar/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/cellar/onboarding-cellar.sp.js'),
      name: 'CreationTunnelOnboardingCellarSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-config-provider',
      path: '/organisations/{oid:str}/create/config-provider',
      type: 'lazy',
      module: () => import('../creation-tunnel/config-provider/configure-config-provider.sp.js'),
      name: 'CreationTunnelConfigureConfigProviderSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-config-provider-onboarding',
      path: '/organisations/{oid:str}/config-provider/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/config-provider/onboarding-config-provider.sp.js'),
      name: 'CreationTunnelOnboardingConfigProviderSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-docker',
      path: '/organisations/{oid:str}/create/docker',
      type: 'lazy',
      module: () => import('../creation-tunnel/docker/configure-docker.sp.js'),
      name: 'CreationTunnelConfigureDockerSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-docker-onboarding',
      path: '/organisations/{oid:str}/docker/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/docker/onboarding-docker.sp.js'),
      name: 'CreationTunnelOnboardingDockerSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-dotnet',
      path: '/organisations/{oid:str}/create/dotnet',
      type: 'lazy',
      module: () => import('../creation-tunnel/dotnet/configure-dotnet.sp.js'),
      name: 'CreationTunnelConfigureDotnetSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-dotnet-onboarding',
      path: '/organisations/{oid:str}/dotnet/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/dotnet/onboarding-dotnet.sp.js'),
      name: 'CreationTunnelOnboardingDotnetSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-elasticsearch',
      path: '/organisations/{oid:str}/create/elasticsearch',
      type: 'lazy',
      module: () => import('../creation-tunnel/elasticsearch/configure-elasticsearch.sp.js'),
      name: 'CreationTunnelConfigureElasticsearchSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-elasticsearch-onboarding',
      path: '/organisations/{oid:str}/elasticsearch/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/elasticsearch/onboarding-elasticsearch.sp.js'),
      name: 'CreationTunnelOnboardingElasticsearchSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-elixir',
      path: '/organisations/{oid:str}/create/elixir',
      type: 'lazy',
      module: () => import('../creation-tunnel/elixir/configure-elixir.sp.js'),
      name: 'CreationTunnelConfigureElixirSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-elixir-onboarding',
      path: '/organisations/{oid:str}/elixir/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/elixir/onboarding-elixir.sp.js'),
      name: 'CreationTunnelOnboardingElixirSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-fs-bucket',
      path: '/organisations/{oid:str}/create/fs-bucket',
      type: 'lazy',
      module: () => import('../creation-tunnel/fs-bucket/configure-fs-bucket.sp.js'),
      name: 'CreationTunnelConfigureFSBucketSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-fs-bucket-onboarding',
      path: '/organisations/{oid:str}/fs-bucket/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/fs-bucket/onboarding-fs-bucket.sp.js'),
      name: 'CreationTunnelOnboardingFSBucketSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-go',
      path: '/organisations/{oid:str}/create/go',
      type: 'lazy',
      module: () => import('../creation-tunnel/go/configure-go.sp.js'),
      name: 'CreationTunnelConfigureGoSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-go-onboarding',
      path: '/organisations/{oid:str}/go/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/go/onboarding-go.sp.js'),
      name: 'CreationTunnelOnboardingGoSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-haskell',
      path: '/organisations/{oid:str}/create/haskell',
      type: 'lazy',
      module: () => import('../creation-tunnel/haskell/configure-haskell.sp.js'),
      name: 'CreationTunnelConfigureHaskellSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-haskell-onboarding',
      path: '/organisations/{oid:str}/haskell/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/haskell/onboarding-haskell.sp.js'),
      name: 'CreationTunnelOnboardingHaskellSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-java-gradle',
      path: '/organisations/{oid:str}/create/java-gradle',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-gradle/configure-java-gradle.sp.js'),
      name: 'CreationTunnelConfigureJavaGradleSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-java-gradle-onboarding',
      path: '/organisations/{oid:str}/java-gradle/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-gradle/onboarding-java-gradle.sp.js'),
      name: 'CreationTunnelOnboardingJavaGradleSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-java-jar',
      path: '/organisations/{oid:str}/create/java-jar',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-jar/configure-java-jar.sp.js'),
      name: 'CreationTunnelConfigureJavaJarSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-java-jar-onboarding',
      path: '/organisations/{oid:str}/java-jar/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-jar/onboarding-java-jar.sp.js'),
      name: 'CreationTunnelOnboardingJavaJarSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-java-maven',
      path: '/organisations/{oid:str}/create/java-maven',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-maven/configure-java-maven.sp.js'),
      name: 'CreationTunnelConfigureJavaMavenSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-java-maven-onboarding',
      path: '/organisations/{oid:str}/java-maven/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-maven/onboarding-java-maven.sp.js'),
      name: 'CreationTunnelOnboardingJavaMavenSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-java-play1',
      path: '/organisations/{oid:str}/create/java-play1',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-play1/configure-java-play1.sp.js'),
      name: 'CreationTunnelConfigureJavaPlay1SP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-java-play1-onboarding',
      path: '/organisations/{oid:str}/java-play1/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-play1/onboarding-java-play1.sp.js'),
      name: 'CreationTunnelOnboardingJavaPlay1SP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-java-play2',
      path: '/organisations/{oid:str}/create/java-play2',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-play2/configure-java-play2.sp.js'),
      name: 'CreationTunnelConfigureJavaPlay2SP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-java-play2-onboarding',
      path: '/organisations/{oid:str}/java-play2/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-play2/onboarding-java-play2.sp.js'),
      name: 'CreationTunnelOnboardingJavaPlay2SP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-java-war',
      path: '/organisations/{oid:str}/create/java-war',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-war/configure-java-war.sp.js'),
      name: 'CreationTunnelConfigureJavaWarSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-java-war-onboarding',
      path: '/organisations/{oid:str}/java-war/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/java-war/onboarding-java-war.sp.js'),
      name: 'CreationTunnelOnboardingJavaWarSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-jenkins',
      path: '/organisations/{oid:str}/create/jenkins',
      type: 'lazy',
      module: () => import('../creation-tunnel/jenkins/configure-jenkins.sp.js'),
      name: 'CreationTunnelConfigureJenkinsSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-jenkins-onboarding',
      path: '/organisations/{oid:str}/jenkins/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/jenkins/onboarding-jenkins.sp.js'),
      name: 'CreationTunnelOnboardingJenkinsSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-keycloak',
      path: '/organisations/{oid:str}/create/keycloak',
      type: 'lazy',
      module: () => import('../creation-tunnel/keycloak/configure-keycloak.sp.js'),
      name: 'CreationTunnelConfigureKeycloakSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-keycloak-onboarding',
      path: '/organisations/{oid:str}/keycloak/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/keycloak/onboarding-keycloak.sp.js'),
      name: 'CreationTunnelOnboardingKeycloakSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-kv',
      path: '/organisations/{oid:str}/create/kv',
      type: 'lazy',
      module: () => import('../creation-tunnel/kv/configure-kv.sp.js'),
      name: 'CreationTunnelConfigureKvSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-kv-onboarding',
      path: '/organisations/{oid:str}/kv/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/kv/onboarding-kv.sp.js'),
      name: 'CreationTunnelOnboardingKvSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-mailpace',
      path: '/organisations/{oid:str}/create/mailpace',
      type: 'lazy',
      module: () => import('../creation-tunnel/mailpace/configure-mailpace.sp.js'),
      name: 'CreationTunnelConfigureMailpaceSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-mailpace-onboarding',
      path: '/organisations/{oid:str}/mailpace/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/mailpace/onboarding-mailpace.sp.js'),
      name: 'CreationTunnelOnboardingMailpaceSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-matomo',
      path: '/organisations/{oid:str}/create/matomo',
      type: 'lazy',
      module: () => import('../creation-tunnel/matomo/configure-matomo.sp.js'),
      name: 'CreationTunnelConfigureMatomoSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-matomo-onboarding',
      path: '/organisations/{oid:str}/matomo/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/matomo/onboarding-matomo.sp.js'),
      name: 'CreationTunnelOnboardingMatomoSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-meteor',
      path: '/organisations/{oid:str}/create/meteor',
      type: 'lazy',
      module: () => import('../creation-tunnel/meteor/configure-meteor.sp.js'),
      name: 'CreationTunnelConfigureMeteorSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-meteor-onboarding',
      path: '/organisations/{oid:str}/meteor/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/meteor/onboarding-meteor.sp.js'),
      name: 'CreationTunnelOnboardingMeteorSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-mongodb',
      path: '/organisations/{oid:str}/create/mongodb',
      type: 'lazy',
      module: () => import('../creation-tunnel/mongodb/configure-mongodb.sp.js'),
      name: 'CreationTunnelConfigureMongodbSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-mongodb-onboarding',
      path: '/organisations/{oid:str}/mongodb/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/mongodb/onboarding-mongodb.sp.js'),
      name: 'CreationTunnelOnboardingMongodbSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-mysql',
      path: '/organisations/{oid:str}/create/mysql',
      type: 'lazy',
      module: () => import('../creation-tunnel/mysql/configure-mysql.sp.js'),
      name: 'CreationTunnelConfigureMysqlSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-mysql-onboarding',
      path: '/organisations/{oid:str}/mysql/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/mysql/onboarding-mysql.sp.js'),
      name: 'CreationTunnelOnboardingMysqlSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-node',
      path: '/organisations/{oid:str}/create/node',
      type: 'lazy',
      module: () => import('../creation-tunnel/node/configure-node.sp.js'),
      name: 'CreationTunnelConfigureNodeSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-node-onboarding',
      path: '/organisations/{oid:str}/node/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/node/onboarding-node.sp.js'),
      name: 'CreationTunnelOnboardingNodeSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-php',
      path: '/organisations/{oid:str}/create/php',
      type: 'lazy',
      module: () => import('../creation-tunnel/php/configure-php.sp.js'),
      name: 'CreationTunnelConfigurePhpSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-php-onboarding',
      path: '/organisations/{oid:str}/php/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/php/onboarding-php.sp.js'),
      name: 'CreationTunnelOnboardingPhpSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-postgresql',
      path: '/organisations/{oid:str}/create/postgresql',
      type: 'lazy',
      module: () => import('../creation-tunnel/postgresql/configure-postgresql.sp.js'),
      name: 'CreationTunnelConfigurePostgresqlSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-postgresql-onboarding',
      path: '/organisations/{oid:str}/postgresql/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/postgresql/onboarding-postgresql.sp.js'),
      name: 'CreationTunnelOnboardingPostgresqlSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-pulsar',
      path: '/organisations/{oid:str}/create/pulsar',
      type: 'lazy',
      module: () => import('../creation-tunnel/pulsar/configure-pulsar.sp.js'),
      name: 'CreationTunnelConfigurePulsarSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-pulsar-onboarding',
      path: '/organisations/{oid:str}/pulsar/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/pulsar/onboarding-pulsar.sp.js'),
      name: 'CreationTunnelOnboardingPulsarSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-python',
      path: '/organisations/{oid:str}/create/python',
      type: 'lazy',
      module: () => import('../creation-tunnel/python/configure-python.sp.js'),
      name: 'CreationTunnelConfigurePythonSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-python-onboarding',
      path: '/organisations/{oid:str}/python/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/python/onboarding-python.sp.js'),
      name: 'CreationTunnelOnboardingPythonSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-redis',
      path: '/organisations/{oid:str}/create/redis',
      type: 'lazy',
      module: () => import('../creation-tunnel/redis/configure-redis.sp.js'),
      name: 'CreationTunnelConfigureRedisSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-redis-onboarding',
      path: '/organisations/{oid:str}/redis/{addonId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/redis/onboarding-redis.sp.js'),
      name: 'CreationTunnelOnboardingRedisSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-ruby',
      path: '/organisations/{oid:str}/create/ruby',
      type: 'lazy',
      module: () => import('../creation-tunnel/ruby/configure-ruby.sp.js'),
      name: 'CreationTunnelConfigureRubySP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-ruby-onboarding',
      path: '/organisations/{oid:str}/ruby/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/ruby/onboarding-ruby.sp.js'),
      name: 'CreationTunnelOnboardingRubySP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-rust',
      path: '/organisations/{oid:str}/create/rust',
      type: 'lazy',
      module: () => import('../creation-tunnel/rust/configure-rust.sp.js'),
      name: 'CreationTunnelConfigureRustSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-rust-onboarding',
      path: '/organisations/{oid:str}/rust/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/rust/onboarding-rust.sp.js'),
      name: 'CreationTunnelOnboardingRustSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-scala',
      path: '/organisations/{oid:str}/create/scala',
      type: 'lazy',
      module: () => import('../creation-tunnel/scala/configure-scala.sp.js'),
      name: 'CreationTunnelConfigureScalaSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-scala-onboarding',
      path: '/organisations/{oid:str}/scala/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/scala/onboarding-scala.sp.js'),
      name: 'CreationTunnelOnboardingScalaSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-creation-tunnel-static',
      path: '/organisations/{oid:str}/create/static',
      type: 'lazy',
      module: () => import('../creation-tunnel/static/configure-static.sp.js'),
      name: 'CreationTunnelConfigureStaticSP',
      title: T('CREATION_TUNNEL_CONFIGURE'),
    },
    {
      id: 'orga-creation-tunnel-static-onboarding',
      path: '/organisations/{oid:str}/static/{appId:str}/onboarding',
      type: 'lazy',
      module: () => import('../creation-tunnel/static/onboarding-static.sp.js'),
      name: 'CreationTunnelOnboardingStaticSP',
      title: T('CREATION_TUNNEL_ONBOARDING'),
    },
    {
      id: 'orga-app-overview',
      path: '/organisations/{oid:str}/applications/{appId:str}',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-overview.sp.js'),
      name: 'AppOverviewSP',
      title: T('OVERVIEW'),
    },
    {
      id: 'orga-app-information',
      path: '/organisations/{oid:str}/applications/{appId:str}/information',
      type: 'static',
      module: require('../statepoints/AppSP.js'),
      name: 'AppSP',
      title: T('INFORMATION'),
    },
    {
      id: 'orga-app-settings',
      path: '/organisations/{oid:str}/applications/{appId:str}/settings',
      type: 'static',
      module: require('../statepoints/AppConfigurationSP.js'),
      name: 'AppConfigurationSP',
      title: T('SCALABILITY'),
    },
    {
      id: 'orga-app-domain-names',
      path: '/organisations/{oid:str}/applications/{appId:str}/vhosts',
      type: 'lazy',
      module: () => import('../statepoints-wc/domain-names.sp.js'),
      name: 'AppDomainNamesSP',
      title: T('DOMAIN_NAMES'),
    },
    {
      id: 'orga-app-tcp-redirections',
      path: '/organisations/{oid:str}/applications/{appId:str}/tcp-redirections',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-tcp-redirections.sp.js'),
      name: 'AppTcpRedirectionsSP',
      title: T('TCP_REDIRECTIONS'),
    },
    {
      id: 'orga-app-services-dependencies',
      path: '/organisations/{oid:str}/applications/{appId:str}/services-dependencies',
      type: 'static',
      module: require('../statepoints/AppServicesDependenciesSP.es6.js'),
      name: 'AppServicesDependenciesSP',
      title: T('SERVICES_DEPENDENCIES'),
    },
    {
      id: 'orga-app-env-vars',
      path: '/organisations/{oid:str}/applications/{appId:str}/variables',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-env-vars.sp.js'),
      name: 'AppEnvVariablesSP',
      title: T('ENVIRONMENT_VARS'),
    },
    {
      id: 'orga-app-exposed-vars',
      path: '/organisations/{oid:str}/applications/{appId:str}/exposed-variables',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-exposed-variables.sp.js'),
      name: 'AppExposedVariablesSP',
      title: T('EXPOSED_VARIABLES'),
    },
    {
      id: 'orga-app-logs',
      path: '/organisations/{oid:str}/applications/{appId:str}/logs',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-logs.sp.js'),
      name: 'AppLogsSP',
      title: T('LOGS'),
    },
    ACCESS_LOGS_ENABLED ? {
      id: 'orga-app-access-logs',
      path: '/organisations/{oid:str}/applications/{appId:str}/app-access-logs',
      type: 'lazy',
      module: () => import('../statepoints-wc/app-access-logs.sp.js'),
      name: 'AppAccessLogsSP',
      title: T('ACCESS_LOGS'),
    } : null,
    {
      id: 'orga-app-deployments',
      path: '/organisations/{oid:str}/applications/{appId:str}/deployments',
      type: 'static',
      module: require('../statepoints/AppDeploymentsSP.js'),
      name: 'AppDeploymentsSP',
      title: T('DEPLOYMENTS_ACTIVITY'),
    },
    {
      id: 'orga-app-metrics',
      path: '/organisations/{oid:str}/applications/{appId:str}/metrics',
      type: 'static',
      module: require('../statepoints/Metrics.es6.js'),
      name: 'MetricsSP',
      title: T('METRICS'),
    },
    {
      id: 'orga-app-metrics-type',
      path: '/organisations/{oid:str}/applications/{appId:str}/metrics/{type:str}',
      type: 'static',
      module: require('../statepoints/Metrics.es6.js'),
      name: 'MetricsSP',
      title: T('METRICS'),
    },
    {
      id: 'orga-addon-creation',
      path: '/organisations/{oid:str}/addons/new',
      type: 'static',
      module: require('../statepoints/AddonCreationSP.js'),
      name: 'AddonCreationSP',
      title: T('ADDON_CREATION'),
    },
    {
      id: 'orga-addon-overview',
      path: '/organisations/{oid:str}/addons/{addonId:str}',
      type: 'static',
      module: addonOverviewModule,
      name: 'AddonConfigurationSP',
      title: T('console.panes.addon-settings.dashboard'),
    },
    {
      id: 'orga-addon-backups',
      path: '/organisations/{oid:str}/addons/{addonId:str}/backups',
      type: 'lazy',
      module: () => import('../statepoints-wc/addon-backups.sp.js'),
      name: 'AddonBackupsSP',
      title: T('ADDON_BACKUPS'),
    },
    {
      id: 'orga-addon-information',
      path: '/organisations/{oid:str}/addons/{addonId:str}/informations',
      type: 'static',
      module: require('../statepoints/AddonSP.js'),
      name: 'AddonSP',
      title: T('ADDON_OVERVIEW'),
    },
    {
      id: 'orga-addon-services-dependencies',
      path: '/organisations/{oid:str}/addons/{addonId:str}/services-dependencies',
      type: 'lazy',
      module: () => import('../statepoints-wc/addon-service-dependencies.sp.js'),
      name: 'AddonServicesDependenciesSP',
      title: T('SERVICES_DEPENDENCIES'),
    },
    {
      id: 'orga-addon-migrations',
      path: '/organisations/{oid:str}/addons/{addonId:str}/migrations',
      type: 'static',
      module: require('../statepoints/AddonMigrationSP.es6.js'),
      name: 'AddonMigrationSP',
      title: T('ADDON_MIGRATION'),
    },
    {
      id: 'orga-addon-migration-details',
      path: '/organisations/{oid:str}/addons/{addonId:str}/migrations/{migrationId:str}',
      type: 'static',
      module: require('../statepoints/AddonMigrationSP.es6.js'),
      name: 'AddonMigrationSP',
      title: T('ADDON_MIGRATION'),
    },
    ADDON_LOGS_ENABLED
      ? {
        id: 'orga-addon-logs',
        path: '/organisations/{oid:str}/addons/{addonId:str}/logs',
        type: 'static',
        module: require('../statepoints/AddonLogsSP.es6.js'),
        name: 'LogsSP',
        title: T('LOGS'),
      }
      : null,
    {
      id: 'orga-addon-metrics',
      path: '/organisations/{oid:str}/addons/{addonId:str}/metrics',
      type: 'static',
      module: require('../statepoints/Metrics.es6.js'),
      name: 'MetricsSP',
      title: T('METRICS'),
    },
    {
      id: 'orga-addon-metrics-type',
      path: '/organisations/{oid:str}/addons/{addonId:str}/metrics/{type:str}',
      type: 'static',
      module: require('../statepoints/Metrics.es6.js'),
      name: 'MetricsSP',
      title: T('METRICS'),
    },
    {
      id: 'orga-addon-redis-explorer',
      path: '/organisations/{oid:str}/addons/{addonId:str}/redis-explorer',
      type: 'lazy',
      module: () => import('../statepoints-wc/addon-redis-explorer.js'),
      name: 'AddonRedisExplorerSP',
      title: T('console.panes.addon-settings.kv-explorer'),
    },
    {
      id: 'orga-addon-materia-explorer',
      path: '/organisations/{oid:str}/addons/{addonId:str}/materia-explorer',
      type: 'lazy',
      module:  () => import('../statepoints-wc/addon-materia-explorer.js'),
      name: 'AddonMateriaExplorerSP',
      title: T('console.panes.addon-settings.kv-explorer'),
    },
    {
      id: 'orga-provider-creation',
      path: '/organisations/{oid:str}/providers/new',
      type: 'static',
      module: require('../statepoints/AddonProviderCreationSP.es6.js'),
      name: 'AddonProviderCreationSP',
      title: T('console.panes.addons-providers.create'),
    },
    {
      id: 'orga-provider-details',
      path: '/organisations/{oid:str}/providers/{providerId:str}',
      type: 'static',
      module: require('../statepoints/AddonProviderSP.js'),
      name: 'AddonProviderSP',
      title: T('PROVIDER'),
    },
    {
      id: 'orga-provider-plans',
      path: '/organisations/{oid:str}/providers/{providerId:str}/plans',
      type: 'static',
      module: require('../statepoints/AddonProviderPlansSP.js'),
      name: 'AddonProviderPlansSP',
      title: T('PROVIDER_PLANS'),
    },
    {
      id: 'orga-invoices',
      path: '/organisations/{oid:str}/invoices',
      type: 'lazy',
      module: () => import('../statepoints-wc/invoice-list.sp.js'),
      name: 'BillsSP',
      title: T('INVOICES'),
    },
    {
      id: 'orga-invoice-details',
      path: '/organisations/{oid:str}/invoices/{bid:str}',
      type: 'static',
      module: require('../statepoints/BillSP.js'),
      name: 'BillSP',
      title: T('INVOICES'),
    },
    {
      id: 'orga-oauth-consumer-creation',
      path: '/organisations/{oid:str}/oauth-consumers/new',
      type: 'static',
      module: require('../statepoints/OauthConsumerCreationSP.es6.js'),
      name: 'OauthConsumerCreationSP',
      title: T('console.oauth-consumers.new-consumer'),
    },
    {
      id: 'orga-oauth-consumer-details',
      path: '/organisations/{oid:str}/oauth-consumers/{consumerKey:str}',
      type: 'static',
      module: require('../statepoints/OauthConsumerSP.es6.js'),
      name: 'OauthConsumerSP',
      title: T('OAUTH_CONSUMER_INFORMATION'),
    },
    {
      id: 'orga-oauth-consumer-edit',
      path: '/organisations/{oid:str}/oauth-consumers/{consumerKey:str}/edit',
      type: 'static',
      module: require('../statepoints/OauthConsumerEditSP.es6.js'),
      name: 'OauthConsumerEditSP',
      title: T('console.oauth-consumers.edit-consumer'),
    },
    // WARNING: if we name the param oid it gets removed by AbstractSP
    {
      id: 'orga-invoice-paypal-success',
      path: '/organisations/{ownerId:str}/invoices/{invoiceId:str}/paypal/success',
      type: 'static',
      module: require('../statepoints/PaypalSuccessSP.es6.js'),
      name: 'PaypalSuccessSP',
    },
    {
      id: 'orga-invoice-paypal-cancel',
      path: '/organisations/{ownerId:str}/invoices/{invoiceId:str}/paypal/cancel',
      type: 'static',
      module: require('../statepoints/PaypalCanceledSP.es6.js'),
      name: 'PaypalCanceledSP',
    },
  ].filter((route) => route != null);
};
