var Bacon = require('baconjs');
var _ = require('lodash');

module.exports = function initializeSummaryProxy(settings) {
  var SummaryProxy = {};

  var API = settings.API;

  var s_summary = null;
  var b_summary = new Bacon.Bus();

  // Get the summary via a HTTP call to the API
  var fetchSummary = function () {
    return API.summary
      .get()
      .send()
      .toEventStream()
      .map(function (summary) {
        return _.extend({}, summary, {
          organisations: _.filter(summary.organisations, matchOrgaId),
        });
      });
  };

  var matchOrgaId = function (orga) {
    return orga.id.indexOf('orga_') === 0;
  };

  var foldlOrgas = function (orgas) {
    return _.reduce(
      orgas,
      function (obj, orga) {
        obj[orga.id] = orga;
        return obj;
      },
      {},
    );
  };

  SummaryProxy.fetch = function (force) {
    if (!s_summary || force) {
      s_summary = SummaryProxy.getSummary().merge(b_summary).toProperty();
    }

    return s_summary;
  };

  SummaryProxy.getSummary = function () {
    return fetchSummary().map(function (summary) {
      return {
        user: summary.user,
        organisations: foldlOrgas(summary.organisations),
      };
    });
  };

  SummaryProxy.fetchOnce = function () {
    return SummaryProxy.fetch().take(1);
  };

  SummaryProxy.fetchUser = function () {
    return SummaryProxy.fetch().map(function (summary) {
      return summary.user;
    });
  };

  SummaryProxy.fetchUserOnce = function () {
    return SummaryProxy.fetchUser().take(1);
  };

  SummaryProxy.fetchOrgas = function () {
    return SummaryProxy.fetchOnce().map(function (summary) {
      return summary.organisations;
    });
  };

  SummaryProxy.fetchOrgasOnce = function () {
    return SummaryProxy.fetchOrgas().first();
  };

  SummaryProxy.fetchOrga = function (orgaId) {
    return SummaryProxy.fetch()
      .flatMapLatest(function (summary) {
        var owner = SummaryProxy.getOwner(summary, orgaId);

        if (owner) {
          return Bacon.once(owner);
        } else {
          // We couldn't find it in the summary. Clear it
          console.warn("Couldn't find owner. Force summary to fetch again");
          return SummaryProxy.fetch(true).flatMapLatest((summary) => {
            const owner = SummaryProxy.getOwner(summary, orgaId);
            // We still couldn't find the owner, something is going on. Log an error
            if (!owner) {
              var error = new Error("Couldn't find organisation " + orgaId + ' in summary');
              console.error(error);
              return Bacon.once(new Bacon.Error(error));
            } else {
              return owner;
            }
          });
        }
      })
      .toProperty();
  };

  SummaryProxy.fetchOrgaOnce = function (orgaId) {
    return SummaryProxy.fetchOrga(orgaId).first();
  };

  SummaryProxy.update = function () {
    var s_summary = SummaryProxy.getSummary();
    b_summary.plug(s_summary);
    return s_summary;
  };

  // In case we wan't to update manually the summary
  SummaryProxy.manualUpdate = function (newSummary) {
    b_summary.push(newSummary);
    return Bacon.once(newSummary);
  };

  SummaryProxy.updateInOrganisation = function (orgaId, part) {
    // hack while the API returns a user in organisations
    if (orgaId && orgaId.indexOf('user_') === 0) {
      orgaId = null;
    }

    var s_req = orgaId ? API.organisations._[part].get().withParams([orgaId]).send() : API.self[part].get().send();

    var s_updatedSummary = Bacon.combineWith(SummaryProxy.fetchOnce(), s_req, function (summary, res) {
      if (orgaId) {
        // Should never happen since even the user is returned
        // in the summary's organisations
        if (!summary.organisations) {
          summary.organisations = {};
        }

        if (!summary.organisations[orgaId]) {
          summary.organisations[orgaId] = {};
        }

        if (part === 'addonproviders') {
          summary.organisations[orgaId]['providers'] = res;
        } else {
          summary.organisations[orgaId][part] = res;
        }
      } else {
        summary.user[part] = res;
      }
      return summary;
    });

    b_summary.plug(s_updatedSummary);

    return s_req;
  };

  SummaryProxy.updateApplications = function (orgaId) {
    return SummaryProxy.updateInOrganisation(orgaId, 'applications');
  };

  SummaryProxy.updateAddons = function (orgaId) {
    return SummaryProxy.updateInOrganisation(orgaId, 'addons');
  };

  SummaryProxy.updateProviders = function (orgaId) {
    return SummaryProxy.updateInOrganisation(orgaId, 'addonproviders');
  };

  SummaryProxy.updateConsumers = function (orgaId) {
    return SummaryProxy.updateInOrganisation(orgaId, 'consumers');
  };

  SummaryProxy.updateOrganisation = function (orgaId) {
    const isOrga = orgaId != null && orgaId.startsWith('orga_');
    var s_orga = isOrga ? API.organisations._.get().withParams([orgaId]).send() : API.self.get().send();

    var s_updatedSummary = Bacon.combineWith(SummaryProxy.fetchOnce(), s_orga, function (summary, orga) {
      if (!isOrga) {
        summary.user = _.extend({}, summary.user, orga);
      } else {
        summary.organisations[orgaId] = _.extend({}, summary.organisations[orgaId], orga);
      }
      return summary;
    });

    b_summary.plug(s_updatedSummary);

    return s_orga;
  };

  SummaryProxy.updateOrganisations = function () {
    var s_orgas = SummaryProxy.fetchUserOnce()
      .map('.id')
      .flatMapLatest(function (userId) {
        return API.organisations.get().withQuery({ user: userId }).send();
      })
      .map(function (organisations) {
        return _.filter(organisations, matchOrgaId);
      });

    var s_updatedSummary = Bacon.combineWith(SummaryProxy.fetchOnce(), s_orgas, function (summary, orgas) {
      var updatedOrgas = _.mapValues(foldlOrgas(orgas), function (orga) {
        return _.extend({}, summary.organisations[orga.id], orga);
      });

      return {
        user: summary.user,
        organisations: updatedOrgas,
      };
    });

    b_summary.plug(s_updatedSummary);
    return s_orgas;
  };

  SummaryProxy.addOrganisation = function (orgaId) {
    return SummaryProxy.updateOrganisations()
      .delay(100) // Bus update
      .flatMapLatest(SummaryProxy.fetchOnce)
      .combine(API.organisations._.members.get().withParams([orgaId]).send(), function (summary, members) {
        summary.organisations[orgaId].role = _.find(members, function (member) {
          return member.member.id === summary.user.id;
        }).role;

        return summary;
      })
      .flatMapLatest(SummaryProxy.manualUpdate)
      .flatMapLatest(_.partial(SummaryProxy.updateAddons, orgaId))
      .flatMapLatest(_.partial(SummaryProxy.updateConsumers, orgaId))
      .flatMapLatest(_.partial(SummaryProxy.updateProviders, orgaId))
      .flatMapLatest(_.partial(SummaryProxy.updateApplications, orgaId))
      .flatMapLatest(SummaryProxy.fetchOrgasOnce);
  };

  SummaryProxy.getOwner = function (summary, orgaId) {
    return !orgaId || orgaId.indexOf('user_') === 0 ? summary.user : summary.organisations[orgaId];
  };

  return SummaryProxy;
};
