const { getPaymentMethods } = require('@clevercloud/client/esm/api/v4/billing.js');
const { getAllOrgaPaymentMethodsErrors } = require('@clevercloud/client/esm/utils/payment.js');
const { sendToApi } = require('../send-to-api.js');
require('@clevercloud/components/dist/cc-article-list.smart.js');
require('@clevercloud/components/dist/cc-doc-list.js');
require('@clevercloud/components/dist/cc-expand.js');
require('@clevercloud/components/dist/cc-warning-payment.js');
const { WARNING_PAYMENT_ENABLED, HOMEPAGE_HEADING_CONSOLE_NAME } = require('../configuration.js');

const VARIANTS = {
  en: [
    {
      heading: 'JavaScript',
      link: `${Console.DOC_BASE_URL}doc/applications/javascript/`,
      description:
        'Deploy your JavaScript/TypeScript and Meteor applications on Node.js with automatic dependency management (via npm ou yarn).',
      icons: ['nodejs', 'meteor'],
    },
    {
      heading: 'PHP & static sites',
      link: `${Console.DOC_BASE_URL}doc/applications/php/`,
      description:
        'Deploy your PHP applications and static sites on Apache2 + PHP-FPM with automatic dependency management (via composer).',
      icons: ['php', 'apache'],
    },
    {
      heading: 'Java, Scala...',
      link: `${Console.DOC_BASE_URL}doc/applications/java/`,
      description:
        'Deploy your JVM based applications (Java, Scala, GraalVM...) with automatic build and dependency management (Maven, Gradle, SBT, Ant...).',
      icons: ['java-jar', 'scala'],
    },
    {
      heading: 'Python',
      link: `${Console.DOC_BASE_URL}doc/applications/python/`,
      description:
        'Deploy your Python and Django applications on Nginx + WSGI with automatic dependency management (via pip) and Celery tasks support.',
      icons: ['python'],
    },
    {
      heading: 'Ruby',
      link: `${Console.DOC_BASE_URL}doc/applications/ruby/`,
      description:
        'Deploy your Ruby applications on Nginx + Puma with automatic dependency management (via Rake, gem...) and Sidekiq tasks support.',
      icons: ['ruby'],
    },
    {
      heading: 'Go',
      link: `${Console.DOC_BASE_URL}doc/applications/golang/`,
      description: 'Deploy your Go applications with automatic dependency management (via go mod, go build...).',
      icons: ['go'],
    },
    {
      heading: 'Rust',
      link: `${Console.DOC_BASE_URL}doc/applications/rust/`,
      description: 'Deploy your Rust applications with automatic dependency management (via Cargo).',
      icons: ['rust'],
    },
    {
      heading: 'Elixir',
      link: `${Console.DOC_BASE_URL}doc/applications/elixir/`,
      description: 'Deploy your Elixir/Phoenix applications with automatic dependency management (via Mix).',
      icons: ['elixir'],
    },
    {
      heading: 'Haskell',
      link: `${Console.DOC_BASE_URL}doc/applications/haskell/`,
      description: 'Deploy your Haskell applications with automatic dependency management (via Stack).',
      icons: ['haskell'],
    },
    {
      heading: '.NET core',
      link: `${Console.DOC_BASE_URL}doc/applications/dotnet/`,
      description: 'Deploy your .NET core applications with automatic dependency management.',
      icons: ['dotnet'],
    },
  ],
  fr: [
    {
      heading: 'JavaScript',
      link: `${Console.DOC_BASE_URL}doc/applications/javascript/`,
      description:
        'Déployez vos applications JavaScript, TypeScript et Meteor sur Node.js avec gestion automatique des dépendances (via npm ou yarn).',
      icons: ['nodejs', 'meteor'],
    },
    {
      heading: 'PHP & sites statiques',
      link: `${Console.DOC_BASE_URL}doc/applications/php/`,
      description:
        'Déployez vos applications PHP ou vos sites statiques sur Apache2 + PHP-FPM avec gestion automatique des dépendances (via composer).',
      icons: ['php', 'apache'],
    },
    {
      heading: 'Java, Scala...',
      link: `${Console.DOC_BASE_URL}doc/applications/java/`,
      description:
        'Déployez vos applications basées sur la JVM (Java, Scala ou GraalVM...) avec gestion automatique du build et des dépendances (Maven, Gradle, SBT, Ant...).',
      icons: ['java-jar', 'scala'],
    },
    {
      heading: 'Python',
      link: `${Console.DOC_BASE_URL}doc/applications/python/`,
      description:
        'Déployez vos applications Python et Django sur Nginx + WSGI avec gestion automatique des dépendances (via pip) et des tâches Celery.',
      icons: ['python'],
    },
    {
      heading: 'Ruby',
      link: `${Console.DOC_BASE_URL}doc/applications/ruby/`,
      description:
        'Déployez vos applications Ruby sur Nginx + Puma avec gestion automatique du build, des dépendances (via Rake, gem...) et des tâches Sidekiq.',
      icons: ['ruby'],
    },
    {
      heading: 'Go',
      link: `${Console.DOC_BASE_URL}doc/applications/golang/`,
      description:
        'Déployez vos applications Go avec gestion automatique du build et des dépendances (via go mod, go build...).',
      icons: ['go'],
    },
    {
      heading: 'Rust',
      link: `${Console.DOC_BASE_URL}doc/applications/rust/`,
      description: 'Déployez vos applications Rust avec gestion automatique du build et des dépendances (via Cargo).',
      icons: ['rust'],
    },
    {
      heading: 'Elixir',
      link: `${Console.DOC_BASE_URL}doc/applications/elixir/`,
      description:
        'Déployez vos applications Elixir/Phoenix avec gestion automatique du build et des dépendances (via Mix).',
      icons: ['elixir'],
    },
    {
      heading: 'Haskell',
      link: `${Console.DOC_BASE_URL}doc/applications/haskell/`,
      description:
        'Déployez vos applications Haskell avec gestion automatique du build et des dépendances (via Stack).',
      icons: ['haskell'],
    },
    {
      heading: '.NET core',
      link: `${Console.DOC_BASE_URL}doc/applications/dotnet/`,
      description: 'Déployez vos applications .NET core avec gestion automatique du build et des dépendances.',
      icons: ['dotnet'],
    },
  ],
};

module.exports = (function () {
  var sp = new (require('./AbstractSP.js'))({
    name: 'HomeSP',
  });

  var Bacon = require('baconjs');

  sp.on('onload', function (req, $container) {
    sp.displayHome($container);
  });

  sp.displayHome = function ($container, user) {
    var s_user = (user ? Bacon.once(user) : API.self.get().send()).toProperty();
    var s_orgas = SummaryProxy.fetchOrgasOnce();
    const mainHeading = $container.find('#homepage-heading-console-name');
    mainHeading.html(T('WELCOME_HOMEPAGE_DYNAMIC', { consoleName: HOMEPAGE_HEADING_CONSOLE_NAME }));

    Bacon.onValues(s_user, s_orgas, function (user, orgas) {
      const ownerList = [user, ...Object.values(orgas)];

      Promise.all(
        ownerList.map(async (orga) => {
          const paymentMethods = await getPaymentMethods({ id: orga.id }).then(sendToApi);
          return { orga, paymentMethods };
        }),
      ).then(([personal, ...otherOrgaList]) => {
        const errors = getAllOrgaPaymentMethodsErrors(personal, otherOrgaList).map((err) => {
          return {
            type: err.type,
            orgaName: err.orga.name,
            orgaBillingLink: `/organisations/${err.orga.id}/payment`,
          };
        });
        if (errors.length > 0 && WARNING_PAYMENT_ENABLED) {
          $container
            .find('.home-payment-warning')
            .html(
              `
                <cc-warning-payment mode="home" errors="[]"></cc-warning-payment>
              `,
            )
            .find('cc-warning-payment')
            .prop('errors', errors);
        }
      });

      if (!user.emailValidated) {
        sp.displayEmailValidationWarning($container, user);
      }

      const lang = (user.lang ?? 'EN').toLowerCase();

      $container.find('cc-doc-list')[0].state = {
        type: 'loaded',
        docs: VARIANTS[lang].map((variant) => {
          return {
            ...variant,
            icons: variant.icons.map((icon) => `https://assets.clever-cloud.com/logos/${icon}.svg`),
          };
        }),
      };

      $container.find('#container-for-article-list').prop('context', { lang, limit: 10 });
    });
  };

  sp.displayEmailValidationWarning = function ($container, user) {
    $container.find('.email-validation').remove();

    var $alert = $container.prepend(
      Templates['HomeSP.email-validation']({
        user: user,
      }),
    );

    $alert.find('button.resend').click(function () {
      var s_confirm = API.self.confirmation_email.get().send();
      s_confirm.onValue($Notification.displaySuccess);
      s_confirm.onError($Notification.displayError);
    });
  };

  return sp;
})();
