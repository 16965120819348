const { logout, login } = require('../login-oauth.js');
const { UNAUTHORIZED_BEHAVIOR } = require('../configuration.js');

function handleUnauthorized(error) {
  if (error.response && error.response.status === 401) {
    if (UNAUTHORIZED_BEHAVIOR === 'console-error') {
      console.error('UNAUTHORIZED ERROR', error);
    } else {
      logout();
      login();
    }
  }
}

export function onApiError(error) {
  handleUnauthorized(error);
  throw error;
}
