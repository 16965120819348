var _ = require('lodash');

var QueryString = (module.exports = (function () {
  var qs = {};

  qs.parse = function (string, decode) {
    string = string[0] === '?' ? string.slice(1) : string;
    return _.reduce(
      string.split('&'),
      function (parsed, pair) {
        var keySplitted = pair.split('=')[0];
        var valueSplitted = pair.split('=')[1];
        var key = decode ? decodeURIComponent(keySplitted) : keySplitted;
        var value = decode ? decodeURIComponent(valueSplitted) : valueSplitted;
        var isArray = false;

        // if it ends with [], then it's an array
        if (key.match(/\[\]$/) !== null) {
          key = key.replace(/\[\]$/, '');
          isArray = true;
        }

        if (parsed[key]) {
          if (typeof parsed[key] === 'object') {
            parsed[key].push(value);
          } else {
            parsed[key] = [parsed[key], value];
          }
        } else if (key) {
          parsed[key] = isArray ? [value] : value;
        }

        return parsed;
      },
      {},
    );
  };

  qs.stringify = function (object) {
    return _.map(object, function (value, key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }).join('&');
  };

  qs.removeOAuthParameters = function (string) {
    var params = qs.parse(string);

    var filteredParams = _.omitBy(params, function (value, key) {
      return key.indexOf('oauth_') === 0 || key.indexOf('user_oauth_') === 0;
    });

    return qs.stringify(filteredParams);
  };

  return qs;
})());
