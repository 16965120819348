const path4js = require('path4js');
const Yajas = require('yajas');
const { authorizePaypalPayment } = require('@clevercloud/client/esm/api/v4/billing.js');
const { sendToApi } = require('../send-to-api.js');

module.exports = (() => {
  const sp = new (require('./AbstractSP.js'))({
    name: 'PaypalSuccessSP',
  });

  sp.on('onload', (req, $container) => {
    const ownerId = req.params.ownerId;
    const invoiceNumber = req.params.invoiceId;
    const payerId = req.search.PayerID;

    const params = { id: ownerId, invoiceNumber, payerId };
    const s_pay = Bacon.fromPromise(authorizePaypalPayment(params).then(sendToApi));

    s_pay.onValue((invoice) => {
      const redirectTo = ownerId.indexOf('orga_') === 0 ? `/organisations/${ownerId}/invoices` : `/users/me/invoices`;

      Yajas.path4js.launchPath(path4js.Request.fromUri(redirectTo));
    });

    s_pay.onError((err) => {
      $Notification.displayError(err);
      $container.find('.alert:not(.hidden), .logs').hide();
      $container.find('.alert.hidden').removeClass('hidden').text(err.message);
    });
  });

  return sp;
})();
