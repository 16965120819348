const Yajas = require('yajas');
const { ErrorStatePoint, Request } = require('path4js');
const { getLazyStatepoint } = require('../statepoints-wc/lazy-statepoint.js');
const { getFeature } = require('../helpers/featureFlag.js');

Yajas.addEventListener('paths.onload', function (path4js) {
  // We cannot put this require at the top for now because of HomeSP and DOC_BASE_URL
  const routes = require('./routes-mapping.js').getRoutes();
  for (const route of routes) {
    const statepoint =
      route.type === 'lazy' ? getLazyStatepoint(route.module, route.name, route.title, route.id) : route.module;

    // We have to do that as destructuring makes path4js crash
    if (route.type === 'static') {
      statepoint.title = route.title;
      statepoint.name = route.name;
      statepoint.id = route.id;
    }
    path4js.addPath(route.path, statepoint);
  }

  // Expose an event based API to redirect to orga user or orga home (decoupling FTW)
  window.addEventListener('cc-redirect', ({ detail }) => {
    // default headers
    const headers = { Accept: '*/*' };

    const { type } = detail;

    if (type === 'orga-home') {
      const path = path4js.pathResolver.computePath({ uri: location.pathname, headers });
      const uri = path.params.oid != null ? '/organisations/' + path.params.oid : '/users/me';
      Yajas.path4js.launchPath({ uri, headers });
    }
  });
});

Yajas.addEventListener('errors.onload', function (path4js) {
  const ErrorSP = new ErrorStatePoint({
    codes: ['4**', '5**'],
  });

  ErrorSP.addEventListener('onload', function (e) {
    e.request.uri = '/';
    Yajas.path4js.launchPath(Request.fromUri('/'));
  });

  path4js.addError(ErrorSP);
});
