export default {
  AF: 'AFGHANISTAN',
  AL: 'ALBANIA',
  DZ: 'ALGERIA',
  AS: 'AMERICAN SAMOA',
  AD: 'ANDORRA',
  AO: 'ANGOLA',
  AI: 'ANGUILLA',
  AQ: 'ANTARCTICA',
  AG: 'ANTIGUA & BARBUDA',
  AR: 'ARGENTINA',
  AM: 'ARMENIA',
  AW: 'ARUBA',
  AU: 'AUSTRALIA',
  AT: 'AUSTRIA',
  AZ: 'AZERBAIJAN',
  BS: 'BAHAMAS',
  BH: 'BAHRAIN',
  BD: 'BANGLADESH',
  BB: 'BARBADOS',
  BY: 'BELARUS',
  BE: 'BELGIUM',
  BZ: 'BELIZE',
  BJ: 'BENIN',
  BM: 'BERMUDA',
  BT: 'BHUTAN',
  BO: 'BOLIVIA',
  BA: 'BOSNIA & HERZEGOVINA',
  BW: 'BOTSWANA',
  BV: 'BOUVET ISLAND',
  BR: 'BRAZIL',
  IO: 'BRITISH INDIAN OCEAN TERRITORY',
  VG: 'BRITISH VIRGIN ISLANDS',
  BN: 'BRUNEI',
  BG: 'BULGARIA',
  BF: 'BURKINA FASO',
  BI: 'BURUNDI',
  KH: 'CAMBODIA',
  CM: 'CAMEROON',
  CA: 'CANADA',
  CV: 'CAPE VERDE',
  BQ: 'CARIBBEAN NETHERLANDS',
  KY: 'CAYMAN ISLANDS',
  CF: 'CENTRAL AFRICAN REPUBLIC',
  TD: 'CHAD',
  CL: 'CHILE',
  CN: 'CHINA',
  CX: 'CHRISTMAS ISLAND',
  CC: 'COCOS (KEELING) ISLANDS',
  CO: 'COLOMBIA',
  KM: 'COMOROS',
  CG: 'CONGO - BRAZZAVILLE',
  CD: 'CONGO - KINSHASA',
  CK: 'COOK ISLANDS',
  CR: 'COSTA RICA',
  HR: 'CROATIA',
  CU: 'CUBA',
  CW: 'CURAÇAO',
  CY: 'CYPRUS',
  CZ: 'CZECHIA',
  CI: 'CÔTE D’IVOIRE',
  DK: 'DENMARK',
  DJ: 'DJIBOUTI',
  DM: 'DOMINICA',
  DO: 'DOMINICAN REPUBLIC',
  EC: 'ECUADOR',
  EG: 'EGYPT',
  SV: 'EL SALVADOR',
  GQ: 'EQUATORIAL GUINEA',
  ER: 'ERITREA',
  EE: 'ESTONIA',
  SZ: 'ESWATINI',
  ET: 'ETHIOPIA',
  FK: 'FALKLAND ISLANDS',
  FO: 'FAROE ISLANDS',
  FJ: 'FIJI',
  FI: 'FINLAND',
  FR: 'FRANCE',
  GF: 'FRENCH GUIANA',
  PF: 'FRENCH POLYNESIA',
  TF: 'FRENCH SOUTHERN TERRITORIES',
  GA: 'GABON',
  GM: 'GAMBIA',
  GE: 'GEORGIA',
  DE: 'GERMANY',
  GH: 'GHANA',
  GI: 'GIBRALTAR',
  GR: 'GREECE',
  GL: 'GREENLAND',
  GD: 'GRENADA',
  GP: 'GUADELOUPE',
  GU: 'GUAM',
  GT: 'GUATEMALA',
  GG: 'GUERNSEY',
  GN: 'GUINEA',
  GW: 'GUINEA-BISSAU',
  GY: 'GUYANA',
  HT: 'HAITI',
  HM: 'HEARD & MCDONALD ISLANDS',
  HN: 'HONDURAS',
  HK: 'HONG KONG SAR CHINA',
  HU: 'HUNGARY',
  IS: 'ICELAND',
  IN: 'INDIA',
  ID: 'INDONESIA',
  IR: 'IRAN',
  IQ: 'IRAQ',
  IE: 'IRELAND',
  IM: 'ISLE OF MAN',
  IL: 'ISRAEL',
  IT: 'ITALY',
  JM: 'JAMAICA',
  JP: 'JAPAN',
  JE: 'JERSEY',
  JO: 'JORDAN',
  KZ: 'KAZAKHSTAN',
  KE: 'KENYA',
  KI: 'KIRIBATI',
  KW: 'KUWAIT',
  KG: 'KYRGYZSTAN',
  LA: 'LAOS',
  LV: 'LATVIA',
  LB: 'LEBANON',
  LS: 'LESOTHO',
  LR: 'LIBERIA',
  LY: 'LIBYA',
  LI: 'LIECHTENSTEIN',
  LT: 'LITHUANIA',
  LU: 'LUXEMBOURG',
  MO: 'MACAO SAR CHINA',
  MG: 'MADAGASCAR',
  MW: 'MALAWI',
  MY: 'MALAYSIA',
  MV: 'MALDIVES',
  ML: 'MALI',
  MT: 'MALTA',
  MH: 'MARSHALL ISLANDS',
  MQ: 'MARTINIQUE',
  MR: 'MAURITANIA',
  MU: 'MAURITIUS',
  YT: 'MAYOTTE',
  MX: 'MEXICO',
  FM: 'MICRONESIA',
  MD: 'MOLDOVA',
  MC: 'MONACO',
  MN: 'MONGOLIA',
  ME: 'MONTENEGRO',
  MS: 'MONTSERRAT',
  MA: 'MOROCCO',
  MZ: 'MOZAMBIQUE',
  MM: 'MYANMAR (BURMA)',
  NA: 'NAMIBIA',
  NR: 'NAURU',
  NP: 'NEPAL',
  NL: 'NETHERLANDS',
  NC: 'NEW CALEDONIA',
  NZ: 'NEW ZEALAND',
  NI: 'NICARAGUA',
  NE: 'NIGER',
  NG: 'NIGERIA',
  NU: 'NIUE',
  NF: 'NORFOLK ISLAND',
  KP: 'NORTH KOREA',
  MK: 'NORTH MACEDONIA',
  MP: 'NORTHERN MARIANA ISLANDS',
  NO: 'NORWAY',
  OM: 'OMAN',
  PK: 'PAKISTAN',
  PW: 'PALAU',
  PS: 'PALESTINIAN TERRITORIES',
  PA: 'PANAMA',
  PG: 'PAPUA NEW GUINEA',
  PY: 'PARAGUAY',
  PE: 'PERU',
  PH: 'PHILIPPINES',
  PN: 'PITCAIRN ISLANDS',
  PL: 'POLAND',
  PT: 'PORTUGAL',
  PR: 'PUERTO RICO',
  QA: 'QATAR',
  RO: 'ROMANIA',
  RU: 'RUSSIA',
  RW: 'RWANDA',
  RE: 'RÉUNION',
  WS: 'SAMOA',
  SM: 'SAN MARINO',
  SA: 'SAUDI ARABIA',
  SN: 'SENEGAL',
  RS: 'SERBIA',
  SC: 'SEYCHELLES',
  SL: 'SIERRA LEONE',
  SG: 'SINGAPORE',
  SX: 'SINT MAARTEN',
  SK: 'SLOVAKIA',
  SI: 'SLOVENIA',
  SB: 'SOLOMON ISLANDS',
  SO: 'SOMALIA',
  ZA: 'SOUTH AFRICA',
  GS: 'SOUTH GEORGIA & SOUTH SANDWICH ISLANDS',
  KR: 'SOUTH KOREA',
  SS: 'SOUTH SUDAN',
  ES: 'SPAIN',
  LK: 'SRI LANKA',
  BL: 'ST. BARTHÉLEMY',
  SH: 'ST. HELENA',
  KN: 'ST. KITTS & NEVIS',
  LC: 'ST. LUCIA',
  MF: 'ST. MARTIN',
  PM: 'ST. PIERRE & MIQUELON',
  VC: 'ST. VINCENT & GRENADINES',
  SD: 'SUDAN',
  SR: 'SURINAME',
  SJ: 'SVALBARD & JAN MAYEN',
  SE: 'SWEDEN',
  CH: 'SWITZERLAND',
  SY: 'SYRIA',
  ST: 'SÃO TOMÉ & PRÍNCIPE',
  TW: 'TAIWAN',
  TJ: 'TAJIKISTAN',
  TZ: 'TANZANIA',
  TH: 'THAILAND',
  TL: 'TIMOR-LESTE',
  TG: 'TOGO',
  TK: 'TOKELAU',
  TO: 'TONGA',
  TT: 'TRINIDAD & TOBAGO',
  TN: 'TUNISIA',
  TR: 'TURKEY',
  TM: 'TURKMENISTAN',
  TC: 'TURKS & CAICOS ISLANDS',
  TV: 'TUVALU',
  UM: 'U.S. OUTLYING ISLANDS',
  VI: 'U.S. VIRGIN ISLANDS',
  UG: 'UGANDA',
  UA: 'UKRAINE',
  AE: 'UNITED ARAB EMIRATES',
  GB: 'UNITED KINGDOM',
  US: 'UNITED STATES',
  UY: 'URUGUAY',
  UZ: 'UZBEKISTAN',
  VU: 'VANUATU',
  VA: 'VATICAN CITY',
  VE: 'VENEZUELA',
  VN: 'VIETNAM',
  WF: 'WALLIS & FUTUNA',
  EH: 'WESTERN SAHARA',
  YE: 'YEMEN',
  ZM: 'ZAMBIA',
  ZW: 'ZIMBABWE',
  AX: 'ÅLAND ISLANDS',
};
