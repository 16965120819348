module.exports = (function () {
  var Role = {};

  var admin = 'ADMIN';
  var manager = 'MANAGER';
  var developer = 'DEVELOPER';
  var accounting = 'ACCOUNTING';

  Role.canAccessBills = function (role) {
    return [admin, accounting].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canAccessRepositories = function (role) {
    return [admin, manager, developer].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canAddApp = function (role) {
    return [admin, manager, developer].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canAddAddon = function (role) {
    return [admin, manager].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canAddMember = function (role) {
    return [admin, manager].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canDeleteOrga = function (role) {
    return [admin].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canEditOrga = function (role) {
    return [admin, manager].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canRemoveApp = function (role) {
    return [admin, manager].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canRemoveMember = function (role) {
    return [admin, manager].indexOf(role.toUpperCase()) >= 0;
  };

  Role.canEditMember = function (role) {
    return [admin, manager].indexOf(role.toUpperCase()) >= 0;
  };

  return Role;
})();
