import '@clevercloud/components/dist/cc-loader.js';
import AddonConfigurationSP from '../statepoints/AddonConfigurationSP.js';
import path4js, { StatePoint } from 'path4js';
import Yajas from 'yajas';
import { ADDONS_IN_CONSOLE_DASHBOARD } from '../etc/addon.js';
import { get as getAddon } from '@clevercloud/client/esm/api/v2/addon.js';
import { getLazyStatepoint } from './lazy-statepoint.js';
import { sendToApi } from '../send-to-api.js';

class AddonOverviewStatepoint extends StatePoint {
  constructor() {
    super();
    this.name = 'AddonConfigurationSP';
    this.title = 'console.panes.addon-settings.dashboard';
  }

  launch(req) {
    // Classic setup for a lazy statepoint
    const $main = document.querySelector('main');
    this.selector = $main;
    $main.innerHTML = `
      <header class="headbar"></header>
      <div class="lazy-statepoint">
        <cc-loader style="position: absolute;left: 0;width: 100%;top: 0;height: 100%"></cc-loader>
      </div>
    `;

    // What should we load?
    // * the new WC based addon overview
    // * or the legacy iframe based addon configuration
    const { oid, addonId } = req.params;
    getProvider(oid, addonId)
      .then((addon) => {
        const providerId = addon.providerId || addon.provider.id;

        let statepoint;
        if (ADDONS_IN_CONSOLE_DASHBOARD.includes(providerId)) {
          const name = 'AddonConfigurationSP';
          const title = 'console.panes.addon-settings.dashboard';
          switch (providerId) {
            case 'addon-pulsar':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-pulsar.sp.js'),
                name,
                title,
              );
              break;
            case 'es-addon':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-elasticsearch.sp.js'),
                name,
                title,
              );
              break;
            case 'jenkins':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-jenkins.sp.js'),
                name,
                title,
              );
              break;
            case 'config-provider':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-config-provider.sp.js'),
                name,
                title,
              );
              break;
            case 'addon-matomo':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-matomo.sp.js'),
                name,
                title,
              );
              break;
            case 'kv':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-materia-kv.sp.js'),
                name,
                title,
              );
              break;
            case 'keycloak':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-keycloak.sp.js'),
                name,
                title,
              );
              break;
            case 'metabase':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-metabase.sp.js'),
                name,
                title,
              );
              break;
            case 'otoroshi':
              statepoint = getLazyStatepoint(
                () => import('../statepoints-wc/addon-overview-otoroshi.sp.js'),
                name,
                title,
              );
              break;
            default:
              throw new Error("Your add-on doesn't support in-console dashboard");
          }
        } else {
          statepoint = AddonConfigurationSP;
        }

        const s_unload = Bacon.fromEvent(this, 'onunload').first();

        // Trigger the statepoint that should be loaded
        statepoint.launch(req);

        // This will trigger the right stuffs for the menu and headbar
        this.fireEvent('onload', req, $main, { s_unload });

        // The will clean up stuffs correctly
        s_unload.onValue(() => statepoint.fireEvent('onunload'));
      })
      .catch((e) => {
        redirectToOrga(oid);
        // TODO redirect to orga with notif
        console.log('addon cannot be found');
        console.error(e);
      });
  }
}

async function getProvider(oid, addonId) {
  const owner = await Console.SummaryProxy.fetchOrga(oid).firstToPromise();
  const addon = owner.addons.find((addon) => addon.id === addonId);
  if (addon != null) {
    return addon;
  }
  return getAddon({ id: owner.id, addonId }).then(sendToApi);
}

export function init() {
  const sp = new AddonOverviewStatepoint();
  return sp;
}

function redirectToOrga(ownerId = '') {
  const newPath = ownerId.startsWith('orga_')
    ? path4js.Request.fromUri('/organisations/' + ownerId)
    : path4js.Request.fromUri('/users/me');
  Yajas.path4js.launchPath(newPath);
}
