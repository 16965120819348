module.exports = (() => {
  const Bacon = require('baconjs');
  const $ = require('jquery');
  const _ = require('lodash');
  const $Panes = require('../modules/panes/main.js');
  const path4js = require('path4js');
  const Yajas = require('yajas');

  let $Metrics;
  let $Advanced;
  let $Custom;
  let $DomEvents;
  let AddonsHelper;

  const T = require('../models/technical/translation.js');

  const { sendToApi } = require('../send-to-api.js');
  const { getToken: getMetricsToken } = require('@clevercloud/client/esm/api/v2/metrics.js');

  const sp = new (require('./AbstractSP.js'))({
    name: 'MetricsSP',
  });

  sp.getStreams = (req) => {
    const { oid } = req.params;
    let s_sourceType, s_resource, s_instances;

    const s_ownerId = oid ? Bacon.constant(oid) : Console.SummaryProxy.fetchUserOnce().map('.id');

    if (req.params.appId) {
      s_sourceType = Bacon.constant('application');
      s_instances = Console.InstanceProxy.fetchAll(req.params.appId, oid)
        .map((instances) => {
          return _.filter(
            instances,
            (i) => i.state === 'UP' || i.state === 'DEPLOYING' || i.state === 'TASK_IN_PROGRESS',
          );
        })
        .takeUntil(Console.s_requestUnload)
        .skipDuplicates(_.isEqual)
        .toProperty();

      s_resource = s_ownerId.flatMapLatest((ownerId) => {
        return API.organisations._.applications._.get()
          .withParams([ownerId, req.params.appId])
          .send()
          .map((app) => {
            app.instance.type = app.instance.type.replace(/^ml_/, '');
            return app;
          });
      });
    } else if (req.params.addonId) {
      s_sourceType = Bacon.constant('addon');

      s_instances = s_ownerId.flatMapLatest((ownerId) => {
        return API.organisations._.addons._.instances
          .get()
          .withParams([ownerId, req.params.addonId])
          .send()
          .map((instances) => {
            return _.filter(instances, (i) => i.state === 'UP');
          });
      });

      s_resource = Bacon.combineTemplate({
        ownerId: s_ownerId,
        instances: s_instances,
      }).flatMapLatest(({ ownerId, instances }) => {
        const s_addon = API.organisations._.addons._.get().withParams([ownerId, req.params.addonId]).send();

        return s_addon.map((addon) => {
          return _.extend({}, addon, {
            instance: { type: addon.provider.id },
            id: addon.realId, // metrics use postgresql_ (and others) for the `app_id` field
            appId: addon.id,
            instances,
          });
        });
      });
    } else {
      s_resource = new Bacon.Error(new Error('We should have an appId or addonId'));
      s_sourceType = Bacon.never();
      s_instances = Bacon.never();
    }

    const s_token = s_ownerId.flatMapLatest(sp.getToken);
    const s_refreshToken = s_token.flatMapLatest(s_ownerId).flatMapLatest(sp.getNewToken);

    const s_metricsToken = Bacon.mergeAll(s_token, s_refreshToken).toProperty();

    s_metricsToken.onValue(() => {
      /* Lazy */
    });

    return { s_instances, s_token: s_metricsToken, s_ownerId, s_resource, s_sourceType };
  };

  sp.on('onload', async (req, $container, streams) => {
    await Promise.all([
      import(/* webpackChunkName: "metrics.lazy" */ '@clevercloud/warp10-cubism'),
      import(/* webpackChunkName: "metrics.lazy" */ '../modules/metrics/advanced.es6.js'),
      import(/* webpackChunkName: "metrics.lazy" */ '../modules/metrics/custom.es6.js'),
      import(/* webpackChunkName: "metrics.lazy" */ '../modules/dom-events/main.es6.js'),
      import(/* webpackChunkName: "metrics.lazy" */ '../modules/metrics/addons-helper.js'),
    ]).then(([$MetricsModule, $AdvancedModule, $CustomModule, $DomEventsModule, AddonsHelperModule]) => {
      $Metrics = $MetricsModule.default;
      $Advanced = $AdvancedModule.default;
      $Custom = $CustomModule.default;
      $DomEvents = $DomEventsModule.default;
      AddonsHelper = AddonsHelperModule.default;
    });

    Bacon.onValues(streams.s_ownerId, streams.s_sourceType, streams.s_resource, (owner, sourceType, resource) => {
      let viewType;

      if (sourceType === 'addon') {
        const availability = AddonsHelper.addonMetrics(resource);
        if (availability !== 'ADDON_IS_SUPPORTED') {
          let text;
          switch (availability) {
            case 'ADDON_NOT_SUPPORTED':
              text = T('console.metrics.addon-not-supported');
              break;
            case 'ADDON_IS_TOO_OLD':
              text = T('console.metrics.addon-too-old');
              break;
            case 'ADDON_PLAN_NOT_SUPPORTED':
              text = T('console.metrics.addon-plan-not-supported');
              break;
          }

          $container.find('.metrics-loader').removeClass('hidden');
          $container.find('.metrics-loader-bars').addClass('hidden');
          $container.find('.metrics-loading-reasons').text(text).removeClass('hidden');

          return;
        }
      }

      switch (req.params.type) {
        case 'global':
          viewType = 'GLOBAL';
          break;
        case 'advanced':
          viewType = 'ADVANCED';
          break;
        case 'custom':
          viewType = 'CUSTOM';
          break;
        default:
          viewType = 'GLOBAL';
      }

      const b_inputEvents = new Bacon.Bus();
      const b_outputEvents = new Bacon.Bus();

      const translations = (key, data) => {
        const translation = T(`console.${key}`, data, true);
        if (translation.toLowerCase() === 'unknown translation') {
          return key;
        } else {
          return translation;
        }
      };

      const metricsState = $Metrics({
        s_token: streams.s_token,
        s_instances: streams.s_instances,
        s_requestUnload: Console.s_requestUnload,
        resource: {
          id: resource.id,
          appId: resource.appId,
          instanceType:
            resource.instanceType ||
            (resource.instance && resource.instance.type) ||
            (resource.provider && resource.provider.id),
          type: sourceType,
        },
        $container,
        owner,
        viewType,
        b_inputEvents,
        b_outputEvents,
        Translations: translations,
        fetchInterval: 10e3,
      });

      sp.hookMetrics(metricsState, b_inputEvents);
      $Metrics.start(metricsState);

      const s_resize = sp.listenPanesResize().takeUntil(Console.s_requestUnload);

      b_inputEvents.plug(s_resize);
      b_inputEvents.plug($Panes.isLoaded.filter((l) => l === true).map(() => ({ type: 'PAGE_READY' })));

      b_outputEvents
        .takeUntil(Console.s_requestUnload)
        .onValue((event) => sp.listenOutputEvents(event, owner, sourceType, resource));

      if (viewType === 'GLOBAL' || 'ADVANCED') {
        sp.listenDomEvents($container, owner, sourceType, resource);
      }

      Bacon.combineTemplate({
        token: streams.s_token.first(),
        instances: streams.s_instances,
      }).onValue(({ token, instances }) => sp.fillMetricsHeader($container, token, resource.id, instances));
    });
  });

  sp.getToken = (orgaId) => {
    const prom = getMetricsToken({ orgaId }).then(sendToApi);
    return Bacon.fromPromise(prom);
  };

  sp.getNewToken = (ownerId) => {
    return Bacon.repeat(() => {
      return Bacon.later(Console.configuration.METRICS_TOKEN_REFRESH).flatMapLatest(() => sp.getToken(ownerId));
    });
  };

  sp.listenPanesResize = () => {
    // click is for browsers not supporting transitionstart event
    const s_transitions = $Panes
      .subscribeToTransitions(['transitionstart', 'transitionend'])
      .filter(({ domEvent }) => $(domEvent.target).hasClass('l-container') || domEvent.type === 'click');

    const s_transitionstart = s_transitions.filter(({ eventType }) => {
      return eventType === 'transitionstart';
    });

    const s_transitionend = s_transitions.filter(({ eventType }) => {
      return eventType === 'transitionend';
    });

    // Dom resize
    const s_resize = $DomEvents.listenForEvents()['resize'].throttle(500);

    const s_pageResizeStart = Bacon.mergeAll(s_transitionstart, s_resize)
      .first()
      .map(() => ({ type: 'PAGE_RESIZE_START' }));

    const s_pageResizeEnd = Bacon.mergeAll(s_transitionend, s_resize)
      .first()
      .map(() => ({ type: 'PAGE_RESIZE_END' }));

    return Bacon.mergeAll(s_pageResizeStart, s_pageResizeEnd);
  };

  sp.listenOutputEvents = (event, owner, sourceType, resource) => {
    switch (event.type) {
      case 'CHANGE_VIEW':
        sp.changeView(event.data, owner, sourceType, resource);
        break;
    }
  };

  sp.changeView = (view, owner, sourceType, resource) => {
    const ownerPath = owner.indexOf('orga_') === 0 ? `/organisations/${owner}` : '/users/me';

    let resourcePath;
    let resourceId;

    if (sourceType === 'application') {
      resourcePath = '/applications';
      resourceId = resource.id;
    } else if (sourceType === 'addon') {
      resourcePath = '/addons';
      resourceId = resource.appId;
    } else {
      throw new Error("sourceType didn't match app or addon");
      return;
    }

    const path = `${ownerPath}${resourcePath}/${resourceId}/metrics/${view}`;

    Yajas.path4js.launchPath(path4js.Request.fromUri(path));
  };

  sp.listenDomEvents = ($container, owner, sourceType, resource) => {
    $container
      .find('.metrics-simple-view-header.advanced-view')
      .asEventStream('click')
      .onValue(() => sp.changeView('advanced', owner, sourceType, resource));

    $container
      .find('.metrics-advanced-view .metrics-custom .btn')
      .asEventStream('click')
      .onValue(() => sp.changeView('custom', owner, sourceType, resource));
  };

  sp.hookMetrics = (metricsState, b_inputEvents) => {
    $Metrics.displayView = (state) => {
      $Metrics.loading(state);

      if (state.viewType === 'GLOBAL') {
        $Metrics.globalView(state);
      } else if (state.viewType === 'ADVANCED') {
        b_inputEvents
          .filter((event) => event.type === 'PAGE_READY')
          .map(() => $Metrics.setMaxPoints(metricsState))
          .onValue((_state) => $Advanced(_state));
      } else if (state.viewType === 'CUSTOM') {
        $Custom(metricsState);
      } else {
        console.error(new Error(`${type} isn't a supported view`));
      }
    };
  };

  sp.fillMetricsHeader = ($container, token, id, instances) => {
    const $metricsHeader = $container.find('.metrics-info-header');
    const initialMetricsWarpscript = $Custom.getInitialMetricsWarpscript({
      token,
      appId: id,
      instances: _.map(instances, 'id'),
    });

    const initialAccessLogsWarpscript = $Custom.getInitialAccessLogsWarpscript({
      token,
      appId: id,
    });

    const warp10Url = Console.configuration.WARP_10_HOST + '/api/v0';
    const { url: metricsUrl } = $Custom.createQuantumUrl(initialMetricsWarpscript);
    const { url: accessLogsUrl } = $Custom.createQuantumUrl(initialAccessLogsWarpscript);
    $metricsHeader.html(Templates['Metrics.metrics-header']({ token, metricsUrl, accessLogsUrl, warp10Url }));
    $metricsHeader.removeClass('hidden');
  };

  return sp;
})();
