module.exports = (function () {
  var $UniformisedDateTime = require('../modules/uniformisedDateTime/main.js');
  var $Modal = require('../modules/modals/main.js');
  var Role = require('../models/business/role.js');
  const Bacon = require('baconjs');
  const { sendToApi } = require('../send-to-api.js');
  const { getAllZones } = require('@clevercloud/client/esm/api/v4/product.js');
  const { filterZones, isMachineLearning, cleanZoneTags } = require('../clever-client/various.js');
  require('@clevercloud/components/dist/cc-block.js');
  require('@clevercloud/components/dist/cc-input-text.js');
  require('@clevercloud/components/dist/cc-zone-input.js');
  const { CcZone } = require('@clevercloud/components/dist/cc-zone.js');

  var sp = new (require('./AbstractSP.js'))({
    name: 'AppSP',
  });

  var Yajas = require('yajas');
  var path4js = require('path4js');

  sp.getStreams = function (req) {
    var s_owner = req.params.oid ? SummaryProxy.fetchOrgaOnce(req.params.oid) : SummaryProxy.fetchUserOnce();

    var s_app;
    if (req.request.justCreated) {
      s_app = Bacon.once(req.request.justCreated);
    } else {
      s_app = s_owner.flatMapLatest((owner) => {
        return API.organisations._.applications._.get().withParams([owner.id, req.params.appId]).send();
      });
    }

    const s_zones = s_app.flatMapLatest((app) => {
      const ml = isMachineLearning(app.instance.type);
      const p_zones = getAllZones()
        .then(sendToApi)
        .then((zones) => filterZones(zones, ml).map(cleanZoneTags));
      return Bacon.fromPromise(p_zones);
    });

    var s_ssh = API.self.keys.get().send();

    var s_members = s_owner.flatMapLatest((owner) => {
      return owner.id.indexOf('user_') === 0
        ? Bacon.once([])
        : API.organisations._.members.get().withParams([owner.id]).send();
    });

    var s_branches = s_owner.flatMapLatest((owner) => {
      return API.organisations._.applications._.branches.get().withParams([owner.id, req.params.appId]).send();
    });

    return {
      s_owner: s_owner,
      s_app: s_app,
      s_zones: s_zones,
      s_ssh: s_ssh,
      s_members: s_members,
      s_branches: s_branches,
    };
  };

  sp.on('onload', function (req, $container, streams) {
    streams.s_branches.onError($Notification.displayError);

    Bacon.onValues(
      streams.s_app,
      streams.s_zones,
      streams.s_owner,
      Bacon.once(req.request.justCreated),
      streams.s_ssh,
      streams.s_members,
      Bacon.once(streams.s_branches.mapError([])),
      _.partial(sp.displayApp, $container),
    );
  });

  sp.displayApp = function ($container, app, zones, owner, justCreated, userSSH, members, s_branches) {
    const isOwnerOrga = owner.id.indexOf('orga_') === 0;
    const buildFlavors = app.instance.flavors.filter((flavor) => {
      const lower = flavor.name.toLowerCase();
      return lower !== 'pico' && lower !== 'nano' && lower !== 'xs';
    });

    var $form = $container.find('form').html(
      Templates['AppSP.form']({
        app: _.extend({}, app, {
          creationDate: $UniformisedDateTime.getWithSeconds(app.creationDate),
        }),
        zoneInputState: { type: 'loaded', zones },
        owner: owner,
        isOwnerOrga: isOwnerOrga,
        members: members,
        userSSH: userSSH,
        justCreated: justCreated ? true : false,
        Role: Role,
        buildFlavors,
      }),
    );

    var s_updateAppInfos;
    var s_submit = $form.asEventStream('submit').doAction('.preventDefault');
    var isGithub = app.deployment.type === 'GIT' && app.deployment.repoState === 'NOT_NEEDED';

    $form
      .find('#separate-build')
      .asEventStream('change')
      // We don't use s_submit because we don't refresh the page
      .takeUntil(Console.s_requestUnload.skip(1))
      .onValue((e) => {
        const $buildFlavor = $form.find('p.default-build-flavor-settings');
        if (e.target.checked) {
          $buildFlavor.removeClass('hidden');
        } else {
          $buildFlavor.addClass('hidden');
        }
      });

    s_branches
      .map(function (branches) {
        return _.filter(branches, function (branch) {
          return branch !== app.branch;
        });
      })
      .onValue(function (branches) {
        $form.find('.deployment-branch').removeClass('label-loading');
        var $select = $form.find("select[name='app-branch']");

        _.each(branches, function (branch) {
          $select.append(Templates['AppSP.form.branch-options']({ branch: branch }));
        });
      });

    let appBody;

    var s_update = s_submit.flatMapLatest(function () {
      var newBranch = {
        branch: $form.find("[name='app-branch']").val(),
      };

      var newDefaultBuildFlavor = {
        flavorName: $form.find("[name='default-build-flavor']").val(),
      };

      appBody = {
        id: app.id,
        name: $form.find("[name='app-name']").val(),
        description: $form.find("[name='app-description']").val(),
        zone: $form.find('cc-zone-input').prop('selected'),
        homogeneous: !$form.find("[name='app-homogeneous']").is(':checked'),
        stickySessions: $form.find("[name='sticky-sessions']").is(':checked'),
        separateBuild: $form.find("[name='separate-build']").is(':checked'),
        cancelOnPush: $form.find("[name='cancel-on-push']").is(':checked'),
        forceHttps: $form.find("[name='force-https']").is(':checked') ? 'ENABLED' : 'DISABLED',
        instanceLifetime: $form.find("[name='instance-lifetime']").is(':checked') ? 'TASK' : 'REGULAR',
      };

      const body = JSON.stringify(appBody);

      var s_app = API.organisations._.applications._.put()
        .withParams([owner.id, app.id])
        .send(body)
        .flatMapLatest(function (app) {
          return SummaryProxy.updateApplications(owner.id);
        });

      var s_updateBranch;

      if (isGithub && app.branch !== newBranch) {
        s_updateBranch = API.organisations._.applications._.branch
          .put()
          .withParams([owner.id, app.id])
          .send(JSON.stringify(newBranch));

        s_updateBranch.onValue(function () {
          app.branch = newBranch;
        });
      } else {
        s_updateBranch = Bacon.constant();
      }

      var s_updateBuildFlavor;
      if (appBody.separateBuild && app.buildFlavor.name !== newDefaultBuildFlavor.flavorId) {
        s_updateBuildFlavor = API.organisations._.applications._.buildflavor
          .put()
          .withParams([owner.id, app.id])
          .send(JSON.stringify(newDefaultBuildFlavor));
      } else {
        s_updateBuildFlavor = Bacon.constant();
      }

      var s_reqs = Bacon.combineAsArray([s_app, s_updateBranch, s_updateBuildFlavor]);
      $form.find("[type='submit']").loadStream(s_reqs);

      return s_reqs;
    });

    s_update.onValue(() => {
      const previousZoneValue = app.zone;
      if (previousZoneValue !== appBody.zone) {
        const fromZone = zones.find((z) => z.name === previousZoneValue);
        const fromZoneText = CcZone.getText(fromZone);
        const toZone = zones.find((z) => z.name === appBody.zone);
        const toZoneText = CcZone.getText(toZone);
        const divZoneSavedToast = document.createElement('div');
        const urlInZoneSavedToast = '/organisations/' + owner.id + '/applications/' + appBody.id + '/vhosts';
        divZoneSavedToast.innerHTML = T('console.app-sp.zone-saved', {
          fromZone: fromZoneText,
          toZone: toZoneText,
          url: urlInZoneSavedToast,
        });
        $Notification.displaySuccess(
          {
            message: divZoneSavedToast,
          },
          { timeout: 0 },
        );
      } else {
        $Notification.displaySuccess({
          message: T('console.app-sp.informations-saved'),
        });
      }
    });
    s_update.onError($Notification.displayError);

    var userDeleteApp = isOwnerOrga ? Role.canRemoveApp(owner.role) : true;

    var userArchiveApp = isOwnerOrga ? Role.canRemoveApp(owner.role) : true;

    var s_delete = $form
      .find('button.remove')
      .asEventStream('click')
      .filter(userDeleteApp)
      .flatMapLatest(_.partial(sp.removeApp, $container, owner, app));

    s_delete.onError($Notification.displayError);
    s_delete.onValue(function () {
      Yajas.path4js.launchPath(path4js.Request.fromUri(!isOwnerOrga ? '/users/me' : '/organisations/' + owner.id));
    });

    var s_archive = $form
      .find('button.archive')
      .asEventStream('click')
      .filter(userArchiveApp)
      .flatMapLatest(_.partial(sp.archiveApp, owner, app));

    s_archive.onError($Notification.displayError);
    s_archive.onValue(function () {
      Yajas.path4js.launchPath(
        path4js.Request.fromUri(
          (!isOwnerOrga ? '/users/me' : '/organisations/' + owner.id) + '/applications/' + app.id + '/information',
        ),
      );
    });
  };

  sp.removeApp = function ($container, owner, app) {
    var $modal = $Modal({
      type: 'confirmation',
      title: T('REMOVE_APPLICATION'),
      body: T('APPLICATION_TYPE_NAME_DELETE', { appName: app.name }),
      textCheck: function (input) {
        return input === app.name;
      },

      Templates: Templates,
    });

    return $modal.s_confirm.flatMapLatest(function () {
      var s_removeApp = API.organisations._.applications._.delete().withParams([owner.id, app.id]).send();
      var s_remove = s_removeApp.flatMapLatest(function (response) {
        // Update the proxy for the corresponding owner
        return SummaryProxy.updateApplications(owner.id)
          .map(response)
          .flatMapLatest(function () {
            return app.deployment.type.toLowerCase() === 'ftp' ? SummaryProxy.updateAddons(owner.id) : Bacon.once();
          });
      });
      $Modal.loadStream($modal, s_remove);
      return s_remove;
    });
  };

  sp.archiveApp = function (owner, app, e) {
    var body = JSON.stringify({
      archived: !app.archived,
    });

    var s_archive = API.organisations._.applications._.put()
      .withParams([owner.id, app.id])
      .send(body)
      .flatMapLatest(function () {
        return SummaryProxy.updateApplications(owner.id);
      });

    $(e.currentTarget).loadStream(s_archive);
    return s_archive;
  };

  return sp;
})();
