module.exports = (() => {
  const $ = require('jquery');
  const GlobalMessage = (text) => {
    $('body .global-message').html(text);
    GlobalMessage.show();
  };

  GlobalMessage.show = () => {
    $('body .global-message').show();
    $('body').addClass('global-message-shown');
  };

  GlobalMessage.hide = () => {
    $('body .global-message').hide();
    $('body').removeClass('global-message-shown');
  };

  return GlobalMessage;
})();
