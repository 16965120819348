module.exports = function () {
  var $ = require('jquery');
  var Bacon = require('baconjs');

  var Templates = require('../../../generated/templates.js');
  var T = require('../../models/technical/translation.js');
  var $SSHKeys = require('../ssh-keys/main.js');
  // TODO: Would be better if lazy loaded
  var envVariables = require('../../statepoints-wc/app-env-vars.sp.js');

  var getAppTechno = require('../../helpers/getAppTechno.js');

  const { getAppDocumentationInfos } = require('../../helpers/getDocumentationInfos.js');

  var $AppDeployment = function (settings) {
    var state = {
      container: $(settings.selector),
      app: settings.app,
      orgaId: settings.orgaId,
      Console: settings.Console,
    };

    $AppDeployment.init(state);

    return state;
  };

  $AppDeployment.init = function (state) {
    if (state.app.deployment.type === 'GIT') {
      state.s_deployment = $AppDeployment
        .displaySSHKeys(state)
        .flatMapLatest($AppDeployment.displayEnvVariables)
        .flatMapLatest($AppDeployment.displayInstructions)
        .flatMapLatest($AppDeployment.fetchOwner)
        .flatMapLatest($AppDeployment.displayDeploying);
    } else if (state.app.deployment.type === 'FTP') {
      state.s_deployment = $AppDeployment.waitForInstance(state);
    } else {
      state.s_deployment = Bacon.once(
        new Bacon.Error({ message: 'Unknown deployment type: ' + state.app.deployment.type }),
      );
    }
  };

  $AppDeployment.waitForInstance = function (state) {
    var fetchDeployment = function () {
      var deployments = state.orgaId
        ? API.organisations._.applications._.deployments.get().withParams([state.orgaId, state.app.id])
        : API.self.applications._.deployments.get().withParams([state.app.id]);

      return deployments
        .withQuery({ limit: 1 })
        .send()
        .flatMapLatest(function (deployments) {
          var deployment = deployments && deployments[0];
          return deployment && deployment.state === 'OK'
            ? Bacon.once(deployment)
            : Bacon.later(10000).flatMapLatest(fetchDeployment);
        });
    };

    state.container.html(Templates['app-deployment.waiting-for-instance'](state));

    return fetchDeployment().map(function (deployment) {
      state.deployment = deployment;
      return state;
    });
  };

  $AppDeployment.displaySSHKeys = function (state) {
    var deployment = state.app.deployment;

    // Don't check for SSH keys on github app creation
    if (deployment.type.toUpperCase() === 'GIT' && deployment.repoState.toUpperCase() === 'NOT_NEEDED') {
      return Bacon.once(state);
    }

    var s_keys = API.self.keys.get().send();

    s_keys.onError($Notification.displayError);

    return s_keys.flatMapLatest(function (keys) {
      if (keys.length > 0) {
        return Bacon.once(state);
      } else {
        state.container.html(Templates['app-deployment.ssh-keys'](state));
        var $button = state.container.find('button.next');

        $SSHKeys({
          selector: state.container.find('.ssh-keys-container'),
        });

        return $button.asEventStream('click').map(state);
      }
    });
  };

  $AppDeployment.displayEnvVariables = function (state) {
    // Here we reuse app-env-vars.sp, there should be a better way, but it works for now :-(
    const req = { params: { oid: state.orgaId, appId: state.app.id } };
    state.container.html('');
    const p_loadEnvVarSp = envVariables.load(req, state.container[0], { restartAppButton: false });
    return Bacon.fromPromise(p_loadEnvVarSp).flatMapLatest(() => {
      state.container.append(`<button type="button" class="next btn btn-blue">${T('NEXT')}</button>`);
      return state.container.find('button.next').asEventStream('click').map(state);
    });
  };

  $AppDeployment.displayInstructions = function (state) {
    var tips = T('console.app-creation.tips.' + state.app.instance.type.toUpperCase());

    state.tips = tips.toLowerCase() !== 'unknown translation' ? tips.split('\n') : [];
    state.instanceType = getAppTechno(state.app.instance.type);
    state.documentationInfos = getAppDocumentationInfos(
      `${state.app.instance.type}+${state.app.instance.variant.slug}`,
    );

    if (state.app.deployment.type === 'GIT') {
      state.container.html(Templates['app-deployment.instructions.git'](state));
    }

    var s_deploy;

    if (state.app.deployment.repoState === 'NOT_NEEDED') {
      var req = state.orgaId
        ? API.organisations._.applications._.instances.post().withParams([state.orgaId, state.app.id])
        : API.self.applications._.instances.post().withParams([state.app.id]);

      if (state.app.deployment.type === 'GIT') {
        req = req.withQuery({ commit: 'HEAD' });
      }

      s_deploy = req.send();
    } else {
      s_deploy = Bacon.constant();
    }

    return s_deploy.flatMapLatest(function () {
      return $AppDeployment
        .fetchLastDeployment(state)
        .first()
        .map(function (lastDeployment) {
          state.deployment = lastDeployment;
          return state;
        });
    });
  };

  $AppDeployment.displayDeploying = function ({ state }) {
    const urlPrefix =  state.orgaId ? `/organisations/${state.orgaId}` : `/users/me`;
    const logsUrl = `${urlPrefix}/applications/${state.app.id}/logs`;
    const message = T('console.app-deployment.app-is-deploying', { logsUrl })

    state.container.html(Templates['app-deployment.deploying']({ message }));

    return $AppDeployment
      .fetchLastDeployment(state)
      .filter(({ state }) => state !== 'WIP')
      .first()
      .map(function (deployment) {
        state.deployment = deployment;
        return state;
      });
  };

  $AppDeployment.fetchLastDeployment = function (state) {
    return $AppDeployment.fetchOwner(state).flatMapLatest(({ ownerId }) => {
      return state.Console.DeploymentProxy.fetchFirst(ownerId, state.app.id).filter((deployment) => deployment); // First deployment can be "undefined" because the app has just been created, no update of the owner deployments map has been done
    });
  };

  $AppDeployment.fetchOwner = (state) => {
    return state.Console.SummaryProxy.fetchOrgaOnce(state.orgaId)
      .map('.id')
      .map((ownerId) => ({ state, ownerId }));
  };

  return $AppDeployment;
};
