export function createElement(elementName, properties = {}) {
  const $element = document.createElement(elementName);
  Object.entries(properties).forEach(([name, value]) => {
    if (name === 'class' || name.startsWith('data-')) {
      $element.setAttribute(name, value);
    } else {
      $element[name] = value;
    }
  });
  return $element;
}

export function appendChildren($parent, children = []) {
  children.forEach(($node) => $parent.appendChild($node));
}

const passThrough = (data) => data;
const setStateLoaded = (data) => ({ type: 'loaded', ...data });

export function plugStreamToElement(s_data, $element, propName, getData = passThrough) {
  plugStream(
    s_data,
    $element,
    propName,
    (value) => {
      $element.error = false;
      return getData(value);
    },
    () => {
      $element.error = true;
    },
  );
}

export function plugStreamToElementState(s_data, $element, getData = setStateLoaded) {
  plugStream(
    s_data,
    $element,
    'state',
    (value) => {
      return getData(value);
    },
    () => {
      $element.state = { type: 'error' };
    },
  );
}

export function plugStream(s_data, $element, propName, getData, onError) {
  s_data.onValue((value) => {
    $element[propName] = getData(value);
  });
  s_data.onError(onError);
}

export function plugPromiseToElement(p_data, $element, propName, getData = passThrough) {
  p_data
    .then((value) => {
      $element.error = false;
      $element[propName] = getData(value);
    })
    .catch((e) => {
      console.log(e);
      $element.error = true;
    });
}

export function plugPromiseToElementState(p_data, $element, getData = setStateLoaded) {
  p_data
    .then((value) => {
      $element.state = getData(value);
    })
    .catch((e) => {
      console.log(e);
      $element.state = { type: 'error' };
    });
}
