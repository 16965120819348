const { iconRemixLock_2Fill } = require('@clevercloud/components/dist/assets/cc-remix.icons');
module.exports = (function () {
  var Bacon = require('baconjs');
  var _ = require('lodash');
  var $ = require('jquery');
  var Templates = require('../../../generated/templates.js');
  var T = require('../../models/technical/translation.js');
  var $AddonPlans = require('../addon-plans/main.es6.js');
  var Cache = require('../cache/main.es6.js');
  var Yajas = require('yajas');
  var path4js = require('path4js');
  const { sendToApi } = require('../../send-to-api.js');
  const {
    getESOptionMonthlyCost,
    cleanZoneTags,
    getAddonZones,
  } = require('../../clever-client/various.js');
  const { getEsOptionsFlavors } = require('@clevercloud/client/esm/api/v2/providers.js');
  const getProviderInformations = require('../../helpers/getAddonProvider.js');
  const { ONE_MONTH } = require('../../lib/price-system.js');
  const { BILLING_ENABLED } = require('../../configuration.js');
  require('@clevercloud/components/dist/cc-addon-elasticsearch-options.js');
  require('@clevercloud/components/dist/cc-addon-postgresql-options.js');
  require('@clevercloud/components/dist/cc-addon-mysql-options.js');
  require('@clevercloud/components/dist/cc-addon-mongodb-options.js');
  require('@clevercloud/components/dist/cc-addon-redis-options.js');
  require('@clevercloud/components/dist/cc-addon-jenkins-options.js');
  require('@clevercloud/components/dist/cc-button.js');
  require('@clevercloud/components/dist/cc-toggle.js');
  require('@clevercloud/components/dist/cc-block.js');
  require('@clevercloud/components/dist/cc-loader.js');
  require('@clevercloud/components/dist/cc-icon.js');
  const { iconRemixLock_2Fill } = require('@clevercloud/components/dist/assets/cc-remix.icons.js');

  var $AddonCreation = function (settings) {
    // We don't use the Cache API because for what we do, sync gets / put are enough
    var cacheKey = $AddonCreation.getCacheKey(settings);
    var data = Cache.get(cacheKey) || {};
    Cache.delete(cacheKey);

    var applications = settings.applications?.map((app) => {
      return {
        ...app,
        variantLogoUrl: app.variantLogoUrl ?? app.instance?.variant?.logo,
      };
    });

    var $addonCreation = {
      $container: $(settings.selector),
      $form: $(settings.selector).find('form.addon-creation'),
      providers: settings.providers,
      priceSystem: settings.priceSystem,
      applications: applications,
      orgaId: settings.orgaId,
      app: settings.app,
      embeded: settings.embeded,
      data: data,
      userId: settings.userId,
      hasAddonCreationRights: _.has(settings, 'hasAddonCreationRights') ? settings.hasAddonCreationRights : true,
      zones: settings.zones,

      SummaryProxy: settings.SummaryProxy,
      Console: settings.Console,
    };

    $AddonCreation.initSteps($addonCreation);

    $AddonCreation.display('provider', $addonCreation);

    return $addonCreation;
  };

  // The "creation" step is just a step that is here to show the global loader
  // We can't use loadStream on the buttons of the "information" or "options" steps because
  // the "options" step uses web components that don't have a loading state
  // The "creation" step will be here to display the loader and if there is an error, the last step
  // will be brought back, just as en effort to not display an error with an endless loader
  $AddonCreation.steps = ['provider', 'plan', 'apps', 'information', 'options', 'creation'];

  $AddonCreation.initSteps = function ($addonCreation) {
    const fieldSetStep = _.filter($AddonCreation.steps, (step) => step !== 'creation');
    _.each(fieldSetStep, function (step) {
      var $step = $('<fieldset>').attr('data-step', step).hide();
      $addonCreation.$form.append($step);
    });

    $addonCreation.streams = _.reduce(
      $AddonCreation.steps,
      function (streams, step) {
        streams[step] = new Bacon.Bus();
        return streams;
      },
      {},
    );

    $addonCreation.s_step = new Bacon.Bus();
    $addonCreation.p_step = $addonCreation.s_step.toProperty('provider');

    var s_data = $addonCreation.streams['provider']
      .flatMapLatest(function ($addonCreation) {
        $AddonCreation.display('plan', $addonCreation);
        return $addonCreation.streams['plan'];
      })
      .flatMapLatest(function ($addonCreation) {
        if ($addonCreation.applications.length > 0) {
          $AddonCreation.display('apps', $addonCreation);
          return $addonCreation.streams['apps'];
        } else if ($addonCreation.app) {
          return Bacon.once(
            _.extend($addonCreation, {
              data: _.extend($addonCreation.data, {
                apps: [$addonCreation.app.id],
              }),
            }),
          );
        } else {
          return Bacon.once($addonCreation);
        }
      })
      .flatMapLatest(function ($addonCreation) {
        $AddonCreation.display('information', $addonCreation);
        return $addonCreation.streams['information'];
      })
      .flatMapLatest(function ($addonCreation) {
        const options = $AddonCreation.getOptions($addonCreation);
        if (options !== null && options.length > 0) {
          $AddonCreation.display('options', $addonCreation);
          return $addonCreation.streams['options'];
        } else {
          return Bacon.once($addonCreation);
        }
      })
      .map(function ($addonCreation) {
        $AddonCreation.display('creation', $addonCreation);
        return $addonCreation;
      });

    s_data
      .errors()
      .mapError(function (error) {
        return error;
      })
      .filter(function (error) {
        var allowedErrors = ['NO_PROVIDER_SELECTED', 'USER_DOESNT_HAVE_CREATION_RIGHTS'];

        return allowedErrors.indexOf(error.code) === -1;
      })
      .onValue($Notification.displayError);

    var s_addon = s_data.flatMapLatest(function ($addonCreation) {
      return $AddonCreation
        .createAddon($addonCreation)
        .flatMapLatest(_.partial($AddonCreation.linkApps, $addonCreation))
        .map(function (addon) {
          return _.extend($addonCreation, {
            addon: addon,
          });
        });
    });

    s_addon
      .flatMapError((error) => $AddonCreation.addonCreationError($addonCreation, error))
      .onError((error) => {
        var allowedErrors = ['NO_PROVIDER_SELECTED', 'USER_DOESNT_HAVE_CREATION_RIGHTS'];
        if (allowedErrors.indexOf(error.code) === -1) {
          $Notification.displayError(error);
          $AddonCreation.display($addonCreation.lastStep, $addonCreation);
        }
      });

    $addonCreation.s_addon = s_addon.map('.addon');
  };

  $AddonCreation.createAddon = function ($addonCreation) {
    var information = { name: $addonCreation.data.name, region: $addonCreation.data.region };
    var provider = $addonCreation.data.provider;
    var plan = $addonCreation.data.plan;
    const options = $addonCreation.data.options;

    var body = {
      name: information.name,
      region: information.region,
      providerId: provider.id,
      plan: plan.id,
      options,
    };

    const bodyStringify = JSON.stringify(body);
    return $addonCreation.orgaId
      ? API.organisations._.addons.post().withParams([$addonCreation.orgaId]).send(bodyStringify)
      : API.self.addons.post().send(bodyStringify);
  };

  $AddonCreation.display = function (step, $addonCreation) {
    var $steps = $addonCreation.$form.find('fieldset');
    const $creationStep = $addonCreation.$container.find('.addonCreation_creation');
    const $step = step === 'creation' ? $creationStep : $steps.filter("[data-step='" + step + "']");

    $addonCreation.s_step.push(step);
    $AddonCreation.display[step]($step, $addonCreation);

    if (step === 'creation') {
      $creationStep.show();
      $addonCreation.$form.hide();
    } else {
      $creationStep.hide();
      $addonCreation.$form.show();
      $addonCreation.lastStep = step;
    }

    if (!$step.is(':visible')) {
      $steps.hide();
      $step.fadeIn();
    }
  };

  $AddonCreation.display['provider'] = function ($step, $addonCreation) {
    if (!$addonCreation.hasAddonCreationRights) {
      var error = new Error("User doesn't have rights to create an add-on");
      error.code = 'USER_DOESNT_HAVE_CREATION_RIGHTS';
      $addonCreation.streams['provider'].plug(Bacon.once(new Bacon.Error(error)));
      return;
    }
    /* Sort providers by name */
    var providers = _.sortBy($addonCreation.providers, function (provider) {
      return provider.name.toLowerCase();
    });

    var $providers = $step.html(
      Templates['AddonCreationSP.providers']({
        embeded: $addonCreation.embeded,
        providers: providers.map((provider) => {
          return {
            ...provider,
            privateIcon:
              provider.status === 'ALPHA' || provider.status === 'BETA_PRIV'
                ? JSON.stringify(iconRemixLock_2Fill)
                : null,
          };
        }),
      }),
    );

    $providers.find('[data-provider]').on('cc-button:click', function (e) {
      $(e.target).prop('waiting', true);

      var provider = _.find(providers, function (provider) {
        return provider.id === $(e.currentTarget).attr('data-provider');
      });

      // Patch the plans with the prices from the billing API
      provider.plans = provider.plans.map((plan) => {
        if (plan['price_id'] == null) {
          return plan;
        }
        // Note: prices are per hour
        const apiPrice =
          $addonCreation.priceSystem?.runtime?.find(
            (pricePlan) => pricePlan['slug_id'].toLowerCase() === plan['price_id'].toLowerCase(),
          )?.price ?? plan.price;

        return {
          ...plan,
          price: apiPrice * ONE_MONTH,
        };
      });

      const s_providerInformations = $AddonCreation
        .getProviderInformations(provider, $addonCreation.priceSystem)
        .then((provider) => {
          return _.extend($addonCreation, {
            data: _.extend($addonCreation.data, {
              provider,
            }),
          });
        });

      $addonCreation.streams['provider'].plug(Bacon.fromPromise(s_providerInformations));
    });

    $addonCreation.streams['provider'].plug(
      $providers
        .find('.addonCreation_noAddonBtn')
        .asEventStream('click')
        .flatMapLatest(function () {
          var error = new Error('No provider has been selected.');
          error.code = 'NO_PROVIDER_SELECTED';
          return Bacon.once(new Bacon.Error(error));
        }),
    );

    if ($addonCreation.data.provider) {
      $providers.find("[data-provider='" + $addonCreation.data.provider.id + "']").addClass('selected');
    }
  };

  $AddonCreation.display['plan'] = function ($step, $addonCreation) {
    var firstPlan = _.chain($addonCreation.data.provider.plans)
      .sortBy(function (plan) {
        return plan.price;
      })
      .first()
      .value();

    var selectedPlan;
    if ($addonCreation.data.plan) {
      selectedPlan = $addonCreation.data.plan.id;
    } else if ($addonCreation.data.provider.plans.length === 1) {
      selectedPlan = firstPlan.id;
    } else {
      selectedPlan = null;
    }

    var s_plan = $AddonPlans({
      $container: $step,
      provider: $addonCreation.data.provider,
      selectedPlan: selectedPlan,
      buttonText: T('NEXT'),
      Templates: Templates,
    });

    s_plan.onValue(function ({ plan }) {
      $addonCreation.streams['plan'].push(
        _.extend($addonCreation, {
          data: _.extend($addonCreation.data, {
            plan: plan,
          }),
        }),
      );
    });

    if ($addonCreation.data.plan) {
      $step
        .find('table.addons-plans [data-plan="' + $addonCreation.data.plan.id + '"]')
        .addClass('addon-plan-selected');
    }
  };

  $AddonCreation.display['options'] = function ($step, $addonCreation) {
    let p_options = null;

    switch ($addonCreation.data.provider.id) {
      case 'es-addon':
        p_options = $AddonCreation.displayElasticsearchOptions($step, $addonCreation);
        break;
      case 'postgresql-addon':
        p_options = $AddonCreation.displayPostgresqlOptions($step, $addonCreation);
        break;
      case 'mysql-addon':
        p_options = $AddonCreation.displayMysqlOptions($step, $addonCreation);
        break;
      case 'mongodb-addon':
        p_options = $AddonCreation.displayMongodbOptions($step, $addonCreation);
        break;
      case 'redis-addon':
        p_options = $AddonCreation.displayRedisOptions($step, $addonCreation);
        break;
      case 'jenkins':
        p_options = $AddonCreation.displayJenkinsOptions($step, $addonCreation);
        break;
      default:
        p_options = Promise.resolve(null);
    }

    p_options.then((options) => {
      $addonCreation.streams['options'].push(
        _.extend($addonCreation, {
          data: _.extend($addonCreation.data, {
            options: _.extend({}, $addonCreation.data.options, options),
          }),
        }),
      );
    });
  };

  $AddonCreation.display['apps'] = function ($step, $addonCreation) {
    /* Sort apps by name */
    var apps = _.sortBy($addonCreation.applications, function (app) {
      return app.name.toLowerCase();
    });

    // If user used the breadcrumb
    if ($addonCreation.data.apps) {
      apps = _.map(apps, function (app) {
        return _.extend({}, app, {
          linked: _.find($addonCreation.data.apps, function (linkedId) {
            return linkedId === app.id;
          })
            ? true
            : false,
        });
      });
    }

    var $apps = $step.html(
      Templates['AddonCreationSP.apps']({
        apps: apps,
      }),
    );

    // If user used the breadcrumb
    if ($addonCreation.data.apps) {
      _.each($addonCreation.data.apps, function (app) {
        $apps.find(`cc-toggle[data-app-id="${app.id}"]`).attr('value', 'link');
      });
    }

    $apps.find('table tr').on('click', (e) => {
      if (e.target.nodeName.toLowerCase() === 'td') {
        const $toggle = $(e.currentTarget).find('cc-toggle');
        $toggle.attr('value', $toggle.attr('value') === 'link' ? 'unlink' : 'link');
      }
    });

    $step.find('cc-button.next').on('cc-button:click', function () {
      const apps = _.chain($addonCreation.applications)
        .filter((app) => $step.find(`cc-toggle[data-app-id="${app.id}"]`).attr('value') === 'link')
        .map('id')
        .value();

      $addonCreation.streams['apps'].push(
        _.extend($addonCreation, {
          data: _.extend($addonCreation.data, { apps }),
        }),
      );
    });
  };

  function getRegion(zones, $addonCreation) {
    if (zones.length === 1) {
      return zones[0].name;
    }
    if ($addonCreation.data != null && $addonCreation.data.region != null) {
      if (zones.find((z) => z.name === $addonCreation.data.region) != null) {
        return $addonCreation.data.region;
      }
    }
    if ($addonCreation.app != null && $addonCreation.app.zone != null) {
      if (zones.find((z) => z.name === $addonCreation.app.zone) != null) {
        return $addonCreation.app.zone;
      }
    }
  }

  $AddonCreation.display['information'] = function ($step, $addonCreation) {
    let versions,
      defaultVersion,
      planType = null;
    const provider = $addonCreation.data.provider;

    if (provider.informations) {
      planType = $AddonCreation.getPlanType($addonCreation);
      versions = provider.informations[planType].map(({ version }) => version);

      if (planType === 'dedicated') {
        defaultVersion = provider.informations.defaultDedicatedVersion;
      }
    }

    const zones = getAddonZones($addonCreation.data.provider, $addonCreation.zones, $addonCreation.data.plan).map(
      cleanZoneTags,
    );

    $step.html(
      Templates['AddonCreationSP.information']({
        provider: $addonCreation.data.provider,
        zoneInputState: { type: 'loaded', zones },
        name: $addonCreation.data.name,
        region: getRegion(zones, $addonCreation),
        planType,
        versions,
        defaultVersion,
      }),
    );

    var s_submit = $step
      .find('input#addon-name')
      .asEventStream('keydown')
      .filter(function (e) {
        return e.keyCode === 13;
      })
      .doAction('.preventDefault');

    var s_formSubmit = $addonCreation.$form.asEventStream('submit');

    Bacon.mergeAll(s_submit, s_formSubmit)
      .takeUntil(Console.s_requestUnload)
      .onValue(function (e) {
        e.preventDefault();
        var $name = $step.find('input#addon-name').removeAttr('data-status');
        var name = $name.val().trim();
        var region = $step.find('cc-zone-input').prop('selected');
        var version = $step.find('select.versions').val();

        if (name.length === 0) {
          $name.attr('data-status', 'error');
          return;
        }

        $addonCreation.streams['information'].push(
          _.extend($addonCreation, {
            data: _.extend($addonCreation.data, {
              name: name,
              region: region,
              options: _.extend($addonCreation.data.options, {
                version,
              }),
            }),
          }),
        );
      });
  };

  $AddonCreation.display['creation'] = function ($step, $addonCreation) {
    if ($addonCreation.app == null) {
      $step.html(`<cc-loader style="position: absolute;left: 0;width: 100%;top: 0;height: 100%"></cc-loader>`);
    }
  };

  $AddonCreation.linkApps = function ($addonCreation, addon) {
    return $addonCreation.SummaryProxy.updateAddons($addonCreation.orgaId).flatMapLatest(function () {
      var s_links = _.reduce(
        $addonCreation.data.apps,
        function (streams, appId) {
          var s = $addonCreation.orgaId
            ? API.organisations._.applications._.addons
                .post()
                .withParams([$addonCreation.orgaId, appId])
                .send(JSON.stringify(addon.id))
            : API.self.applications._.addons.post().withParams([appId]).send(JSON.stringify(addon.id));

          return streams.concat([s]);
        },
        [],
      );

      return Bacon.combineAsArray(s_links).map(addon);
    });
  };

  $AddonCreation.addonCreationError = function ($addonCreation, error) {
    // User did not satisfy our requirements
    if (error.id === 506 || error.id === 6006) {
      var cacheKey = $AddonCreation.getCacheKey($addonCreation);
      $Notification.displayError({ message: T('console.orga-misses-payment-informations') });
      Cache.set(cacheKey, $addonCreation.data);

      var orgaId = $addonCreation.orgaId;
      var path = orgaId ? '/organisations/' + orgaId + '/information' : '/users/me/information';
      var queryParam =
        '?redirect=' + encodeURIComponent(orgaId ? '/organisations/' + orgaId + '/addons/new' : '/users/me/addons/new');

      Yajas.path4js.launchPath(path4js.Request.fromUri(path + queryParam));

      return Bacon.never();
    } else {
      return Bacon.once(new Bacon.Error(error));
    }
  };

  $AddonCreation.getCacheKey = function ($addonCreation) {
    return 'addon-creation.' + ($addonCreation.orgaId || $addonCreation.userId);
  };

  $AddonCreation.getPlanType = function ($addonCreation) {
    const planTypeFeature = $addonCreation.data.plan.features.find((feature) => feature.name.toLowerCase() === 'type');
    if (!planTypeFeature) {
      throw 'Missing Type feature to know if the plan is a dedicated or shared plan';
    }
    return planTypeFeature.value.toLowerCase() === 'shared' ? 'clusters' : 'dedicated';
  };

  $AddonCreation.getProviderInformations = function (provider, priceSystem) {
    return getProviderInformations(provider).then((informations) => {
      provider.informations = informations;

      if (provider.id === 'es-addon') {
        return $AddonCreation.getElasticsearchOptionsDetails(priceSystem).then((optionsDetails) => {
          provider.optionsDetails = optionsDetails;
          return provider;
        });
      } else {
        return Promise.resolve(provider);
      }
    });
  };

  $AddonCreation.getElasticsearchOptionsDetails = function (priceSystem) {
    return getEsOptionsFlavors()
      .then(sendToApi)
      .then(({ services }) => {
        if (BILLING_ENABLED) {
          return {
            kibana: { ...services.kibana, monthlyCost: getESOptionMonthlyCost(services.kibana.price_id, priceSystem) },
            apm: { ...services.apm, monthlyCost: getESOptionMonthlyCost(services.apm.price_id, priceSystem) },
          };
        } else {
          return {
            kibana: { ...services.kibana },
            apm: { ...services.apm },
          };
        }
      });
  };

  $AddonCreation.displayElasticsearchOptions = function ($step, $addonCreation) {
    $step.html(
      `<cc-addon-elasticsearch-options class="centered-maxed-width-container"></cc-addon-elasticsearch-options>`,
    );
    const $options = $step.find('cc-addon-elasticsearch-options');

    if ($addonCreation.data.provider.informations) {
      const options = $AddonCreation
        .getOptions($addonCreation)
        .map((option) => {
          switch (option.name) {
            case 'encryption':
              return option;
            case 'apm':
              return { ...option, flavor: $addonCreation.data.provider.optionsDetails.apm };
            case 'kibana':
              return { ...option, flavor: $addonCreation.data.provider.optionsDetails.kibana };
            default:
              return null;
          }
        })
        .filter((option) => option != null);

      $options.prop('options', options);

      return new Promise((resolve) => {
        $step.on('cc-addon-elasticsearch-options:submit', ({ originalEvent: { detail } }) => {
          resolve({
            encryption: detail.encryption.toString(),
            services: JSON.stringify([
              { name: 'kibana', enabled: detail.kibana },
              { name: 'apm', enabled: detail.apm },
            ]),
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  $AddonCreation.displayPostgresqlOptions = function ($step, $addonCreation) {
    $step.html(`<cc-addon-postgresql-options class="centered-maxed-width-container"></cc-addon-postgresql-options>`);
    const $options = $step.find('cc-addon-postgresql-options');

    if ($addonCreation.data.provider.informations) {
      const options = $AddonCreation.getOptions($addonCreation).filter((option) => option.name === 'encryption');

      $options.prop('options', options);

      return new Promise((resolve) => {
        $step.on('cc-addon-postgresql-options:submit', ({ originalEvent: { detail } }) => {
          resolve({
            encryption: detail.encryption.toString(),
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  $AddonCreation.displayMysqlOptions = function ($step, $addonCreation) {
    $step.html(`<cc-addon-mysql-options class="centered-maxed-width-container"></cc-addon-mysql-options>`);
    const $options = $step.find('cc-addon-mysql-options');

    if ($addonCreation.data.provider.informations) {
      const options = $AddonCreation.getOptions($addonCreation).filter((option) => option.name === 'encryption');

      $options.prop('options', options);

      return new Promise((resolve) => {
        $step.on('cc-addon-mysql-options:submit', ({ originalEvent: { detail } }) => {
          resolve({
            encryption: detail.encryption.toString(),
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  $AddonCreation.displayMongodbOptions = function ($step, $addonCreation) {
    $step.html(`<cc-addon-mongodb-options class="centered-maxed-width-container"></cc-addon-mongodb-options>`);
    const $options = $step.find('cc-addon-mongodb-options');

    if ($addonCreation.data.provider.informations) {
      const options = $AddonCreation.getOptions($addonCreation).filter((option) => option.name === 'encryption');

      $options.prop('options', options);

      return new Promise((resolve) => {
        $step.on('cc-addon-mongodb-options:submit', ({ originalEvent: { detail } }) => {
          resolve({
            encryption: detail.encryption.toString(),
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  $AddonCreation.displayRedisOptions = function ($step, $addonCreation) {
    $step.html(`<cc-addon-redis-options class="centered-maxed-width-container"></cc-addon-redis-options>`);
    const $options = $step.find('cc-addon-redis-options');

    if ($addonCreation.data.provider.informations) {
      const options = $AddonCreation.getOptions($addonCreation).filter((option) => option.name === 'encryption');

      $options.prop('options', options);

      return new Promise((resolve) => {
        $step.on('cc-addon-redis-options:submit', ({ originalEvent: { detail } }) => {
          resolve({
            encryption: detail.encryption.toString(),
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  $AddonCreation.displayJenkinsOptions = function ($step, $addonCreation) {
    $step.html(`<cc-addon-jenkins-options class="centered-maxed-width-container"></cc-addon-jenkins-options>`);
    const $options = $step.find('cc-addon-jenkins-options');

    if ($addonCreation.data.provider.informations) {
      const options = $AddonCreation.getOptions($addonCreation).filter((option) => option.name === 'encryption');

      $options.prop('options', options);

      return new Promise((resolve) => {
        $step.on('cc-addon-jenkins-options:submit', ({ originalEvent: { detail } }) => {
          resolve({
            encryption: detail.encryption.toString(),
          });
        });
      });
    } else {
      return Promise.resolve(null);
    }
  };

  $AddonCreation.getOptions = function ($addonCreation) {
    if ($addonCreation.data.provider.informations) {
      // if we have a version, it means that we got a features array, even if empty it should exist
      const selectedVersion = $addonCreation.data.options.version;
      if (selectedVersion) {
        const planType = $AddonCreation.getPlanType($addonCreation);
        // We should be able to find the version we want
        const providerVersion = $addonCreation.data.provider.informations[planType].find(
          ({ version }) => version === selectedVersion,
        );
        if (providerVersion) {
          return providerVersion.features.map((feature) => {
            const featureStatus = $addonCreation.data.options[feature.name];
            if (featureStatus) {
              feature.enabled = featureStatus === 'true' ? true : false;
            }

            return feature;
          });
        }
      }
    }

    return null;
  };

  return $AddonCreation;
})();
