const { getAddonProvider } = require('@clevercloud/client/esm/api/v4/addon-providers.js');
const { sendToApi } = require('../send-to-api');

module.exports = ({ id }) => {
  return getAddonProvider({ providerId: id })
    .then(sendToApi)
    .then((informations) => {
      const clusters = informations.clusters;
      const dedicated = Object.keys(informations.dedicated).map((key) => {
        return {
          version: key,
          features: informations.dedicated[key].features,
        };
      });

      return {
        clusters,
        dedicated,
        defaultDedicatedVersion: informations.defaultDedicatedVersion,
      };
    })
    .catch(() => null);
};
