var $ = require('jquery');
var Bacon = require('baconjs');

module.exports = function () {
  $.fn.loadStream = function (stream, delay, id) {
    var $element = this.addClass('is-loading').attr('disabled', 'disabled');

    if (id) {
      $element.attr('data-load-id', id);
    }

    stream.onEnd(function () {
      setTimeout(function () {
        // If multiple loadStreams are registered on the same element
        // check the ID and only remove the loader if the last registered ID is the same as ours
        if (!id || $element.attr('data-load-id') === id) {
          $element.removeClass('is-loading').removeAttr('disabled').removeAttr('data-load-id');
        }
      }, delay || 0);
    });

    return this;
  };
};
