module.exports = (function(){

          var _ = require("lodash");

          this["Templates"] = this["Templates"] || {};

this["Templates"]["golang_mysql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Golang</h3>\n<pre>\n  import (\n    &#9;"github.com/ziutek/mymysql/mysql"\n    &#9;_ "github.com/ziutek/mymysql/native"\n  )\n\n  db := mysql.New("tcp",\n    &#9;"",\n    &#9;os.Getenv("MYSQL_ADDON_HOST") + ":" + os.Getenv("MYSQL_ADDON_PORT"),\n    &#9;os.Getenv("MYSQL_ADDON_USER"),\n    &#9;os.Getenv("MYSQL_ADDON_PASSWORD"),\n    &#9;os.Getenv("MYSQL_ADDON_DB")\n  )\n</pre>\n';

}
return __p
};

this["Templates"]["java_mysql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Java</h3>\n<pre>\n  String url = "jdbc:mysql://" + System.getenv("MYSQL_ADDON_HOST") + ":3306/" + System.getenv("MYSQL_ADDON_DB");\n  String utilisateur = System.getenv("MYSQL_ADDON_USER");\n  String motDePasse = System.getenv("MYSQL_ADDON_PASSWORD");\n\n  Connection connexion = null;\n  connexion = DriverManager.getConnection( url, utilisateur, motDePasse );\n</pre>\n';

}
return __p
};

this["Templates"]["nodejs_mysql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Node.JS</h3>\n<pre>\n  npm install --save mysql\n\n  var mysql      = require(\'mysql\');\n  var connection = mysql.createConnection({\n    &#9;host     : process.env.MYSQL_ADDON_HOST,\n    &#9;database : process.env.MYSQL_ADDON_DB,\n    &#9;user     : process.env.MYSQL_ADDON_USER,\n    &#9;password : process.env.MYSQL_ADDON_PASSWORD\n  });\n</pre>\n';

}
return __p
};

this["Templates"]["php_mysql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>PHP</h3>\n<pre>\n  $bdd = new PDO(\n    &#9;"mysql:host=" . getenv("MYSQL_ADDON_HOST") . ";dbname=" . getenv("MYSQL_ADDON_DB") . ";port=" . getenv("MYSQL_ADDON_PORT"),\n    &#9;getenv("MYSQL_ADDON_USER"),\n    &#9;getenv("MYSQL_ADDON_PASSWORD")\n  );\n</pre>\n';

}
return __p
};

this["Templates"]["python_mysql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Python</h3>\n<pre>\n  import MySQLdb\n  import os\n\n  db = MySQLdb.connect(\n    &#9;host=os.environ["MYSQL_ADDON_HOST"],\n    &#9;user=os.environ["MYSQL_ADDON_USER"],\n    &#9;passwd=os.environ["MYSQL_ADDON_PASSWORD"],\n    &#9;db=os.environ["MYSQL_ADDON_DB"]\n  )\n</pre>\n';

}
return __p
};

this["Templates"]["ruby_mysql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Ruby</h3>\n<pre>\n  require \'mysql\'\n\n  con = Mysql.new ENV[\'MYSQL_ADDON_HOST\'], ENV[\'MYSQL_ADDON_USER\'], ENV[\'MYSQL_ADDON_PASSWORD\'], ENV[\'MYSQL_ADDON_DB\']\n</pre>\n';

}
return __p
};

this["Templates"]["scala_mysql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Scala</h3>\n<pre>\n  import java.sql.DriverManager\n  import java.sql.Connection\n\n  val url = "jdbc:mysql://" + sys.env("MYSQL_ADDON_HOST") + "/" + sys.env("MYSQL_ADDON_DB")\n  val user = sys.env("MYSQL_ADDON_USER")\n  val password = sys.env("MYSQL_ADDON_PASSWORD")\n\n  val connection:Connection = null\n  connection = DriverManager.getConnection(url, user, password)\n</pre>\n';

}
return __p
};

this["Templates"]["golang_postgresql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Golang</h3>\n<pre>\n  import (\n    &#9;"database/sql"\n    &#9;_ "github.com/lib/pq"\n  )\n  db, err := sql.Open("postgres", os.Getenv("POSTGRESQL_ADDON_URI"))\n</pre>\n';

}
return __p
};

this["Templates"]["java_postgresql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Java</h3>\n<pre>\n  String url = "jdbc:postgresql://" + System.getenv("POSTGRESQL_ADDON_HOST") + "/" + System.getenv("POSTGRESQL_ADDON_DB");\n  String utilisateur = System.getenv("POSTGRESQL_ADDON_USER");\n  String motDePasse = System.getenv("POSTGRESQL_ADDON_PASSWORD");\n\n  Connection connexion = null;\n  connexion = DriverManager.getConnection( url, utilisateur, motDePasse );\n</pre>\n';

}
return __p
};

this["Templates"]["nodejs_postgresql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Node.JS</h3>\n<pre>\n  npm install pg --save\n\n  var pg = require(\'pg\');\n  pg.connect(process.env.POSTGRESQL_ADDON_URI, function(err, client, done){});\n</pre>\n';

}
return __p
};

this["Templates"]["php_postgresql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>PHP</h3>\n<pre>\n  $bdd = new PDO(\n    &#9;"pgsql:host=" . getenv("POSTGRESQL_ADDON_HOST") . ";dbname=" . getenv("POSTGRESQL_ADDON_DB"),\n    &#9;getenv("POSTGRESQL_ADDON_USER"),\n    &#9;getenv("POSTGRESQL_ADDON_PASSWORD")\n  )\n</pre>\n';

}
return __p
};

this["Templates"]["python_postgresql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Python</h3>\n<pre>\n  import psycopg2\n\n  psycopg2.connect(\n    &#9;host=os.environ["POSTGRESQL_ADDON_HOST"],\n    &#9;user=os.environ["POSTGRESQL_ADDON_USER"],\n    &#9;password=os.environ["POSTGRESQL_ADDON_PASSWORD"],\n    &#9;database=os.environ["POSTGRESQL_ADDON_DB"]\n  )\n</pre>\n';

}
return __p
};

this["Templates"]["ruby_postgresql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Ruby</h3>\n<pre>\n  require \'pg\'\n\n  PGconn.connect(\n    &#9;ENV["POSTGRESQL_ADDON_HOST"],\n    &#9;ENV["POSTGRESQL_ADDON_PORT"],\n    &#9;nil,\n    &#9;nil,\n    &#9;ENV["POSTGRESQL_ADDON_DB"],\n    &#9;ENV["POSTGRESQL_ADDON_USER"],\n    &#9;ENV["POSTGRESQL_ADDON_PASSWORD"]\n  )\n</pre>\n';

}
return __p
};

this["Templates"]["scala_postgresql_addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h3>Scala</h3>\n<pre>\n  val url = "jdbc:postgresql://" + sys.env("POSTGRESQL_ADDON_HOST") + "/" + sys.env("POSTGRESQL_ADDON_DB")\n  val user = sys.env("POSTGRESQL_ADDON_USER")\n  val password = sys.env("POSTGRESQL_ADDON_PASSWORD")\n  val connection:Connection = null\n\n  connection = DriverManager.getConnection(url, user, password)\n</pre>\n';

}
return __p
};

this["Templates"]["AddonCreationSP.apps"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<cc-block class="centered-maxed-width-container">\n  <div slot="header-title">' +
__e( T("SELECT_THE_APPS_ETC") ) +
'</div>\n  <div slot="content" class="bloc">\n    <div class="bloc-content">\n      <table class="apps">\n        <thead>\n        <tr>\n          <th>Type</th>\n          <th>' +
__e( T("NAME") ) +
'</th>\n          <th></th>\n        </tr>\n        </thead>\n        <tbody>\n        ';
 _.each(apps, function(app) { ;
__p += '\n        <tr>\n          <td><img class="addon-creation_logo" src="' +
__e( app.variantLogoUrl ) +
'" alt=""></td>\n          <td>' +
__e( app.name ) +
'</td>\n          <td>\n            <cc-toggle class="addon-creation_appLinkToggle" data-app-id="' +
__e( app.id ) +
'" choices=\'[{"label":"' +
__e( T("UNLINK") ) +
'","value":"unlink"}, {"label":"' +
__e( T("LINK") ) +
'","value":"link"}]\' value="' +
__e( app.linked ? 'link' : 'unlink' ) +
'"></cc-toggle>\n          </td>\n        </tr>\n        ';
 }); ;
__p += '\n        </tbody>\n      </table>\n    </div>\n  </div>\n  <cc-button slot="footer" class="next btn-bottom" primary>' +
__e( T("NEXT") ) +
'</cc-button>\n</cc-block>\n';

}
return __p
};

this["Templates"]["AddonCreationSP.information"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<cc-block class="centered-maxed-width-container">\n  <div slot="header-title">' +
__e( T("console.addon-creation.what-information", provider) ) +
'</div>\n\n  <div slot="content" class="form-field">\n    <label class="label-large" for="addon-name">' +
((__t = ( T("NAME") )) == null ? '' : __t) +
': *</label>\n    <input required name="name" id="addon-name" type="text" class="input-large" value="' +
__e( name ) +
'" autocomplete="off" />\n  </div>\n\n  ';
 if(versions && versions.length > 0) { ;
__p += '\n    <!-- don\'t display available versions for clusters for now -->\n    ';
 if(planType !== "clusters") { ;
__p += '\n      <div slot="content" class="form-field">\n        <label class="label-large" for="addon-version">' +
__e( T("VERSION") ) +
'</label>\n        <label class="label-select">\n          <select class="versions select-large" name="version" id="addon-version">\n            ';
 _.each(_.reverse(versions), function(version) { ;
__p += '\n              <option value="' +
__e( version ) +
'" ' +
__e( defaultVersion === version ? 'selected=selected' : '' ) +
'>\n            ' +
__e( version ) +
'\n            </option>\n            ';
});
__p += '\n          </select>\n        </label>\n      </div>\n    ';
};
__p += '\n  ';
};
__p += '\n\n  <div slot="content" class="form-field">\n    <label class="label-large">' +
((__t = ( T("ZONE") )) == null ? '' : __t) +
': *</label>\n    <cc-zone-input state=\'' +
__e( JSON.stringify(zoneInputState) ) +
'\' selected="' +
__e( (region != null) ? region : 'par' ) +
'" style="height: 380px"></cc-zone-input>\n  </div>\n\n  <button slot="footer" class="btn btn-blue next" type="submit" style="margin: 0.5em 1em 1em">' +
__e( T("NEXT") ) +
'</button>\n</cc-block>\n';

}
return __p
};

this["Templates"]["AddonCreationSP.providers"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<cc-block>\n  ';
 if (embeded) { ;
__p += '\n  <div slot="header-title">' +
((__t = ( T("console.addon-creation.do-you-need-an-addon") )) == null ? '' : __t) +
'</div>\n  ';
 } ;
__p += '\n\n  ';
 if (embeded) { ;
__p += '\n  <div slot="content"><button type="button" class="addonCreation_noAddonBtn btn btn-blue">' +
((__t = ( T("I_DONT_NEED_ANY_ADDON") )) == null ? '' : __t) +
'</button></div>\n  ';
 } ;
__p += '\n\n  <ul slot="content" class="addonCreation_choices list-providers providers">\n    ';
 _.each(providers, (provider) => { ;
__p += '\n    <li class="addonCreation_choice_item">\n      <img class="addonCreation_choice_logo" src="' +
__e( provider.logoUrl ) +
'" alt="' +
__e( provider.name ) +
'">\n      ';
 if (provider.privateIcon) { ;
__p += '\n      <div class="addonCreation_choice_private"><cc-icon icon="' +
__e( provider.privateIcon ) +
'" a11y-name="' +
__e( T('console.addon-creation.private') ) +
'"></cc-icon></div>\n      ';
 } ;
__p += '\n      <div class="addonCreation_choice_name">' +
__e( provider.name ) +
'</div>\n      <div class="addonCreation_choice_description">' +
__e( provider.shortDesc ) +
'</div>\n      <cc-button class="addonCreation_choice_btn" data-provider="' +
__e( provider.id ) +
'">' +
__e( T("console.addon-creation.select-provider") ) +
'</cc-button>\n    </li>\n    ';
 }); ;
__p += '\n  </ul>\n\n  ';
 if (embeded) { ;
__p += '\n  <div slot="footer"><button type="button" class="addonCreation_noAddonBtn btn btn-blue">' +
((__t = ( T("I_DONT_NEED_ANY_ADDON") )) == null ? '' : __t) +
'</button></div>\n  ';
 } ;
__p += '\n</cc-block>\n';

}
return __p
};

this["Templates"]["AddonPlans.plans"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<cc-block>\n  <div slot="header-title">' +
__e( T("console.addon-creation.what-plan", provider) ) +
'</div>\n  <div slot="content" class="bloc bloc-x-overflow">\n    <div class="bloc-content">\n      <table class="addons-plans">\n        <tbody>\n        <tr>\n          <th> </th>\n          <th><strong>' +
__e( T('PLAN_NAME') ) +
'</strong></th>\n          ';
 _.each(provider.plans[0].features, function(feature) { ;
__p += '\n          <th data-align="' +
__e( feature.rightAligned ? 'right' : 'left' ) +
'"> ' +
((__t = ( feature.name )) == null ? '' : __t) +
' </th>\n          ';
 }); ;
__p += '\n        </tr>\n        ';
 _.each(provider.plans, function(plan) { ;
__p += '\n        <tr data-plan="' +
__e( plan.id ) +
'" class="' +
__e( selectedPlan === plan.id ? 'addon-plan-selected' : '' ) +
'">\n          <td>\n            <input type="radio" name="price" value="" data-plan="' +
__e( plan.id ) +
'" ' +
__e( plan.id === selectedPlan ? 'checked' : '' ) +
'/>\n          </td>\n          <td>' +
__e( plan.name ) +
'</td>\n          ';
 _.each(plan.features, function(feature) { ;
__p += '\n          <td data-align="' +
__e( feature.rightAligned ? 'right' : 'left' ) +
'">' +
((__t = ( feature.value )) == null ? '' : __t) +
'</td>\n          ';
 }); ;
__p += '\n        </tr>\n        ';
 }); ;
__p += '\n        </tbody>\n      </table>\n    </div>\n  </div>\n  <cc-button slot="footer" class="next btn-bottom" primary ' +
__e( selectedPlan ? '' : 'disabled="disabled"' ) +
'>' +
__e( buttonText ) +
'</cc-button>\n</cc-block>\n';

}
return __p
};

this["Templates"]["addon-provider-add-feature"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-container">\n  <div class="bloc">\n    <div class="bloc-popin">\n      <h2>\n        ' +
((__t = ( T("console.addon-provider.add-feature-title") )) == null ? '' : __t) +
'\n      </h2>\n      <p class="bloc-description">\n        ' +
((__t = ( T("console.addon-provider.add-feature-desc") )) == null ? '' : __t) +
'\n      </p>\n    </div>\n    <div class="bloc-content">\n      <form action="#">\n        <table class="plans">\n          <thead>\n            <tr>\n              <th>' +
((__t = ( T("PLAN_NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("SLUG_NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("PRICE") )) == null ? '' : __t) +
'</th>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              <th>' +
__e( feature.name ) +
'</th>\n              ';
 }); ;
__p += '\n              <th>\n                <input class="input-medium" type="text" name="name" placeholder="' +
((__t = ( T('NAME') )) == null ? '' : __t) +
'" />\n              </th>\n            </tr>\n          </thead>\n          <tbody>\n            ';
 _.each(plans, function(plan) { ;
__p += '\n            ';
 var planFeatures = _.groupBy(plan.features, "name"); ;
__p += '\n            <tr>\n              <td>\n                <strong>\n                  ' +
__e( plan.name ) +
'\n                </strong>\n              </td>\n              <td>' +
__e( plan.slug ) +
'</td>\n              <td>' +
__e( plan.price ) +
'</td>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              ';
 var planFeature = planFeatures[feature.name] && planFeatures[feature.name][0]; ;
__p += '\n              <td>' +
__e( planFeature ? planFeature.value : "" ) +
'</td>\n              ';
 }); ;
__p += '\n              <td><input class="input-medium" type="text" name="value-for-' +
__e( plan.id ) +
'" /></td>\n            </tr>\n            ';
 }); ;
__p += '\n          </tbody>\n        </table>\n\n        <button type="reset" class="btn cancel">' +
((__t = ( T("CANCEL") )) == null ? '' : __t) +
'</button>\n        <button type="submit" class="btn btn-green add-feature">' +
((__t = ( T("ADD_FEATURE") )) == null ? '' : __t) +
'</button>\n      </form>\n    </div>\n  </div>\n</div>';

}
return __p
};

this["Templates"]["addon-provider-add-plan"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-container">\n  <div class="bloc">\n    <div class="bloc-popin">\n      <h2>\n        ' +
((__t = ( T("console.addon-provider.add-plan-title") )) == null ? '' : __t) +
'\n      </h2>\n      <p class="bloc-description">\n        ' +
((__t = ( T("console.addon-provider.add-plan-desc") )) == null ? '' : __t) +
'\n      </p>\n    </div>\n    <div class="bloc-content">\n      <form action="#">\n        <table class="plans">\n          <thead>\n            <tr>\n              <th>' +
((__t = ( T("PLAN_NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("SLUG_NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("PRICE") )) == null ? '' : __t) +
'</th>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              <th>' +
__e( feature.name ) +
'</th>\n              ';
 }); ;
__p += '\n            </tr>\n          </thead>\n          <tbody>\n            ';
 _.each(plans, function(plan) { ;
__p += '\n            ';
 var planFeatures = _.groupBy(plan.features, "name"); ;
__p += '\n            <tr>\n              <td>' +
__e( plan.name ) +
'</td>\n              <td>' +
__e( plan.slug ) +
'</td>\n              <td>' +
__e( plan.price ) +
'</td>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              ';
 var planFeature = planFeatures[feature.name] && planFeatures[feature.name][0]; ;
__p += '\n              <td>' +
__e( planFeature ? planFeature.value : "" ) +
'</td>\n              ';
 }); ;
__p += '\n            </tr>\n            ';
 }); ;
__p += '\n            <tr>\n              <td><input class="input-medium" type="text" name="name" placeholder="' +
((__t = ( T('PLAN_NAME') )) == null ? '' : __t) +
'" /></td>\n              <td><input class="input-medium" type="text" name="slug" placeholder="' +
((__t = ( T('SLUG_NAME') )) == null ? '' : __t) +
'" /></td>\n              <td><input class="input-medium" type="text" name="price" placeholder="' +
((__t = ( T('PRICE') )) == null ? '' : __t) +
'" /></td>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              <td><input class="input-medium" type="text" name="feature-' +
__e( feature.name ) +
'" /></td>\n              ';
 }); ;
__p += '\n            </tr>\n          </tbody>\n        </table>\n\n        <button type="reset" class="btn cancel">' +
((__t = ( T("CANCEL") )) == null ? '' : __t) +
'</button>\n        <button type="submit" class="btn btn-green add-plan">' +
((__t = ( T("ADD_PLAN") )) == null ? '' : __t) +
'</button>\n      </form>\n    </div>\n  </div>\n</div>';

}
return __p
};

this["Templates"]["addon-provider-edit-plans"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-container">\n  <div class="bloc">\n    <div class="bloc-popin">\n      <h2>\n        ' +
((__t = ( T("console.addon-provider.edit-plan-title") )) == null ? '' : __t) +
'\n      </h2>\n      <p class="bloc-description">\n        ' +
((__t = ( T("console.addon-provider.edit-plan-desc") )) == null ? '' : __t) +
'\n      </p>\n    </div>\n    <div class="bloc-content">\n      <form action="#">\n        <table class="plans">\n          <thead>\n            <tr>\n              <th>' +
((__t = ( T("PLAN_NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("SLUG_NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("PRICE") )) == null ? '' : __t) +
'</th>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              <th data-feature="' +
__e( feature.name ) +
'">' +
__e( feature.name ) +
'&nbsp;<span class="remove ion-ios7-close-outline"></span></th>\n              ';
 }); ;
__p += '\n            </tr>\n          </thead>\n          <tbody>\n            ';
 _.each(plans, function(plan) { ;
__p += '\n            ';
 var planFeatures = _.groupBy(plan.features, "name"); ;
__p += '\n            <tr data-plan="' +
__e( plan.id ) +
'">\n              <td>\n                <strong>\n                  <span class="remove ion-ios7-close-outline"></span>&nbsp;' +
__e( plan.name ) +
'\n                </strong>\n              </td>\n              <td><input class="input-medium" type="text" name="slug-' +
__e( plan.id ) +
'" value="' +
__e( plan.slug ) +
'" /></td>\n              <td><input class="input-medium" type="text" name="price-' +
__e( plan.id ) +
'" value="' +
__e( plan.price ) +
'" /></td>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              ';
 var planFeature = planFeatures[feature.name] && planFeatures[feature.name][0]; ;
__p += '\n              <td data-feature="' +
__e( feature.name ) +
'"><input class="input-medium" type="text" name="feature-' +
__e( feature.name ) +
'-' +
__e( plan.id ) +
'" value="' +
__e( planFeature ? planFeature.value : '' ) +
'" /></td>\n              ';
 }); ;
__p += '\n            </tr>\n            ';
 }); ;
__p += '\n          </tbody>\n        </table>\n\n        <button type="reset" class="btn cancel">' +
((__t = ( T("CANCEL") )) == null ? '' : __t) +
'</button>\n        <button type="submit" class="btn btn-green edit-plans">' +
((__t = ( T("console.addon-provider.apply-plans") )) == null ? '' : __t) +
'</button>\n      </form>\n    </div>\n  </div>\n</div>';

}
return __p
};

this["Templates"]["addon-provider-plans"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-container">\n  <div class="bloc">\n    <div class="bloc-popin">\n      <h2>\n        ' +
((__t = ( T("console.addon-provider.plans-title") )) == null ? '' : __t) +
'\n      </h2>\n      <p class="bloc-description">\n        ' +
((__t = ( T("console.addon-provider.plans-desc") )) == null ? '' : __t) +
'\n      </p>\n    </div>\n    <div class="bloc-content">\n      <table class="plans">\n          <thead>\n            <tr>\n              <th>' +
((__t = ( T("PLAN_NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("SLUG_NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("PRICE") )) == null ? '' : __t) +
'</th>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              <th>' +
__e( feature.name ) +
'</th>\n              ';
 }); ;
__p += '\n            </tr>\n          </thead>\n          <tbody>\n            ';
 _.each(plans, function(plan) { ;
__p += '\n            ';
 var planFeatures = _.groupBy(plan.features, "name"); ;
__p += '\n            <tr>\n              <td><strong>' +
__e( plan.name ) +
'</strong></td>\n              <td>' +
__e( plan.slug ) +
'</td>\n              <td>' +
__e( plan.price ) +
'</td>\n              ';
 _.each(features, function(feature) { ;
__p += '\n              ';
 var planFeature = planFeatures[feature.name] && planFeatures[feature.name][0]; ;
__p += '\n              <td>' +
__e( planFeature ? planFeature.value : "" ) +
'</td>\n              ';
 }); ;
__p += '\n            </tr>\n            ';
 }); ;
__p += '\n          </tbody>\n        </table>\n\n      <button type="button" class="btn btn-blue add-plan">' +
((__t = ( T("ADD_PLAN") )) == null ? '' : __t) +
'</button>\n      <button type="button" class="btn btn-blue add-feature">' +
((__t = ( T("ADD_FEATURE") )) == null ? '' : __t) +
'</button>\n      <button type="button" class="btn btn-blue edit-plans">' +
((__t = ( T("EDIT_PLANS") )) == null ? '' : __t) +
'</button>\n    </div>\n  </div>\n</div>';

}
return __p
};

this["Templates"]["AppCreationSP.deployment-type"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="the-card">\n  <ul class="appCreation_choices">\n    ';
 _.each(types, (type) => { ;
__p += '\n    <li class="appCreation_choice_item">\n      <button type="button" class="appCreation_choice_btn" data-type="' +
__e( type ) +
'">\n        ';
 const logoName = (type === 'ftp') ? 'sftp' : type; ;
__p += '\n        <img class="appCreation_choice_logo" src="https://assets.clever-cloud.com/logos/' +
__e( logoName ) +
'.svg" alt="' +
__e( type ) +
'">\n        <span class="appCreation_choice_name">' +
__e( type ) +
'</span>\n      </button>\n    </li>\n    ';
 }); ;
__p += '\n  </ul>\n</div>\n';

}
return __p
};

this["Templates"]["AppCreationSP.github-error"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h4>' +
((__t = ( T("console.app-creation.github-restrictions") )) == null ? '' : __t) +
'</h4>\n\n<ul class="app-creation-github-steps">\n  ';
 _.each(steps, function(step) { ;
__p += '\n  <li>' +
((__t = ( T("console.app-creation.github-restrictions." + step) )) == null ? '' : __t) +
'</li>\n  ';
 }); ;
__p += '\n</ul>\n';

}
return __p
};

this["Templates"]["AppCreationSP.github"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="github-apps">\n\n  <div class="fresh-new-app info-box">\n    <h4>' +
__e( T("console.app-creation.github-apps.local-repo.title") ) +
'</h4>\n    <p>' +
__e( T("console.app-creation.github-apps.local-repo.body") ) +
'</p>\n    <div class="new-app-button">\n      <button class="btn btn-blue new-app new-app" data-app="">' +
__e( T("console.app-creation.github-apps.local-repo.validate") ) +
'</button>\n    </div>\n  </div>\n  <div class="github-dropdown info-box">\n    <h4>' +
__e( T("console.app-creation.github-apps.github-repo.title") ) +
'</h4>\n    ';
 if(githubApps){;
__p += '\n      <div class="loading-github-apps loading-container">' +
__e( T("LOADING_GITHUB_APPS") ) +
'\n        <div class="loader">\n          <div class="loader-inner line-scale"><div></div><div></div><div></div><div></div><div></div></div>\n        </div>\n      </div>\n      <div class="github-apps-content">\n        <p>' +
__e( T("console.app-creation.github-apps.github-repo.body") ) +
'</p>\n      </div>\n    ';
} else{ ;
__p += '\n      <div class="link-github">\n        <p>' +
__e( T("console.app-creation.github.github-link") ) +
'</p>\n        <div class="link-github-button">\n          <button type="button" class="btn btn-blue">' +
__e( T("LINK_YOUR_GITHUB_ACCOUNT") ) +
'</button>\n        </div>\n      </div>\n    ';
};
__p += '\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AppCreationSP.information"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<cc-block class="centered-maxed-width-container">\n\n  ';
 var nameError = typeof error != "undefined" && error.fields && error.fields.name || ""; ;
__p += '\n  ';
 var descriptionError = typeof error != "undefined" && error.fields && error.fields.description || ""; ;
__p += '\n\n  <div slot="header-title">' +
((__t = ( T("console.app-creation.app-information") )) == null ? '' : __t) +
'</div>\n\n  <div slot="content" class="form-field">\n    <label class="label-large" for="application-name">' +
((__t = ( T("NAME") )) == null ? '' : __t) +
': *</label>\n    <input name="application-name" type="text" class="input-large" value="' +
__e( name ? name : (information ? information.name : '') ) +
'" id="application-name" data-status="' +
__e( nameError ? 'error' : '' ) +
'" autocomplete="off"/>\n    <span class="input-helper">' +
__e( nameError ) +
'</span>\n  </div>\n\n  <div slot="content" class="form-field">\n    <label class="label-large" for="application-description">' +
((__t = ( T("DESCRIPTION") )) == null ? '' : __t) +
':</label>\n    <input name="application-description" type="text" class="input-large" value="' +
__e( information && information.description ) +
'" id="application-description" data-status="' +
__e( descriptionError ? 'error' : '' ) +
'"/>\n    <span class="input-helper">' +
__e( descriptionError ) +
'</span>\n  </div>\n\n  <div slot="content" class="form-field">\n    <label class="label-large">' +
((__t = ( T("ZONE") )) == null ? '' : __t) +
': *</label>\n    <cc-zone-input state=\'' +
__e( JSON.stringify(zoneInputState) ) +
'\' selected="par" style="height: 380px"></cc-zone-input>\n  </div>\n\n  <div slot="footer">\n    <button type="submit" class="create btn btn-blue btn-bottom">' +
((__t = ( T("CREATE") )) == null ? '' : __t) +
'</button>\n  </div>\n\n</cc-block>\n';

}
return __p
};

this["Templates"]["AppCreationSP.instance-type"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<cc-block>\n\n  <div slot="header-title">' +
((__t = ( T("console.app-creation.what-kind-of-app") )) == null ? '' : __t) +
'</div>\n\n  <div slot="content-body" class="documentation">' +
((__t = ( T("DOC_ABOUT_APPS") )) == null ? '' : __t) +
'</div>\n\n  <ul slot="content-body" class="appCreation_choices instances">\n    ';
 _.each(instances, (instance) => { ;
__p += '\n    <li class="appCreation_choice_item">\n      <button type="button" class="appCreation_choice_btn" data-instance="' +
__e( instance.type ) +
'" data-variant="' +
__e( instance.variant.id ) +
'">\n        <img class="appCreation_choice_logo" src="' +
__e( instance.variant.logo ) +
'" alt="' +
__e( instance.variant.name ) +
'">\n        <span class="appCreation_choice_name">' +
__e( instance.variant.name ) +
'</span>\n      </button>\n    </li>\n    ';
 }); ;
__p += '\n  </ul>\n\n</cc-block>\n';

}
return __p
};

this["Templates"]["AppCreationSP.suggested-configuration"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p +=
((__t = ( T("console.app-creation.scaling-suggestion", {
  flavorName: flavorName,
  memory: memory,
  cpus: cpus,
  price: price
}) )) == null ? '' : __t) +
'\n\n<div>\n  <button type="button" class="btn btn-blue btn-right next">' +
((__t = ( T("NEXT") )) == null ? '' : __t) +
'</button>\n  <button type="button" class="btn btn-right edit">' +
((__t = ( T("EDIT") )) == null ? '' : __t) +
'</button>\n</div>\n';

}
return __p
};

this["Templates"]["app-deployment.deploying"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h4 class="app-deployment-deploying">' +
((__t = ( message )) == null ? '' : __t) +
'</h4>\n';

}
return __p
};

this["Templates"]["app-deployment.instructions.git"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h4>' +
((__t = ( T("console.app-deployment.before-pushing") )) == null ? '' : __t) +
'</h4>\n\n<ul class="app-deployment-tips">\n  ';
 _.each(tips, function(tip) { ;
__p += '\n    <li>' +
((__t = ( tip )) == null ? '' : __t) +
'</li>\n  ';
 }); ;
__p += '\n  ';
 if(documentationInfos.link && documentationInfos.name){ ;
__p += '\n    <li>\n      ' +
((__t = ( T("console.app-creation.tips.doc-available", {
        instanceType: documentationInfos.name || "",
        docUrl: documentationInfos.link || "",
      }) )) == null ? '' : __t) +
'\n    </li>\n  ';
};
__p += '\n</ul>\n\n';
 if(app.deployment.repoState !== 'NOT_NEEDED'){ ;
__p += '\n  <h4>' +
((__t = ( T("console.app-deployment.deployment-git-instructions") )) == null ? '' : __t) +
'</h4>\n\n  <div class="boxes">\n    <div class="boxes-body">\n      <pre>\n        <code>\n          git remote add clever ' +
__e( app.deployUrl ) +
'\n          git push -u clever master\n          <span class="code-comment"># git push -u clever branch:master if you want to push a specific branch</span>\n        </code>\n      </pre>\n    </div>\n  </div>\n';
};
__p += '\n\n';
 if(app.deployment.repoState === 'NOT_NEEDED'){ ;
__p += '\n  <p class="repo-creation-text">' +
__e( T("console.app-deployment.starting-github") ) +
'</p>\n';
 } else{ ;
__p += '\n  <p class="repo-creation-text">' +
((__t = ( T("console.app-deployment.waiting-for-git-push") )) == null ? '' : __t) +
'</p>\n';
};
__p += '\n<div class="git-loader">\n\t<div class=\'loader\'>\n\t  <div class=\'bar\'></div>\n\t  <div class=\'bar\'></div>\n\t  <div class=\'bar\'></div>\n\t  <div class=\'bar\'></div>\n\t  <div class=\'bar\'></div>\n\t</div>\n</div>\n';

}
return __p
};

this["Templates"]["app-deployment.ssh-keys"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="app-deployment-ssh-keys">\n\t<h4>' +
((__t = ( T("console.app-deployment.add-ssh-key") )) == null ? '' : __t) +
'</h4>\n\n\t<div class="ssh-keys-container">\n\t\t<div class="documentation ssh-keys-doc"></div>\n\t\t<div class="bloc bloc-ssh-keys">\n\t\t<div class="bloc-popin">\n\t\t\t<h2>' +
((__t = ( T("SSH_KEYS") )) == null ? '' : __t) +
'</h2>\n\t\t</div>\n\t\t<div class="bloc-content">\n\t\t\t<ul class="ssh-keys">\n\t\t\t<li>' +
((__t = ( T("LOADING") )) == null ? '' : __t) +
'</li>\n\t\t\t</ul>\n\t\t</div>\n\t\t</div>\n\n\t\t<div class="bloc bloc-ssh-keys github-bloc" style="display:none">\n\t\t<div class="bloc-popin">\n\t\t\t<h2>' +
((__t = ( T("GITHUB_SSH_KEYS") )) == null ? '' : __t) +
'</h2>\n\t\t</div>\n\t\t<div class="bloc-content">\n\t\t\t<ul class="github-ssh-keys"></ul>\n\t\t</div>\n\t\t</div>\n\t</div>\n\n\t<button class="btn btn-blue next next-ssh-keys btn-right">' +
__e( T("NEXT") ) +
'</button>\n</div>';

}
return __p
};

this["Templates"]["app-deployment.waiting-for-instance"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h4>' +
((__t = ( T("console.app-deployment.wait-for-instance") )) == null ? '' : __t) +
' <span class="ion-load-c ion-loading-c"></span></h4>\n';

}
return __p
};

this["Templates"]["apps-pane"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="l-apps">\n  <link rel="preload" as="image" href="/img/app-status/restart-failed.svg">\n  <link rel="preload" as="image" href="/img/app-status/restarting.svg">\n  <link rel="preload" as="image" href="/img/app-status/restarting-with-downtime.svg">\n  <link rel="preload" as="image" href="/img/app-status/running.svg">\n  <link rel="preload" as="image" href="/img/app-status/start-failed.svg">\n  <link rel="preload" as="image" href="/img/app-status/starting.svg">\n  <link rel="preload" as="image" href="/img/app-status/stopped.svg">\n  <link rel="preload" as="image" href="/img/app-status/unknown.svg">\n  <div class="l-apps_inner">\n    <div data-tpl="header"></div>\n    <div data-tpl="ticket-center"></div>\n    <div data-tpl="create-menu"></div>\n    <div data-tpl="filter"></div>\n    <div class="l-apps_body">\n      <div class="l-apps_bodyMain">\n        <div data-tpl="messages"></div>\n        <div data-tpl="apps-active-addons"></div>\n        <div data-tpl="oauth-consumers"></div>\n        <div data-tpl="addon-providers"></div>\n      </div>\n      <div data-tpl="apps-archived"></div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["apps-pane.addon-providers"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (menuItems.length > 0) { ;
__p += '\n<div class="menuList">\n  <div class="menuList_title">' +
__e( T('console3.apps-pane.addon-providers') ) +
' (' +
__e( menuItems.length ) +
')</div>\n  <ul>\n    ' +
((__t = ( Templates['apps-pane.menu-items']({ menuItems }) )) == null ? '' : __t) +
'\n  </ul>\n</div>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["apps-pane.app-status"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<img class="menuItem_icon menuItem_icon--status" data-gravity="w"\n    src="/img/app-status/' +
__e( status ) +
'.svg"\n    title="' +
__e( T(`console3.appStatus.${status}`) ) +
'">\n';

}
return __p
};

this["Templates"]["apps-pane.apps-active-addons"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (menuItems.length > 0) { ;
__p += '\n<ul class="menuList">\n  ' +
((__t = ( Templates['apps-pane.menu-items']({ menuItems }) )) == null ? '' : __t) +
'\n</ul>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["apps-pane.apps-archived"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (apps.length > 0) { ;
__p += '\n<div class="archivedApps" data-state="' +
__e( toggleState ) +
'">\n  <button class="archivedApps_toggle">\n    <i class="icon ion-filing"></i>\n    <span>' +
__e( T('console3.apps-pane.archives') ) +
' (' +
__e( apps.length ) +
')</span>\n    <i class="icon ion-arrow-right-b"></i>\n    <i class="icon ion-arrow-down-b"></i>\n  </button>\n  <!-- We need to use scrollIntoView and we need alignement -->\n  <!-- this invisible div was the only way to do it -->\n  <div class="archivedApps_anchor"></div>\n  <ul class="archivedApps_list">\n    ' +
((__t = ( Templates['apps-pane.menu-items']({ menuItems: apps }) )) == null ? '' : __t) +
'\n  </ul>\n</div>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["apps-pane.create-menu"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="l-apps_createMenu" data-state="' +
__e( toggleState ) +
'">\n\n  ';
if (newTunnelActive) { ;
__p += '\n  <a\n    class="l-apps_createMenu_new ' +
__e( ownerHasCreatedItems ? '' : 'l-apps_createMenu_new--new' ) +
'"\n    href="' +
__e( newTunnelHref ) +
'"\n  >\n    <i class="icon ion-plus-round"></i>\n    <span>' +
__e( T('console3.apps-create.create') ) +
'</span>\n  </a>\n  ';
 } ;
__p += '\n  ';
 if (!newTunnelActive) { ;
__p += '\n  <button class="l-apps_createMenu_toggle ' +
__e( ownerHasCreatedItems ? '' : 'l-apps_createMenu_toggle--new' ) +
'">\n    <i class="icon ion-plus-round"></i>\n    <span>' +
__e( T('console3.apps-create.create') ) +
'</span>\n    <i class="icon ion-arrow-right-b"></i>\n    <i class="icon ion-arrow-down-b"></i>\n  </button>\n  <ul class="l-apps_createMenu_list">\n    <li><a class="l-apps_createMenu_item" href="' +
__e( createAppHref ) +
'">' +
__e( T('console3.apps-create.application') ) +
'</a></li>\n    <li><a class="l-apps_createMenu_item" href="' +
__e( createAddonHref ) +
'">' +
__e( T('console3.apps-create.addon') ) +
'</a></li>\n    <li><a class="l-apps_createMenu_item" href="' +
__e( createOAuthConsumerHref ) +
'">' +
__e( T('console3.apps-create.oauth-consumer') ) +
'</a></li>\n    ';
 if (!isUser) { ;
__p += '\n    <li><a class="l-apps_createMenu_item" href="' +
__e( createAddonProviderHref ) +
'">' +
__e( T('console3.apps-create.addon-provider') ) +
'</a></li>\n    ';
 } ;
__p += '\n  </ul>\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
};

this["Templates"]["apps-pane.filter"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (ownerHasCreatedItems) { ;
__p += '\n<div class="l-apps_filter">\n  <div class="l-apps_filterWrapper">\n    <i class="icon ion-search"></i>\n    <input class="l-apps_filterQuery" value="' +
__e( filterQuery ) +
'" spellcheck="false" placeholder="' +
__e( T('console3.apps-filter.placeholder') ) +
'">\n  </div>\n</div>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["apps-pane.header"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 const name = isUser ? T('console.panes.personal-space') : ownerName ;
__p += '\n<a class="l-apps_head ' +
__e( selected ? 'selected' : '' ) +
' ' +
__e( cleverEnterprise ? 'l-apps_head--enterprise' : '' ) +
'"\n    href="' +
__e( ownerHref ) +
'"\n    title="' +
__e( name ) +
'' +
__e( cleverEnterprise ? ' (Clever Cloud Enterprise)' : '' ) +
'" data-gravity="w">\n  <i class="icon ion-home"></i>\n  <span>' +
__e( name ) +
'</span>\n  ';
 if(cleverEnterprise) { ;
__p += '\n    <cc-icon icon="' +
__e( iconCleverEnterprise ) +
'"></cc-icon>\n  ';
 } ;
__p += '\n</a>\n';

}
return __p
};

this["Templates"]["apps-pane.menu-items"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(menuItems, ({ selected, linkUrl, logoUrl, logoLabel, name, status, id }) => { ;
__p += '\n<li class="menuItem ' +
__e( selected ? 'selected' : '' ) +
'" data-app="' +
__e( id ) +
'">\n  <a class="menuItem_link" href="' +
__e( linkUrl ) +
'">\n    <img class="menuItem_icon menuItem_icon--main" src="' +
__e( logoUrl ) +
'" alt="' +
__e( logoLabel ) +
'">\n    <span class="menuItem_name">' +
__e( name ) +
'</span>\n    ';
 if (status != null) { ;
__p += '\n    ' +
((__t = ( Templates['apps-pane.app-status']({ status }) )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n  </a>\n</li>\n';
 }); ;
__p += '\n';

}
return __p
};

this["Templates"]["apps-pane.messages"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (!ownerHasCreatedItems || filterResultsAreEmpty) { ;
__p += '\n<div class="l-apps_messages_wrapper">\n  <div class="l-apps_messages">\n    ';
 if (!ownerHasCreatedItems) { ;
__p += '\n    <div class="l-apps_messages_line">' +
__e( T('console3.apps-messages.empty-1') ) +
'</div>\n    <div class="l-apps_messages_line">' +
((__t = ( T('console3.apps-messages.empty-2') )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n    ';
 if (ownerHasCreatedItems && filterResultsAreEmpty) { ;
__p += '\n    <div class="l-apps_messages_line">' +
__e( T('console3.apps-messages.no-results') ) +
'</div>\n    <div class="l-apps_messages_line"><strong>' +
__e( filterQuery ) +
'</strong></div>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["apps-pane.oauth-consumers"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (menuItems.length > 0) { ;
__p += '\n<div class="menuList">\n  <div class="menuList_title">' +
__e( T('console3.apps-pane.oauth-consumers') ) +
' (' +
__e( menuItems.length ) +
')</div>\n  <ul>\n    ' +
((__t = ( Templates['apps-pane.menu-items']({ menuItems }) )) == null ? '' : __t) +
'\n  </ul>\n</div>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["apps-pane.ticket-center"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<a class="l-apps_ticketCenter ' +
__e( ticketCenterSelected ? 'selected' : '' ) +
'"\n    href="' +
__e( ownerHref ) +
'/ticket-center" data-gravity="w">\n  <i class="icon ion-chatboxes"></i> ' +
__e( T("console.ticket-center.contact") ) +
'\n</a>\n';

}
return __p
};

this["Templates"]["Avatar"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="avatar-desc">\n  <label class="label-large">\n    ' +
((__t = ( isOrga ? T("ORGANISATION_MANAGE_AVATAR") : T("USER_MANAGE_AVATAR") )) == null ? '' : __t) +
'\n  </label>\n  <span class="avatar-requirements">\n    ' +
((__t = ( T("AVATAR_REQUIREMENTS") )) == null ? '' : __t) +
'\n  </span>\n  <div class="choose-avatars">\n    <div class="custom-avatar">\n      <div class="avatar">\n        ';
 if(avatarUrl && avatarType == "ccapi") { ;
__p += '\n          <img src="' +
__e( avatarUrl ) +
'" class="avatar-img"/>\n        ';
 } else { ;
__p += '\n          <span class="no-avatar ion ion-android-social-user"></span>\n        ';
 } ;
__p += '\n      </div>\n      <div class="avatar-upload">\n        <button class="btn upload">' +
((__t = ( T("AVATAR_UPLOAD_FILE") )) == null ? '' : __t) +
'</button>\n      </div>\n      <div class="chosen-avatar">\n        <span class="ion ion-checkmark-circled"></span>\n      </div>\n      <input type="file" class="upload-avatar-hidden" accept="image/*" />\n    </div>\n    ';
 if(gravatarAvatar) { ;
__p += '\n      <div class="gravatar-avatar">\n        <div class="avatar gravatar-pic">\n          <img src="' +
__e( gravatarAvatar ) +
'" class="avatar-img" />\n        </div>\n        <div class="choose-this-avatar">\n          <button class="choose-avatar btn" data-source="gravatar">' +
((__t = ( T("AVATAR_USE_GRAVATAR") )) == null ? '' : __t) +
'</button>\n        </div>\n        <div class="chosen-avatar ' +
__e( avatarType == 'gravatar' ? 'active' : '' ) +
'">\n          <span class="ion ion-checkmark-circled"></span>\n        </div>\n      </div>\n    ';
 } ;
__p += '\n    ';
 if(githubAvatar) { ;
__p += '\n      <div class="github-avatar">\n        <div class="avatar">\n          <img src="' +
__e( githubAvatar ) +
'" class="avatar-img"/>\n        </div>\n        <div class="choose-this-avatar">\n          <button class="choose-avatar btn" data-source="github">' +
((__t = ( T("AVATAR_USE_GITHUB") )) == null ? '' : __t) +
'</button>\n        </div>\n        <div class="chosen-avatar ' +
__e( avatarType == 'github' ? 'active' : '' ) +
'">\n          <span class="ion ion-checkmark-circled"></span>\n        </div>\n      </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};

this["Templates"]["cards-list"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<ul class="credit-card-list">\n  ';
 if(cards.length > 0){ ;
__p += '\n    ';
 _.each(cards, function(card) { ;
__p += '\n      ';
 if(card.type === "CREDITCARD") { ;
__p += '\n        ';
 var number = card.number ? card.number.replace(/^(.{4})(.{4})(.{4})(.{4})$/, "$1 $2 $3 $4") : card.email; ;
__p += '\n        <li class="credit-card card-line ' +
__e( selectedCardId === card.token ? 'selected' : '' ) +
'">\n          ';
 if(selectCard === "radio") { ;
__p += '\n            <label>\n              <input type="radio" name="credit-card" data-token="' +
__e( card.token ) +
'" ' +
__e( selectedCardId === card.token ? 'checked="checked"' : '' ) +
' />\n              <div class="card-body">\n                <span>credit card - ' +
__e( number ) +
'</span>\n                ';
 if(card.preferredNetwork != null) { ;
__p += '\n                  <img class="card-body-network" src="' +
__e( card.networkLogo ) +
'" alt="' +
__e( card.networkLogoAlt ) +
'">\n                ';
 } ;
__p += '\n              </div>\n              <div class="card-body-holder">' +
__e( card.holderName ) +
'</div>\n            </label>\n          ';
 } ;
__p += '\n          ';
 if(selectCard === "button"){ ;
__p += '\n            <div class="card-body">credit card - ' +
__e( number ) +
'</div><div class="card-body-holder">' +
__e( card.holderName ) +
'</div>\n            ';
 if(selectedCardId === card.token){ ;
__p += '\n              <span class="card-selected">Selected</span>\n            ';
}else{ ;
__p += '\n              <button data-token="' +
__e( card.token ) +
'" class="btn btn-right">' +
__e( T("console.cards.use-this-card") ) +
'</button>\n            ';
};
__p += '\n          ';
};
__p += '\n        </li>\n      ';
} else if(card.type === "SEPA_DEBIT") {;
__p += '\n        <li class="credit-card card-line ' +
__e( selectedCardId === card.token ? 'selected' : '' ) +
'">\n          ';
 if(selectCard === "radio"){ ;
__p += '\n            <label>\n              <input type="radio" name="credit-card" data-token="' +
__e( card.token ) +
'" ' +
__e( selectedCardId === card.token ? 'checked="checked"' : '' ) +
' />\n              <div class="card-body">' +
__e( T("console.owner-cards.sepa-debit-account") ) +
' - ' +
__e( card.formattedNumber ) +
'</div>\n            </label>\n          ';
};
__p += '\n          ';
 if(selectCard === "button"){ ;
__p += '\n            <div class="card-body">' +
__e( T("console.owner-cards.sepa-debit-account") ) +
' - ' +
__e( card.formattedNumber ) +
' </div>\n            ';
 if(selectedCardId === card.token){ ;
__p += '\n              <span class="card-selected">Selected</span>\n            ';
}else{ ;
__p += '\n              <button data-token="' +
__e( card.token ) +
'" class="btn btn-right">' +
__e( T("console.cards.use-this-card") ) +
'</button>\n            ';
};
__p += '\n          ';
};
__p += '\n        </li>\n      ';
};
__p += '\n    ';
 }); ;
__p += '\n  ';
 } else{ ;
__p += '\n    <li class="no-credit-cards card-line">' +
__e( T("console.cards.no-credit-card") ) +
'</li>\n  ';
};
__p += '\n</ul>\n';

}
return __p
};

this["Templates"]["cards-new"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="add-card-container">\n  <h3>' +
__e( T("console.user-credit-cards.new-credit-card") ) +
'</h3>\n  <form id="payment-form">\n    <div class="form-row">\n      <div id="card-element"></div>\n      <div id="card-errors" class="alert" data-status="error" role="alert"></div>\n    </div>\n    <div class="submit-payment-container">\n      <button class="btn btn-blue submit-payment" disabled="disabled">' +
__e( T("console.owner-cards.add-payment-method") ) +
'</button>\n    </div>\n  </form>\n</div>';

}
return __p
};

this["Templates"]["cards"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-container">\n  ';
 if(cards && cards.length > 0) { ;
__p += '\n    <h3>' +
__e( T("CHOOSE_A_PAYMENT_METHOD") ) +
':</h3>\n  ';
};
__p += '\n\n  <div class="bloc">\n    <div class="bloc-content credit-cards">\n      <div class="credit-card-list-container"></div>\n      ';
 if(cardType === "CREDITCARD") { ;
__p += '\n        ' +
((__t = ( Templates["cards-new"]() )) == null ? '' : __t) +
'\n      ';
} else if(cardType === "SEPA_DEBIT") { ;
__p += '\n        ' +
((__t = ( Templates["sepa-new"]() )) == null ? '' : __t) +
'\n      ';
};
__p += '\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["sepa-new"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="add-sepa-container">\n  <h3>' +
__e( T("console.owner-cards.add-new-sepa") ) +
'</h3>\n  <form id="payment-sepa-form">\n    <div class="form-row inline sepa-details">\n      <div class="col col-name">\n        <label for="name">\n          ' +
__e( T("NAME") ) +
'\n        </label>\n        <cc-input-text id="name" name="name" placeholder="Jenny Rosen" required>\n      </div>\n      <div class="col col-email">\n        <label for="email">\n          ' +
__e( T("console.owner-cards.email-address") ) +
'\n        </label>\n        <cc-input-text\n        id="email"\n        name="email"\n        type="email"\n        placeholder="jenny.rosen@example.com"\n        required\n        >\n      </div>\n    </div>\n\n    <div class="form-row form-row-iban">\n      <div class="form-iban-element">\n        <label for="iban-element">\n          IBAN\n        </label>\n        <div id="iban-element">\n          <!-- A Stripe Element will be inserted here. -->\n        </div>\n      </div>\n\n      <div class="save-iban-container">\n        <button class="btn btn-blue submit-sepa" disabled="disabled">' +
__e( T("console.owner-cards.add-sepa") ) +
'</button>\n      </div>\n    </div>\n    <div id="sepa-error-message" role="alert" class="alert" data-status="error"></div>\n  </form>\n</div>';

}
return __p
};

this["Templates"]["headbar.addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header-context"><cc-input-text value="' +
__e( app.id ) +
'" readonly clipboard></cc-input-text></div>\n<h1>\n  ' +
__e( title ) +
' - ' +
__e( app.name || app.id ) +
'\n  ';
 if(beta){ ;
__p += '\n    <span class="beta-feature">beta</span>\n  ';
};
__p += '\n</h1>\n\n';

}
return __p
};

this["Templates"]["headbar.app"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header-context header-app"><cc-input-text value="' +
__e( app.id ) +
'" readonly clipboard></cc-input-text> <span class="headbar-context-action app-link"></span><span class="headbar-context-action deploy-button"></span></div>\n<h1>\n  ' +
__e( title ) +
' - ' +
__e( app.name || app.id ) +
'\n  ';
 if(beta){ ;
__p += '\n    <span class="beta-feature">beta</span>\n  ';
};
__p += '\n</h1>\n\n';

}
return __p
};

this["Templates"]["headbar.applink-button"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<a href="https://' +
__e( fqdn ) +
'" target="_blank" class="btn btn-headbar app-link" title="' +
__e( T('OPEN_MY_APP_IN_TAB') ) +
'" data-gravity="ne" >\n  <span class="ion-link"></span>\n</a>\n';

}
return __p
};

this["Templates"]["headbar.deploy-button"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<button type="button" class="btn btn-headbar deploy" title="' +
__e( T("RESTART_THIS_APP") ) +
'" data-gravity="ne">\n  <span class="ion-' +
__e( running ? 'refresh' : 'play' ) +
'"></span>\n</button>\n';

}
return __p
};

this["Templates"]["headbar.help"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="headbar-context help">\n    <span class="headbar-context-action help">\n        <a class="btn btn-headbar crisp-button"\n           href="/ticket-center-choice"\n           title="' +
__e( T('console.ticket-center.contact') ) +
'"\n           data-gravity="ne">\n            <span class="list-icon list-icon-big"><i class="icon ion-chatboxes"></i></span>\n        </a>\n    </span>\n</div>\n';

}
return __p
};

this["Templates"]["headbar.readlogs-button"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var href = (orgaId ? "/organisations/" + orgaId : "/users/me") + "/applications/" + appId + "/logs"; ;
__p += '\n<a href="' +
__e( href ) +
'" class="btn read-logs">' +
((__t = ( T("READ_LOGS") )) == null ? '' : __t) +
'</a>\n';

}
return __p
};

this["Templates"]["Metrics.advanced-charts-container"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="charts-container" data-metrics="' +
__e( key ) +
'">\n  <div class="charts-description">\n    <h4>' +
__e( description ) +
'</h4>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["Metrics.advanced-loading"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="chart-loading">\n  ' +
((__t = ( Templates["loader-bars"]() )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
};

this["Templates"]["Metrics.custom-view"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="bloc">\n    <div class="change-view-buttons">\n      <button class="btn btn-right">' +
__e( T("console.metrics.advanced") ) +
'</button>\n    </div>\n  <div class="bloc-popin">\n    <h2>Custom warpscript</h2>\n  </div>\n  <div class="bloc-content">\n    <div class="alert warpscript-error hidden" data-status="error"></div>\n    <textarea class="warpscript">' +
__e( warpscript ) +
'</textarea>\n    <button class="btn btn-primary btn-blue btn-right execute">Execute</button>\n    <div class="quantum-url">\n      <p>Quantum perma link:\n        <a href="#" class="quantum-link"></a>\n      </p>\n    </div>\n    <div class="warpscript-results hidden"></div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["Metrics.metrics-header"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="metrics-token metric-info">\n  <h3>' +
__e( T("console.metrics.metrics-token") ) +
'</h3>\n  <cc-input-text readonly clipboard secret value="' +
__e( token ) +
'" class="token"></cc-input-text>\n  <p>' +
__e( T("console.metrics.metrics-token-content") ) +
'</p>\n</div>\n<div class="metrics-host metric-info">\n  <h3>' +
__e( T("console.metrics.metrics-host") ) +
'</h3>\n  <cc-input-text readonly clipboard value="' +
__e( warp10Url ) +
'" class="warp10-url"></cc-input-text>\n  <p>' +
__e( T("console.metrics.metrics-host-content") ) +
' (<a href="https://www.warp10.io/doc/reference" target="_blank" rel="noopener noreferrer" class="warp10-url">Warp10</a>)</p>\n</div>\n<div class="metrics-quantum-shorturl metric-info">\n  <h3>' +
__e( T("console.metrics.metrics-explore") ) +
'</h3>\n  <p>' +
__e( T("console.metrics.metrics-explore-content") ) +
'<br>\n    <a href="' +
__e( metricsUrl ) +
'" class="warp10-url" target="_blank" rel="noopener noreferrer">' +
__e( T("console.metrics.metrics-explore-content-metrics") ) +
'</a> /\n    <a href="' +
__e( accessLogsUrl ) +
'" class="warp10-url" target="_blank" rel="noopener noreferrer">' +
__e( T("console.metrics.metrics-explore-content-access-logs") ) +
'</a>\n  </p>\n</div>\n';

}
return __p
};

this["Templates"]["modal"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal ' +
__e( customClasses.join(' ') ) +
'" id="modal">\n  <div class="modal-popin">\n    <h2>' +
__e( title  ) +
'</h2>\n    <div class="modal-content">\n      <form action="#">\n        ';
 if(type === "confirmation"){ ;
__p += '\n          <p>' +
((__t = ( body )) == null ? '' : __t) +
'</p>\n          ';
 if(input){ ;
__p += '\n            <input type="text" class="input-medium" name="user-input" required autocomplete="off" />\n          ';
};
__p += '\n          <div>\n            <button type="button" class="cancel btn">' +
__e( cancelButtonText ? cancelButtonText : T("CANCEL") ) +
'</button>\n            <button type="submit" class="btn ' +
__e( submitButtonClass ? submitButtonClass : 'btn-red' ) +
' ">' +
((__t = ( submitButtonText ? submitButtonText : T("REMOVE") )) == null ? '' : __t) +
'</button>\n          </div>\n        ';
} else if(type === "information"){ ;
__p += '\n          <p>' +
((__t = ( body )) == null ? '' : __t) +
'</p>\n          <div class="understood-btn">\n            <button class="btn btn-primary" type="submit">' +
__e( submitButtonText ? submitButtonText : T("UNDERSTOOD") ) +
'</button>\n          </div>\n        ';
} else if(type === 'user-input'){ ;
__p += '\n          ' +
((__t = ( body )) == null ? '' : __t) +
'\n          <button type="button" class="btn cancel">' +
__e( cancelButtonText ? cancelButtonText : T("CANCEL") ) +
'</button>\n          <button type="submit" class="btn btn-blue">' +
((__t = ( submitButtonText ? submitButtonText : T("SAVE") )) == null ? '' : __t) +
'</button>\n        ';
} else if(type === 'payment'){ ;
__p += '\n          ' +
((__t = ( body )) == null ? '' : __t) +
'\n          <button type="button" class="btn cancel">' +
__e( cancelButtonText ? cancelButtonText : T("CANCEL") ) +
'</button>\n          <button type="button" class="btn btn-red disable-payment">' +
__e( T("DISABLE") ) +
'</button>\n          <button type="submit" class="btn btn-blue">' +
((__t = ( submitButtonText ? submitButtonText : T("SAVE") )) == null ? '' : __t) +
'</button>\n        ';
} else if(type === 'custom'){ ;
__p += '\n          ' +
((__t = ( body )) == null ? '' : __t) +
'\n        ';
};
__p += '\n      </form>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["modal.addon-deletion-apps-linked"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p +=
__e( T("ADDON_TYPE_NAME_DELETE", {addonName: addonName}) ) +
'<br /><br />\n' +
__e( T("console.modals.addon-deletion-linked-apps") ) +
'<br /><br />\n\n';
 apps.forEach(function(app){ ;
__p += '\n  <a href="' +
__e( orgaId ? '/organisations/' + orgaId : '/users/me') +
'/applications/' +
__e( app.id ) +
'/addons">' +
__e( app.name ) +
' (' +
__e( app.id ) +
')</a><br />\n';
});
__p += '\n';

}
return __p
};

this["Templates"]["modal.addon-deletion-ftp-app"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p +=
((__t = ( T("console.modals.addon-deletion-ftp-app", {link: link}) )) == null ? '' : __t) +
'\n';

}
return __p
};

this["Templates"]["modal.addon-migration"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal-addon-migration">\n  <p>' +
((__t = ( T("console.addon-migration.confirm-modal.body.plan-slug", { plan: plan }))) == null ? '' : __t) +
'<br>\n  ' +
((__t = ( T("console.addon-migration.confirm-modal.body.plan-version", { version: version }) )) == null ? '' : __t) +
'<!--\n  -->';
 if(linkedApplications.length > 0) { ;
__p += '\n    ' +
__e( T("console.addon-migration.confirm-modal.body.plan-linked-applications") ) +
'</p>\n    <ul>\n      ';
 linkedApplications.forEach(function(application) { ;
__p += '\n        <li><a href="' +
__e( application.url ) +
'">' +
__e( application.name || application.id ) +
' - ' +
__e( application.id ) +
'</a></li>\n      ';
});
__p += '\n    </ul>\n    ';
};
__p += '\n  <p>\n  <p>' +
((__t = ( modalPriceText )) == null ? '' : __t) +
'</p>\n</div>\n<div>\n\t<button type="button" class="cancel btn">' +
((__t = ( T("CANCEL") )) == null ? '' : __t) +
'</button>\n\t<button type="submit" class="btn btn-orange">' +
__e( T("console.addon-migration.confirm-modal.submit") ) +
'</button>\n</div>\n';

}
return __p
};

this["Templates"]["modal.backup-codes"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="backup-codes" data-hj-masked >\n  <p>' +
__e( T("console.user-security.print-backup-codes-modal") ) +
'</p>\n  ';
 _.each(backupCodes, function(list){ ;
__p += '\n    <ul>\n      ';
 _.each(list, function(code){ ;
__p += '\n        <li>' +
__e( code ) +
'</li>\n      ';
});
__p += '\n    </ul>\n  ';
});
__p += '\n</div>\n';

}
return __p
};

this["Templates"]["modal.coupon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"\n  viewBox="0 0 70 87.5" enable-background="new 0 0 70 70" xml:space="preserve" fill="#A4B1C9" height="150">\n  <path fill-rule="evenodd" clip-rule="evenodd" d="M33.5,38.8c0,9.5,0,18.7,0,28.4c-6.8,0-13.5,0.2-20.1-0.2C10.5,67,10,65.1,10,63.1  c-0.1-8,0-16,0-24.3C18,38.8,25.6,38.8,33.5,38.8z" />\n  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4,67.4c0-9.7,0-19,0-28.6c7.7,0,15.3,0,23.4,0c0,8.2,0,16.2,0,24.4  c0,2.9-2.3,4.2-5,4.2C49.9,67.4,43.9,67.4,37.4,67.4z" />\n  <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4,13.4c2.2-2.7,4-5.3,6.2-7.7c3.1-3.4,7.1-4,10.6-2c3.3,1.9,5.1,5.8,4.2,9.4  c-1.1,4.1-4,6.4-8.7,6.5c-8.3,0.1-16.6,0.1-24.9,0c-4.6-0.1-7.4-2.4-8.4-6.7c-0.8-3.5,0.8-7.2,4-9.1c3.5-2.1,7.2-1.7,10.4,1.5  C31.1,7.8,33.1,10.6,35.4,13.4z M32.8,16.1c-2.6-3.3-4.2-6.1-6.6-8.1c-1.4-1.1-4.2-1.7-5.8-1c-1.4,0.6-3.1,2.6-2.6,5.1  c0.3,1.7,2.7,3.3,4.4,3.7C25.3,16.6,28.5,16.1,32.8,16.1z M38,16.2c4.4,0.2,7.6,0.4,10.5-0.3c3.1-0.7,4.2-2.7,4.5-4.5  c0.2-1.3-0.8-3.7-2.5-4.4c-1.6-0.7-4.4-0.2-5.7,0.9C42.3,9.8,39.9,13.8,38,16.2z" />\n  <path fill-rule="evenodd" clip-rule="evenodd" d="M33.5,23.4c0,4,0,7.5,0,11.5c-4.2,0-8.3,0-12.4,0c-2.2,0-4.4,0-6.6,0  c-6.2,0-8,0.7-8-6.5c0-2.1,0.4-4.9,4.2-5C18.1,23.2,25.5,23.4,33.5,23.4z" />\n  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4,34.9c0-4.3,0-7.7,0-11.5c8,0,15.8-0.1,23.6,0.1c3,0.2,2.6,4,2.6,4.2  c0,8.1-0.9,7.3-7.8,7.2C49.7,34.9,43.6,34.9,37.4,34.9z" /></svg>\n<div>\n  <input type="text" name="input_text" placeholder="myCoupon" autocomplete="off" value="' +
__e( value ) +
'" ' +
__e( required ? 'required="required"' : '' ) +
' />\n</div>\n<br />\n';

}
return __p
};

this["Templates"]["modal.input-text"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <input type="text" name="input_text" autocomplete="off" value="' +
__e( value ) +
'" ' +
__e( required ? 'required="required"' : '' ) +
' />\n</div>\n<br />\n';

}
return __p
};

this["Templates"]["modal.mfa-password"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<p>' +
__e( T("console.user-security.modal-enter-password") ) +
'</p>\n<input name="user-input" type="password" required="required" ' +
__e( wrongPassword ? 'data-status=error' : '' ) +
' />\n<br/>\n';

}
return __p
};

this["Templates"]["modal.oauth-consumer-deletion"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p +=
__e( T("console.oauth-consumers.remove-modal-body", {consumer: consumer}) ) +
'<br /><br />\n';

}
return __p
};

this["Templates"]["OauthConsumerCreation.consumer-info"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-content">\n  <label class="label-large">' +
__e( T("console.user-oauth-tokens.oauth-application-name") ) +
'</label>\n  <input type="text" class="input-large" name="application-name" required value="' +
__e( consumer ? consumer.name : '' ) +
'" />\n  <label class="label-large">' +
__e( T("console.user-oauth-tokens.oauth-application-description") ) +
'</label>\n  <input type="text" class="input-large" name="application-description" required value="' +
__e( consumer ? consumer.description : '' ) +
'" />\n  <label class="label-large">' +
__e( T("console.user-oauth-tokens.oauth-application-homepage-url") ) +
'</label>\n  <input type="url" class="input-large" name="application-homepage" required value="' +
__e( consumer ? consumer.url : '' ) +
'" />\n  <span></span>\n  <label class="label-large">' +
__e( T("console.user-oauth-tokens.oauth-application-base-url") ) +
'</label>\n  <input type="url" class="input-large" name="application-oauth-callback" required value="' +
__e( consumer ? consumer.baseUrl : '' ) +
'" />\n  <span></span>\n  <label class="label-large">' +
__e( T("console.user-oauth-tokens.oauth-application-picture") ) +
' (HTTP(s))</label>\n  <input type="url" class="input-large" name="application-image" required value="' +
__e( consumer ? consumer.picture : '' ) +
'" />\n  <span></span>\n</div>\n';
 if(!consumer){ ;
__p += '\n  <div class="submit-button-info">\n    <button type="submit" class="btn btn-blue">' +
__e( T("NEXT") ) +
'</button>\n  </div>\n';
};
__p += '\n';

}
return __p
};

this["Templates"]["OauthConsumerCreation.consumer-rights"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="rights">\n    <div class="access">\n      ';
 _.each(rights.access, function(val, key){ ;
__p += '\n        <input type="checkbox" name="' +
__e( key ) +
'" ' +
__e( val ? "checked" : "" ) +
'>' +
__e( T("console.user-oauth-tokens.rights-" + key) ) +
'<br />\n      ';
});
__p += '\n    </div>\n    <div class="manage">\n      ';
 _.each(rights.manage, function(val, key){ ;
__p += '\n        <input type="checkbox" name="' +
__e( key ) +
'" ' +
__e( val ? "checked" : "" ) +
'>' +
__e( T("console.user-oauth-tokens.rights-" + key) ) +
'<br />\n      ';
});
__p += '\n    </div>\n    ';
 if(!consumer){ ;
__p += '\n    <div class="submit-button-rights">\n      <button type="submit" class="btn btn-blue">' +
__e( T("CREATE") ) +
'</button>\n    </div>\n    ';
};
__p += '\n</div>\n';

}
return __p
};

this["Templates"]["OwnerCreditCardsSP.credit-card"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="credit-card card-line ' +
__e( isDefault ? 'selected' : '' ) +
'" data-token="' +
__e( token ) +
'">\n  <label>\n    ';
 if(type === "CREDITCARD"){ ;
__p += '\n      <div class="card-body">\n        <span>' +
__e( T("console.owner-cards.credit-card") ) +
' - ' +
__e( number ) +
'</span>\n        ';
 if(preferredNetwork != null) { ;
__p += '\n          <img class="card-body-network" src="' +
__e( networkLogo ) +
'" alt="' +
__e( networkLogoAlt ) +
'">\n        ';
 } ;
__p += '\n    </div>\n      <div class="card-body-holder">\n        ';
 if(holderName !== null) { ;
__p += '\n          ' +
__e( T("console.owner-cards.owner-name") ) +
': ' +
__e( holderName ) +
'\n        ';
};
__p += '\n      </div>\n    ';
} else if(type === "PAYPAL"){ ;
__p += '\n      <div class="card-body">' +
__e( T("console.owner-cards.paypal") ) +
' - ' +
__e( email ) +
'</div>\n    ';
} else if(type === "SEPA_DEBIT"){ ;
__p += '\n      <div class="card-body">' +
__e( T("console.owner-cards.sepa-debit-account") ) +
' - ' +
__e( formattedNumber ) +
'</div>\n    ';
};
__p += '\n    ';
 if(type !== "SEPA_DEBIT" && expirationDate) { ;
__p += '\n      <div class="card-expiration">' +
__e( T("console.owner-cards.expire-date") ) +
': ' +
__e( expirationDate ) +
'</div>\n    ';
 } ;
__p += '\n  </label>\n  <div class="default-card">\n    ';
 if(type === "CREDITCARD" || type === "SEPA_DEBIT"){ ;
__p += '\n      <button type="button" class="btn btn-white set-default-card">' +
__e( T("console.cards.add-new-card.make-default") ) +
'</button>\n    ';
};
__p += '\n    <span class="card-selected">' +
__e( T("console.owner-cards.default-card") ) +
'</span>\n  </div>\n  ';
 if(removable){ ;
__p += '\n    <button data-token="' +
__e( token ) +
'" class="btn btn-right btn-square btn-red remove">\n      <i class="ion ion-trash-a"></i>\n    </button>\n  ';
};
__p += '\n</li>\n';

}
return __p
};

this["Templates"]["OwnerCreditCardsSP.no-cards"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<span class="no-rows no-payment-methods">' +
__e( T("NO_CREDIT_CARDS_REGISTERED") ) +
'</span>\n';

}
return __p
};

this["Templates"]["owner-notifications"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="display-hooks">\n  <div class="notifications-hooks-change" data-current-tab="emailhooks">\n    <button class="btn btn-primary emailhooks" disabled="disabled" data-target="emailhooks">' +
__e( T("EMAILS") ) +
'</button>\n    <button class="btn btn-primary webhooks no-disable" data-target="webhooks">' +
__e( T("console.owner-notifications.hooks.webhook") ) +
'</button>\n  </div>\n  <div class="emailhooks tab" data-tab="emailhooks"></div>\n  <div class="webhooks tab" data-tab="webhooks"></div>\n</div>\n<div class="add-hook"></div>\n';

}
return __p
};

this["Templates"]["owner-notifications.add-hook"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="bloc">\n  <div class="bloc-popin">\n    <h2>\n      ';
 if(hookType === "webhook"){ ;
__p += '\n        ' +
__e( T("console.owner-notifications.add-hook.title-webhook") ) +
'\n      ';
} else if(hookType === "emailhook"){ ;
__p += '\n        ' +
__e( T("console.owner-notifications.add-hook.title-mailhook") ) +
'\n      ';
};
__p += '\n    </h2>\n  </div>\n  <div class="bloc-content">\n    <form action="#">\n      <label class="label-large" for="webhook-name">' +
__e( T("NAME") ) +
'</label>\n      <input class="input-large" name="webhook-name" type="text" required />\n\n      ';
 if(hookType === "webhook"){ ;
__p += '\n        <label class="label-large" for="webhook-target-url">' +
__e( T("console.owner-notifications.add-hook.event-target-url-format") ) +
'</label>\n        <input class="input-large" name="webhook-target-url" type="url" required />\n        <select name="webhook-event-format">\n          ';
 _.each(formats, function(format) { ;
__p += '\n            <option value="' +
__e( format ) +
'">' +
__e( format ) +
'</option>\n          ';
});
__p += '\n        </select>\n      ';
} else if(hookType === "emailhook"){ ;
__p += '\n        <label class="label-large" for="mailhook-notify-target">\n          ' +
__e( T("console.owner-notifications.add-hook.notify-target") ) +
':\n        </label>\n        <select name="mailhook-notify-target">\n          <option value="email">' +
__e( T("console.owner-notifications.add-hook.email") ) +
'</option>\n          ';
 if(isOrga){ ;
__p += '\n          <option value="userid">' +
__e( T("console.owner-notifications.add-hook.userid") ) +
'</option>\n            <option value="organisation">' +
__e( T("console.owner-notifications.add-hook.organisation") ) +
'</option>\n          ';
};
__p += '\n        </select>\n        <div class="mailhook-notify-target-content"></div>\n      ';
};
__p += '\n\n      <label class="label-large" for="webhook-event-type">' +
__e( T("console.owner-notifications.events") ) +
'</label>\n      <div class="dropdown-scope-events"></div>\n\n      <label class="label-large" for="webhook-scope">' +
__e( T("console.owner-notifications.add-hook.scope") ) +
'</label>\n      <div class="dropdown-scope-apps"></div>\n\n      <button type="submit" class="btn btn-primary btn-blue">' +
__e( T("CREATE") ) +
'</button>\n      <button type="cancel" class="btn btn-primary">' +
__e( T("CANCEL") ) +
'</button>\n    </form>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["owner-notifications.mailhooks"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="bloc">\n  <div class="bloc-popin">\n    <h2>' +
__e( T("console.owner-notifications.hooks.emailhooks") ) +
'</h2>\n  </div>\n  <div class="bloc-content">\n    <button class="btn btn-primary add-mailhook top">' +
__e( T("ADD") ) +
'</button>\n    <table class="mailhooks">\n      <tr>\n        <th>' +
__e( T("NAME") ) +
'</th>\n        <th>Type</th>\n        <th>' +
__e( T("console.owner-notifications.mailhooks.event-target") ) +
'</th>\n        <th></th>\n      </tr>\n      ';
 if(mailhooks.length <= 0){ ;
__p += '\n        <tr>\n          <td>' +
__e( T("console.owner-notifications.mailhooks.no-mailhooks-yet") ) +
'</td>\n          <td></td>\n          <td></td>\n          <td></td>\n        </tr>\n      ';
} else{ ;
__p += '\n        ';
 _.each(mailhooks, function(mailhook){ ;
__p += '\n          <tr data-mailhook-id="' +
__e( mailhook.id ) +
'">\n            <td>' +
__e( mailhook.name ) +
'</td>\n            <td>';
 if(mailhook.notified){ ;
__p += '\n              ' +
__e( T("console.owner-notifications.add-hook." + mailhook.notified.type) ) +
'\n              ';
} else {;
__p += '\n                ' +
__e( T("console.owner-notifications.add-hook.email") ) +
'\n              ';
};
__p += '\n            </td>\n            <td>\n              ';
 if(mailhook.notified){ ;
__p += '\n                ';
 if(mailhook.notified.type === "organisation"){ ;
__p += '\n                  ';
 if(owner.id.indexOf("orga_") === 0){ ;
__p += '\n                    ' +
__e( T("console.owner-notifications.mailhooks.organisation", {name: owner.name || owner.id}) ) +
'\n                  ';
} else if(owner.id.indexOf("user_") === 0){;
__p += '\n                    ' +
__e( T("console.owner-notifications.mailhooks.only-you") ) +
'\n                  ';
};
__p += '\n                ';
} else { ;
__p += '\n                  ' +
__e( mailhook.notified.targets.join(', ') ) +
'\n                ';
};
__p += '\n              ';
} else if(owner.id.indexOf("orga_") === 0){ ;
__p += '\n                ' +
__e( T("console.owner-notifications.mailhooks.organisation", {name: owner.name || owner.id}) ) +
'\n              ';
} else if(owner.id.indexOf("user_") === 0){ ;
__p += '\n                ' +
__e( T("console.owner-notifications.mailhooks.only-you") ) +
'\n              ';
};
__p += '\n            </td>\n            <td><button class="btn btn-small btn-red btn-right remove"><i class="ion ion-trash-a" title="' +
__e( T('REMOVE') ) +
'"></i></button></td>\n          </tr>\n        ';
});
__p += '\n      ';
};
__p += '\n    </table>\n    <button class="btn btn-primary add-mailhook bottom">' +
__e( T("ADD") ) +
'</button>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["owner-notifications.notify-email"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<label class="label-large" for="notify-content-emails">Emails (separate with a comma ",")</label>\n<input class="input-large" name="notify-content-emails" type="text" required />\n';

}
return __p
};

this["Templates"]["owner-notifications.notify-user-id"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<label class="label-large" for="notify-content-user-id">Select Users to receive mails</label>\n<div class="dropdown-notify"></div>\n';

}
return __p
};

this["Templates"]["owner-notifications.webhooks"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="bloc">\n  <div class="bloc-popin">\n    <h2>' +
__e( T("console.owner-notifications.hooks.webhook") ) +
'</h2>\n    <p class="bloc-description">' +
__e( T("console.owner-notifications.webhooks.description") ) +
'</p>\n  </div>\n  <div class="bloc-content">\n    <button class="btn btn-primary add-webhook top">' +
__e( T("ADD") ) +
'</button>\n    <table class="webhooks">\n      <tr>\n        <th>' +
__e( T("NAME") ) +
'</th>\n        <th>' +
__e( T("console.owner-notifications.webhooks.event-target-url") ) +
'</th>\n        <th>' +
__e( T("console.owner-notifications.webhooks.event-format") ) +
'</th>\n        <th></th>\n      </tr>\n      ';
 if(webhooks.length <= 0){ ;
__p += '\n        <tr>\n          <td>' +
__e( T("console.owner-notifications.webhooks.no-webhooks-yet") ) +
'</td>\n          <td></td>\n          <td></td>\n          <td></td>\n        </tr>\n      ';
} else { ;
__p += '\n        ';
 _.each(webhooks, function(webhook){ ;
__p += '\n          <tr data-webhook-id="' +
__e( webhook.id ) +
'">\n            <td>' +
__e( webhook.name ) +
'</td>\n            <td>' +
__e( webhook.urls[0].url ) +
'</td>\n            <td>' +
__e( webhook.urls[0].format ) +
'</td>\n            <td><button class="btn btn-small btn-red btn-right remove"><i class="ion ion-trash-a" title="' +
__e( T('REMOVE') ) +
'"></i></button></td>\n          </tr>\n        ';
});
__p += '\n      ';
};
__p += '\n    </table>\n    <button class="btn btn-primary add-webhook bottom">' +
__e( T("ADD") ) +
'</button>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["orgas.bottom-links"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li><a class="crisp-button" href="/ticket-center-choice"><span class="list-icon list-icon-big"><i class="icon ion-chatboxes"></i></span> ' +
((__t = ( T("console.ticket-center.contact") )) == null ? '' : __t) +
'</a></li>\n<li><a href="https://www.clevercloudstatus.com"><span class="list-icon list-icon-big"><i class="icon ion-checkmark-circled"></i></span> ' +
((__t = ( T("console.platform-status-link") )) == null ? '' : __t) +
'</a></li>\n<li><a href="/users/me/information"><span class="list-icon list-icon-big"><i class="icon ion-gear-a"></i></span> ' +
((__t = ( T("PROFILE") )) == null ? '' : __t) +
'</a></li>\n<li><a href="' +
__e( Console.DOC_BASE_URL ) +
'doc/" target="_blank"><span class="list-icon list-icon-big"><i class="icon ion-help-buoy"></i></span> ' +
((__t = ( T("DOCUMENTATION") )) == null ? '' : __t) +
'</a></li>\n<li class="logout"><a href="/auth/logout"><span class="list-icon list-icon-big"><i class="icon ion-power"></i></span> ' +
((__t = ( T("LOGOUT") )) == null ? '' : __t) +
'</a></li>\n';

}
return __p
};

this["Templates"]["orgas.list"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(owners, function(owner) { ;
__p += '\n<li data-organisation="' +
__e( owner.id ) +
'" class="' +
__e( owner.active ? 'selected' : '' ) +
'">\n  ';
 if(owner.active){ ;
__p += '\n    <div class="selected-icon-container"><div class="selected-icon"></div></div>\n  ';
};
__p += '\n  <a href="' +
__e( owner.lastUrl || owner.href ) +
'">\n    ';
 if(owner.avatar) { ;
__p += '\n    <img class="list-icon list-icon-big" src="' +
__e( owner.avatar ) +
'" />\n    ';
 } else { ;
__p += '\n    <span class="list-icon list-icon-big">' +
__e( owner.initials ) +
'</span>\n    ';
 } ;
__p += '\n    ' +
__e( owner.id.indexOf("orga_") == 0 ? owner.name : T("console.panes.personal-space") ) +
'\n  </a>\n</li>\n';
 }); ;
__p += '\n';
 if (isEmailValidated) { ;
__p += '\n<li class="add-link-pane"><a href="/organisations/new"><span class="list-icon list-icon-big list-icon-transparent"></span> ' +
((__t = ( T("ADD_AN_ORGANISATION") )) == null ? '' : __t) +
'</a></li>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["settings.addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="' +
__e( page === 'AddonConfigurationSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'">' +
__e( T("console.panes.addon-settings.dashboard") ) +
'</a></li>\n';
 if (hasServiceDependencies) { ;
__p += '\n  <li class="' +
__e( page === 'AddonServicesDependenciesSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/services-dependencies">' +
__e( T("SERVICES_DEPENDENCIES") ) +
'</a></li>\n';
 } ;
__p += '\n';
 if (!inConsoleDashboard) { ;
__p += '\n  <li class="' +
__e( page === 'AddonSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/informations">' +
__e( T("INFORMATION") ) +
'</a></li>\n';
 } ;
__p += '\n';
 if (hasBackups) { ;
__p += '\n  <li class="' +
__e( page === 'AddonBackupsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/backups">' +
__e( T("console.panes.addon-settings.backups") ) +
'</a></li>\n';
 } ;
__p += '\n';
 if (canUpgrade) { ;
__p += '\n  <li class="' +
__e( page === 'AddonMigrationSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/migrations">' +
__e( T("console.panes.addon-settings.migration") ) +
'</a></li>\n';
 } ;
__p += '\n';
 if (hasLogs) {;
__p += '\n  <li class="' +
__e( page === 'LogsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/logs">' +
__e( T("LOGS") ) +
'</a></li>\n';
 } ;
__p += '\n';
 if (hasMetrics) {;
__p += '\n  <li class="' +
__e( page === 'MetricsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/metrics/global">' +
__e( T("METRICS") ) +
'</a></li>\n';
 } ;
__p += '\n';
 if (hasRedisExplorer) {;
__p += '\n<li class="' +
__e( page === 'AddonRedisExplorerSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/redis-explorer">' +
__e( T("console.panes.addon-settings.kv-explorer") ) +
'</a></li>\n';
 } ;
__p += '\n';
 if (hasMateriaExplorer) {;
__p += '\n<li class="' +
__e( page === 'AddonMateriaExplorerSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/materia-explorer">' +
__e( T("console.panes.addon-settings.kv-explorer") ) +
'</a></li>\n';
 } ;
__p += '\n';
 if(documentation && documentation.name && documentation.link){ ;
__p += '\n<li class="with-icon"><a href="' +
__e( documentation.link ) +
'" target="_blank">\n  <cc-icon icon="' +
__e( docIcon ) +
'"></cc-icon>\n  <span class="link-text">' +
__e( T("DOC_FOR_APP_TYPE") ) +
' ' +
__e( documentation.name ) +
'</span>\n</a></li>\n';
 } ;
__p += '\n';
 if(hasFeedbackLink){ ;
__p += '\n<li class="with-icon"><a href="' +
__e( feedbackLink ) +
'" target="_blank">\n  <cc-icon icon="' +
__e( feedbackIcon ) +
'"></cc-icon>\n  <span class="link-text">' +
__e( T("GIVE_FEEDBACK") ) +
'</span>\n</a></li>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["settings.app"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="' +
__e( page == 'AppOverviewSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'">' +
((__t = ( T("OVERVIEW") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AppSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/information">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AppConfigurationSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/settings">' +
((__t = ( T("SCALABILITY") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AppDomainNamesSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/vhosts">' +
((__t = ( T("DOMAIN_NAMES") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AppTcpRedirectionsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/tcp-redirections">' +
((__t = ( T("menu.TCP_REDIRECTIONS") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AppEnvVariablesSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/variables">' +
((__t = ( T("ENVIRONMENT_VARS") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AppServicesDependenciesSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/services-dependencies">' +
((__t = ( T("SERVICES_DEPENDENCIES") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AppExposedVariablesSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/exposed-variables">' +
__e( T("EXPOSED_VARIABLES") ) +
'</a></li>\n<li class="' +
__e( page == 'AppDeploymentsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/deployments">' +
((__t = ( T("DEPLOYMENTS_ACTIVITY") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AppLogsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/logs">' +
((__t = ( T("LOGS") )) == null ? '' : __t) +
'</a></li>\n';
 if(accessLogsEnabled){ ;
__p += '\n<li class="' +
__e( page == 'AppAccessLogsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/app-access-logs">' +
((__t = ( T("ACCESS_LOGS") )) == null ? '' : __t) +
'</a></li>\n';
 } ;
__p += '\n<li class="' +
__e( page == 'MetricsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/metrics/global">' +
((__t = ( T("METRICS") )) == null ? '' : __t) +
'</a></li>\n';
 if(documentation && documentation.name && documentation.link){ ;
__p += '\n<li class="with-icon"><a href="' +
__e( documentation.link ) +
'" target="_blank">\n  <cc-icon icon="' +
__e( docIcon ) +
'"></cc-icon>\n  <span class="link-text">' +
__e( T("DOC_FOR_APP_TYPE") ) +
' ' +
__e( documentation.name ) +
'</span>\n</a></li>\n';
 } ;
__p += '\n';
 if(newLogsFeedback){ ;
__p += '\n<li class="with-icon"><a href="' +
__e( newLogsFeedback.link ) +
'" target="_blank">\n  <cc-icon icon="' +
__e( newLogsFeedback.icon ) +
'"></cc-icon>\n  <span class="link-text">' +
__e( T("console.logs.new.feedback") ) +
'</span>\n</a></li>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["settings.consumer"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li class="' +
__e( page === 'OauthConsumerSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'">' +
__e( T("INFORMATION") ) +
'</a></li>\n<li class="' +
__e( page === 'OauthConsumerEditSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href + '/edit' ) +
'">' +
__e( T("EDIT") ) +
'</a></li>\n';

}
return __p
};

this["Templates"]["settings.orga"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="' +
__e( page == 'OrgaOverviewSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/overview">' +
((__t = ( T("OVERVIEW") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'OrgaSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/information">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'OrgaMembersSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/members">' +
((__t = ( T("MEMBERS") )) == null ? '' : __t) +
'</a></li>\n';
 if(canAccessSharedSoftwares) { ;
__p += '\n<li class="' +
__e( page == 'OrgaSaaSSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/saas">' +
__e( T('SHARED_SOFTWARES') ) +
'</a></li>\n';
 } ;
__p += '\n';
 if(canAccessGrafana) { ;
__p += '\n<li class="' +
__e( page == 'GrafanaSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/grafana">' +
((__t = ( T("METRICS.grafana") )) == null ? '' : __t) +
'</a></li>\n';
 } ;
__p += '\n';
 if(canAccessBills) { ;
__p += '\n<li class="' +
__e( page == 'PaymentSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/payment">' +
__e( T("console.panes.payment") ) +
'</a></li>\n<li class="' +
__e( ['BillSP', 'BillsSP'].indexOf(page) >= 0 ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/invoices">' +
((__t = ( T("INVOICES") )) == null ? '' : __t) +
'</a></li>\n';
 } ;
__p += '\n';
 if(canAccessNotifications) { ;
__p += '\n  <li class="' +
__e( page == 'OwnerNotificationsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/notifications">Notifications</a></li>\n';
};
__p += '\n';

}
return __p
};

this["Templates"]["settings.provider"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li class="' +
__e( page == 'AddonProviderSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'AddonProviderPlansSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/plans">' +
((__t = ( T("PLANS") )) == null ? '' : __t) +
'</a></li>\n';

}
return __p
};

this["Templates"]["settings.user"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="' +
__e( page == 'OrgaOverviewSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href + '/overview' ) +
'">' +
((__t = ( T("OVERVIEW") )) == null ? '' : __t) +
'</a></li>\n<li class="' +
__e( page == 'OrgaSP' ? 'selected' : '' ) +
'"><a href="' +
__e( '/organisations/' + ownerId + '/information' ) +
'">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</a></li>\n';
 if(canAccessGrafana) { ;
__p += '\n<li class="' +
__e( page == 'GrafanaSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/grafana">' +
((__t = ( T("METRICS.grafana") )) == null ? '' : __t) +
'</a></li>\n';
 } ;
__p += '\n';
 if(canAccessBills) { ;
__p += '\n<li class="' +
__e( page == 'PaymentSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/payment">' +
__e( T("console.panes.payment") ) +
'</a></li>\n<li class="' +
__e( ['BillSP', 'BillsSP'].indexOf(page) >= 0 ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/invoices">' +
((__t = ( T("INVOICES") )) == null ? '' : __t) +
'</a></li>\n';
 } ;
__p += '\n<li class="' +
__e( page == 'OwnerNotificationsSP' ? 'selected' : '' ) +
'"><a href="' +
__e( href ) +
'/notifications">Notifications</a></li>\n';

}
return __p
};

this["Templates"]["payment.checkout-button"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="payment-checkout-button">\n  <button class="btn btn-green checkout">' +
__e( T("PAY_N_EUROS", {price: price}) ) +
'</button>\n  <div class="payment-error alert" data-status="error"></div>\n</div>';

}
return __p
};

this["Templates"]["providers"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<cc-block class="do-pay">\n  <div slot="header-title">' +
((__t = ( T("CHOOSE_A_PAYMENT_METHOD") )) == null ? '' : __t) +
'</div>\n  <div slot="content" class="payment-providers-list">\n    ';
 _.each(providers, function(provider) { ;
__p += '\n      <cc-button type="button" class="choose" primary data-provider="' +
__e( provider.name ) +
'" image="' +
__e( provider.imgUrl ) +
'">' +
__e( provider.title ) +
'</cc-button>\n    ';
 }); ;
__p += '\n  </div>\n</cc-block>\n\n<cc-smart-container context=\'{ "ownerId": "' +
__e( orgaId ) +
'", "invoiceNumber": "' +
__e( invoice.number ) +
'" }\'>\n  <cc-invoice></cc-invoice>\n</cc-smart-container>\n';

}
return __p
};

this["Templates"]["maintenance"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="alert big-alert" data-status="warning" style="text-align:center">' +
((__t = ( T("MAINTENANCE_MESSAGE") )) == null ? '' : __t) +
'</div>\n';

}
return __p
};

this["Templates"]["Pricer"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="documentation documentation-pricing">' +
((__t = ( T("DOC_ABOUT_SCALING") )) == null ? '' : __t) +
'</div>\n<div class="pricer-price"></div>\n<section class="autoscalability"></section>\n<section class="horizontal-static"></section>\n<section class="horizontal-dynamic"></section>\n<section class="vertical-static"></section>\n<section class="vertical-dynamic"></section>\n';

}
return __p
};

this["Templates"]["Pricer.autoscalability"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<br>\n<h4>' +
((__t = ( T("AUTOSCALABILITY") )) == null ? '' : __t) +
'</h4>\n\n<p>' +
((__t = ( T("AUTOSCALABILITY_TEXT") )) == null ? '' : __t) +
'</p>\n\n<div class="switch">\n  <input type="radio" name="autoscalability" id="autoscalability-enabled" value="enabled" ' +
__e( autoscalability ? "checked" : "" ) +
' />\n  <label for="autoscalability-enabled">' +
((__t = ( T("ENABLED") )) == null ? '' : __t) +
'</label>\n\n  <input type="radio" name="autoscalability" id="autoscalability-disabled" value="disabled" ' +
((__t = ( !autoscalability ? "checked" : "" )) == null ? '' : __t) +
' />\n  <label for="autoscalability-disabled">' +
((__t = ( T("DISABLED") )) == null ? '' : __t) +
'</label>\n</div>\n';

}
return __p
};

this["Templates"]["Pricer.flavor"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="instance-flavor">\n  <input id="instance-flavor-' +
__e(name ) +
'" type="radio" name="instance-flavor" value="' +
__e( name ) +
'" ' +
((__t = ( selected ? "checked" : "" )) == null ? '' : __t) +
' ' +
((__t = ( disabled ? 'disabled' : '' )) == null ? '' : __t) +
' style="display:none" />\n  <label for="instance-flavor-' +
__e( name ) +
'">\n    <div class="flavor-name">' +
__e( name.replace(/ML_/i, '') ) +
'</div>\n    ';
 if(gpus > 0) { ;
__p += '\n      <div class="flavor-gpus">\n        ' +
__e( gpus ) +
' GPUs\n      </div>\n    ';
};
__p += '\n    <div class="flavor-cpu">' +
__e( microservice ? T("console.pricer.shared-cpu") : cpus + ' CPUs' ) +
'</div>\n    <div class="flavor-memory">\n        ' +
__e( formattedMem ) +
' RAM\n    </div>\n  </label>\n</div>\n';

}
return __p
};

this["Templates"]["Pricer.horizontal-dynamic"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h4>' +
((__t = ( T("HORIZONTAL_SCALING") )) == null ? '' : __t) +
'</h4>\n\n<p>' +
((__t = ( T("HORIZONTAL_SCALABILITY_TEXT") )) == null ? '' : __t) +
'</p>\n\n<input type="hidden" name="instance-count-min" value="' +
((__t = ( minInstances )) == null ? '' : __t) +
'" />\n<input type="hidden" name="instance-count-max" value="' +
((__t = ( maxInstances )) == null ? '' : __t) +
'" />\n\n<div class="rangepicker horizontal"></div>\n';

}
return __p
};

this["Templates"]["Pricer.horizontal-static"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h4>' +
((__t = ( T("HORIZONTAL_SCALING") )) == null ? '' : __t) +
'</h4>\n\n<p>' +
((__t = ( T("HORIZONTAL_STATIC_TEXT") )) == null ? '' : __t) +
'</p>\n\n<input type="hidden" name="instance-count-min" value="' +
((__t = ( minInstances )) == null ? '' : __t) +
'" />\n<input type="hidden" name="instance-count-max" value="' +
((__t = ( maxInstances )) == null ? '' : __t) +
'" />\n\n<div class="rangepicker horizontal"></div>\n';

}
return __p
};

this["Templates"]["Pricer.vertical-dynamic"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h4>' +
((__t = ( T("VERTICAL_SCALING") )) == null ? '' : __t) +
'</h4> \n\n<p>' +
((__t = ( T("VERTICAL_SCALABILITY_TEXT") )) == null ? '' : __t) +
'</p>\n\n<input type="hidden" name="instance-flavor-min" value="' +
((__t = ( minFlavor.name )) == null ? '' : __t) +
'" />\n<input type="hidden" name="instance-flavor-max" value="' +
((__t = ( maxFlavor.name )) == null ? '' : __t) +
'" />\n\n<div class="rangepicker vertical"></div>\n';

}
return __p
};

this["Templates"]["Pricer.vertical-static"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<h4>' +
((__t = ( T("VERTICAL_SCALING") )) == null ? '' : __t) +
'</h4>\n\n<p>' +
((__t = ( T("VERTICAL_STATIC_TEXT") )) == null ? '' : __t) +
'</p>\n\n<input type="hidden" name="instance-flavor-min" value="' +
((__t = ( minFlavor.name )) == null ? '' : __t) +
'" />\n<input type="hidden" name="instance-flavor-max" value="' +
((__t = ( maxFlavor.name )) == null ? '' : __t) +
'" />\n\n<div class="flavorpicker"></div>\n';

}
return __p
};

this["Templates"]["quick-search-results"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="quick-search-results">\n  <h3>' +
__e( title ) +
'</h3>\n  ';
 if(results.length > 0){ ;
__p += '\n    <ul>\n      ';
 _.each(results, function(result){
      var type;
      if(result.id.indexOf('app_') === 0){
        if(result.providerId){
          type = "(" + T('console.quick-search.addon') + ")";
        } else{
          type = "(" + T('console.quick-search.app') + ")";
        }
      } else if(result.id.indexOf('addon_') === 0){
        type = "(" + T('console.quick-search.addon') + ")";
      } else{
        type = "";
      } ;
__p += '\n      <li data-id="' +
__e( result.id + result.name ) +
'" data-is-addon="' +
__e( result.providerId ? true : false ) +
'" data-real-id="' +
__e( result.id ) +
'">\n        <a href="' +
__e( result.url ) +
'">\n          ' +
((__t = ( result.archived ? "<b>[" + T("console.quick-search.archived") + "]</b>" : "" )) == null ? '' : __t) +
'\n          ' +
__e( result.name || result.id ) +
' ' +
__e( type ) +
'\n        </a>\n      </li>\n      ';
});
__p += '\n    </ul>\n  ';
};
__p += '\n</div>\n';

}
return __p
};

this["Templates"]["quick-search"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="quick-search">\n  <input type="text" class="search" autocomplete="off" placeholder="' +
__e( T('console.quick-search.description') ) +
'" value="' +
__e( initialSearch ) +
'"/>\n  <div class="results"></div>\n</div>\n';

}
return __p
};

this["Templates"]["shortcuts"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="shortcuts">\n  <h3>' +
__e( T("console.shortcuts.title") ) +
'</h3>\n  <p>' +
__e( T("console.shortcuts.not-much-shortcuts") ) +
'</p>\n\n  <div class="shortcuts-tables">\n    <div class="navigation shortcuts-list">\n      <table class="shortcuts-list-table">\n        <tr>\n          <th></th>\n          <th>' +
__e( T("console.shortcuts.navigation") ) +
'</th>\n        </tr>\n        <tr>\n          <td>/</td>\n          <td>' +
__e( T("console.shortcuts.global-search") ) +
'</td>\n        </tr>\n        <!--<tr>\n          <td><,></td>\n          <td>' +
__e( T("console.shortcuts.toggle-blue-pane") ) +
'\n          </tr>-->\n        <tr>\n          <td>h</td>\n          <td>' +
__e( T("console.ticket-center.contact") ) +
'</td>\n        </tr>\n        <tr>\n          <td>?</td>\n          <td>' +
__e( T("console.shortcuts.shortcuts-help") ) +
'</td>\n        </tr>\n      </table>\n    </div>\n    <div class="applications shortcuts-list">\n      <table class="shortcuts-list-table">\n        <tr>\n          <th></th>\n          <th>' +
__e( T("console.shortcuts.application-addon-shortcuts") ) +
'</th>\n        </tr>\n        <tr>\n          <td>a</td>\n          <td>' +
__e( T("console.shortcuts.jump-to-app-activity") ) +
'</td>\n        </tr>\n        <tr>\n          <td>d</td>\n          <td>' +
__e( T("console.shortcuts.jump-to-domain-names") ) +
'</td>\n        </tr>\n        <tr>\n          <td>i</td>\n          <td>' +
__e( T("console.shortcuts.jump-to-app-information") ) +
'</td>\n        </tr>\n        <tr>\n          <td>e</td>\n          <td>' +
__e( T("console.shortcuts.jump-to-app-env-variables") ) +
'</td>\n        </tr>\n        <tr>\n          <td>l</td>\n          <td>' +
__e( T("console.shortcuts.jump-to-app-logs") ) +
'</td>\n        </tr>\n        <tr>\n          <td>m</td>\n          <td>' +
__e( T("console.shortcuts.jump-to-app-metrics") ) +
'</td>\n        </tr>\n        <tr>\n          <td>o</td>\n          <td>' +
__e( T("console.shortcuts.jump-to-overview") ) +
'</td>\n        </tr>\n        <tr>\n          <td>s</td>\n          <td>' +
__e( T("console.shortcuts.jump-to-app-scalability") ) +
'</td>\n        </tr>\n        <tr>\n          <td>shift o</td>\n          <td>' +
__e( T("console.shortcuts.open-app-in-tab") ) +
'</td>\n        </tr>\n      </table>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["ssh-keys.documentation"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p +=
__e( T("console.user-ssh-keys.documentation.title") ) +
'\n' +
((__t = ( T("console.user-ssh-keys.documentation.generate", {email: userEmail || ""}) )) == null ? '' : __t) +
'\n';

}
return __p
};

this["Templates"]["ssh-keys.github-key"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li>\n  <span class="key-name">' +
__e( name ) +
'</span>\n  <span class="key-value">' +
__e( fingerprint ) +
'</span>\n  <span class="key-actions">\n    <button type="button" class="add btn btn-right">' +
((__t = ( T("console.ssh-key.import") )) == null ? '' : __t) +
'</button>\n  </span>\n</li>\n';

}
return __p
};

this["Templates"]["ssh-keys.github-no-keys"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li>' +
__e( T("console.ssh-keys.github-no-keys") ) +
'</li>';

}
return __p
};

this["Templates"]["ssh-keys.ssh-key-new"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li>\n  <form class="form-oneline" action="#">\n    <span class="key-name">\n      <input class="input-medium" type="text" name="name" placeholder="' +
((__t = ( T('SSH_KEY_NAME_EXAMPLE') )) == null ? '' : __t) +
'" required />\n    </span>\n    <span class="key-value">\n      <input class="input-medium" type="text" name="content" placeholder="' +
((__t = ( T('SSH_KEY_CONTENT_EXAMPLE') )) == null ? '' : __t) +
'" required />\n    </span>\n    <span class="key-actions">\n      <button type="submit" class="btn btn-right">' +
((__t = ( T("ADD") )) == null ? '' : __t) +
'</button>\n    </span>\n  </form>\n</li>\n';

}
return __p
};

this["Templates"]["ssh-keys.ssh-key"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li>\n  <span class="key-name">' +
__e( name ) +
'</span>\n  <span class="key-value">' +
__e( fingerprint ) +
'</span>\n  <span class="key-actions">\n    <button type="button" class="remove btn btn-right">' +
((__t = ( T("REMOVE") )) == null ? '' : __t) +
'</button>\n  </span>\n</li>\n';

}
return __p
};

this["Templates"]["user-information"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-field">\n  <label class="label-large" for="user-name">' +
((__t = ( T("NAME") )) == null ? '' : __t) +
': *</label>\n  <input required name="name" id="user-name" type="text" value="' +
__e( information.name ) +
'" class="input-large" autocomplete="name" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.name || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label class="label-large" for="user-phone">' +
((__t = ( T("PHONE") )) == null ? '' : __t) +
': *</label>\n  <input required name="phone" id="user-phone" type="tel" value="' +
__e( information.phone ) +
'" class="input-large" autocomplete="tel" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.phone || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label class="label-large" for="user-address">' +
((__t = ( T("ADDRESS") )) == null ? '' : __t) +
': *</label>\n  <input required name="address" id="user-address" type="text" value="' +
__e( information.address ) +
'" class="input-large" autocomplete="street-address" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.address || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label class="label-large" for="user-city">' +
((__t = ( T("CITY") )) == null ? '' : __t) +
': *</label>\n  <input required name="city" id="user-city" type="text" value="' +
__e( information.city ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.city || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label class="label-large" for="user-zipcode">' +
((__t = ( T("ZIPCODE") )) == null ? '' : __t) +
': *</label>\n  <input required name="zipcode" id="user-zipcode" type="text" value="' +
__e( information.zipcode ) +
'" class="input-large" autocomplete="postal-code" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.zipcode || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label class="label-large" for="user-country">' +
((__t = ( T("COUNTRY") )) == null ? '' : __t) +
': *</label>\n  <label class="label-select">\n    <select class="countries select-large" name="country" id="user-country">\n      <option>?</option>\n      ';
 _.each(configuration.COUNTRIES, function(country, code) { ;
__p += '\n      <option value="' +
__e( code ) +
'" ' +
__e( (information.country == country) ? "selected" : "" ) +
' >' +
__e( country ) +
'</option>\n      ';
 }); ;
__p += '\n    </select>\n  </label>\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.country || "" ) +
'</span>\n</div>\n\n<button class="btn btn-blue update" type="button">' +
((__t = ( T("SAVE") )) == null ? '' : __t) +
'</button>\n\n';
 if(!information.oauthApps || information.oauthApps.indexOf("github") === -1) { ;
__p += '\n<hr />\n\n<button class="btn btn-blue github-link" type="button">' +
((__t = ( T("LINK_YOUR_GITHUB_ACCOUNT") )) == null ? '' : __t) +
'</button>\n';
 } else { ;
__p += '\n<hr />\n\n<p> ' +
((__t = ( T("YOU_HAVE_LINKED_YOUR_GITHUB_ACCOUNT") )) == null ? '' : __t) +
' </p> <button class="btn btn-blue github-unlink" type="button">' +
((__t = ( T("UNLINK_YOUR_GITHUB_ACCOUNT") )) == null ? '' : __t) +
'</button>\n';
 } ;
__p += '\n\n';
 if(typeof languages != "undefined") { ;
__p += '\n<hr />\n\n<div class="form-field">\n  <label class="label-large" for="user-language">' +
((__t = ( T("LANGUAGE") )) == null ? '' : __t) +
':</label>\n  <label class="label-select">\n    <select class="languages select-large" name="language" id="user-language">\n      ';
 _.each(languages, function(language, code) { ;
__p += '\n      <option value="' +
__e( code ) +
'" ' +
__e( currentLang === code ? 'selected' : '' ) +
'>' +
__e( language ) +
'</option>\n      ';
 }); ;
__p += '\n    </select>\n  </label>\n  <div class="alert language" data-status="warning" style="display:none">' +
((__t = ( T("YOU_NEED_TO_REFRESH_YOUR_BROWSER_AFTER_CHANGING_THE_CONSOLE_LANGUAGE") )) == null ? '' : __t) +
'</div>\n</div>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["zoom-selector-bar"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="zoom-bar">\n  <span>Zoom: </span>\n  <span class="zoom-unit" data-seconds="' +
__e( 5 * 60 ) +
'">5m</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 15 * 60 ) +
'">15m</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 30 * 60 ) +
'">30m</span>\n  <span class="zoom-unit selected" data-seconds="' +
__e( 60 * 60 ) +
'">1h</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 2 ) +
'">2h</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 3 ) +
'">3h</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 6 ) +
'">6h</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 12 ) +
'">12h</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 24 ) +
'">1d</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 24 * 3 ) +
'">3d</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 24 * 7 ) +
'">7d</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 24 * 14 ) +
'">14d</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 24 * 30 ) +
'">1m</span>\n  <span class="zoom-unit" data-seconds="' +
__e( 3600 * 24 * 30 * 3 ) +
'">3m</span>\n  <span class="zoom-unit" data-seconds="' +
__e( max ) +
'">all</span>\n</div>\n';

}
return __p
};

this["Templates"]["AddonConfigurationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="addon-configuration">\n  <form target="configuration"></form>\n  <iframe name="configuration" data-hj-masked></iframe>\n</div>\n';

}
return __p
};

this["Templates"]["AddonCreationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  ' +
((__t = ( Templates["AddonCreationSP.header"]() )) == null ? '' : __t) +
'\n</header>\n\n<form action="#" class="addon-creation"></form>\n<div class="addonCreation_creation"></div>\n';

}
return __p
};

this["Templates"]["AddonCreationSP.header"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header-content addon-creation">\n  <h1>' +
((__t = ( T("ADDON_CREATION") )) == null ? '' : __t) +
'</h1>\n  <ul class="header-steps">\n    <li data-step="provider">' +
((__t = ( T("PROVIDER") )) == null ? '' : __t) +
'</li>\n    <li data-step="plan">' +
((__t = ( T("PLAN") )) == null ? '' : __t) +
'</li>\n    <li data-step="apps">' +
((__t = ( T("APPLICATIONS") )) == null ? '' : __t) +
'</li>\n    <li data-step="information">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</li>\n    <li data-step="options">' +
((__t = ( T("OPTIONS") )) == null ? '' : __t) +
'</li>\n    <li data-step="invoice">' +
((__t = ( T("INVOICE") )) == null ? '' : __t) +
'</li>\n  </ul>\n</div>\n';

}
return __p
};

this["Templates"]["AddonCreationSP.user-misses-create-rights"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="bloc">\n    <div class="bloc-popin">\n      <div class="alert" data-status="error">\n        <p>' +
__e( T("console.addon-creation.missing-create-rights") ) +
'</p>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AddonMigrationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar"></header>\n\n<div class="card-container content"></div>\n';

}
return __p
};

this["Templates"]["AddonMigrationSP.informations"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="bloc addonchangeplan-migration-informations">\n  <h2>' +
__e( T("console.addon-migration.step1") ) +
'</h2>\n  <div class="bloc-content">\n    <p>' +
((__t = ( T("console.addon-migration.step1-explanations") )) == null ? '' : __t) +
'</p>\n    <div class="addonchangeplan-migration-informations-actions">\n      <button class="btn btn-green next">' +
__e( T("console.addon-migration.step1-next") ) +
'</button>\n    </div>\n  </div>\n</div>\n\n<div class="bloc addonchangeplan-migration-past-migrations">\n  <h2>' +
__e( T("console.addon-migration.past-migrations") ) +
'</h2>\n  <div class="bloc-content">\n    <div class="migration-activity">\n      <div class="activity-timeline">\n        <div class="timelineBar"></div>\n        ';
 if(migrations.length === 0) { ;
__p += '\n          <div class="no-activity">' +
__e( T("console.addon-migration.no-past-migrations") ) +
'</div>\n        ';
} else { ;
__p += '\n          ';
 migrations.forEach(function(migration) { ;
__p += '\n            <div class="activity-block" data-migration-id="' +
__e( migration.migrationId ) +
'">\n            ';
 if(migration.status === "OK" || (migration.status === "RECOVERED" && migration.isAborted)) { ;
__p += '\n              <div class="activity-action ion-checkmark-circled state-ok"></div>\n            ';
} else if(migration.status === "FAILED") { ;
__p += '\n              <div class="activity-action ion-close-circled state-error"></div>\n            ';
} else if(migration.status === "RUNNING") {;
__p += '\n              <div class="activity-action ion-code-working state-pending"></div>\n            ';
} else if(migration.status === "RECOVERED" && !migration.isAborted) {;
__p += '\n              <div class="activity-action ion-ios7-undo state-recovered"></div>\n            ';
};
__p += '\n              <div class="activity-details">\n                <div class="activity-header">\n                  <h4 class="activity-header-state">\n                    ';
 if(migration.status === "RECOVERED" && migration.isAborted) { ;
__p += '\n                      ' +
__e( T("console.addon-migration.ABORTED") ) +
'\n                    ';
} else if(migration.status === "RECOVERED" && !migration.isAborted) { ;
__p += '\n                      ' +
__e( T("console.addon-migration.FAILED") ) +
'\n                    ';
} else { ;
__p += '\n                      ' +
__e( T("console.addon-migration." + migration.status) ) +
'\n                    ';
};
__p += '\n                  </h4>\n                </div>\n                <div class="activity-date">\n                  <i class="ion ion-clock"></i> <cc-datetime-relative datetime="' +
__e( migration.requestDate ) +
'"></cc-datetime-relative>\n                </div>\n              </div>\n            </div>\n          ';
});
__p += '\n        ';
};
__p += '\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AddonMigrationSP.migration"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="bloc addonchangeplan-migration-migrate">\n  <h2>' +
__e( T("console.addon-migration.step3") ) +
'</h2>\n  <div class="bloc-content migration-steps">\n    <ol class="migration-steps-content">\n      ';
 migration.steps.forEach(function(step) {
        if(step.status === "OK") { ;
__p += '\n          <li class="step done ion-checkmark-round" title="' +
__e( T('console.addon-migration.step-started-at', step) ) +
'">\n            ' +
__e( T("console.addon-migration.steps." + step.value) ) +
'\n          </li>\n        ';
} else if(step.status === "RUNNING") { ;
__p += '\n          <li class="step in-progress" title="' +
__e( T('console.addon-migration.step-started-at', step) ) +
'">\n            <div class="loader-inner line-scale">\n              <div></div>\n              <div></div>\n              <div></div>\n              <span class="in-progress-content">' +
__e( T("console.addon-migration.steps." + step.value) ) +
'</span>\n            </div>\n          </li>\n        ';
} else if(step.status === "FAILED" || step.status === "ABORTED") { ;
__p += '\n          <li class="step failed ion-close" title="' +
__e( T('console.addon-migration.step-started-at', step) ) +
'">\n            ' +
__e( T("console.addon-migration.steps." + step.value) ) +
'\n          </li>\n        ';
} else if(step.status === "RECOVERED") {;
__p += '\n          <li class="step recovered ion-ios7-undo" title="' +
__e( T('console.addon-migration.step-started-at', step) ) +
'">\n            ' +
__e( T("console.addon-migration.steps." + step.value) ) +
'\n          </li>\n        ';
};
__p += '\n      ';
});
__p += '\n      ';
 if(migration.status === "OK") { ;
__p += '\n        <li class="step done ion-checkmark-round">' +
__e( T("console.addon-migration.end.successful", { migrationRuntime }) ) +
'</li>\n      ';
} else if(migration.status === "FAILED") { ;
__p += '\n        <li class="step failed ion-close">' +
__e( T("console.addon-migration.end.failed") ) +
'</li>\n      ';
} else if(migration.status === "RECOVERED" && !isAborted) { ;
__p += '\n        <li class="step done ion-checkmark-round">' +
__e( T("console.addon-migration.end.recovered") ) +
'</li>\n      ';
} else if(migration.status === "RECOVERED" && isAborted) { ;
__p += '\n        <li class="step done ion-checkmark-round">' +
__e( T("console.addon-migration.end.aborted") ) +
'</li>\n      ';
};
__p += '\n    </ol>\n\n  </div>\n  <div class="bloc-content">\n    ';
 if(migration.status === "OK" || migration.status === "FAILED" || migration.status === "RECOVERED" || migration.status === "RUNNING") { ;
__p += '\n      <div class="migration-actions">\n        ';
 if(migration.status === "OK"){ ;
__p += '\n          <button class="btn btn-green next">' +
__e( T("NEXT") ) +
'</button>\n        ';
} else if(migration.status === "FAILED" || (migration.status === "RECOVERED" && !isAborted)) { ;
__p += '\n          <a\n            class="btn btn-blue support"\n            href="' +
__e( ticketCenterURL ) +
'"\n          >' +
__e( T("console.addon-migration.contact-support") ) +
'</a>\n        ';
} else if(migration.status === "RUNNING" && currentMigrationStep && currentMigrationStep.value === "DUMP_AND_RESTORE") {;
__p += '\n          <button class="btn btn-orange abort">' +
__e( T("console.addon-migration.abort-migration") ) +
'</button>\n        ';
};
__p += '\n      </div>\n    ';
};
__p += '\n    ';
 if(outputMessage) { ;
__p += '\n      <div class="step-error-message">\n        <h3>' +
__e( T("console.addon-migration.error-output") ) +
'</h3>\n        <pre class="migration-error-message">' +
__e( outputMessage ) +
'</pre>\n      </div>\n    ';
};
__p += '\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AddonMigrationSP.plans"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="bloc addonchangeplan-migration-configuration">\n  <form class="target-database-migration">\n    <h2>' +
__e( T("console.addon-migration.step2") ) +
'</h2>\n    <div class="bloc-content current-database">\n      <h3 class="current-database-title">' +
__e( T("console.addon-migration.current-database") ) +
'</h3>\n\n      <div class="current-database-content">\n        <div class="current-database-content-name">\n          <p class="table-content">' +
__e( T("NAME") ) +
'</p>\n          <p class="table-content">' +
__e( addon.name ) +
'</p>\n        </div>\n        <div class="current-database-content-plan">\n          <p class="table-content">' +
__e( T("PLAN") ) +
'</p>\n          <p class="table-content">' +
__e( addon.plan.name ) +
'</p>\n        </div>\n        <div class="current-database-content-location">\n          <p class="table-content">' +
__e( T("ZONE") ) +
'</p>\n          <p class="table-content"><cc-zone state=\'' +
__e( JSON.stringify(currentZone) ) +
'\' mode="small-infra" style="display: inline-flex" %></cc-zone></p>\n        </div>\n      </div>\n    </div>\n    <div class="bloc-content target-database">\n      <h3 class="target-database-title">' +
__e( T("console.addon-migration.target-database") ) +
'</h3>\n      <div class="target-database-content">\n        <div class="target-database-content-name form-field">\n          <span class="target-database-content-entry">' +
__e( T("NAME") ) +
'</span>\n          <input type="text" name="target-database-name" value="' +
__e( addon.name || addon.id ) +
'" disabled="disabled"/>\n        </div>\n\n        <div class="target-database-content-plan form-field">\n          <span class="target-database-content-entry">' +
__e( T("PLAN") ) +
'</span>\n          <select name="target-database-plan" required="required">\n            ';
 provider.plans.forEach(function(plan) { ;
__p += '\n              <option value="' +
__e( plan.id ) +
'" ' +
__e( addon.plan.id === plan.id ? "selected='selected'" : "" ) +
'>\n                ' +
__e( plan.name ) +
': ' +
__e( plan.featuresJoin ) +
'\n              </option>\n            ';
});
__p += '\n          </select>\n        </div>\n\n        <div class="target-database-content-version form-field">\n          <span class="target-database-content-entry">' +
__e( T("console.addon-migration.target-version") ) +
'</span>\n          <div class="target-database-version-number">\n            ' +
((__t = ( Templates["AddonMigrationSP.version"]({
              provider: provider,
              versions: versions,
              currentSelectedVersion: currentSelectedVersion,
            }) )) == null ? '' : __t) +
'\n          </div>\n        </div>\n\n        <div class="target-database-content-location form-field">\n          <span class="target-database-content-entry">' +
__e( T("ZONE") ) +
'</span>\n          <select name="target-database-location">\n            ';
 zones.forEach(function(zone) { ;
__p += '\n              <option value="' +
__e( zone.name ) +
'" ' +
__e( addon.region === zone.name ? 'selected=selected' : '' ) +
'>\n                ' +
__e( zone.text ) +
'\n              </option>\n            ';
});
__p += '\n          </select>\n        </div>\n      </div>\n    </div><!-- Intentionnal to avoid a space and the width: 50% doesn\'t work\n    --><div class="bloc-content target-pricing">\n      <h3 class="target-pricing-title">' +
__e( T("SUMMARY") ) +
'</h3>\n      <div class="target-pricing-content">\n        <div class="monthly-summary">\n          <div class="monthly-summary-text">\n            ' +
__e( T("console.addon-migration.target-pricing.monthly-price") ) +
'\n          </div>\n          <div class="monthly-summary-price">\n            <span class="monthly-summary-price-content">' +
__e( addon.plan.price ) +
'</span>' +
__e( T("PRICE_PER_MONTH") ) +
'\n          </div>\n          <div class="monthly-summary-details">\n            ' +
__e( T("console.addon-migration.target-pricing.monthly-from", { month: pricingNextMonth.nextMonth }) ) +
'\n          </div>\n        </div>\n        <div class="monthly-current">\n          <div class="monthly-current-text">\n            ' +
__e( T("console.addon-migration.target-pricing.monthly-prorata") ) +
'\n          </div>\n          <div class="monthly-current-price">\n            0€\n          </div>\n          <div class="monthly-current-details">\n            ' +
__e( T("console.addon-migration.target-pricing.monthly-from-to", pricingNextMonth) ) +
'\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="addonchangeplan-migration-configuration-actions form-field">\n      <input type="reset" class="btn abort" value="' +
__e( T('ABORT') ) +
'" />\n      <button type="submit" class="btn btn-green">' +
__e( T("console.addon-migration.migrate") ) +
'</button>\n    </div>\n  </form>\n</div>\n';

}
return __p
};

this["Templates"]["AddonMigrationSP.version"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<select class="target-database-version-number-select" name="target-database-version" ' +
__e( versions.length === 1 ? 'disabled="disabled"' : '' ) +
' required>\n  ';
 var defaultSelected = versions.length > 1 && currentSelectedVersion == null; ;
__p += '\n  <option disabled ' +
__e( defaultSelected ? 'selected' : '' ) +
' value>' +
__e( T("console.addon-migration.target-version-select") ) +
'</option>\n  ';
 versions.forEach(function(version) { ;
__p += '\n    ';
 var selected = false;
      if(versions.length === 1) {
        selected = true;
      } else if(currentSelectedVersion === version) {
        selected = true;
      }
    ;
__p += '\n    <option\n      value="' +
__e( version ) +
'"\n      ' +
__e( selected ? 'selected="selected"' : '' ) +
'\n    >\n      ' +
__e( provider.name ) +
' ' +
__e( version ) +
'\n    </option>\n  ';
});
__p += '\n</select>\n';

}
return __p
};

this["Templates"]["AddonMigrationSP.zone"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 zones.forEach(function(zone) { ;
__p += '\n  <option value="' +
__e( zone.name ) +
'" ' +
__e( addon.region === zone.name ? 'selected=selected' : '' ) +
'>\n    ' +
__e( zone.text ) +
'\n  </option>\n';
});
__p += '\n';

}
return __p
};

this["Templates"]["AddonProviderCreationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <h1>Create an addon provider</h1>\n</header>\n\n<div class="addon-providers">\n  <div class="documentation">' +
((__t = ( T("console.addonproviders.create-documentation") )) == null ? '' : __t) +
'</div>\n</div>\n\n<div class="bloc addon-providers">\n  <div class="bloc-content addon-providers-new">\n    <form action="#">\n      <fieldset>\n        <label class="label label-large">Addon manifest</label>\n        <input type="file" accept="application/json" name="addon-manifest" required>\n      </fieldset>\n      <br />\n      <button type="submit" class="create btn btn-blue">' +
__e( T("CREATE") ) +
'</button>\n    </form>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AddonProviderPlansSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <h1>' +
((__t = ( T("PROVIDER_PLANS") )) == null ? '' : __t) +
'</h1>\n</header>\n\n<div class="alert"></div>\n\n<div class="plans-configuration"></div>\n';

}
return __p
};

this["Templates"]["AddonProviderSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <h1>' +
((__t = ( T("PROVIDER") )) == null ? '' : __t) +
'</h1>\n</header>\n\n<div class="card-container">\n  <div class="documentation documentation-pricing btn-right">\n    ' +
((__t = ( T("DOC_ABOUT_ADDON-PROVIDER") )) == null ? '' : __t) +
'\n  </div>\n  <h2>\n    ' +
__e( T("console.addon-provider.new") ) +
'\n  </h2>\n  <p class="bloc-description">\n    ' +
__e( T("console.addon-provider.new-desc") ) +
'\n  </p>\n  <form action="#" class="provider"></form>\n</div>\n\n';

}
return __p
};

this["Templates"]["AddonProviderSP.form"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="form-field">\n  <label for="provider-name" class="label-large">' +
((__t = ( T("NAME") )) == null ? '' : __t) +
':</label>\n  <input name="name" id="provider-name" type="text" value="' +
__e( information.name ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.name || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label for="provider-shortDesc" class="label-large">' +
((__t = ( T("SHORT_DESCRIPTION") )) == null ? '' : __t) +
':</label>\n  <input name="shortDesc" id="provider-shortDesc" type="text" value="' +
__e( information.shortDesc ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.shortDesc || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label for="provider-longDesc" class="label-large">' +
((__t = ( T("LONG_DESCRIPTION") )) == null ? '' : __t) +
':</label>\n  <input name="longDesc" id="provider-longDesc" type="text" value="' +
__e( information.longDesc ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.longDesc || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label for="provider-logoUrl" class="label-large">' +
((__t = ( T("LOGO_URL") )) == null ? '' : __t) +
':</label>\n  <input name="logoUrl" id="provider-logoUrl" type="text" value="' +
__e( information.logoUrl ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.logoUrl || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label for="provider-supportEmail" class="label-large">' +
((__t = ( T("SUPPORT_EMAIL") )) == null ? '' : __t) +
':</label>\n  <input name="supportEmail" id="provider-supportEmail" type="email" value="' +
__e( information.supportEmail ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.supportEmail || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label for="provider-website" class="label-large">' +
((__t = ( T("WEBSITE") )) == null ? '' : __t) +
':</label>\n  <input name="website" id="provider-website" type="text" value="' +
__e( information.website ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.website || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label for="provider-twitterName" class="label-large">' +
((__t = ( T("TWITTER_NAME") )) == null ? '' : __t) +
':</label>\n  <input name="twitterName" id="provider-twitterName" type="text" value="' +
__e( information.twitterName ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.twitterName || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label for="provider-googlePlusName" class="label-large">' +
((__t = ( T("GOOGLE_PLUS_NAME") )) == null ? '' : __t) +
':</label>\n  <input name="googlePlusName" id="provider-googlePlusName" type="text" value="' +
__e( information.googlePlusName ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.googlePlusName || "" ) +
'</span>\n</div>\n\n<div class="form-field">\n  <label for="provider-analyticsId" class="label-large">' +
((__t = ( T("GOOGLE_ANALYTICS_ID") )) == null ? '' : __t) +
':</label>\n  <input name="analyticsId" id="provider-analyticsId" type="text" value="' +
__e( information.analyticsId ) +
'" class="input-large" />\n  <span>' +
__e( typeof error != "undefined" && error.fields && error.fields.analyticsId || "" ) +
'</span>\n</div>\n\n<button class="btn btn-blue" type="submit">' +
((__t = ( T("SAVE") )) == null ? '' : __t) +
'</button>\n';

}
return __p
};

this["Templates"]["AddonSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar"></header>\n\n<div class="addon centered-maxed-width-container"></div>\n';

}
return __p
};

this["Templates"]["AddonSP.addon-tags"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(tags, function(tag) { ;
__p += '\n  <li>' +
__e( tag ) +
'<span class="ion-close-round tags-close" data-tag-val="' +
__e( tag ) +
'"></span></li>\n';
 }); ;
__p += '\n';

}
return __p
};

this["Templates"]["AddonSP.addon"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<cc-block>\n  <div slot="content">\n    <div class="addon-card">\n      <div class="addon-logo" style="background-image: url(\'' +
__e( addon.provider.logoUrl ) +
'\')"></div>\n      <div class="addon-name">\n        <h2 class="addon-update-name">' +
__e( name || provider.name ) +
'</h2>\n        <i class="addon-update-name ion ion-edit"></i>\n      </div>\n\n      <div class="addon-buttons">\n        <a href="' +
__e( href ) +
'" class="btn btn-blue addon-website">' +
((__t = ( T("CONFIGURATION") )) == null ? '' : __t) +
'</a>\n        <a href="' +
__e( provider.website ) +
'" class="btn addon-website">' +
((__t = ( T("GO_TO_WEBSITE") )) == null ? '' : __t) +
'</a>\n        <button type="button" class="btn btn-red addon-website remove">' +
((__t = ( T("REMOVE_ADDON") )) == null ? '' : __t) +
'</button>\n      </div>\n      <div class="addon-info">\n        <div class="addon-plan">\n          <div class="addon-plan-label">' +
__e( T("PLAN") ) +
'</div>\n          <div class="addon-plan-value">' +
__e( addon.plan.name ) +
'</div>\n        </div>\n        <div class="addon-realId">\n          <div class="addon-realId-label">' +
__e( T("console.addonsp.addon-id") ) +
'</div>\n          <cc-input-text value="' +
__e( realId ) +
'" readonly clipboard></cc-input-text>\n        </div>\n        <div class="addon-region">\n          <div class="addon-region-label">' +
((__t = ( T("ZONE") )) == null ? '' : __t) +
'</div>\n          <div class="addon-region-value"><cc-zone state=\'' +
__e( JSON.stringify(zoneState) ) +
'\' mode="small-infra" style="display: inline-flex" %></cc-zone></div>\n        </div>\n        <ul class="addon-tags tags">\n          <div class="addon-tags-label">' +
__e( T("TAGS") ) +
'</div>\n          <div class="tags-list">\n            ' +
((__t = ( Templates["AddonSP.addon-tags"]({tags: tags}) )) == null ? '' : __t) +
'\n          </div>\n          <input type="text" name="add-addon-tag" placeholder="' +
__e( T("console.addonsp.addon-tag-placeholder") ) +
'"/>\n        </ul>\n      </div>\n    </div>\n\n    <div class="bloc">\n      <div class="bloc-popin">\n        <h2>' +
((__t = ( T("APPLICATIONS") )) == null ? '' : __t) +
'</h2>\n      </div>\n      <div class="bloc-content">\n        <table>\n          <thead>\n            <tr>\n              <th>' +
((__t = ( T("NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("DESCRIPTION") )) == null ? '' : __t) +
'</th>\n              <th></th>\n            </tr>\n          </thead>\n          <tbody class="addon-applications">\n            ';
 _.each(apps, function(app) { ;
__p += '\n            <tr>\n              ';

                var href = (orgaId ?
                  '/organisations/' + orgaId :
                  '/users/me'
                ) + '/applications/' + app.id;
              ;
__p += '\n              <td><a href="' +
__e( href ) +
'">' +
__e( app.name ) +
'</a></td>\n              <td>' +
__e( app.description ) +
'</td>\n              ';
 var vhost = _.head(app.vhosts); ;
__p += '\n              <td>\n                ';
 if(vhost) { ;
__p += '\n                ';
 var appHref = (vhost.secure ? "http://" : "https://") + vhost.fqdn; ;
__p += '\n                <a href="' +
__e( appHref ) +
'" class="btn btn-right">' +
((__t = ( T("OPEN") )) == null ? '' : __t) +
'</a>\n                ';
 } ;
__p += '\n              </td>\n            </tr>\n            ';
 }); ;
__p += '\n            <tr>\n              <td colspan="3">\n                ';
 var href = (orgaId ? "/organisations/" + orgaId : "/users/me") + "/applications/new"; ;
__p += '\n                <a href="' +
__e( href ) +
'">' +
((__t = ( T("ADD_AN_APPLICATION") )) == null ? '' : __t) +
'</a>\n              </td>\n            </tr>\n          </tbody>\n        </table>\n      </div>\n    </div>\n\n    <div class="bloc">\n      <div class="bloc-popin">\n        <h2>' +
((__t = ( T("FEATURES") )) == null ? '' : __t) +
'</h2>\n      </div>\n      <div class="bloc-content">\n        <table>\n          <thead>\n            <tr>\n              <th>' +
((__t = ( T("NAME") )) == null ? '' : __t) +
'</th>\n              <th>' +
((__t = ( T("VALUE") )) == null ? '' : __t) +
'</th>\n            </tr>\n          </thead>\n          <tbody>\n            ';
 _.each(plan.features, function(feature) { ;
__p += '\n            <tr>\n              <td>' +
__e( feature.name ) +
'</td>\n              <td>' +
__e( feature.value ) +
'</td>\n            </tr>\n            ';
 }); ;
__p += '\n          </tbody>\n        </table>\n      </div>\n    </div>\n  </div>\n</cc-block>\n\n<br>\n<div data-hj-masked>\n  <cc-env-var-form context="env-var-simple" readonly style="border: none; padding: 0"></cc-env-var-form>\n</div>\n\n<br>\n';
 if(codeExemples){ ;
__p += '\n<cc-block style="overflow-x: auto">\n  <div slot="content">\n    <div class="bloc-popin">\n      <h2>' +
((__t = ( T("console.addon.connect-from-your-app") )) == null ? '' : __t) +
'</h2>\n    </div>\n    <div class="boxes-body">\n      ' +
((__t = ( codeExemples )) == null ? '' : __t) +
'\n    </div>\n  </div>\n</cc-block>\n';
};
__p += '\n';

}
return __p
};

this["Templates"]["AppConfigurationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar"></header>\n<div class="app-configuration centered-maxed-width-container">\n  <div class="box">\n    <div class="conso-bloc conso-bloc-credits">\n      <div class="conso-label">' +
((__t = ( T("MINIMUM_CONSUMPTION") )) == null ? '' : __t) +
'</div>\n      <div class="conso-value minimum-consumption"><div class="pulse"></div></div>\n    </div>\n\n    <div class="conso-bloc">\n      <div class="conso-label">' +
((__t = ( T("MAXIMUM_CONSUMPTION") )) == null ? '' : __t) +
'</div>\n      <div class="conso-value maximum-consumption"><div class="pulse"></div></div>\n    </div>\n  </div>\n\n  <div class="card-container">\n    <div class="current-configuration">\n      <div>\n        ' +
((__t = ( T("LOADING") )) == null ? '' : __t) +
'\n      </div>\n    </div>\n  </div>\n\n  <div class="card-container">\n  <div class="bloc">\n    <div class="bloc-popin">\n      <h2>' +
((__t = ( T("console.app-edit-scaling-options") )) == null ? '' : __t) +
'</h2>\n    </div>\n    <div class="bloc-content">\n      <form action="#" class="update-configuration">\n        <div class="pricer">\n        </div>\n        <div class="form-field">\n          <button type="submit" class="btn btn-green btn-big-valid">' +
((__t = ( T("UPDATE_CONFIGURATION") )) == null ? '' : __t) +
'</button>\n        </div>\n      </form>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AppConfigurationSP.current-configuration"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var autoscalability = (minInstances != maxInstances) || (minFlavor.name != maxFlavor.name); ;
__p += '\n\n<div class="scaling-config">\n  <div class="scaling-autoscale">\n    <div class="scaling-label">' +
((__t = ( T("AUTOSCALABILITY") )) == null ? '' : __t) +
'</div>\n    <div class="scaling-value">\n        ' +
((__t = ( !autoscalability ? T("DISABLED") : T("ENABLED") )) == null ? '' : __t) +
'\n    </div>\n  </div>\n  <div class="scaling-horizontal">\n    <div class="scaling-label">' +
((__t = ( T("HORIZONTAL_SCALING") )) == null ? '' : __t) +
'</div>\n    <div class="scaling-value">\n      ' +
((__t = ( !autoscalability ? minInstances : T("FROM_TO", {min: minInstances, max: maxInstances}) )) == null ? '' : __t) +
'\n    </div>\n  </div>\n  <div class="scaling-vertical">\n    <div class="scaling-label">' +
((__t = ( T("VERTICAL_SCALING") )) == null ? '' : __t) +
'</div>\n    <div class="scaling-value">\n      ' +
((__t = ( !autoscalability ? minFlavor.name : T("FROM_TO", {min: minFlavor.name, max: maxFlavor.name}) )) == null ? '' : __t) +
'\n    </div>\n  </div>\n</div>';

}
return __p
};

this["Templates"]["AppCreationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <div class="header-content app-creation">\n    <h1>' +
((__t = ( T("APP_CREATION") )) == null ? '' : __t) +
'</h1> -\n    <ul class="header-steps">\n      <li data-step="instance-type" class="active">' +
((__t = ( T("INSTANCE") )) == null ? '' : __t) +
'</li>\n      <li data-step="deployment-type">' +
((__t = ( T("DEPLOYMENT_TYPE") )) == null ? '' : __t) +
'</li>\n      <li data-step="scaling-configuration">' +
((__t = ( T("SCALABILITY") )) == null ? '' : __t) +
'</li>\n      <li data-step="information">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</li>\n    </ul>\n  </div>\n</header>\n\n<form class="app-creation centered-maxed-width-container" action="#"></form>\n<form class="addon-creation" action="#" style="display:none"></form>\n<div class="addonCreation_creation centered-maxed-width-container"></div>\n<div class="app-deployment centered-maxed-width-container" style="display:none"></div>\n<div class="app-success centered-maxed-width-container" style="display:none"></div>\n';

}
return __p
};

this["Templates"]["AppCreationSP.configuration"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<cc-block>\n\n  <div slot="header-title">' +
((__t = ( T("console.app-creation.how-many-instances") )) == null ? '' : __t) +
'</div>\n\n  <div slot="content-body" class="documentation">' +
((__t = ( T("DOC_ABOUT_SCALING") )) == null ? '' : __t) +
'</div>\n\n  <div slot="content-body" class="suggested-configuration" style="display:none"></div>\n  <div slot="content-body" class="pricer" style="display:none"></div>\n\n  <button slot="content-body" type="button" class="btn btn-blue btn-right next" style="display:none; justify-self: end;">' +
((__t = ( T("NEXT") )) == null ? '' : __t) +
'</button>\n\n</cc-block>\n';

}
return __p
};

this["Templates"]["AppCreationSP.success"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="app-success-logo">\n  <span class="ion-checkmark-circled"></span>\n</div>\n\n<h3>' +
((__t = ( T("console.app-creation.your-app-is-running") )) == null ? '' : __t) +
'</h3>\n\n<a target="_blank" href="http://' +
__e( app.vhosts[0].fqdn ) +
'">http://' +
__e( app.vhosts[0].fqdn ) +
'</a>\n\n';
 if(app.deployment.type == "FTP") { ;
__p += '\n';
 var href = (orgaId ? "/organisations/" + orgaId : "/users/me") + "/addons/" + app.id; ;
__p += '\n<div>' +
((__t = ( T("console.app-creation.ftp-instructions") )) == null ? '' : __t) +
'<a href="' +
__e( href ) +
'">' +
__e( href ) +
'</a></div>\n';
 } ;
__p += '\n';

}
return __p
};

this["Templates"]["AppDeploymentSP.deploying-loader"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<button class="btn btn-round deploying-loader"></button>\n';

}
return __p
};

this["Templates"]["AppDeploymentsSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar"></header>\n\n<div class="cancel-deployment centered-maxed-width-container"></div>\n<div class="centered-maxed-width-container">\n  <cc-block>\n    <div slot="header-title">' +
((__t = ( T('DEPLOYMENTS_ACTIVITY') )) == null ? '' : __t) +
'</div>\n    <div slot="content-body" class="app-activity">\n      ' +
((__t = ( Templates["AppDeploymentsSP.loader"]() )) == null ? '' : __t) +
'\n    </div>\n  </cc-block>\n</div>\n';

}
return __p
};

this["Templates"]["AppDeploymentsSP.cancel-deployment"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="alert" data-status="warning">\n  ' +
((__t = ( T("DEPLOYMENT_ABORTION_MESSAGE") )) == null ? '' : __t) +
'\n  <button type="button" class="btn btn-red btn-right abort no-disable"><i class="ion-close-circled"></i> ' +
((__t = ( T("ABORT") )) == null ? '' : __t) +
'</button>\n</div>\n';

}
return __p
};

this["Templates"]["AppDeploymentsSP.loader"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="loader-container">\n  <div class="loader app-activities">\n    <div class="loader-inner line-scale"><div></div><div></div><div></div><div></div><div></div></div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AppDeploymentsSP.logs"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 var displayDeployments = function(deployment, index){
  var action; ;
__p += '\n  <div class="activity-block">\n    ';
 if(deployment.state == "OK") {
    action = deployment.action ;
__p += '\n    <div class="activity-action ion-checkmark-circled state-ok" title="' +
((__t = ( T('DEPLOYMENT_' + deployment.state) )) == null ? '' : __t) +
'"></div>\n    ';
 } else if(deployment.state == "WIP" && index === 0) {
    action = T("DEPLOYMENT_WIP").toUpperCase(); ;
__p += '\n    <div class="activity-action ion-code-working state-pending" title="' +
((__t = ( T('DEPLOYMENT_' + deployment.state) )) == null ? '' : __t) +
'"></div>\n    ';
 } else if(deployment.state == "CANCELLED"){
    action = deployment.action + " (" + T("DEPLOYMENT_CANCELLED") + ")"; ;
__p += '\n    <div class="activity-action ion-close-circled state-error" title="' +
((__t = ( T('DEPLOYMENT_' + deployment.state) )) == null ? '' : __t) +
'"></div>\n    ';
 } else {
    action = deployment.action; ;
__p += '\n    <div class="activity-action ion-close-circled state-error"></div>\n    ';
 } ;
__p += '\n\n    <div class="activity-details">\n      <div class="activity-header">\n        ';
 if(deployment.state == "WIP" && index === 0) {
        action = '';
        } else{
        action = deployment.action;
        } ;
__p += '\n        <h4 class="activity-header-state">' +
__e( action ) +
' ' +
((__t = ( T('DEPLOYMENT_' + deployment.state) )) == null ? '' : __t) +
'</h4>\n      </div>\n      <span class="activity-date">\n        <i class="ion ion-clock"></i> <cc-datetime-relative datetime="' +
__e( deployment.date ) +
'"></cc-datetime-relative> via ' +
__e( deployment.cause ) +
'\n      </span>\n      ';
 if(!isGITApp || deployment.commit){ ;
__p += '\n        <div class="activity-actions">\n          ';
 if((lastCommit && lastCommit === deployment.commit && deployment.state === "OK") || (!isGITApp && deployment.state === "OK" && deployment.action === "DEPLOY")){;
__p += '\n          <a href="http://' +
__e( vhost.fqdn ) +
'" target="_blank" class="deployment-action ' +
__e( !isGITApp ? 'ftp' : '' ) +
'">\n              <i class="ion ion-forward"></i><br />\n              <span>' +
__e( T('OPEN_MY_APP_IN_TAB') ) +
'</span>\n            </a>\n          ';
} else if(deployment.state === "WIP" && index === 0){ ;
__p += '\n          <a class="deployment-action" href="' +
__e( orgaId ? '/organisations/' + orgaId + '/applications/' + appId + '/logs' : '/users/me/applications/' + appId + '/logs' ) +
'">\n              <i class="ion ion-document-text"></i><br />\n              <span>' +
__e( T('READ_LOGS') ) +
'</span>\n            </a>\n          ';
} else if(isGITApp){;
__p += '\n            <a class="deployment-action" data-commitid="' +
__e( deployment.commit ) +
'">\n              <i class="ion ion-skip-backward"></i><br />\n              <span>' +
__e( T("console.app-deployments.replay-commit") ) +
'</span>\n            </a>\n          ';
};
__p += '\n        </div>\n      ';
};
__p += '\n      ';
 if(deployment.author && deployment.author.member && (deployment.author.member.name || deployment.author.member.email || deployment.author.member.id)){ ;
__p += '\n        <div class="activity-author">\n          <i class="ion ion-person"></i> ' +
__e( deployment.author.member.name || deployment.author.member.email || deployment.author.member.id ) +
'\n        </div>\n      ';
 } ;
__p += '\n      ';
 if(type === 'GIT' && deployment.commit){ ;
__p += '\n        <div class="activity-commit">\n          <span class="activity-commit-hash">Commit ID</span> #' +
__e( deployment.commit.substr(0,8) ) +
'\n        </div>\n      ';
 } ;
__p += '\n      ';
 if((deployment.timestamp - sevenDaysAgo) > 0){ ;
__p += '\n        <div class="activity-deployment-logs">\n          <span>\n            <a href="' +
__e( (orgaId ? '/organisations/' + orgaId : '/users/me') + '/applications/' + appId + '/logs?deploymentId=' + deployment.uuid ) +
'">\n              <i class="ion ion-document-text"></i>\n              ' +
__e( T("LOGS") ) +
'\n            </a>\n          </span>\n        </div>\n      ';
};
__p += '\n    </div>\n  </div>\n';
 }; ;
__p += '\n\n<div class="activity-timeline">\n<div class="timelineBar"></div>\n';
 _.each(deployments, displayDeployments); ;
__p += '\n</div>\n<div class="activity-more-loader">\n  ' +
((__t = ( Templates["AppDeploymentsSP.loader"]() )) == null ? '' : __t) +
'\n</div>\n<div class="activity-no-more">\n  ' +
__e( T("console.app-deployments.no-more-deployments") ) +
'\n</div>\n';

}
return __p
};

this["Templates"]["AppDeploymentsSP.no-deployments"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="bloc">\n  <div class="bloc-content no-activity">\n    <p>' +
__e( T('console.app-deployments.no-activity') ) +
'</p>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AppSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar"></header>\n\n<form action="#" class="centered-maxed-width-container"></form>\n';

}
return __p
};

this["Templates"]["AppSP.form"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

if(app.last_deploy <= 0){
  var href = (isOwnerOrga ? '/organisations/' + owner.id : '/users/me') + '/applications/' + app.id;
  var addonHref = (isOwnerOrga === 0 ? '/organisations/' + owner.id : '/users/me') + '/addons/' + app.id  + '/settings' ;
__p += '\n\n  ';
 if(justCreated) { ;
__p += '\n    <div class="success">\n      <div class="success-logo">\n        <span class="ion-checkmark-circled"></span>\n      </div>\n      <div class="success-message">\n        ' +
__e( T("APPLICATION_HAS_BEEN_CREATED") ) +
'\n      </div>\n    </div>\n  ';
};
__p += '\n\n  <cc-block>\n    <div slot="header-title">' +
__e( T("STEP_BY_STEP_DEPLOYMENT") ) +
'</div>\n    <table slot="content-body" class="table app-informations">\n      <tbody>\n        ';
if(app.deployment.type == "GIT" && !app.webhookUrl) { ;
__p += '\n        <tr>\n          <th>' +
__e( T("PUSH_STEP") ) +
'</th>\n          <td width="100%">\n            <cc-input-text value="git remote add clever ' +
__e( app.deployUrl ) +
'\ngit push clever master" multi readonly clipboard style="width: 100%"></cc-input-text>\n          </td>\n        </tr>\n        ';
 } else if(app.deployment.type == "GIT" && app.webhookUrl){ ;
__p += '\n          <tr>\n            <th>' +
__e( T("INSTRUCTIONS") ) +
'</th>\n            <td>' +
((__t = ( T("GITHUB_INSTRUCTIONS_DOC") )) == null ? '' : __t) +
'</td>\n          </tr>\n          <tr>\n            <th>' +
__e( T("GITHUB_SECRET") ) +
'</th>\n            <td>\n              <cc-input-text value="' +
__e( app.webhookSecret ) +
'" readonly clipboard secret></cc-input-text>\n            </td>\n          </tr>\n          <tr>\n            <th>' +
__e( T("console.deployment.custom-branch") ) +
'</th>\n            <td>' +
__e( app.branch ) +
'</td>\n          </tr>\n        ';
 } else if(app.deployment.type == "FTP") { ;
__p += '\n          <tr>\n            <th>' +
__e( T("INSTRUCTIONS") ) +
'</th>\n            <td>' +
((__t = ( T("FSBUCKET_INSTRUCTIONS", {addonHref: addonHref}) )) == null ? '' : __t) +
'</td>\n          </tr>\n      ';
 } ;
__p += '\n      </tbody>\n    </table>\n  </cc-block>\n  <br/>\n';
};
__p += '\n\n<cc-block>\n  <div slot="header-title">' +
__e( T("APP_INFORMATION") ) +
'</div>\n  <table slot="content-body" class="table app-informations">\n    <tbody>\n      <tr>\n        <th>' +
__e( T("APPLICATION_ID") ) +
'</th>\n        <td>' +
__e( app.id ) +
'</td>\n      </tr>\n      <tr>\n        <th>' +
__e( T("CREATION_DATE") ) +
'</th>\n        <td>' +
__e( app.creationDate ) +
'</td>\n      </tr>\n      <tr>\n        <th>' +
__e( T("INSTANCE_TYPE") ) +
'</th>\n        <td>' +
__e( app.instance.variant.name + " -- " + app.instance.version ) +
'</td>\n      </tr>\n      <tr>\n        ';
if(app.last_deploy > 0){
          if(app.deployment.type == "GIT" && app.webhookUrl){ ;
__p += '\n            <th>' +
__e( T("GITHUB_APPLICATION_SETTINGS") ) +
'</th>\n            <td>\n              <cc-input-text value="' +
__e( app.webhookUrl ) +
'" readonly clipboard></cc-input-text>\n            </td>\n          ';
} else if(app.deployment.type == "FTP"){ ;
__p += '\n            <th>' +
__e( T("FSBUCKET_DEPLOYMENT") ) +
'</th>\n            <td><a href="' +
__e( (isOwnerOrga ? '/organisations/' + owner.id : '/users/me' ) + '/addons/' + app.id ) +
'">' +
__e( T("CLICK_ACCESS_FSBUCKET") ) +
'</a></td>\n          ';
} else{ ;
__p += '\n            <th>' +
__e( T("DEPLOYMENT_URL") ) +
'</th>\n            <td><cc-input-text value="' +
__e( app.deployUrl ) +
'" readonly clipboard></cc-input-text></td>\n          ';
}
        };
__p += '\n      </tr>\n      ';
 if(app.deployment.type == "GIT" && app.webhookUrl) { ;
__p += '\n      <tr>\n        <th>' +
__e( T("GITHUB_SECRET") ) +
'</th>\n        <td>\n          <cc-input-text value="' +
__e( app.webhookSecret ) +
'" readonly clipboard secret></cc-input-text>\n        </td>\n      </tr>\n      <tr>\n        <th>' +
__e( T("console.app-sp.github-url") ) +
'</th>\n        <td><cc-input-text value="' +
__e( app.deployment.url ) +
'" readonly clipboard></cc-input-text></td>\n      </tr>\n      ';
 } ;
__p += '\n      ';
 if(app.instance.type.indexOf("apache+php") == 0) { ;
__p += '\n      <tr>\n        <th>' +
__e( T("ZONE") ) +
'</th>\n        <td>' +
__e( app.zone ) +
'</td>\n      </tr>\n      ';
 } ;
__p += '\n    </tbody>\n  </table>\n</cc-block>\n\n<br>\n<cc-block>\n  <div slot="header-title">' +
__e( T("APP_EDITION") ) +
'</div>\n  <div slot="content" class="form-field">\n    <label class="label-large" for="app-name">' +
__e( T("NAME") ) +
':</label>\n    <input class="input-large" name="app-name" id="app-name" type="text" value="' +
__e( app.name ) +
'" />\n  </div>\n\n  <div slot="content" class="form-field">\n    <label class="label-large" for="app-description">' +
__e( T("DESCRIPTION") ) +
':</label>\n    <input class="input-large" name="app-description" id="app-description" type="text" value="' +
__e( app.description ) +
'" />\n  </div>\n\n  ';
 if(app.instance.type.indexOf("apache+php") < 0) { ;
__p += '\n  <div slot="content" class="form-field">\n    <label class="label-large">' +
__e( T("ZONE") ) +
':</label>\n    <cc-zone-input state=\'' +
__e( JSON.stringify(zoneInputState) ) +
'\' selected="' +
__e( app.zone ) +
'" style="height: 300px; width: 455px;"></cc-zone-input>\n  </div>\n  ';
 } ;
__p += '\n\n  ';
 if(app.deployment.type === 'GIT' && app.webhookUrl){ ;
__p += '\n    <div slot="content" class="form-field edit-branch">\n      <label class="label-large" for="app-branch">' +
__e( T("console.deployment.custom-branch") ) +
':</label>\n      <label class="label-select label-loading deployment-branch">\n        <select class="select-large" name="app-branch">\n          <option value="' +
__e( app.branch ) +
'" selected>' +
__e( app.branch ) +
'</option>\n        </select>\n      </label>\n    </div>\n  ';
};
__p += '\n\n  <div slot="content" class="bloc">\n    <div class="bloc-content no-padding">\n      <ul class="checkboxed">\n        <li class="app-edit ">\n          <input type="checkbox" name="app-homogeneous" class="checkbox" id="app-homogeneous" ' +
__e( app.homogeneous ? "" : "checked" ) +
' />\n          <label for="app-homogeneous">' +
__e( T("ZERO_DOWNTIME_DEPLOYMENT") ) +
'</label>\n          <p>\n            ' +
__e( T("ZERO_DOWNTIME_DEPLOYMENT_DESC") ) +
'\n          </p>\n        </li>\n        <li class="app-edit">\n          <input type="checkbox" name="sticky-sessions" class="checkbox" id="sticky-sessions" ' +
__e( app.stickySessions ? "checked" : "" ) +
' />\n          <label for="sticky-sessions">' +
__e( T("STICKY_SESSIONS") ) +
'</label>\n          <p>\n            ' +
__e( T("STICKY_SESSIONS_DESC") ) +
'\n          </p>\n        </li>\n        <li class="app-edit">\n          <input type="checkbox" name="separate-build" class="checkbox" id="separate-build" ' +
__e( app.separateBuild ? "checked": "" ) +
' />\n          <label for="separate-build">' +
__e( T("console.appsp.separate-build-title") ) +
'</label>\n          <p>' +
__e( T("console.appsp.separate-build") ) +
'</p>\n          <p class="default-build-flavor-settings ' +
__e( !app.separateBuild ? 'hidden': '' ) +
'">\n            ' +
__e( T("console.appsp.default-build-flavor") ) +
'\n            <select class="default-build-flavor" name="default-build-flavor">\n              ';
 buildFlavors.forEach(function(flavor) { ;
__p += '\n                <option value="' +
__e( flavor.name ) +
'" ' +
__e( app.buildFlavor.name.toLowerCase() === flavor.name.toLowerCase() ? 'selected="selected"' : '' ) +
'>\n                  ' +
__e( flavor.name.toUpperCase() ) +
'\n                </option>\n              ';
});
__p += '\n            </select>\n          </p>\n        </li>\n        <li class="app-edit">\n          <div class="form-field">\n          <input type="checkbox" name="cancel-on-push" class="checkbox" id="cancel-on-push" ' +
__e( app.cancelOnPush ? "checked" : "" ) +
' />\n          <label for="cancel-on-push">' +
__e( T("console.appsp.cancel-on-push-title") ) +
'</label>\n          <p>' +
__e( T("console.appsp.cancel-on-push-body") ) +
'</p>\n          </div>\n        </li>\n        <li class="app-edit ">\n          <input type="checkbox" name="force-https" class="checkbox" id="force-https" ' +
__e( app.forceHttps === 'ENABLED' ? "checked" : "" ) +
' />\n          <label for="force-https">' +
__e( T("console.appsp.force-https-title") ) +
'</label>\n          <p>' +
((__t = ( T("console.appsp.force-https-body") )) == null ? '' : __t) +
'</p>\n        </li>\n        <li class="app-edit ">\n          <input type="checkbox" name="instance-lifetime" class="checkbox" id="instance-lifetime" ' +
__e( app.instance.lifetime === 'TASK' ? "checked" : "" ) +
' />\n          <label for="instance-lifetime">' +
__e( T("TASK") ) +
'</label>\n          <p>\n            ' +
__e( T("TASK_DESC") ) +
'\n          </p>\n        </li>\n      </ul>\n    </div>\n    <div class="form-field">\n      <button class="btn btn-blue" type="submit">' +
__e( T("SAVE") ) +
'</button>\n      <button class="btn" type="reset">' +
__e( T("RESET") ) +
'</button>\n    </div>\n  </div>\n</cc-block>\n\n<br>\n';
 if(!isOwnerOrga || Role.canRemoveApp(owner.role)){ ;
__p += '\n<cc-block>\n  <div slot="header-title">' +
__e( T("console.appsp.archived-app-title") ) +
'</div>\n  <ul slot="content-body">\n    <li class="app-edit">\n      ' +
__e( T("console.appsp.archived-app-body") ) +
'\n      <button type="button" class="btn btn-right archive">' +
__e( app.archived ? T("console.appsp.archived-app-button-unarchive") : T("console.appsp.archived-app-button-archive") ) +
'</button>\n    </li>\n  </ul>\n</cc-block>\n';
};
__p += '\n\n<br>\n<cc-block>\n  <div slot="header-title">' +
__e( T("APP_DELETION") ) +
'</div>\n  <ul slot="content-body">\n    <li class="app-edit">\n      ';
 if(!isOwnerOrga || Role.canRemoveApp(owner.role)){ ;
__p += '\n        <button type="button" class="btn btn-red btn-right remove" ' +
__e( !isOwnerOrga || Role.canRemoveApp(owner.role) ? '' : "disabled=disabled" ) +
'>' +
__e( T("REMOVE_APPLICATION") ) +
'</button>\n        <div>' +
__e( T("REMOVE_APPLICATION_?") ) +
'</div>\n        ';
 } else if(isOwnerOrga && !Role.canRemoveApp(owner.role)){
          var canRemoveApps = _.filter(members, function(member){
            return Role.canRemoveApp(member.role);
          }); ;
__p += '\n          <div class="remove-app no-rights">' +
__e( T("REMOVE_APPLICATION_WRONG_RIGHTS") ) +
':\n            ' +
((__t = ( _.map(canRemoveApps, function(member){
              return Templates["AppSP.form.remove-apps-no-rights"]({
                email: member.member.email
              }).trim();
            }).join(', ') )) == null ? '' : __t) +
'\n        </div>\n      ';
 } ;
__p += '\n    </li>\n  </ul>\n</cc-block>\n';

}
return __p
};

this["Templates"]["AppSP.form.branch-options"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<option value="' +
__e( branch ) +
'">' +
__e( branch ) +
'</option>\n';

}
return __p
};

this["Templates"]["AppSP.form.remove-apps-no-rights"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<a href="mailto:' +
__e( email ) +
'">' +
__e( email ) +
'</a>\n';

}
return __p
};

this["Templates"]["AppServicesDependenciesSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar"></header>\n\n<div class="alert app centered-maxed-width-container"></div>\n\n<div class="card-container addons-services-container centered-maxed-width-container">\n  <div class="bloc addons-services">\n    <div class="bloc-popin">\n      <h2>' +
__e( T("console.service-dependencies.link-addons") ) +
'</h2>\n    </div>\n    <div class="bloc-content">\n      <div class="dropdown-pick-addons">\n        <div class="dropdown-container"></div>\n        <button class="btn btn-blue link addon-link">' +
__e( T("ADD") ) +
'</button>\n      </div>\n      <table>\n        <thead>\n          <tr>\n            <th>' +
((__t = ( T("NAME") )) == null ? '' : __t) +
'</th>\n            <th>' +
((__t = ( T("ADDON") )) == null ? '' : __t) +
'</th>\n            <th>' +
((__t = ( T("PLAN_NAME") )) == null ? '' : __t) +
'</th>\n            <th>' +
((__t = ( T("ZONE") )) == null ? '' : __t) +
'</th>\n            <th></th>\n          </tr>\n        </thead>\n        <tbody class="addons">\n          <tr>\n            <td>' +
((__t = ( T("LOADING") )) == null ? '' : __t) +
'</td>\n            <td></td>\n            <td></td>\n            <td></td>\n            <td></td>\n          </tr>\n        </tbody>\n      </table>\n    </div>\n  </div>\n</div>\n\n<div class="card-container centered-maxed-width-container">\n  <div class="bloc app-dependencies-services">\n    <div class="bloc-popin">\n      <h2>' +
__e( T("console.service-dependencies.link-applications") ) +
'</h2>\n    </div>\n    <div class="bloc-content">\n      <div class="dropdown-pick-dependencies">\n        <div class="dropdown-container"></div>\n        <button class="btn btn-blue link dependency-link">' +
__e( T("ADD") ) +
'</button>\n      </div>\n      <table>\n        <thead>\n          <tr>\n            <th>' +
((__t = ( T("NAME") )) == null ? '' : __t) +
'</th>\n            <th>' +
((__t = ( T("TYPE") )) == null ? '' : __t) +
'</th>\n            <th>' +
((__t = ( T("DEPLOYMENT_STATE") )) == null ? '' : __t) +
'</th>\n            <th></th>\n          </tr>\n        </thead>\n        <tbody class="app-dependencies">\n          <tr>\n            <td>' +
((__t = ( T("LOADING") )) == null ? '' : __t) +
'</td>\n            <td></td>\n            <td></td>\n            <td></td>\n          </tr>\n        </tbody>\n      </table>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["AppServicesDependenciesSP.addons"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(addons, function(addon) { ;
__p += '\n';
 var href = (orgaId ? '/organisations/' + orgaId : '/users/me') + '/addons/' + addon.id; ;
__p += '\n';
 const zoneState = zones.find((z) => z.name === addon.region); ;
__p += '\n<tr data-addon="' +
__e( addon.id ) +
'">\n  <td><a href="' +
__e( href ) +
'">' +
__e( addon.name || addon.id ) +
'</a></td>\n  <td>' +
__e( addon.provider.name ) +
'</td>\n  <td>' +
__e( addon.plan.name ) +
'</td>\n  <td><cc-zone state=\'' +
__e( JSON.stringify(zoneState) ) +
'\' mode="small-infra" style="display: inline-flex" %></cc-zone></td>\n  <td>\n    <button class="btn btn-red btn-small unlink-addon btn-right"><i class="ion ion-minus-circled"></i></button>\n  </td>\n</tr>\n';
 }); ;
__p += '\n';

}
return __p
};

this["Templates"]["AppServicesDependenciesSP.app"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p +=
((__t = ( T("WARNING_DIFFERENT_ZONE", {appName: app.name, zone: zone.text }) )) == null ? '' : __t) +
'\n';

}
return __p
};

this["Templates"]["AppServicesDependenciesSP.dependencies"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(dependencies, function(dependency) { ;
__p += '\n';
 var href = (orgaId ? '/organisations/' + orgaId : '/users/me') + '/applications/' + dependency.id + '/exposed-variables'; ;
__p += '\n<tr data-dependency="' +
__e( dependency.id ) +
'">\n  <td><a href="' +
__e( href ) +
'">' +
__e( dependency.name || dependency.id ) +
'</a></td>\n  <td>' +
__e( dependency.instanceType || dependency.instance.type ) +
'</td>\n  <td class="application-state" data-status="' +
__e( dependency.status ) +
'"><span class="list-icon list-icon-small ion-record"></span></td>\n  <td>\n    <button class="btn btn-red btn-small btn-right unlink-dependency"><i class="ion ion-trash-a"></i></button>\n  </td>\n</tr>\n';
 }); ;
__p += '\n';

}
return __p
};

this["Templates"]["BillSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <h1>' +
((__t = ( T("INVOICE") )) == null ? '' : __t) +
'</h1>\n</header>\n\n<div class="invoice" data-hj-masked></div>\n\n<div class="payment" data-hj-masked></div>\n';

}
return __p
};

this["Templates"]["BillSP.invoice-pay"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(card){ ;
__p += '\n  <button type="submit" data-action="pay-addon" class="btn btn-right btn-green addon-creation-pay ' +
__e( card ? '' : 'hidden' ) +
'">\n    ' +
__e( T("console.bills.pay-addon", card) ) +
'\n  </button>\n';
} else{ ;
__p += '\n  <button type="button" data-action="add-payment-method" class="btn btn-right btn-blue new-payment-method ' +
__e( card ? 'hidden' : '' ) +
'">\n    ' +
__e( T("console.owner-cards.add-payment-method") ) +
'\n  </button>\n';
};
__p += '\n';

}
return __p
};

this["Templates"]["HomeSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<cc-expand class="home-payment-warning"></cc-expand>\n\n<div class="home-header">\n  <h1 class="home-title" id="homepage-heading-console-name">' +
((__t = ( T("WELCOME_HOMEPAGE") )) == null ? '' : __t) +
'</h1>\n</div>\n\n<div class="home-feeds">\n\n  <h2 class="home-title">' +
((__t = ( T("GETTING_STARTED") )) == null ? '' : __t) +
'</h2>\n  <p class="home-description">' +
((__t = ( T("GETTING_STARTED_HEADLINE") )) == null ? '' : __t) +
'</p>\n\n  <div class="home-content">\n    <ul class="regular-tutorials">\n      <li><i class="ion-document-text"></i><a target="_blank" href="' +
__e( Console.DOC_BASE_URL ) +
'doc/quickstart/">Create an application</a></li>\n      <li><i class="ion-document-text"></i><a target="_blank" href="' +
__e( Console.DOC_BASE_URL ) +
'doc/quickstart/#create-your-first-add-on">Addons</a></li>\n      <li><i class="ion-document-text"></i><a target="_blank" href="' +
__e( Console.DOC_BASE_URL ) +
'doc/applications/php/">Run your PHP app</a></li>\n      <li><i class="ion-document-text"></i><a target="_blank" href="' +
__e( Console.DOC_BASE_URL ) +
'guides/node-js-mongo-db/">Sample Node.js app with MongoDB</a></li>\n      <li><i class="ion-document-text"></i><a target="_blank" href="' +
__e( Console.DOC_BASE_URL ) +
'doc/administrate/domain-names/">Setup your domain name</a></li>\n      <li><i class="ion-document-text"></i><a target="_blank" href="' +
__e( Console.DOC_BASE_URL ) +
'doc/account/ssh-keys-management/">Setup your SSH keys</a></li>\n      <li><i class="ion-document-text"></i><a target="_blank" href="' +
__e( Console.DOC_BASE_URL ) +
'doc/find-help/faq/">FAQ</a></li>\n      <li><i class="ion-document-text"></i><a target="_blank" href="' +
__e( Console.DOC_BASE_URL ) +
'doc/reference/common-configuration/">Common apps configuration</a></li>\n    </ul>\n  </div>\n</div>\n\n<div class="docs-links">\n  <h2 class="home-title">' +
((__t = ( T("DOCUMENTATION") )) == null ? '' : __t) +
'</h2>\n  <p class="home-description"></p>\n  <cc-doc-list></cc-doc-list>\n</div>\n\n<div class="news-feed">\n  <h2 class="home-title">' +
((__t = ( T("PRODUCT_CHANGES") )) == null ? '' : __t) +
'</h2>\n  <cc-smart-container id="container-for-article-list">\n    <cc-article-list></cc-article-list>\n  </cc-smart-container>\n</div>\n';

}
return __p
};

this["Templates"]["HomeSP.email-validation"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="home-email-validation alert email-validation" data-status="info">\n  <p class="home-email-validation-msg">\n    ' +
__e( T("VALIDATE_YOUR_EMAIL_ADDRESS", {email: user.email}) ) +
'\n  </p>\n  <button type="button" class="btn btn-small btn-blue resend">' +
((__t = ( T("RESEND_EMAIL_BUTTON") )) == null ? '' : __t) +
'</button>\n</div>\n';

}
return __p
};

this["Templates"]["LogsSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar"></header>\n<div class="logs-container"></div>\n';

}
return __p
};

this["Templates"]["MetricsSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar beta"></header>\n\n<div class="metrics-info-header card-container hidden"></div>\n\n<div class="card-container metrics-container">\n  <div class="metrics-loader">\n    <div class="metrics-loader-bars">\n      ' +
((__t = ( Templates["loader-bars"]() )) == null ? '' : __t) +
'\n    </div>\n    <div class="metrics-loading-reasons loading-container hidden">\n      ' +
((__t = ( T("console.metrics.no-metrics-yet") )) == null ? '' : __t) +
'\n    </div>\n  </div>\n  <div class="metrics-simple-view hidden">\n    <div class="metrics-simple-view-header advanced-view">\n      <div class="change-view-buttons">\n        <button class="btn btn-right">' +
__e( T("console.metrics.advanced") ) +
'</button>\n      </div>\n      <h2>' +
__e( T("console.metrics.simple-view") ) +
'</h2>\n    </div>\n    <div class="metrics-overview"></div>\n  </div>\n  <div class="metrics-advanced-view hidden">\n    <div class="metrics-selectors">\n      <div class="metrics-zoom-selector"></div>\n      <div class="metrics-custom">\n        <button class="btn">' +
__e( T("console.metrics.custom_view") ) +
'</button>\n      </div>\n      <div class="metrics-date-selectors">\n        <div class="metrics-date-selector metrics-date-selector-end">\n          <span>' +
__e( T("console.metrics.timespan-until") ) +
'</span>\n          <input></input>\n        </div>\n      </div>\n      <div class="dropdown-selector"></div>\n    </div>\n    <div class="metrics-charts-container"></div>\n  </div>\n  <div class="metrics-custom-view hidden"></div>\n</div>\n';

}
return __p
};

this["Templates"]["OauthConsumerCreationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n    <h1>' +
__e( T("console.oauth-consumers.new-consumer") ) +
'</h1>\n</header>\n\n<div class="create-oauth-consumer">\n    <div class="oauth-consumer-creation">\n        <form action="#" data-step="consumer-info"></form>\n        <form action="#" data-step="consumer-rights"></form>\n    </div>\n</div>\n';

}
return __p
};

this["Templates"]["OauthConsumerEditSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n    <h1>' +
__e( T("console.oauth-consumers.edit-consumer") ) +
'</h1>\n</header>\n\n<div class="edit-oauth-consumer"></div>\n';

}
return __p
};

this["Templates"]["OauthConsumerEditSP.consumer"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="bloc-content">\n  <form action="#">\n    <div class="oauth-consumer-info">\n      ' +
((__t = ( Templates["OauthConsumerCreation.consumer-info"]({consumer: consumer}) )) == null ? '' : __t) +
'\n    </div>\n    <div class="oauth-consumer-rights">\n      ' +
((__t = ( Templates["OauthConsumerCreation.consumer-rights"]({consumer: consumer, rights: consumer.parsedRights}) )) == null ? '' : __t) +
'\n    </div>\n    <div class="buttons">\n      <button type="submit" class="btn btn-blue">' +
__e( T("SAVE") ) +
'</button>\n    </div>\n  </form>\n</div>\n';

}
return __p
};

this["Templates"]["OauthConsumerSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <h1>OAuth consumer</h1>\n</header>\n\n<div class="oauth-consumer" data-hj-masked></div>\n';

}
return __p
};

this["Templates"]["OauthConsumerSP.consumer"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="oauth-consumer-logo">\n  <img src="' +
__e( consumer.picture || 'https://cc-assets.cellar-c1.clvrcld.net/console/tokens-icon.png' ) +
'" alt="' +
__e( consumer.name ) +
'" title="' +
__e( consumer.name ) +
'"/>\n  <h2>' +
__e( consumer.name ) +
'</h2>\n</div>\n<div class="oauth-consumer-logo-line">\n  ' +
__e( T("console.user-oauth-tokens.key") ) +
':<br>\n  <cc-input-text value="' +
__e( consumer.key ) +
'" readonly clipboard secret></cc-input-text>\n</div>\n<div class="oauth-consumer-logo-line">\n  ' +
__e( T("console.user-oauth-tokens.secret") ) +
':<br>\n  <cc-input-text value="' +
__e( secret ) +
'" readonly clipboard secret></cc-input-text>\n</div>\n<div class="oauth-consumer-logo-line">\n  ' +
__e( T('console.user-oauth-tokens.oauth-application-homepage-url') ) +
':\n  <a href="' +
__e( consumer.url ) +
'" target="_blank" rel="noreferrer noopener">' +
__e( consumer.url ) +
'</a></td>\n</div>\n<div class="oauth-consumer-logo-line">\n  ' +
__e( T('console.user-oauth-tokens.oauth-application-base-url') ) +
':\n  <a href="' +
__e( consumer.baseUrl ) +
'" target="_blank" rel="noreferrer noopener">' +
__e( consumer.baseUrl ) +
'</a></td>\n</div>\n<div class="buttons">\n  <button class="btn btn-red delete">' +
__e( T("DELETE") ) +
'</button>\n</div>\n';

}
return __p
};

this["Templates"]["OrgaCreationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <h1>' +
((__t = ( T("ORGA_CREATION") )) == null ? '' : __t) +
'</h1>\n</header>\n\n<div class="card-container centered-maxed-width-container">\n  <form class="orga-creation" action="#">\n    ' +
((__t = ( Templates["OrgaSP.informations-edit"]({
      orga: {},
      vatData: {},
      readonly: false,
      countries: countries
    }))) == null ? '' : __t) +
'\n    <button type="submit" class="btn btn-blue">' +
((__t = ( T("CREATE") )) == null ? '' : __t) +
'</button>\n  </form>\n</div>\n';

}
return __p
};

this["Templates"]["OrgaJoinSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <div class="header-context"><cc-input-text value="' +
__e( params.oid ) +
'" readonly clipboard></cc-input-text></div>\n</header>\n';

}
return __p
};

this["Templates"]["OrgaSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <div class="header-context"><cc-input-text value="' +
__e( params.oid ) +
'" readonly clipboard></cc-input-text></div>\n  <h1>' +
((__t = ( T("ORGANISATION") )) == null ? '' : __t) +
'</h1>\n</header>\n<div class="orga-sp-container centered-maxed-width-container"></div>\n';

}
return __p
};

this["Templates"]["OrgaSP.company-details"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(orga.isCompany){ ;
__p += '\n  <div class="form-field">\n    <label class="label-large" for="orga-company">' +
((__t = ( T("COMPANY_NAME") )) == null ? '' : __t) +
' *</label>\n    <input class="input-large" name="orga-company" id="orga-company" type="text" value="' +
__e( orga.company ) +
'" ' +
__e( readonly ? "disabled" : "" ) +
' required autocomplete="organization" />\n  </div>\n  <div class="form-field">\n    <label class="label-large" for="orga-vat">' +
((__t = ( T("VAT_NUMBER") )) == null ? '' : __t) +
'</label>\n    <input\n      class="input-large"\n      name="orga-vat"\n      id="orga-vat"\n      type="text"\n      value="' +
__e( orga.VAT ) +
'"\n      ' +
__e( readonly ? "disabled" : "" ) +
'\n      data-status=' +
__e( vatData.status ) +
'\n      ' +
__e( orga.vatState === "NOT_NEEDED" ? 'disabled' : '' ) +
'\n    />\n    <span class="input-helper">' +
__e( vatData.helper ) +
'</span>\n  </div>\n  <div class="form-field">\n    <input name="orga-no-vat" id="orga-no-vat" type="checkbox" ' +
__e( orga.vatState === "NOT_NEEDED" ? "checked" : "" ) +
'/>\n    <label name="orga-no-vat" for="orga-no-vat">' +
__e( T("console.orga.no-vat-number") ) +
'</label>\n  </div>\n';
};
__p += '\n\n<div class="form-field">\n  <label class="label-large" for="orga-billing-email">' +
__e( T("console.orgasp.billing_email") ) +
'</label>\n  <input class="input-large" name="orga-billing-email" id="orga-billing-email" type="email" value="' +
__e( orga.billingEmail ) +
'" ' +
__e( readonly ? "disabled" : "" ) +
' autocomplete="email" />\n</div>\n<div class="form-field">\n  <label class="label-large" for="orga-address">' +
((__t = ( T("ADDRESS") )) == null ? '' : __t) +
' *</label>\n  <input class="input-large" name="orga-address" id="orga-address" type="text" value="' +
__e( orga.address ) +
'" ' +
__e( readonly ? "disabled" : "" ) +
' autocomplete="street-address" />\n</div>\n<div class="form-field">\n  <label class="label-large" for="orga-city">' +
((__t = ( T("CITY") )) == null ? '' : __t) +
' *</label>\n  <input class="input-large" name="orga-city" id="orga-city" type="text" value="' +
__e( orga.city ) +
'" ' +
__e( readonly ? "disabled" : "" ) +
' />\n</div>\n<div class="form-field">\n  <label class="label-large" for="orga-zipcode">' +
((__t = ( T("ZIPCODE") )) == null ? '' : __t) +
' *</label>\n  <input class="input-large" name="orga-zipcode" id="orga-zipcode" type="text" value="' +
__e( orga.zipcode ) +
'" ' +
__e( readonly ? "disabled" : "" ) +
' autocomplete="postal-code" />\n</div>\n<div class="form-field">\n  <label class="label-large" for="orga-country">' +
((__t = ( T("COUNTRY") )) == null ? '' : __t) +
' *</label>\n  <label class="label-select">\n    <select name="orga-country" id="orga-country" class="countries select-large" ' +
__e( readonly ? "disabled" : "" ) +
'>\n      <option>?</option>\n      ';
 _.each(countries, function(country, code) { ;
__p += '\n      <option value="' +
__e( code ) +
'" ' +
__e( country == orga.country ? "selected" : "" ) +
'>' +
__e( country ) +
'</option>\n      ';
 }); ;
__p += '\n    </select>\n  </label>\n</div>\n';

}
return __p
};

this["Templates"]["OrgaSP.information"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(orga.vatState === "INVALID" || orga.vatState === "PENDING_VALIDATION"){ ;
__p += '\n  <div class="card-container alert"\n    data-status="' +
__e( orga.vatState === "INVALID" ? 'error' : 'warning' ) +
'"\n  >\n    ';
 if(orga.vatState === "INVALID"){ ;
__p += '\n      <p>' +
__e( T("console.orga.invalid-vat-number") ) +
'</p>\n    ';
} else if(orga.vatState === "PENDING_VALIDATION"){ ;
__p += '\n      <p>' +
__e( T("console.orga.vat-number-in-validation") ) +
'</p>\n    ';
};
__p += '\n  </div>\n';
};
__p += '\n<div class="card-container">\n  <form action="#" class="orga-edit">\n    ' +
((__t = ( Templates["OrgaSP.informations-edit"]({
      countries: countries,
      orga: orga,
      readonly: readonly,
      vatData: vatData
    }))) == null ? '' : __t) +
'\n    ';
 if(!readonly) { ;
__p += '\n    <button type="submit" class="btn btn-blue">' +
((__t = ( T("EDIT_ORGANISATION") )) == null ? '' : __t) +
'</button>\n    ';
 } ;
__p += '\n\n    ';
 if(Role.canDeleteOrga(orga.role) && !isPersonalOrga) { ;
__p += '\n    <hr />\n    <p> ' +
((__t = ( T("REMOVE_ORGANISATION_?") )) == null ? '' : __t) +
' </p>\n\n    <button type="button" class="remove btn btn-red">' +
((__t = ( T("REMOVE_ORGANISATION") )) == null ? '' : __t) +
'</button>\n    ';
 } ;
__p += '\n  </form>\n  <div class="avatar-container"></div>\n</div>\n';

}
return __p
};

this["Templates"]["OrgaSP.informations-edit"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<fieldset data-step="organisation_information">\n  <legend>' +
__e( T("ORGANISATION") ) +
'</legend>\n  <div class="form-field">\n    <label class="label-large" for="orga-name">' +
((__t = ( T("ORGANISATION_NAME") )) == null ? '' : __t) +
': *</label>\n    <input name="orga-name" type="text" class="input-large" id="orga-name" value="' +
__e( orga.name ) +
'" ' +
__e( readonly ? "readonly" : "" ) +
' />\n  </div>\n  <div class="form-field">\n    <label class="label-large" for="orga-description">' +
((__t = ( T("DESCRIPTION") )) == null ? '' : __t) +
': *</label>\n    <input name="orga-description" type="text" class="input-large" id="orga-description" value="' +
__e( orga.description ) +
'" ' +
__e( readonly ? "readonly" : "" ) +
' />\n  </div>\n\n  <div class="form-field">\n    <label class="label-large">' +
((__t = ( T("IS_THIS_ORGANISATION_A_COMPANY_?") )) == null ? '' : __t) +
'</label>\n    <label class="block" >\n      <input type="radio" name="orga-is-company" value="false" ' +
__e( orga.isCompany ? '' : 'checked' ) +
' ' +
__e( readonly ? "disabled" : "" ) +
' />\n      ' +
((__t = ( T("NO_IT_IS_NOT_A_COMPANY") )) == null ? '' : __t) +
'\n    </label>\n    <label class="block" >\n      <input type="radio" name="orga-is-company" value="true" ' +
__e( orga.isCompany ? 'checked' : '' ) +
' ' +
__e( readonly ? "disabled" : "" ) +
' />\n      ' +
((__t = ( T("YES_IT_IS_A_COMPANY") )) == null ? '' : __t) +
'\n    </label>\n  </div>\n</fieldset>\n<fieldset data-step="organisation_invoicing">\n  <legend>' +
__e( T("console.orga.orga-invoicing") ) +
'</legend>\n  <div class="form-field">\n    <label class="label-large" for="orga-customer-full-name">' +
__e( T("NAME") ) +
': *</label>\n    <input name="orga-customer-full-name" type="text" class="input-large" id="orga-customer-full-name" value="' +
__e( orga.customerFullName ) +
'" ' +
__e( readonly ? "readonly" : "" ) +
' />\n  </div>\n\n  <div class="company-details">\n    ' +
((__t = ( Templates["OrgaSP.company-details"]({countries: countries, orga: orga, readonly: readonly, vatData: vatData}) )) == null ? '' : __t) +
'\n  </div>\n</fieldset>\n';

}
return __p
};

this["Templates"]["OwnerNotificationsSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <div class="header-context"><cc-input-text value="' +
__e( params.oid ) +
'" readonly clipboard></cc-input-text></div>\n  <h1>Notifications</h1>\n</header>\n\n<div class="card-container centered-maxed-width-container">\n  <div class="pulse"></div>\n</div>\n';

}
return __p
};

this["Templates"]["PaymentSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="headbar">\n  <div class="header-context"><cc-input-text value="' +
__e( params.oid ) +
'" readonly clipboard></cc-input-text></div>\n  <h1>' +
__e( T("console.panes.payment") ) +
'</h1>\n</header>\n\n<cc-header-orga class="cc-header-orga centered-maxed-width-container"></cc-header-orga>\n\n<div class="payment-layouts centered-maxed-width-container"></div>\n\n<div class="invoice-container payment centered-maxed-width-container"></div>\n';

}
return __p
};

this["Templates"]["PaymentSP.monthly-invoice-layout"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="credit-cards-container">\n  ' +
((__t = ( Templates["PaymentSP.owner-credit-cards"]({ canSepa }) )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
};

this["Templates"]["PaymentSP.owner-credit-cards"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="payment-method-warning"></div>\n\n<cc-block class="owner-payment-container">\n  <div slot="header-title">' +
__e( T("console.user-credit-cards.payment-methods") ) +
'</div>\n  <div slot="content" class="bloc payment-methods">\n    <div class="bloc-popin">\n      <p class="bloc-description">\n        ' +
((__t = ( T("console.user-credit-cards.payment-methods.desc") )) == null ? '' : __t) +
'\n      </p>\n    </div>\n    <div class="bloc-content credit-cards">\n      <div class="pulse"></div>\n      <div class="credit-card-list-container">\n        <div class="orga-credit-cards">\n          <ul class="credit-card-list"></ul>\n        </div>\n      </div>\n      ' +
((__t = ( Templates["cards-new"]() )) == null ? '' : __t) +
'\n      ';
 if (canSepa) { ;
__p += '\n        ' +
((__t = ( Templates["sepa-new"]() )) == null ? '' : __t) +
'\n      ';
 } ;
__p += '\n    </div>\n    <div class="supported-payment-methods">\n      <p class="secure-payment">\n        <img class="secure-payment-icon" alt="" src="/img/payment-methods/secure.svg" width="25" height="25"> <span>' +
((__t = ( T('console.user-credit-cards.secure-payment') )) == null ? '' : __t) +
'</span>\n      </p>\n\n      <a class="stripe-credits" href="https://stripe.com/" title="' +
((__t = ( T('console.user-credit-cards.link.stripe') )) == null ? '' : __t) +
'" target="_blank">\n        <img alt="' +
((__t = ( T('console.user-credit-cards.link.stripe') )) == null ? '' : __t) +
'" src="/img/payment-methods/stripe.svg" width="105" height="25">\n      </a>\n\n      <ul class="supported-payment-methods-list">\n        <li><img lang="fr" alt="Cartes Bancaires" src="/img/payment-methods/cb.svg"></li>\n        <li><img alt="Mastercard" src="/img/payment-methods/mastercard.svg"></li>\n        <li><img alt="Visa" src="/img/payment-methods/visa.svg"></li>\n        <li><img alt="American Express" src="/img/payment-methods/amex.svg"></li>\n      </ul>\n    </div>\n  </div>\n</cc-block>\n';

}
return __p
};

this["Templates"]["PaypalCanceledSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="bloc">\n    <div class="bloc-popin">\n      <div class="alert" data-status="info"></div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["PaypalSuccessSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="bloc">\n    <div class="bloc-popin">\n      <div class="alert" data-status="info">\n        <p>We are finishing your payment..</p>\n      </div>\n      <div class="bloc-content logs">\n        <div class="logs-loader">\n          <span class="logs-loader-block"></span>\n          <span class="logs-loader-block"></span>\n          <span class="logs-loader-block"></span>\n          <span class="logs-loader-block"></span>\n          <span class="logs-loader-block"></span>\n          <span class="logs-loader-block"></span>\n          <span class="logs-loader-block"></span>\n          <span class="logs-loader-block"></span>\n          <span class="logs-loader-block"></span>\n        </div>\n      </div>\n      <div class="hidden alert" data-status="error"></div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["UserInformationSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="is-header-menu">\n  <ul>\n    <li class="active">\n      <a href="/users/me/information">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/emails">' +
((__t = ( T("EMAILS") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/authentication">' +
((__t = ( T("console.user-security.title") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/ssh-keys">' +
((__t = ( T("SSH_KEYS") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/tokens">' +
((__t = ( T("console.user-oauth-tokens") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/deletion">' +
((__t = ( T("console.user-account-deletion.heading") )) == null ? '' : __t) +
'</a>\n    </li>\n  </ul>\n</header>\n\n<div class="alert centered-maxed-width-container" data-status="info">\n  <p>' +
((__t = ( T("LCEN_NOTE") )) == null ? '' : __t) +
'</p>\n</div>\n\n<cc-block class="centered-maxed-width-container">\n  <div slot="header-title">' +
((__t = ( T("PROFILE") )) == null ? '' : __t) +
'</div>\n  <form slot="content-body" action="#" class="information">' +
((__t = ( T("LOADING") )) == null ? '' : __t) +
'</form>\n</cc-block>\n\n<cc-block class="centered-maxed-width-container" style="margin-top: 1em;">\n  <div slot="header-title">' +
((__t = ( T("USER_MANAGE_AVATAR") )) == null ? '' : __t) +
'</div>\n  <div slot="content-body" class="avatar-container user-avatar"></div>\n</cc-block>\n';

}
return __p
};

this["Templates"]["UserSSHKeysSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="is-header-menu">\n  <ul>\n    <li>\n      <a href="/users/me/information">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/emails">' +
((__t = ( T("EMAILS") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/authentication">' +
((__t = ( T("console.user-security.title") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li class="active">\n      <a href="/users/me/ssh-keys">' +
((__t = ( T("SSH_KEYS") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/tokens">' +
((__t = ( T("console.user-oauth-tokens") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/deletion">' +
((__t = ( T("console.user-account-deletion.heading") )) == null ? '' : __t) +
'</a>\n    </li>\n  </ul>\n</header>\n\n<div class="documentation ssh-keys-doc centered-maxed-width-container"></div>\n\n<div class="bloc bloc-ssh-keys centered-maxed-width-container">\n  <div class="bloc-popin">\n    <h2>' +
((__t = ( T("SSH_KEYS") )) == null ? '' : __t) +
'</h2>\n  </div>\n  <div class="bloc-content">\n    <ul class="ssh-keys">\n      <li>' +
((__t = ( T("LOADING") )) == null ? '' : __t) +
'</li>\n    </ul>\n  </div>\n</div>\n\n<div class="bloc bloc-ssh-keys github-bloc centered-maxed-width-container" style="display:none">\n  <div class="bloc-popin">\n    <h2>' +
((__t = ( T("GITHUB_SSH_KEYS") )) == null ? '' : __t) +
'</h2>\n  </div>\n  <div class="bloc-content">\n    <ul class="github-ssh-keys"></ul>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["UserSecuritySP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="is-header-menu">\n  <ul>\n    <li>\n      <a href="/users/me/information">' +
((__t = ( T("INFORMATION") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/emails">' +
((__t = ( T("EMAILS") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li class="active">\n      <a href="/users/me/authentication">' +
((__t = ( T("console.user-security.title") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/ssh-keys">' +
((__t = ( T("SSH_KEYS") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/tokens">' +
((__t = ( T("console.user-oauth-tokens") )) == null ? '' : __t) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/deletion">' +
((__t = ( T("console.user-account-deletion.heading") )) == null ? '' : __t) +
'</a>\n    </li>\n  </ul>\n</header>\n\n<div class="user-security-container">\n  <cc-block class="bloc card-container change-password">\n    <div slot="content-header" class="bloc-popin">\n      <h2>' +
__e( T("CHANGE_PASSWORD") ) +
'</h2>\n    </div>\n    <div slot="content-body" class="bloc-content">\n      <form action="#" class="password">\n        <fieldset>\n          <div class="form-field">\n            <label class="label-large" for="user-old-password">' +
((__t = ( T("OLD_PASSWORD") )) == null ? '' : __t) +
':</label>\n            <input name="oldPassword" required id="user-old-password" type="password" class="input-large" autocomplete="current-password" />\n          </div>\n          <div class="form-field">\n            <label class="label-large" for="user-new-password">' +
((__t = ( T("NEW_PASSWORD") )) == null ? '' : __t) +
':</label>\n            <input name="newPassword" required id="user-new-password" type="password" class="input-large" autocomplete="new-password" />\n          </div>\n          <div class="form-field">\n            <label class="label-large" for="user-confirmed-password">' +
((__t = ( T("CONFIRM_PASSWORD") )) == null ? '' : __t) +
':</label>\n            <input name="confirmedPassword" required id="user-confirmed-password" type="password" class="input-large" autocomplete="new-password"/>\n          </div>\n          <div class="form-field">\n            <label>\n              <input name="revokeTokens" type="checkbox" checked/>' +
__e( T("console.user-security.revoke-tokens") ) +
'</label>\n          </div>\n        </fieldset>\n\n        <button type="submit" class="btn btn-blue">' +
((__t = ( T("CHANGE_PASSWORD") )) == null ? '' : __t) +
'</button>\n      </form>\n    </div>\n  </cc-block>\n\n  <cc-block class="bloc card-container mfa-overview-container">\n    <div slot="content-header" class="bloc-popin">\n      <h2>' +
__e( T("console.user-security.mfa") ) +
'</h2>\n    </div>\n    <div slot="content-body" class="bloc-content mfa-overview">\n      <div class="pulse"></div>\n    </div>\n  </cc-block>\n\n  <div class="bloc card-container register-mfa">\n    <div class="bloc-popin">\n      <h2>' +
__e( T("console.user-security.enable-mfa") ) +
'</h2>\n    </div>\n    <div class="bloc-content">\n      <p>' +
__e( T("console.user-security.download")) +
' <a href="https://support.google.com/accounts/answer/1066447" rel="noopener, noreferrer">Google Authenticator</a> (<a href="https://freeotp.github.io/" rel="noopener, noreferrer">' +
__e( T("console.user-security.alternative") ) +
'</a>) ' +
__e( T("console.user-security.scan-this-code") ) +
'\n      <div class="qrcode-details">\n        <div class="qrcode" data-hj-masked></div>\n        <div class="no-qrcode alert" data-status="info"></div>\n      </div>\n\n      <form action="#" class="mfa-confirmation">\n        <fieldset>\n          <div class="form-field">\n            <label class="label-input label-large" for="pin-code">' +
__e( T("console.user-security.enter-pin-code") ) +
'</label>\n            <input type="number" placeholder="PIN code" class="input-large pin-code" max="999999" patern="[0-9]{6}"/>\n            <div class="pin-code-error">' +
__e( T("console.user-security.wrong-pin") ) +
'</div>\n          </div>\n\n          <div class="form-field">\n            <label>\n              <input name="revokeTokens" type="checkbox" checked/>' +
__e( T("console.user-security.revoke-tokens") ) +
'</label>\n          </div>\n        </fieldset>\n\n        <button type="submit" class="btn btn-blue">' +
__e( T("console.user-security.enable-mfa-submit") ) +
'</button>\n      </form>\n    </div>\n  </div>\n</div>\n';

}
return __p
};

this["Templates"]["UserSecuritySP.mfa-overview"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(!mfaEnabled){ ;
__p += '\n  <div class="mfa-disabled">\n    <p>' +
__e( T("console.user-security.mfa-status") ) +
': ' +
__e( T("console.user-security.status-disabled") ) +
'</p>\n    <button class="btn btn-primary btn-blue enable-mfa">' +
__e( T("console.user-security.enable-mfa-short") ) +
'</button>\n  </div>\n';
} else { ;
__p += '\n  <div class="mfa-enabled">\n    <div class="manage-mfa">\n      <p>' +
__e( T("console.user-security.mfa-status") ) +
': ' +
__e( T("ENABLED") ) +
'<p>\n      <p class="alert" data-status="info">' +
__e( T("console.user-security.save-codes") ) +
'</p>\n      <button class="btn btn-primary btn-blue backup-codes">' +
__e( T("console.user-security.display-backup-codes") ) +
'</button>\n      <button class="btn btn-primary btn-red disable-mfa">' +
__e( T("console.user-security.disable-mfa-short") ) +
'</button>\n    </div>\n  </div>\n';
};
__p += '\n';

}
return __p
};

this["Templates"]["UserSecuritySP.no-qrcode"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<p>\n  ' +
__e( T("console.user-security.cant-scan") ) +
'<br />\n  ' +
__e( T("console.user-security.mfa-details") ) +
' :<br />\n  ' +
__e( T("console.user-security.account") ) +
': ' +
__e( email ) +
'<br />\n  ' +
__e( T("console.user-security.key") ) +
': ' +
__e( secret ) +
'<br />\n  ' +
__e( T("console.user-security.time-based") ) +
': ' +
__e( timeBased ? T("console.user-security.yes") : T("console.user-security.no") ) +
'\n</p>\n';

}
return __p
};

this["Templates"]["UserTokensSP"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<header class="is-header-menu">\n  <ul>\n    <li>\n      <a href="/users/me/information">' +
__e( T("INFORMATION") ) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/emails">' +
__e( T("EMAILS") ) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/authentication">' +
__e( T("console.user-security.title") ) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/ssh-keys">' +
__e( T("SSH_KEYS") ) +
'</a>\n    </li>\n    <li class="active">\n      <a href="/users/me/tokens">' +
__e( T("console.user-oauth-tokens") ) +
'</a>\n    </li>\n    <li>\n      <a href="/users/me/deletion">' +
__e( T("console.user-account-deletion.heading") ) +
'</a>\n    </li>\n  </ul>\n</header>\n\n<div class="alert cli-tokens centered-maxed-width-container" data-status></div>\n\n<div class="bloc user-tokens centered-maxed-width-container">\n  <cc-block>\n    <div slot="header-title">\n      ' +
__e( T("console.user-oauth-tokens") ) +
'\n    </div>\n    <button slot="header-right" class="btn btn-right btn-red remove-all">' +
__e( T("REVOKE_ALL_ACCESS_TOKENS") ) +
'</button></h2>\n    <div slot="content-body" class="bloc-content">\n      <table>\n        <thead>\n          <th>' +
__e( T("CREATION_DATE") ) +
' (UTC)</th>\n          <th>' +
__e( T("LAST_USAGE") ) +
' (UTC)</th>\n          <th>' +
__e( T("EXPIRATION_DATE") ) +
' (UTC)</th>\n          <th>' +
__e( T("SOURCE") ) +
'</th>\n          <th></th>\n        </thead>\n        <tbody class="tokens">\n          <tr><td colspan="5">' +
__e( T("LOADING") ) +
'</td></tr>\n        </tbody>\n      </table>\n    </div>\n  </cc-block>\n</div>\n';

}
return __p
};

this["Templates"]["UserTokensSP.tokens"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(tokens, function(token) { ;
__p += '\n';
 var sameToken = token.token === currentToken; ;
__p += '\n<tr data-token="' +
__e( token.token ) +
'">\n  <td>' +
__e( token.creationDate ) +
'</td>\n  <td>' +
__e( token.lastUtilisation ) +
'</td>\n  <td>' +
__e( token.expirationDate ) +
'</td>\n  <td><a href="' +
__e( token.consumer.url ) +
'" target="_blank">' +
__e( token.source ) +
'</a></td>\n  <td><button type="button" class="btn btn-right remove" ' +
__e( sameToken ? "disabled" : "" ) +
'>' +
__e( sameToken ? T("console.user-tokens.token-in-use") : T("REVOKE") ) +
'</button></td>\n</tr>\n';
 }); ;
__p += '\n';

}
return __p
};

this["Templates"]["container"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div data-statepoint="' +
__e( name ) +
'" data-timestamp="' +
__e( timestamp ) +
'"></div>\n';

}
return __p
};

this["Templates"]["credits.package"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(typeof coupon == "undefined" || ["FREE_DROP_AMOUNT","INTERNAL_FREE"].indexOf(coupon.type) < 0) { ;
__p += '\n<tr>\n  <td class="title">' +
((__t = ( T(title) )) == null ? '' : __t) +
'</td>\n  <td class="desc">' +
__e( T(title + "_DESCRIPTION") ) +
'</td>\n  <td>\n    <button class="btn btn-primary btn-right" data-package-id="' +
__e( id ) +
'">' +
__e( NumberFormat.format(price, 2) ) +
'€ ' +
((__t = ( T("console.credits.ex-tax") )) == null ? '' : __t) +
'</button>\n  </td>\n</tr>\n';
 } else { ;
__p += '\n<tr>\n  <td class="title">' +
((__t = ( T("FREE_CREDITS") )) == null ? '' : __t) +
'</td>\n  <td class="desc">' +
__e( coupon.name ) +
'</td>\n  <td>\n    <button class="btn btn-primary btn-right" data-package-id="' +
__e( id ) +
'">' +
__e( NumberFormat.format(dropsQuantity*dropPrice.value, 2) ) +
'€</button>\n  </td>\n</tr>\n';
 } ;
__p += '\n\n';
 if(typeof coupon != "undefined") { ;
__p += '\n\n';
 if(coupon.type == "DISCOUNT_PRICE_PERCENT") { ;
__p += '\n<div class="list-rounded-comment"><div class="originalPrice">' +
__e( NumberFormat.format(dropsQuantity*dropPrice.value, 2) ) +
'€</div> -' +
__e( Math.round(coupon.value*100) ) +
'%</div>\n\n';
 } else if(coupon.type == "DISCOUNT_PRICE_AMOUNT") { ;
__p += '\n<div class="list-rounded-comment"><div class="originalPrice">' +
__e( NumberFormat.format(dropsQuantity*dropPrice.value, 2) ) +
'€</div> -' +
__e( coupon.value ) +
'€</div>\n\n';
 } else if(coupon.type == "DISCOUNT_DROP_PERCENT") { ;
__p += '\n<div class="list-rounded-comment">+' +
__e( NumberFormat.format(coupon.value*dropsQuantity*dropPrice.value, 2) ) +
'€ ' +
((__t = ( T("FOR_FREE") )) == null ? '' : __t) +
' (' +
__e( Math.round(coupon.value*100) ) +
'%)</div>\n';
 } ;
__p += '\n\n';
 } ;
__p += '\n\n\n';

}
return __p
};

this["Templates"]["loader-bars"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="loader-bars">\n  <div class="loader-bars__bar"></div>\n  <div class="loader-bars__bar"></div>\n  <div class="loader-bars__bar"></div>\n  <div class="loader-bars__bar"></div>\n  <div class="loader-bars__bar"></div>\n  <div class="loader-bars__ball"></div>\n</div>\n';

}
return __p
};

this["Templates"]["main-loader"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<img class="main-loader-icon progress-anim" src="' +
__e( logoUrl ) +
'" alt="">\n<progress max="100"></progress>\n<div class="main-loader-message"></div>\n';

}
return __p
};

this["Templates"]["unavailable-email"] = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="alert big-alert email-validation" data-status="warning">\n  <h2>' +
__e( T("console.non-validated-email-title") ) +
'</h2>\n  <p>' +
((__t = ( T("console.non-validated-email-body", {email: email}) )) == null ? '' : __t) +
'<br /><br /></p>\n  <button type="button" class="btn btn-primary resend">' +
__e( T("console.non-validated-email-button") ) +
'</button>\n</div>\n';

}
return __p
};

          return this["Templates"];

        })();