module.exports = (function () {
  var Bacon = require('baconjs');
  var _ = require('lodash');
  var $ = require('jquery');
  const moment = require('moment');

  var $Cards = require('../cards/view.es6.js');
  const LoadLibrary = require('../load-external-libraries/main.es6.js');
  const T = require('../../models/technical/translation.js');
  const { initPaypalPayment } = require('@clevercloud/client/esm/api/v4/billing.js');
  const { sendToApi } = require('../../send-to-api.js');
  const { initStripePayment, authorizeStripePayment } = require('@clevercloud/client/esm/api/v4/billing.js');
  const { formatCurrency } = require('@clevercloud/components/dist/lib/i18n/i18n-number.js');
  const { getLanguage } = require('@clevercloud/components/dist/i18n.js');
  require('@clevercloud/components/dist/cc-button.js');
  require('@clevercloud/components/dist/cc-invoice.smart.js');

  var $Payment = function (settings) {
    var state = {
      container: $(settings.container),
      invoice: settings.invoice,
      providers: settings.providers,
      methods: settings.methods,
      defaultMethod: settings.defaultMethod || null,
      orgaId: settings.orgaId,
      data: {},
    };

    const s_payment = $Payment.init(state);

    s_payment.onError(function (error) {
      console.error(error);
      state.container
        .find('.payment-error')
        .show()
        .text(error.message || error);
    });
    s_payment.delay(1000).onValue(function () {
      $Notification.displaySuccess({ message: T('PAYMENT_SUCCESS') });
      state.container.find('.payment-error').hide();
    });

    return s_payment;
  };

  $Payment.init = function (state) {
    // This stream will emit once the user clicks on a payment provider
    var s_invoice = $Payment.displayProviders(state);

    return s_invoice.flatMapLatest(function (state) {
      switch (state.data.provider.name) {
        case 'PAYPAL':
          return $Payment.payWithPaypal(state);
        case 'CREDITCARD': {
          const methods = state.methods.filter((method) => method.type === 'CREDITCARD');
          return $Payment.displayCards(_.extend({}, state, { methods }), 'CREDITCARD');
        }
        case 'SEPA_DEBIT': {
          const methods = state.methods.filter((method) => method.type === 'SEPA_DEBIT');
          return $Payment.displayCards(_.extend({}, state, { methods }), 'SEPA_DEBIT');
        }
        default:
          return new Bacon.Error({
            message: T('UNSUPPORTED_METHOD'),
          });
      }
    });
  };

  $Payment.displayProviders = function (state) {
    var providersByName = _.groupBy(state.providers, 'name');

    state.container.html(Templates['providers'](state));

    return state.container
      .find('cc-button.choose')
      .asEventStream('cc-button:click')
      .doAction('.preventDefault')
      .map((event) => {
        event.target.waiting = true;
        Array.from(event.target.parentElement.querySelectorAll('cc-button')).forEach((btn) => (btn.disabled = true));
        const name = event.target.getAttribute('data-provider');
        return {
          provider: providersByName[name][0],
          event,
        };
      })
      .first()
      .flatMapLatest(function (obj) {
        return {
          ...state,
          data: {
            ...state.data,
            provider: obj.provider,
          },
        };
      });
  };

  $Payment.payWithPaypal = function (state) {
    initPaypalPayment({ id: state.orgaId, invoiceNumber: state.invoice.number })
      .then(sendToApi)
      .then(({ url }) => {
        window.location = url;
      });
    return Bacon.never();
  };

  $Payment.displayCards = function (state, cardType) {
    var selectedCard = _.find(state.methods, function (c) {
      return c.isDefault;
    });

    var $cards = $Cards({
      container: state.container,
      cards: state.methods,
      selectedCardId: selectedCard && selectedCard.token,
      ownerId: state.orgaId || 'user_',
      cardType,
    });

    const s_checkout = $cards.s_card.flatMapLatest(() => {
      const $checkoutContainer = state.container.find('.card-container');
      $checkoutContainer.find('.payment-checkout-button').remove();
      $checkoutContainer.append(
        Templates['payment.checkout-button']({
          price: formatCurrency(getLanguage(), state.invoice.total.amount),
        }),
      );
      var $checkout = state.container.find('button.checkout');
      return $checkout.asEventStream('click').doAction('.preventDefault');
    });

    return $cards.s_card.sampledBy(s_checkout).flatMapLatest(function (card) {
      const params = { id: state.orgaId, invoiceNumber: state.invoice.number };
      var s_pay = Bacon.fromPromise(initStripePayment(params, card).then(sendToApi)).flatMapError((paymentError) => {
        console.log({ paymentError });
        if (paymentError.response.status === 402) {
          return $Payment
            .handleCardPaymentError(paymentError.responseBody.paymentIntent, card, cardType)
            .flatMapLatest((paymentIntent) => {
              const params = {
                id: state.orgaId,
                invoiceNumber: state.invoice.number,
                paymentId: paymentIntent.id,
              };
              return Bacon.fromPromise(authorizeStripePayment(params).then(sendToApi));
            });
        } else {
          return Bacon.once(new Bacon.Error(paymentError));
        }
      });

      state.container.find('button.checkout').loadStream(s_pay);
      return s_pay;
    });
  };

  $Payment.handleCardPaymentError = (paymentIntentError, card, cardType) => {
    return LoadLibrary('stripe-client').flatMapLatest((stripe) => {
      return Bacon.fromBinder((sink) => {
        let payment;

        if (cardType === 'CREDITCARD') {
          payment = stripe.handleCardPayment(paymentIntentError.clientSecret, {
            payment_method: card.token,
          });
        } else if (cardType === 'SEPA_DEBIT') {
          payment = stripe.confirmSepaDebitPayment(paymentIntentError.clientSecret, {
            payment_method: card.token,
          });
        } else {
          throw new Error(`Unknown cardType ${cardType}`);
        }

        payment.then((result) => {
          sink(result.error ? new Bacon.Error(result.error) : result.paymentIntent);
          sink(new Bacon.End());
        });
      });
    });
  };

  $Payment.getNextMonthDate = function () {
    const nextMonthDate = moment().add(1, 'month').toDate();
    const formatOptions = {
      month: 'long',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat(T.getCurrentLanguage(), formatOptions).format(nextMonthDate);
  };

  return $Payment;
})();
