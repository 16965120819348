module.exports = (function () {
  var $Github = function () {
    return $Github;
  };

  $Github.fetchApps = function () {
    return API.github.applications.get().send();
  };

  $Github.link = function () {
    var s_link = $Github.getTransactionId();
    s_link.onValue(function (data) {
      window.location.href =
        Console.configuration.API_HOST +
        '/v2/github/link?transactionId=' +
        encodeURIComponent(data.transactionId) +
        '&redirectUrl=' +
        encodeURIComponent(window.location.href);
    });
    return s_link;
  };

  $Github.unlink = function ($button) {
    var s_remove = API.github.link
      .delete()
      .send()
      .flatMapLatest(function () {
        return SummaryProxy.updateOrganisation();
      });
    $button.loadStream(s_remove);
    return s_remove;
  };

  $Github.getTransactionId = function () {
    return API.github.get().send();
  };

  return $Github;
})();
