var $ = require('jquery');
var _ = require('lodash');
var Bacon = require('baconjs');
const { initCleverClient } = require('@clevercloud/client/esm/legacy-client.browser.js');
const { updateRootContext } = require('@clevercloud/components/dist/smart-manager.js');
const { getAccessToken } = require('../../login-oauth.js');
const { getCrispTicketCenterhash } = require('../../send-to-api.js');
const { onApiError } = require('../../lib/api-error-handlers.js');

var QueryString = require('../../helpers/querystring.js');
var $Ping = require('../ping/main.js');
var initializeSummaryProxy = require('../../models/business/proxys/summary.js');
var randomSentences = require('../../helpers/randomSentences.js');
const { HOMEPAGE_LOGO } = require('../../configuration.js');

var $Loader = (module.exports = function (settings) {
  var state = {
    container: settings.container,
    configuration: settings,
    Templates: settings.Templates,
  };

  return $Loader.init(state);
});

$Loader.init = function (state) {
  var streams = {};

  // Listens to 401 errors that have been triggered from an API call within a web component and handles it.
  window.addEventListener('cc-api:error', ({ detail: error }) => onApiError(error));

  state.dom = streams.dom = $Loader.loadDOM(state).toProperty();

  var s_maintenance = $Ping
    .getMaintenanceState()
    .mapError(function () {
      return false;
    })
    .skipDuplicates();
  state.consoleHash = streams.consoleHash = $Ping
    .getConsoleHash()
    .mapError(function () {
      return null;
    })
    .toProperty();

  var s_tokensWithError = s_maintenance
    .filter(function (maintenance) {
      return !maintenance;
    })
    // Build a valid Authorization header
    .flatMapLatest(() => $Loader.loadTokens());

  s_tokensWithError.onError(() => LoginAs.login());

  var s_tokens = s_tokensWithError.skipErrors();

  state.api = streams.api = s_tokens.flatMapLatest(_.partial($Loader.loadAPIClient, state)).take(1).toProperty();

  state.summaryProxy = streams.summaryProxy = state.api.map(_.partial($Loader.loadSummaryProxy, state)).toProperty();
  state.summary = state.summaryProxy.flatMapLatest((SummaryProxy) => SummaryProxy.fetch()).toProperty();

  // If we don't have any tokens but the summary is handled by the cache
  $Loader.handleNoTokens(state.summary, s_tokens, state);

  state.user = streams.user = state.summary.map('.user').toProperty();
  state.orgas = streams.orgas = state.summary.map('.organisations').toProperty();
  // We don't need api but we need to wait for it
  state.crispTicketCenterHash = streams.crispTicketCenterHash = streams.api
    .flatMapLatest(() => {
      return Bacon.fromPromise(getCrispTicketCenterhash());
    })
    .toProperty();

  state.providers = streams.providers = streams.api
    .flatMapLatest((api) => api.products.addonproviders.get().send())
    .toProperty();

  state.dom.onValue(_.partial($Loader.displayProgressBar, state, streams));

  var s_apiError = state.summary
    .flatMapLatest(function () {
      return false;
    })
    .mapError(function (error) {
      return error.message && error.message.toLowerCase() === 'internal server error';
    })
    .bufferWithCount(2)
    .map(function (states) {
      return _.every(states, function (s) {
        return s === true;
      });
    })
    .skipDuplicates();

  state.maintenance = streams.maintenance = Bacon.mergeAll(s_maintenance, s_apiError).toProperty();
  state.all = Bacon.combineTemplate(streams).toProperty();

  return state;
};

$Loader.loadDOM = function (state) {
  return Bacon.fromCallback($);
};

$Loader.loadTokens = function () {
  var searchParams = _.extend({}, QueryString.parse(window.location.search, true), {
    consumer_oauth_token_secret: localStorage.consumer_oauth_token_secret,
  });

  // Login As a user
  if (LoginAs.isLogged()) {
    history.pushState(null, null, '?' + QueryString.removeOAuthParameters(window.location.search));

    return Bacon.constant(LoginAs.getTokens());
    // Not logged in but we have the oauth_token to request the access_tokens
  } else if (
    searchParams &&
    searchParams.oauth_token &&
    searchParams.oauth_token == localStorage.consumer_oauth_token
  ) {
    history.pushState(null, null, '?' + QueryString.removeOAuthParameters(window.location.search));

    return Bacon.fromPromise(getAccessToken(searchParams))
      .delay(0) // Ugly hack to wait for the onValue listener of clever-client which set localStorage items
      .map(function (tokens) {
        return LoginAs.setTokens({
          user_oauth_token: tokens.oauth_token,
          user_oauth_token_secret: tokens.oauth_token_secret,
        });
      });
    // Not Logged
  } else if (searchParams && searchParams.oauth_token && searchParams.oauth_token_secret) {
    history.pushState(null, null, '?' + QueryString.removeOAuthParameters(window.location.search));
    return LoginAs.setTokens({
      user_oauth_token: searchParams.oauth_token,
      user_oauth_token_secret: searchParams.oauth_token_secret,
    });
  } else {
    return Bacon.Error(new Error('E_NOT_LOGGED_IN'));
  }
};

$Loader.loadAPIClient = function (state, tokens) {
  const cleverClient = initCleverClient(
    _.defaults(
      {
        OAUTH_CONSUMER_KEY: state.configuration.API_CONSUMER_KEY,
        OAUTH_CONSUMER_SECRET: state.configuration.API_CONSUMER_SECRET,
        API_OAUTH_TOKEN: tokens.user_oauth_token,
        API_OAUTH_TOKEN_SECRET: tokens.user_oauth_token_secret,
      },
      state.configuration,
    ),
    onApiError,
  );

  // TODO: maybe move this somewhere else
  updateRootContext({
    apiConfig: {
      API_HOST: state.configuration.API_HOST,
      WARP_10_HOST: state.configuration.WARP_10_HOST,
      OAUTH_CONSUMER_KEY: state.configuration.API_CONSUMER_KEY,
      OAUTH_CONSUMER_SECRET: state.configuration.API_CONSUMER_SECRET,
      API_OAUTH_TOKEN: tokens.user_oauth_token,
      API_OAUTH_TOKEN_SECRET: tokens.user_oauth_token_secret,
    },
  });

  return Bacon.once(cleverClient);
};

$Loader.loadSummaryProxy = function (state, API) {
  return initializeSummaryProxy(
    _.defaults(
      {
        API: API,
      },
      state.configuration,
    ),
  );
};

$Loader.fetchAllApps = function (SummaryProxy) {
  return SummaryProxy.fetch().toProperty();
};

$Loader.displayProgressBar = function (state, streams) {
  var $container = $(state.container);

  $container.html(
    state.Templates['main-loader']({
      logoUrl: HOMEPAGE_LOGO,
    }),
  );
  var $progressBar = $container.find('progress').delay(200).addClass('progress-anim');
  var $bootLogo = $container.find('img').addClass('progress-anim');
  var $sentences = randomSentences($container.find('.main-loader-message'), 'start');

  streams = _.map(streams, function (stream, name) {
    return stream.take(1).map(name).toProperty();
  });

  var mergedStreams = Bacon.mergeAll(streams);

  var step = Math.round((100 * 1) / _.size(streams));
  var s_percentage = mergedStreams.bufferWithTime(100).scan(0, function (total, items) {
    return total + step * _.size(items);
  });

  s_percentage.assign($progressBar, 'val');

  mergedStreams.onEnd(function () {
    randomSentences($sentences, 'stop');
  });
};

$Loader.handleNoTokens = function (s_summary, s_tokens) {
  Bacon.combineTemplate({
    summary: s_summary.first(),
    tokens: s_tokens,
  })
    .flatMapLatest((obj) => {
      const tokens = obj.tokens;
      if (tokens.user_oauth_token && tokens.user_oauth_token_secret) {
        return Bacon.never();
      }
    })
    .onValue($Loader.redirectToHome);
};

$Loader.redirectToHome = function () {
  window.location.href = '/';
};
