import '@clevercloud/components/dist/cc-env-var-form.js';
import '@clevercloud/components/dist/cc-env-var-linked-services.js';
import * as dom from './dom.js';
import {
  getAllEnvVars,
  getAllEnvVarsForDependencies,
  getAllLinkedAddons,
  redeploy,
  updateAllEnvVars,
} from '@clevercloud/client/esm/api/v2/application.js';
import { getAllEnvVars as getAllAddonEnvVars } from '@clevercloud/client/esm/api/v2/addon.js';
import { getApp, getEnvVarValidationMode } from '../lib/variables.js';
import { sendToApi } from '../send-to-api.js';
import { toNameValueObject } from '@clevercloud/client/esm/utils/env-vars.js';
import { notifyError, notifySuccess } from '@clevercloud/components/dist/lib/notifications.js';
import { i18n } from '@clevercloud/components/dist/i18n.js';
import { getEnvVarsWithEolVersions, getEolNotice } from '../helpers/eol-versions.js';
import { setNavigationGuard } from '../etc/router-helper.js';

export async function load(req, $main, { restartAppButton = true }) {
  const { oid, appId } = req.params;

  // Fetch appName so we can use it in text/help
  // If error is thrown, an error notification will be displayed and this will throw (and eagerly stop this function)
  const app = await getApp(oid, appId, $main);
  const appName = app.name;
  const validationMode = getEnvVarValidationMode(app);

  // Init child components and add them to the main view
  const $appEnvVar = dom.createElement('cc-env-var-form', { appName, context: 'env-var' });
  $main.classList.add('lazy-statepoint--grid');
  $main.classList.add('centered-maxed-width-container');
  dom.appendChildren($main, [$appEnvVar]);

  setNavigationGuard(() => {
    if ($appEnvVar.hasUnsavedModifications) {
      return {
        title: T('console.modals.navigation-guard.env-var.heading'),
        body: T('console.modals.navigation-guard.env-var.body'),
      };
    }
  });

  // Fetch stuffs and update components
  getAllEnvVars({ id: oid, appId })
    .then(sendToApi)
    .then((variables) => {
      $appEnvVar.state = { type: 'loaded', validationMode, variables };
      handleEolVariables(variables, $appEnvVar);
    })
    .catch(() => {
      $appEnvVar.state = { type: 'error' };
    });

  // If this is loaded for the env-var tab (and not through app creation)
  if (restartAppButton) {
    // Init child components and add them to the main view
    const $addonsEnvVar = dom.createElement('cc-env-var-linked-services', {
      type: 'addon',
      appName,
      state: { type: 'loading' },
    });
    const $linkedAppsEnvVar = dom.createElement('cc-env-var-linked-services', {
      type: 'app',
      appName,
      state: { type: 'loading' },
    });
    dom.appendChildren($main, [$addonsEnvVar, $linkedAppsEnvVar]);

    // Fetch stuffs and update components
    const loadLinkedAddons = () => getAllLinkedAddons({ id: oid, appId }).then(sendToApi);
    const loadAddonEnvVars = ({ id: addonId }) => getAllAddonEnvVars({ id: oid, addonId }).then(sendToApi);
    loadLinkedServices($addonsEnvVar, loadLinkedAddons, loadAddonEnvVars);

    const loadLinkedApps = () =>
      getAllEnvVarsForDependencies({ id: oid, appId })
        .then(sendToApi)
        .then((linkedApps) => linkedApps.map((app) => ({ ...app, name: app.app_name })));
    const loadExposedEnvVars = ({ env }) => Promise.resolve(env);
    loadLinkedServices($linkedAppsEnvVar, loadLinkedApps, loadExposedEnvVars);
  }

  // Add event listeners
  $appEnvVar.addEventListener('cc-env-var-form:submit', ({ detail: newVars }) => {
    const newVarsObject = toNameValueObject(newVars);

    $appEnvVar.state = {
      ...$appEnvVar.state,
      type: 'saving',
    };

    updateAllEnvVars({ id: oid, appId }, newVarsObject)
      .then(sendToApi)
      .then(async () => {
        $appEnvVar.state = {
          ...$appEnvVar.state,
          variables: newVars,
        };
        if (restartAppButton) {
          $appEnvVar.restartApp = true;
        }
        notifySuccess(i18n('cc-env-var-form.update.success'));
        const $previousEolNotice = document.querySelector('.eol-variables-notice');

        if ($previousEolNotice == null) {
          await handleEolVariables(newVars, $appEnvVar, false);
        } else {
          await handleEolVariables(newVars, $previousEolNotice, true);
        }
      })
      .catch(() => notifyError(i18n('cc-env-var-form.update.error')))
      .finally(() => {
        $appEnvVar.state = {
          ...$appEnvVar.state,
          type: 'loaded',
        };
      });
  });

  $appEnvVar.addEventListener('cc-env-var-form:restart-app', () => {
    redeploy({ id: oid, appId })
      .then(sendToApi)
      .then(() => ($appEnvVar.restartApp = false))
      // TODO: We should do better than this
      .catch(console.error);
  });
}

// Generic load services for linked addons and linked apps
// => Load linked services
// => load variables for each service
// => update data or error on $component
function loadLinkedServices($component, loadServices, loadVariables) {
  loadServices()
    .then((serviceList) => {
      $component.state = {
        type: 'loaded',
        servicesStates: serviceList.map(({ name }) => ({ name, type: 'loading' })),
      };

      serviceList.forEach((service, index) => {
        const setNewServiceState = (newServiceState) => {
          $component.state = {
            type: 'loaded',
            servicesStates: $component.state.servicesStates.map((oldServiceState, oldIndex) => {
              return oldIndex === index ? newServiceState : oldServiceState;
            }),
          };
        };

        loadVariables(service)
          .then((variables) => {
            console.log(service.name, variables);
            setNewServiceState({
              type: 'loaded',
              name: service.name,
              variables,
            });
          })
          .catch((e) => {
            console.error(e);
            setNewServiceState({
              type: 'error',
              name: service.name,
            });
          });
      });
    })
    .catch((e) => {
      console.error(e);
      $component.state = { type: 'error' };
    });
}

function handleEolVariables(variables, $container, alreadyHasNotice) {
  const envVarsWithEolVersions = getEnvVarsWithEolVersions(variables);

  if (envVarsWithEolVersions.length === 0) {
    if (alreadyHasNotice) {
      $container.remove();
    }
    return;
  }

  const $notice = getEolNotice(envVarsWithEolVersions);

  if (alreadyHasNotice) {
    $container.replaceWith($notice);
  } else {
    $container.before($notice);
  }
}
