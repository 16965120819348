module.exports = (function () {
  var $AddonProviderPlans = function (settings) {
    var state = {
      container: $(settings.selector),
      plans: settings.plans,
      features: settings.features,
      orgaId: settings.orgaId,
      providerId: settings.providerId,
      data: {},
    };

    $AddonProviderPlans.displayPlans(state);

    return state;
  };

  $AddonProviderPlans.displayPlans = function (state) {
    state.container.html(
      Templates['addon-provider-plans']({
        plans: state.plans,
        features: state.features,
      }),
    );

    state.container.find('button.add-plan').click(function () {
      $AddonProviderPlans.displayAddPlan(state);
    });

    state.container.find('button.add-feature').click(function () {
      $AddonProviderPlans.displayAddFeature(state);
    });

    state.container.find('button.edit-plans').click(function () {
      $AddonProviderPlans.displayEditPlans(state);
    });
  };

  $AddonProviderPlans.displayAddPlan = function (state) {
    state.container.html(
      Templates['addon-provider-add-plan']({
        plans: state.plans,
        features: state.features,
      }),
    );

    state.container.find('button.cancel').click(function () {
      $AddonProviderPlans.displayPlans(state);
    });

    state.container.find('form').submit(function (e) {
      e.preventDefault();
      var form = this;

      var body = JSON.stringify({
        name: form.name.value,
        slug: form.slug.value,
        price: parseFloat(this.price.value.replace(/,/g, '.')),
        features: [],
      });

      var s_plan = API.organisations._.addonproviders._.plans
        .post()
        .withParams([state.orgaId, state.providerId])
        .send(body);

      var s_planWithFeatures = s_plan.flatMapLatest(function (plan) {
        var body = JSON.stringify({
          id: plan.id,
          name: plan.name,
          slug: plan.slug,
          price: plan.price,
          features: _.map(state.features, function (feature) {
            return _.extend({}, feature, {
              value: form['feature-' + feature.name].value,
            });
          }),
        });
        return API.organisations._.addonproviders._.plans._.put()
          .withParams([state.orgaId, state.providerId, plan.id])
          .send(body);
      });

      var s_plans = Bacon.once(state.plans).combine(s_planWithFeatures, '.concat');

      $(form).find("[type='submit']").loadStream(s_plans, 1000);

      s_plans.onValue(function (plans) {
        state.plans = plans;
        $AddonProviderPlans.displayPlans(state);
      });
      s_plans.onError($Notification.displayError);
    });
  };

  $AddonProviderPlans.displayAddFeature = function (state) {
    state.container.html(
      Templates['addon-provider-add-feature']({
        plans: state.plans,
        features: state.features,
      }),
    );

    state.container.find('button.cancel').click(function () {
      $AddonProviderPlans.displayPlans(state);
    });

    state.container.find('form').submit(function (e) {
      e.preventDefault();

      var form = this;
      var body = JSON.stringify({
        name: this.name.value,
        type: 'NUMBER',
      });
      var s_feature = API.organisations._.addonproviders._.features
        .post()
        .withParams([state.orgaId, state.providerId])
        .send(body);
      var s_features = s_feature.map(function (feature) {
        return state.features.concat([feature]);
      });

      var s_plans = s_feature.flatMapLatest(function (feature) {
        return Bacon.combineAsArray(
          _.reduce(
            state.plans,
            function (arr, plan) {
              var body = JSON.stringify({
                id: plan.id,
                name: plan.name,
                slug: plan.slug,
                price: plan.price,
                features: plan.features.concat([
                  {
                    name: feature.name,
                    type: feature.type,
                    value: form['value-for-' + plan.id].value,
                  },
                ]),
              });
              var s_plan = API.organisations._.addonproviders._.plans._.put()
                .withParams([state.orgaId, state.providerId, plan.id])
                .send(body);
              return arr.concat([s_plan]);
            },
            [],
          ),
        );
      });

      $(this).find("[type='submit']").loadStream(s_plans, 1000);

      Bacon.onValues(s_features, s_plans, function (features, plans) {
        state.features = features;
        state.plans = plans;

        $AddonProviderPlans.displayPlans(state);
      });
      Bacon.mergeAll(s_features, s_plans).onError($Notification.displayError);
    });
  };

  $AddonProviderPlans.displayEditPlans = function (state) {
    var featuresToDelete = [];
    var plansToDelete = [];

    state.container.html(
      Templates['addon-provider-edit-plans']({
        plans: state.plans,
        features: state.features,
      }),
    );

    state.container.find('button.cancel').click(function () {
      $AddonProviderPlans.displayPlans(state);
    });

    state.container.find('.remove').click(function () {
      var featureName = $(this).parents('th').attr('data-feature');
      var planId = $(this).parents('tr').attr('data-plan');

      if (planId) {
        state.container.find("[data-plan='" + planId + "']").remove();
        plansToDelete.push(planId);
      } else if (featureName) {
        state.container.find("[data-feature='" + featureName + "']").remove();
        featuresToDelete.push(featureName);
      }
    });

    var removePlansAndFeatures = function (state, plans, features) {
      var s_removedFeatures =
        features.length <= 0
          ? Bacon.once()
          : Bacon.combineAsArray(
              _.reduce(
                features,
                function (arr, feature) {
                  var s_remove = API.organisations._.addonproviders._.features._.delete()
                    .withParams([state.orgaId, state.providerId, encodeURIComponent(btoa(feature))])
                    .send();
                  return arr.concat([s_remove]);
                },
                [],
              ),
            );

      return s_removedFeatures.flatMapLatest(function () {
        return plans.length <= 0
          ? Bacon.once()
          : Bacon.combineAsArray(
              _.reduce(
                plans,
                function (arr, planId) {
                  var s_remove = API.organisations._.addonproviders._.plans._.delete()
                    .withParams([state.orgaId, state.providerId, planId])
                    .send();
                  return arr.concat([s_remove]);
                },
                [],
              ),
            );
      });
    };

    state.container.find('form').submit(function (e) {
      e.preventDefault();
      var form = this;

      var s_plans = removePlansAndFeatures(state, plansToDelete, featuresToDelete).flatMapLatest(function (s) {
        state.plans = _.reject(state.plans, function (plan) {
          return plansToDelete.indexOf(plan.id) >= 0;
        });

        state.features = _.reject(state.features, function (feature) {
          return featuresToDelete.indexOf(feature.name) >= 0;
        });

        return state.plans.length <= 0
          ? Bacon.once([])
          : Bacon.combineAsArray(
              _.reduce(
                state.plans,
                function (arr, plan) {
                  var body = JSON.stringify({
                    id: plan.id,
                    name: plan.name,
                    slug: form['slug-' + plan.id].value,
                    price: form['price-' + plan.id].value,
                    features: _.map(state.features, function (feature) {
                      return _.extend({}, feature, {
                        value: form['feature-' + feature.name + '-' + plan.id].value,
                      });
                    }),
                  });

                  var s_plan = API.organisations._.addonproviders._.plans._.put()
                    .withParams([state.orgaId, state.providerId, plan.id])
                    .send(body);
                  return arr.concat([s_plan]);
                },
                [],
              ),
            );
      });

      $(form).find("[type='submit']").loadStream(s_plans, 1000);

      s_plans.onValue(function (plans) {
        state.plans = plans;
        $AddonProviderPlans.displayPlans(state);
      });
      s_plans.onError($Notification.displayError);
    });
  };

  return $AddonProviderPlans;
})();
