const { iconRemixStarFill } = require('@clevercloud/components/dist/assets/cc-remix.icons.js');
const i18n = require('../models/technical/translation.js');
const { saveFeature } = require('../helpers/featureFlag.js');
const { SettingManager } = require('../lib/settings.js');

module.exports = (function () {
  const Bacon = require('baconjs');
  const $Logs = require('../modules/logs/main.js');
  const AddonsHelper = require('../modules/logs/addons-helper.js');

  const sp = new (require('./AbstractSP.js'))({
    name: 'LogsSP',
  });

  sp.getStreams = (req, $container) => {
    const s_owner = SummaryProxy.fetchOrgaOnce(req.params.oid);
    const s_ownerId = s_owner.map('.id');

    const s_continuousOwner = SummaryProxy.fetchOrga(req.params.oid);

    let s_resource;
    let s_sourceType;

    if (req.params.addonId) {
      s_resource = s_owner.flatMapLatest((owner) => {
        const s_addon = API.organisations._.addons._.get().withParams([owner.id, req.params.addonId]).send();
        const s_instances = API.organisations._.addons._.instances
          .get()
          .withParams([owner.id, req.params.addonId])
          .send();

        return Bacon.combineTemplate({
          addon: s_addon,
          instances: s_instances,
        }).map(({ addon, instances }) => {
          return _.extend({}, addon, {
            logsStatus: AddonsHelper.addonLogs(addon, instances),
          });
        });
      });
      s_sourceType = Bacon.constant('addon');
    } else {
      s_resource = new Bacon.Error(new Error('We should have an addonId'));
      s_sourceType = Bacon.never();
    }

    return { s_ownerId, s_resource, s_sourceType };
  };

  sp.on('onload', (req, $container, streams) => {
    Bacon.onValues(streams.s_ownerId, streams.s_sourceType, (ownerId, sourceType) => {
      const source = { sourceType: sourceType, sourceObject: streams.s_resource };

      $Logs({
        $container: $container.find('.logs-container'),
        ownerId,
        source,
        urlSearchParams: req.search,
      }).onValue(() => {});
    });
  });

  return sp;
})();
