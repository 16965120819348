import { navigateTo } from '../lib/utils.js';
const $Modal = require('../../src/modules/modals/main.js');

/**
 * @typedef {{ title: string, body: string }} ModalContext
 */

/** @type {() => (ModalContext|null)} */
let globalNavigationGuard;

/**
 * Sets up a click event listener to handle navigation within the application.
 * Intercepts clicks on anchor tags and handles them through the application's
 * navigation system instead of default browser behavior. Supports navigation
 * guards and modal confirmations.
 *
 * The function:
 * - Allows ctrl/cmd + click to open links in new tabs
 * - Intercepts clicks on anchor tags
 * - Checks if links are to the same origin
 * - Handles navigation guard confirmations via modals
 * - Cleans up navigation guard on unload
 *
 * @returns {void}
 */
export function watchNavigations() {
  window.addEventListener('click', (e) => {
    // if ctrl + click or cmd + click, let the link open in a new tab
    if (e.ctrlKey || e.metaKey) {
      return;
    }

    const link = e.composedPath().find((node) => {
      return node.nodeName != null && node.nodeName.toUpperCase() === 'A' && node.href != null;
    });
    if (link != null) {
      const currentUrl = new URL(location.href);
      const linkUrl = new URL(link.href);
      if (currentUrl.origin === linkUrl.origin) {
        e.preventDefault();
        const modalContext = globalNavigationGuard?.();
        const path = linkUrl.pathname + linkUrl.search;
        if (modalContext == null) {
          navigateTo(path);
        } else {
          showModal(modalContext, () => navigateTo(path));
        }
      }
    }
  });

  Console.s_requestUnload.onValue(() => {
    globalNavigationGuard = null;
  });
}
/**
 * Sets a navigation guard callback function that controls navigation confirmation.
 *
 * @param {() => (ModalContext|null)} guardCallback - A callback function that returns either null
 *        (to allow navigation) or an object with modal content (to show confirmation dialog). The returned object
 *        should contain a title string and body string for the modal.
 * @returns {void}
 */
export function setNavigationGuard(guardCallback) {
  globalNavigationGuard = guardCallback;
}

/**
 * Shows a confirmation modal dialog with specified content and handles user confirmation.
 *
 * @param {ModalContext} modalContext - title and body strings for the modal
 * @param {Function} confirmCallback - Callback function to execute when user confirms
 */
function showModal(modalContext, confirmCallback) {
  const $modal = $Modal({
    type: 'confirmation',
    title: modalContext.title,
    body: modalContext.body,
    cancelButtonText: T('console.modals.navigation-guard.cancel-btn'),
    submitButtonText: T('console.modals.navigation-guard.leave-btn'),
    submitButtonClass: 'btn-red',
    Templates,
  });

  const confirm = $modal.s_confirm.flatMapLatest(function () {
    return Bacon.once();
  });

  confirm.onValue(() => {
    $Modal.remove($modal);
    confirmCallback();
  });
}
