'use strict';

const $ = require('jquery');
const _ = require('lodash');
const Bacon = require('baconjs');

const applyTemplate = require('../../helpers/applyTemplate');
const {
  getOwnerDetails,
  getAllApps,
  getAddons,
  getOauthConsumers,
  getAddonProviders,
} = require('./apps-pane.data-streams');
const { getFeature } = require('../../helpers/featureFlag');
require('@clevercloud/components/dist/cc-icon.js');

const newCreationTunnel = getFeature('NEW_CREATION_TUNNEL');

function lowerCase(property) {
  return (obj) => {
    return obj[property].toLowerCase();
  };
}

function filter(s_list, s_filterQuery) {
  return Bacon.combineAsArray([s_list, s_filterQuery]).map(([list, filterQuery]) => {
    const tokens = filterQuery.toLowerCase().split(' ');
    const [keywordTokens, textTokens] = _.partition(tokens, (s) => s.match(/^is:./));
    return _(list)
      .filter(({ matchers = [] }) => {
        return _.every(keywordTokens, (keyword) => matchers.includes(keyword));
      })
      .filter((item) => {
        const name = item.name.toLowerCase();
        const logoLabel = item.logoLabel && item.logoLabel.toLowerCase();
        return _.every(textTokens, (text) => {
          const nameInclude = name.includes(text);
          if (!nameInclude) {
            if (logoLabel) {
              return logoLabel.includes(text);
            } else {
              return false;
            }
          } else {
            return nameInclude;
          }
        });
      })
      .value();
  });
}

function getLocalStorageItem(key) {
  return JSON.parse(localStorage.getItem(key));
}

function setLocalStorageItem(key, value) {
  return !Console.LoginAs.isLoggedAsAdmin() && localStorage.setItem(key, JSON.stringify(value));
}

const createMenuToggleSelector = '.l-apps_createMenu_toggle';
const filterQuerySelector = '.l-apps_filterQuery';
const appsBodySelector = '.l-apps_body';
const archivedAppsSelector = '.archivedApps';
const archivedAppsToggleSelector = '.archivedApps_toggle';
const archivedAppsAnchorSelector = '.archivedApps_anchor';

function initComponent(domInsertPointSelector, settings) {
  // init root template (just once)
  const $root = applyTemplate(domInsertPointSelector, 'apps-pane')();

  // init data streams from s_owner
  const s_ownerId = settings.s_owner.map((owner) => owner.id).skipDuplicates(_.isEqual);
  const s_ownerDetails = getOwnerDetails(settings.s_owner);
  const s_allApps = getAllApps(settings.s_owner, settings.Console);
  const s_activeApps = s_allApps.map((apps) => _.reject(apps, 'archived'));
  const s_archivedApps = s_allApps.map((apps) => _.filter(apps, 'archived'));
  const s_addons = getAddons(settings.s_owner);
  const s_oauthConsumers = getOauthConsumers(settings.s_owner);
  const s_addonProviders = getAddonProviders(settings.s_owner);
  const s_activeAppsAndAddons = Bacon.combineAsArray(s_activeApps, s_addons).map(([activeApps, addons]) => [
    ...activeApps,
    ...addons,
  ]);

  // init header
  s_ownerDetails.skipDuplicates(_.isEqual).onValue(applyTemplate('[data-tpl="header"]', 'apps-pane.header'));

  s_ownerDetails
    .skipDuplicates(_.isEqual)
    .onValue(applyTemplate('[data-tpl="ticket-center"]', 'apps-pane.ticket-center'));

  // make sure we reset body scroll position when owner changes
  s_ownerId.onValue(() => $root.find(appsBodySelector).scrollTop(0));

  const s_ownerHasCreatedItems = Bacon.combineAsArray(s_allApps, s_addons, s_oauthConsumers, s_addonProviders)
    .map((lists) => lists.some(({ length }) => length > 0))
    .skipDuplicates(_.isEqual);

  // init create menu
  const s_windowClicks = $(window)
    .asEventStream('click')
    .map(() => 'CLOSED');

  const s_createMenuToggleClicks = $root.asEventStream('click', createMenuToggleSelector).map((e) => {
    e.stopPropagation();
    return 'TOGGLE';
  });

  const s_createMenuToggleState = Bacon.mergeAll(s_windowClicks, s_createMenuToggleClicks).scan(
    'CLOSED',
    (oldState, newState) => {
      if (newState === 'CLOSED') {
        return 'CLOSED';
      }
      return oldState === 'CLOSED' ? 'OPENED' : 'CLOSED';
    },
  );

  Bacon.combineAsArray(s_ownerHasCreatedItems, s_ownerDetails, s_createMenuToggleState, s_ownerId)
    .map(([ownerHasCreatedItems, owner, toggleState, ownerId]) => {
      return {
        ownerHasCreatedItems,
        isUser: owner.isUser,
        createAppHref: `${owner.ownerHref}/applications/new`,
        createAddonHref: `${owner.ownerHref}/addons/new`,
        createOAuthConsumerHref: `${owner.ownerHref}/oauth-consumers/new`,
        createAddonProviderHref: `${owner.ownerHref}/providers/new`,
        newTunnelHref: `/organisations/${ownerId}/create`,
        newTunnelActive: newCreationTunnel,
        toggleState,
      };
    })
    .skipDuplicates(_.isEqual)
    .onValue(applyTemplate('[data-tpl="create-menu"]', 'apps-pane.create-menu'));

  // init filter
  const s_ownerSavedFilter = s_ownerId.map((ownerId) => {
    const oldFilterQueries = getLocalStorageItem('appsPane-filterQueries');
    const filterQuery = _.get(oldFilterQueries, ownerId, '');
    return filterQuery;
  });

  Bacon.combineAsArray(s_ownerSavedFilter, s_ownerHasCreatedItems)
    .map(([filterQuery, ownerHasCreatedItems]) => ({ ownerHasCreatedItems, filterQuery }))
    .onValue(applyTemplate('[data-tpl="filter"]', 'apps-pane.filter'));

  const s_filterQueryInputs = $root.asEventStream('input', filterQuerySelector).map(({ target }) => $(target).val());

  const s_filterQuery = s_ownerSavedFilter.flatMap((filterQuery) => s_filterQueryInputs.toProperty(filterQuery));

  s_ownerId
    .sampledBy(s_filterQuery, (ownerId, filterQuery) => ({ ownerId, filterQuery }))
    .onValue(({ ownerId, filterQuery }) => {
      const oldFilterQueries = getLocalStorageItem('appsPane-filterQueries');
      const newFilterQueries = _.assign({}, oldFilterQueries, { [ownerId]: filterQuery });
      setLocalStorageItem('appsPane-filterQueries', newFilterQueries);
    });

  $root
    .asEventStream('keyup', filterQuerySelector)
    // 27 keyCode for ESC key
    .filter(({ keyCode }) => keyCode === 27)
    .onValue(({ target }) => {
      $(target).blur();
    });

  // init filtered lists
  const s_filteredActiveAppsAndAddons = filter(s_activeAppsAndAddons, s_filterQuery);
  const s_filteredOauthConsumers = filter(s_oauthConsumers, s_filterQuery);
  const s_filteredAddonProviders = filter(s_addonProviders, s_filterQuery);
  const s_filteredArchivedApps = filter(s_archivedApps, s_filterQuery);
  const s_filterResultsAreEmpty = Bacon.combineAsArray(
    s_filteredActiveAppsAndAddons,
    s_filteredOauthConsumers,
    s_filteredAddonProviders,
    s_filteredArchivedApps,
  ).map(([appsAndAddons, oauthConsumers, addonProviders, archivedApps]) => {
    return [...appsAndAddons, ...oauthConsumers, ...addonProviders, ...archivedApps].length === 0;
  });

  // init messages
  Bacon.combineAsArray(s_ownerHasCreatedItems, s_filterResultsAreEmpty, s_filterQuery)
    .map(([ownerHasCreatedItems, filterResultsAreEmpty, filterQuery]) => {
      return { ownerHasCreatedItems, filterResultsAreEmpty, filterQuery };
    })
    .skipDuplicates(_.isEqual)
    .onValue(applyTemplate('[data-tpl="messages"]', 'apps-pane.messages'));

  // attach title attributes when text is too long to trigger "tipsy" tooltips
  function attachTooltips($parentElement) {
    _.each($parentElement.find('.menuItem_name'), (elem) => {
      if (elem.scrollWidth > elem.clientWidth) {
        elem.setAttribute('title', elem.textContent);
        elem.setAttribute('data-gravity', 'w');
      }
    });
  }

  // init active apps and addons
  s_filteredActiveAppsAndAddons
    .map((appsAndAddons) => ({
      menuItems: _.sortBy(appsAndAddons, lowerCase('name')),
    }))
    .skipDuplicates(_.isEqual)
    .map(applyTemplate('[data-tpl="apps-active-addons"]', 'apps-pane.apps-active-addons'))
    .onValue(attachTooltips);

  // init oauth consumers
  s_filteredOauthConsumers
    .map((oauthConsumers) => ({
      menuItems: _.sortBy(oauthConsumers, lowerCase('name')),
    }))
    .skipDuplicates(_.isEqual)
    .map(applyTemplate('[data-tpl="oauth-consumers"]', 'apps-pane.oauth-consumers'))
    .onValue(attachTooltips);

  // init addon providers
  s_filteredAddonProviders
    .map((addonProviders) => ({
      menuItems: _.sortBy(addonProviders, lowerCase('name')),
    }))
    .skipDuplicates(_.isEqual)
    .map(applyTemplate('[data-tpl="addon-providers"]', 'apps-pane.addon-providers'))
    .onValue(attachTooltips);

  // init archived apps
  const s_archivedAppsToggleClicks = $root.asEventStream('click', archivedAppsToggleSelector);

  // each time someone clicks on the archived apps toggle button, this stream:
  // * figures out the new state for the current owner (between CLOSED & OPENED)
  // * saves the states for all owners in localStorage
  // * emits the new state for the current owner
  const s_archivedAppsToggleState = s_ownerId.sampledBy(s_archivedAppsToggleClicks).map((ownerId) => {
    const archivedAppsState = getLocalStorageItem('archivedAppsState');
    const oldState = _.get(archivedAppsState, [ownerId, 'state'], 'CLOSED');
    const newState = oldState === 'CLOSED' ? 'OPENED' : 'CLOSED';
    const newArchivedAppsState = _.assign({}, archivedAppsState, {
      [ownerId]: { state: newState },
    });
    setLocalStorageItem('archivedAppsState', newArchivedAppsState);
    return newState;
  });

  const s_archivedAppsWithState = s_ownerId
    .sampledBy(s_filteredArchivedApps, (ownerId, apps) => {
      const archivedAppsState = getLocalStorageItem('archivedAppsState');
      const lastState = _.get(archivedAppsState, [ownerId, 'state'], 'CLOSED');
      return {
        toggleState: lastState,
        apps: _.sortBy(apps, lowerCase('name')),
      };
    })
    .skipDuplicates(_.isEqual);

  s_archivedAppsWithState
    .map(applyTemplate('[data-tpl="apps-archived"]', 'apps-pane.apps-archived'))
    .onValue(attachTooltips);

  s_archivedAppsToggleState.onValue((toggleState) => {
    const $archivedApps = $root.find(archivedAppsSelector);
    const $anchor = $root.find(archivedAppsAnchorSelector);
    const $toggle = $root.find(archivedAppsToggleSelector);
    if (toggleState === 'OPENED') {
      $archivedApps.attr('data-state', toggleState);
      setTimeout(() => $toggle[0].scrollIntoView({ behavior: 'smooth', block: 'start' }), 75);
    } else {
      $anchor[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
      setTimeout(() => $archivedApps.attr('data-state', toggleState), 500);
    }
  });
}

module.exports = initComponent;
