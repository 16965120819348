// List of addon provider id that need to be loaded inside the console with new WC and no iframe
export const ADDONS_IN_CONSOLE_DASHBOARD = [
  'es-addon',
  'addon-pulsar',
  'jenkins',
  'config-provider',
  'addon-matomo',
  'kv',
  'keycloak',
  'metabase',
  'otoroshi',
];
export const HAS_SERVICE_DEPENDENCIES = [
  'es-addon',
  'addon-pulsar',
  'jenkins',
  'config-provider',
  'kv',
  'keycloak',
  'metabase',
  'otoroshi',
];

export function getLinks(details) {
  const links = [];
  const kibana = details.services.find((s) => s.name === 'kibana');
  if (kibana != null && kibana.enabled) {
    links.push({ type: 'kibana', href: getKibanaLink(details) });
    const apm = details.services.find((s) => s.name === 'apm');
    if (apm != null && apm.enabled) {
      links.push({ type: 'apm', href: getApmLink(details) });
    }
  }
  return links;
}

function getKibanaLink(details) {
  return `https://kibana-${details.config.host}`;
}

function getApmLink(details) {
  return `${getKibanaLink(details)}/app/apm`;
}
