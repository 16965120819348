const { ANALYTICS_SITE_ID, MATOMO_DIMENSIONS } = require('../../configuration.js');

let matomoTracker;

export async function trackPageView(path, id, referrerPath) {
  if (matomoTracker == null) {
    const userId = await Console.User.firstToPromise().then((user) => user.id);
    matomoTracker = await initAnalytics(userId);
  }

  if (matomoTracker != null) {
    if (referrerPath != null) {
      matomoTracker.setReferrerUrl(window.location.origin + referrerPath);
    }

    if (path != null) {
      matomoTracker.setCustomUrl(window.location.origin + path);
    }

    matomoTracker.trackPageView(id);
  }
}

function initTracker({ url, siteId, dimensions, userId }) {
  const matomoTrackingUrl = buildUrl(url, 'ma-server');
  const matomoScriptUrl = buildUrl(url, 'ma-script');

  window._paq = window._paq || [];

  //Setting up matomo with the server url, the site ID on the Matomo side,..., to enable the connection with the Matomo server
  _paq.push(['setTrackerUrl', matomoTrackingUrl]);
  _paq.push(['setSiteId', siteId]);
  _paq.push(['disableAlwaysUseSendBeacon']);

  return (
    loadScript(matomoScriptUrl)
      // get `matomoTracker`
      .then(getMatomoAsyncTracker)
      // initialize `matomoTracker`
      .then((matomoTracker) => {
        if (dimensions != null) {
          for (const dimension of JSON.parse(dimensions)) {
            if (Console.configuration[dimension.name] != null) {
              matomoTracker.setCustomDimension(dimension.id, Console.configuration[dimension.name]);
            }
          }
        }
        matomoTracker.disableCookies();
        matomoTracker.setUserId(userId);
        return matomoTracker;
      })
  );
}

function buildUrl(base, pathname) {
  const url = new URL(base);
  url.pathname = pathname;
  return url.toString();
}

function loadScript(scriptUrl) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.type = 'text/javascript';
    script.src = scriptUrl;

    const head = document.head || document.querySelector('head');
    head.appendChild(script);

    script.onload = resolve;
    script.onerror = () => reject(new Error(`Matomo script could not be loaded from ${scriptUrl}`));
  });
}

function getMatomoAsyncTracker() {
  return new Promise((resolve, reject) => {
    const checkInterval = 50;
    const timeout = 3000;
    const waitStart = Date.now();
    const interval = setInterval(() => {
      if (window.Piwik != null) {
        clearInterval(interval);
        return resolve(window.Piwik.getAsyncTracker());
      }

      if (Date.now() >= waitStart + timeout) {
        clearInterval(interval);

        reject(new Error(`[matomo-connection]: window.Piwik undefined after waiting for ${timeout}ms`));
      }
    }, checkInterval);
  });
}

function initAnalytics(userId) {
  return initTracker({
    url: window.location.origin,
    siteId: ANALYTICS_SITE_ID,
    dimensions: MATOMO_DIMENSIONS,
    userId,
  }).catch((error) => {
    console.error(error);
  });
}
