import { pickNonNull } from '@clevercloud/client/esm/pick-non-null.js';

// TODO: move to clever-client
/**
 * GET /v4/billing/price-system
 * @param {Object} params
 * @param {String} params.owner_id
 * @param {String} params.zone_id
 */

/**
 * This function takes a price as a parameter and round the decimals to have a fixed price. (e.g: 6.799999999999996 => 6.80)
 * @param {Number} Price
 * @returns {Number} decimals number rounded up
 *
 */
export function roundPriceDecimalUp(number) {
  return Number.parseFloat(number.toFixed(2));
}

const ONE_HOUR = 1;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_MONTH = ONE_DAY * 30;
