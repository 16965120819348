import { html, render } from 'lit';
import { sanitize } from '@clevercloud/components/dist/lib/i18n/i18n-sanitize.js';
import { createElement } from '../statepoints-wc/dom.js';
import eolVersionsByVariableName from '../lib/eol-versions-by-env-var-name.json' with { type: 'json' };
import { getLanguage } from '@clevercloud/components/dist/i18n.js';

export function getEnvVarsWithEolVersions(variables) {
  const eolVersionVariables = [];
  const versionVariables = variables.filter(({ name }) => eolVersionsByVariableName[name] != null);

  for (const versionVariable of versionVariables) {
    const { recommendedVersion, eolLink, eolVersions, runtime } = eolVersionsByVariableName[versionVariable.name];

    const correspondingEolVersion = getCorrespondingEolVersion(eolVersions, versionVariable.value);

    if (correspondingEolVersion != null) {
      eolVersionVariables.push({
        runtime,
        recommendedVersion,
        eolLink,
        variableName: versionVariable.name,
        specifiedVersion: versionVariable.value,
        ...correspondingEolVersion,
      });
    }
  }

  return eolVersionVariables;
}

function getCorrespondingEolVersion(eolVersions, specifiedVersion) {
  return eolVersions.find(({ version }) => {
    const specifiedVersionAsString = specifiedVersion.toString();
    const [eolMajor, eolMinor] = version.split('.');
    const [specifiedMajor, specifiedMinor] = specifiedVersionAsString.split('.');

    if (eolMinor != null) {
      return eolMajor === specifiedMajor && eolMinor === specifiedMinor;
    }

    return eolMajor === specifiedMajor;
  });
}

export function getEolNotice(eolVariables, envVarScreenHref) {
  const language = getLanguage();
  const noticeIntent = eolVariables.some(({ intent }) => intent === 'warning') ? 'warning' : 'info';
  const dateFormatLang = language === 'fr' ? 'fr-FR' : 'en-US';
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const $notice = createElement('cc-notice', {
    class: 'eol-variables-notice',
    intent: noticeIntent,
    heading: T('console.info.eol.heading'),
  });

  const template = html`
    <style>
      .eol-variables-container {
        display: grid;
        gap: 0.5em;
      }
      .eol-variables-message code {
        background-color: var(--cc-color-bg-neutral);
        border: 1px solid var(--cc-color-border-neutral-weak, #eee);
        border-radius: var(--cc-border-radius-default, 0.25em);
        font-family: var(--cc-ff-monospace);
        font-size: 0.9em;
        padding: 0.25em;
      }
      .eol-variables-message {
        margin: 0;
      }
    </style>
    <div class="eol-variables-container" slot="message">
      ${eolVariables.map((eolVariable) =>
        renderEolVariableMessage(eolVariable, envVarScreenHref, dateFormatLang, dateOptions),
      )}
    </div>
  `;

  render(template, $notice);

  return $notice;
}

function renderEolVariableMessage(
  { runtime, variableName, eolDate, eolLink, specifiedVersion, recommendedVersion },
  envVarScreenHref,
  dateFormatLang,
  dateOptions,
) {
  const eolVersionMention =
    eolLink != null
      ? T('console.info.eol.message.specified-version-with-link', { runtime, specifiedVersion, eolLink })
      : T('console.info.eol.message.specified-version-without-link', { runtime, specifiedVersion });
  // the end of life date is optional
  const eolSinceMention =
    eolDate != null
      ? T('console.info.eol.message.since', {
          eolDate: new Date(eolDate).toLocaleDateString(dateFormatLang, dateOptions),
        })
      : '';
  const eolFixMention = recommendedVersion.includes('.')
    ? T('console.info.eol.message.fix-minor', { variableName, recommendedVersion })
    : T('console.info.eol.message.fix-major', {
        variableName,
        recommendedVersion,
      });
  // we don't want to insert a link if users are already on the environment variables page
  const eolEnvVarScreenMention =
    envVarScreenHref != null
      ? T('console.info.eol.message.env-var-with-link', { envVarScreenHref })
      : T('console.info.eol.message.env-var-without-link');
  const messageContent = `${eolVersionMention}${eolSinceMention}. ${eolFixMention}${eolEnvVarScreenMention}`;

  return html` <p class="eol-variables-message">${sanitize(messageContent)}</p> `;
}
