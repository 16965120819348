module.exports = (() => {
  const sp = new (require('./AbstractSP.js'))({
    name: 'PaypalCanceledSP',
  });

  sp.on('onload', (req, $container) => {
    $Notification.displayError({ message: T('console.invoices.paypal-canceled') });
    $container.find('.alert').text(T('console.invoices.paypal-canceled'));
  });

  return sp;
})();
