import { sendToApi } from '../send-to-api.js';
import { CC_ENV } from '../configuration.js';
import {
  _delete as deleteSetting,
  get as getSetting,
  put as putSetting,
} from '@clevercloud/client/esm/api/v4/settings.js';

/**
 * This class manages the settings that is stored and retrieved through the Settings API.
 * It has the ability to save any settings and ensure the data to respect the right schema.
 * To do that, it uses some converters that are dedicated to migrate data to the latest version of the schema/
 */
export class SettingManager {
  /**
   *
   * @param {string} name The name of the setting to manage
   * @param {string} version The current version of the schema
   * @param {{[oldSchemaVersion: string]: ((dataWithOldFormat: any) => any)}} [converters] The converters that help maintaining setting always in the last version of the schema
   */
  constructor (name, version, converters) {
    this._env = CC_ENV;
    this._name = name;
    this._version = version;
    this._converters = converters ?? {};
  }

  /**
   * Gets the setting. If the setting found is not using the last schema version, we will try to convert it using the given converters.
   * If there is no converter for the version found for the setting, then, the setting will be deleted and the method returns null.
   *
   * @return {Promise<*|null>}
   */
  async getSetting () {
    let raw;
    try {
      raw = await getSetting({ name: this._name, env: this._env }).then(sendToApi);
    }
    catch (e) {
      if (e.response.status === 404) {
        return null;
      }
      throw e;
    }

    if (raw == null) {
      return null;
    }
    let parsedRaw = JSON.parse(raw.value);
    const version = parsedRaw['@version'];

    // when the data is not using the expected schema version, we will try to convert it using the right converter defined above.
    if (version !== this._version) {
      const converter = this._converters[version];
      if (converter == null) {
        // no converter was found => we don't want to leave that old (and not-convertible data) in the database
        // So we delete the setting, so that the user will come back to default setting.
        await this.deleteSetting();
        return null;
      }
      else {
        // we convert the data using the right converter
        const convertedData = converter(parsedRaw.data);
        // we save the converted data with the new schema version
        await this.putSetting(convertedData);
        return convertedData;
      }
    }

    return parsedRaw.data;
  }

  /**
   * Save setting
   *
   * @param {any} data
   * @return {Promise<void>}
   */
  async putSetting (data) {
    await putSetting({
      name: this._name,
      env: this._env,
    }, JSON.stringify({ '@version': this._version, data }))
      .then(sendToApi);
  }

  /**
   * Delete setting
   *
   * @return {Promise<void>}
   */
  async deleteSetting () {
    await deleteSetting({ name: this._name, env: this._env }).then(sendToApi);
  }
}
