module.exports = (function () {
  const { getLanguage } = require('@clevercloud/components/dist/i18n.js');
  const {
    prepareNumberBytesFormatter,
    formatCurrency,
  } = require('@clevercloud/components/dist/lib/i18n/i18n-number.js');
  var Bacon = require('baconjs');
  var _ = require('lodash');
  var $ = require('jquery');
  var Templates = require('../../../generated/templates.js');
  var T = require('../../models/technical/translation.js');
  var $Pricer = require('../pricer/view.js');
  var $Dropdown = require('@clevercloud/console3-dropdown');
  var $Github = require('../github/main.js');
  const { filterZones, cleanZoneTags, isMachineLearning } = require('../../clever-client/various.js');
  const { ONE_MONTH } = require('../../lib/price-system.js');
  require('@clevercloud/components/dist/cc-block.js');
  require('@clevercloud/components/dist/cc-zone-input.js');

  const lang = getLanguage();
  const formatBytes =
    lang === 'fr' ? prepareNumberBytesFormatter(lang, 'o', '\u202f') : prepareNumberBytesFormatter(lang, 'B', ' ');

  var savedConfiguration;

  var $AppCreation = function (settings) {
    var $appCreation = {
      $form: $(settings.selector),
      orgaId: settings.orgaId,
      githubApps: settings.githubApps,
      instances: settings.instances,
      price: settings.price,
      zones: settings.zones,
      data: {},
      priceSystem: settings.priceSystem,

      SummaryProxy: settings.SummaryProxy,
      Console: settings.Console,
    };

    $AppCreation.initSteps($appCreation);

    /* Start by displaying instance types, or github apps if they are available */
    $AppCreation.display('github-app', $appCreation);

    return $appCreation;
  };

  $AppCreation.steps = [
    'github-app',
    'instance-type',
    'deployment-type',
    'scaling-configuration',
    'information',
    'user-information',
    'github-error',
  ];

  $AppCreation.initSteps = function ($appCreation) {
    _.each($AppCreation.steps, function (step) {
      var $step = $('<fieldset>').attr('data-step', step).hide();
      $appCreation.$form.append($step);
    });

    $appCreation.streams = _.reduce(
      $AppCreation.steps,
      function (streams, step) {
        streams[step] = new Bacon.Bus();
        return streams;
      },
      {},
    );

    $appCreation.displayStream = new Bacon.Bus();

    /* Display Github applications */
    $appCreation.streams['github-app'].onValue(function ($appCreation) {
      $AppCreation.display('instance-type', $appCreation);
    });

    /* Display deployment types once instance choosen */
    $appCreation.streams['instance-type'].onValue(function ($appCreation) {
      var deployments = $appCreation.data.instance.deployments;

      /* Don't display choice list if there is only one deployment type */
      if (_.size(deployments) === 1) {
        $appCreation.streams['deployment-type'].push(
          _.extend($appCreation, {
            data: _.extend($appCreation.data, {
              deploy: _.head(deployments),
            }),
          }),
        );
      } else if (_.size(deployments) > 1 && $appCreation.data.githubApp) {
        // for PHP apps from GitHub
        $appCreation.streams['deployment-type'].push(
          _.extend($appCreation, {
            data: _.extend($appCreation.data, {
              deploy: 'git',
            }),
          }),
        );
      } else {
        $AppCreation.display('deployment-type', $appCreation);
      }
    });

    /* Then display scaling configuration */
    $appCreation.streams['deployment-type'].onValue(function ($appCreation) {
      $AppCreation.display('scaling-configuration', $appCreation);
    });

    /* Once we have all the information we need, we try to submit it */
    var s_app_first = $appCreation.streams['information']
      .filter(function ($appCreation) {
        return $AppCreation.filterSubmission($appCreation);
      })
      .flatMap(function ($appCreation) {
        $appCreation.locked = true;

        var s_app = $AppCreation.createApplication($appCreation);
        s_app.onError(function () {
          $appCreation.locked = false;
        });

        return s_app;
      });

    var s_user = $appCreation.streams['user-information'];

    var s_app_retry = s_user.flatMapLatest(function ($appCreation) {
      return $AppCreation.createApplication($appCreation);
    });

    var s_app = Bacon.mergeAll(s_app_first, s_app_retry);
    $appCreation.application = s_app.map(function ($appCreation) {
      return $appCreation.app;
    });
  };

  $AppCreation.filterSubmission = function ($appCreation) {
    return typeof $appCreation.app === 'undefined' && !$appCreation.locked;
  };

  $AppCreation.createApplication = function ($appCreation) {
    var data = _.omit($appCreation.data, 'instance');
    data.description = data.description || data.name;
    data.oauthService = data.githubApp && 'github';
    data.oauthAppId = data.githubApp && data.githubApp.id;

    var body = JSON.stringify(data);

    var s_app = (
      $appCreation.orgaId
        ? API.organisations._.applications.post().withParams([$appCreation.orgaId]).send(body)
        : API.self.applications.post().send(body)
    ).map(function (app) {
      return _.extend($appCreation, {
        app: _.extend({}, app, {
          instanceType: app.instance.type,
        }),
      });
    });

    var s_appCreated = s_app.flatMapLatest(function ($appCreation) {
      return $appCreation.SummaryProxy.updateApplications($appCreation.orgaId)
        .flatMapLatest(function () {
          return data.deploy === 'ftp'
            ? $appCreation.SummaryProxy.updateAddons($appCreation.orgaId) // PHP FTP FS BUCKET
            : Bacon.once();
        })
        .map($appCreation);
    });

    // Delay because we need the appsPane to first render the new app
    Bacon.onValues(s_appCreated.delay(500), SummaryProxy.fetchUserOnce(), function ($appCreation, me) {
      $appCreation.Console.$panes.slidingPanes.find('li[data-app="' + $appCreation.app.id + '"]').addClass('selected');
    });

    $appCreation.$form.find("[type='submit']").loadStream(s_appCreated, 1000);

    s_appCreated.onError(function (error) {
      $Notification.displayError(error);

      /* If the submission fails because of a lack of personal information, display user information form */
      if (parseInt(error.id) === 3525) {
        API.self
          .get()
          .send()
          .onValue(function (user) {
            $AppCreation.display(
              'user-information',
              _.extend($appCreation, {
                user: user,
              }),
            );
          });
      } else if (parseInt(error.id) === 2501) {
        /* If something fails with Github */
        $AppCreation.display(
          'github-error',
          _.extend($appCreation, {
            githubError: error,
          }),
        );
      } else {
        $AppCreation.display(
          'information',
          _.extend($appCreation, {
            appError: error,
          }),
        );
      }
    });

    return s_appCreated;
  };

  $AppCreation.display = function (step, $appCreation) {
    var $steps = $appCreation.$form.find('fieldset');
    var $step = $steps.filter("[data-step='" + step + "']");

    step =
      ($appCreation.data && $appCreation.data.instance) ||
      $AppCreation.steps.indexOf(step) < $AppCreation.steps.indexOf('instance-type')
        ? step
        : 'instance-type';

    $AppCreation.display[step]($step, $appCreation);
    $appCreation.displayStream.push(step);

    if (!$step.is(':visible')) {
      $steps.hide();
      $step.fadeIn();
    }
  };

  $AppCreation.display['github-app'] = function ($step, $appCreation) {
    $step.html(
      Templates['AppCreationSP.github']({
        githubApps: $appCreation.githubApps !== null,
        orgaId: $appCreation.orgaId,
      }),
    );

    var s_apps = $appCreation.githubApps || Bacon.constant(null);

    var appClicked = function (apps, selected) {
      var app = _.find(apps, function (app) {
        return app.id === selected.id;
      });

      $appCreation.streams['github-app'].push(
        _.extend($appCreation, {
          data: _.extend($appCreation.data, {
            githubApp: app,
          }),
        }),
      );
    };

    $step.find('button.new-app[data-app]').one('click', function (e) {
      e.preventDefault();
      appClicked([]);
    });

    s_apps.onValue(function (apps) {
      if (apps) {
        var dropdownApps = _.reduce(
          apps,
          function (obj, app) {
            if (obj[app.owner]) {
              obj[app.owner] = obj[app.owner].concat([app]);
            } else {
              obj[app.owner] = [app];
            }
            return obj;
          },
          {},
        );
        $step.find('.loading-github-apps').hide();

        var $githubAppsContainer = $step.find('.github-apps .github-dropdown .github-apps-content');

        var $dropdown = $Dropdown({
          container: $githubAppsContainer,
          map: dropdownApps,
          defaultText: T('console.app-creation.select-github-repo'),
          placeholder: T('console.app-creation.select-github-repo-placeholder'),

          Templates: Templates,
          T,
        });

        $githubAppsContainer.fadeIn();

        $dropdown.s_search.onValue(_.partial(appClicked, apps));
      } else {
        $step
          .find('.link-github-button button')
          .asEventStream('click')
          .onValue(function (e) {
            var Github = $Github();
            var s_link = Github.link();
            $(e.target).loadStream(s_link);
          });
      }
    });
  };

  $AppCreation.display['instance-type'] = function ($step, $appCreation) {
    var instances = _.chain($appCreation.instances)
      /* Only keep instances that are enabled */
      .filter(function (instance) {
        return instance.enabled;
      })
      /* And sort the remaining instances by name */
      .sortBy(function (instance) {
        return instance.variant.name;
      })
      .value();

    var $instances = $step
      .html(
        Templates['AppCreationSP.instance-type']({
          instances: instances,
        }),
      )
      .find('.instances');

    $instances.find('[data-instance]').click(function () {
      var $elem = $(this);
      var instance = _.find(instances, function (instance) {
        return instance.type === $elem.attr('data-instance') && instance.variant.id === $elem.attr('data-variant');
      });

      $appCreation.streams['instance-type'].push(
        _.extend($appCreation, {
          data: _.extend($appCreation.data, {
            instance: instance,
            instanceType: instance.type,
            instanceVersion: instance.version,
            instanceVariant: instance.variant.id,
          }),
        }),
      );
    });
  };

  $AppCreation.display['deployment-type'] = function ($step, $appCreation) {
    var $types = $step.html(
      Templates['AppCreationSP.deployment-type']({
        types: $appCreation.data.instance.deployments,
      }),
    );

    $types.find('[data-type]').click(function () {
      var type = $(this).attr('data-type');
      $appCreation.streams['deployment-type'].push(
        _.extend($appCreation, {
          data: _.extend($appCreation.data, {
            deploy: type,
          }),
        }),
      );
    });
  };

  $AppCreation.display['scaling-configuration'] = function ($step, $appCreation) {
    $step.html(Templates['AppCreationSP.configuration']());

    const $suggested = $step.find('.suggested-configuration');
    const $configuration = $step.find('.pricer');
    const $documentation = $step.find('.documentation');

    const defaultFlavorPrice =
      $appCreation.priceSystem?.runtime?.find(
        (ps) => ps['slug_id'] === $appCreation.data.instance.defaultFlavor['price_id'],
      )?.price ?? 0;

    let patchedBuildFlavor = $appCreation.data.instance.buildFlavor;
    let patchedDefaultFlavor = $appCreation.data.instance.defaultFlavor;

    // We patch the flavors with the billing API prices
    const patchedFlavors = $appCreation.data.instance.flavors.map((flavor) => {
      if (flavor?.['price_id'] == null) {
        return flavor;
      }

      const price = $appCreation.priceSystem?.runtime?.find((ps) => ps['slug_id'] === flavor['price_id'])?.price ?? 0;

      if (flavor.price_id === patchedBuildFlavor.price_id) {
        patchedBuildFlavor = { ...patchedBuildFlavor, price };
      }
      if (flavor.price_id === patchedDefaultFlavor.price_id) {
        patchedDefaultFlavor = { ...patchedDefaultFlavor, price };
      }

      return { ...flavor, price };
    });

    const $pricer = $Pricer({
      selector: $configuration,
      instance: {
        ...$appCreation.data.instance,
        buildFlavor: patchedBuildFlavor,
        defaultFlavor: patchedDefaultFlavor,
        flavors: patchedFlavors,
      },
      // This is the price of the default flavor when you decide to edit the app scalability
      // Note: the number will be formatted in the `Pricer`
      price: defaultFlavorPrice * ONE_MONTH,
      configuration:
        savedConfiguration && savedConfiguration.instanceType === $appCreation.data.instanceType
          ? savedConfiguration.configuration
          : null,
    });

    /* Configuration from manipulating the pricer */
    const s_configuration = $pricer.configuration.toProperty();

    /* Suggest to accept the initial configuration if we have a static conf with only one instance */
    s_configuration.take(1).onValue(function (conf) {
      if (conf.autoscalability === true || conf.staticConfiguration.minInstances > 1) {
        $configuration.show();
        $step.find('.next').show();
        $documentation.hide();
      } else {
        $suggested
          .html(
            Templates['AppCreationSP.suggested-configuration']({
              flavorName: conf.staticConfiguration.minFlavor.name,
              memory: formatBytes(conf.staticConfiguration.minFlavor.mem * 1024 * 1024),
              cpus: conf.staticConfiguration.minFlavor.cpus,
              // This is the price of the default flavor in the suggested configuration
              // Note: we don't rely on the `Pricer` here so we can give the formatted price directly
              price: formatCurrency(lang, defaultFlavorPrice * ONE_MONTH),
            }),
          )
          .show();

        const s_edit = $suggested.find('.edit').asEventStream('click');

        s_edit.onValue(function () {
          $suggested.hide();
          $configuration.show();
          $step.find('.next').show();
          $documentation.hide();
        });
      }
    });

    $appCreation.streams['scaling-configuration'].plug(
      s_configuration.map(function (conf) {
        savedConfiguration = {
          configuration: _.clone(conf),
          instanceType: $appCreation.data.instanceType,
        };

        const configuration = _.clone(conf.autoscalability ? conf.dynamicConfiguration : conf.staticConfiguration);
        configuration.minFlavor = configuration.minFlavor.name;
        configuration.maxFlavor = configuration.maxFlavor.name;

        return _.extend($appCreation, {
          data: _.extend($appCreation.data, configuration),
        });
      }),
    );

    $appCreation.streams['scaling-configuration'].onValue(function () {
      /* Do not let it be lazy, not yet */
    });

    $step.find('button.next').click(function () {
      $AppCreation.display('information', $appCreation);
    });
  };

  $AppCreation.display['information'] = function ($step, $appCreation) {
    const ml = isMachineLearning($appCreation.data.instance.type);
    const zones = filterZones($appCreation.zones, ml).map(cleanZoneTags);

    $step.html(
      Templates['AppCreationSP.information']({
        zoneInputState: { type: 'loaded', zones },
        information: $appCreation.data,
        error: $appCreation.appError,
        name: $appCreation.data.githubApp ? $appCreation.data.githubApp.name : null,
      }),
    );

    $appCreation.$form.submit(function (e) {
      e.preventDefault();

      $appCreation.streams['information'].push(
        _.extend($appCreation, {
          data: _.extend($appCreation.data, {
            name: $step.find('input[name="application-name"]').val(),
            description: $step.find('input[name="application-description"]').val(),
            zone: $step.find('cc-zone-input').prop('selected'),
          }),
        }),
      );
    });
  };

  $AppCreation.display['user-information'] = function ($step, $appCreation) {
    var state = $UserInformation({
      selector: $step,
      user: $appCreation.user,
      Console: Console,
    });

    $appCreation.streams['user-information'].plug(
      state.user.map(function (user) {
        return _.extend($appCreation, {
          user: user,
        });
      }),
    );
  };

  $AppCreation.display['github-error'] = function ($step, $appCreation) {
    $step.html(Templates['AppCreationSP.github-error']($appCreation.githubError));
  };

  return $AppCreation;
})();
