const { logout, login } = require('../login-oauth.js');

module.exports = (function () {
  var path4js = require('path4js');

  var sp = new path4js.SimpleStatePoint({
    work: function (req) {
      sp.fireEvent('onload', req);
    },
  });

  sp.on('onload', function () {
    function finishLogout() {
      /* Change url in order to avoid a HTTP redirection to /auth/logout on user login */
      history.pushState(null, null, '/');
      logout();
      login();
    }

    const oauth_token = Console.LoginAs.getTokens().user_oauth_token;

    API.self.tokens._.delete()
      .withParams([oauth_token])
      .send()
      .onValue(() => finishLogout());
  });

  return sp;
})();
