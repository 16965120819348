module.exports = function initialize$Notification() {
  const toaster = document.querySelector('cc-toaster');

  var $n = {};

  $n.displayError = function (error) {
    error = typeof error === 'string' ? JSON.parse(error) : error;

    if (error.stack) {
      console.error(error);
    }

    // Some errors may provide an error code instead of a message
    var message;
    if (error.message) {
      message = {
        message: error.message,
      };
    } else {
      message = error.code;
    }

    message = T(message);

    message = extractMessageFromHTMLError(message) || message;

    toaster.show({
      message: message,
      intent: 'danger',
    });
  };

  $n.displaySuccess = function (success, options) {
    success = typeof success === 'string' ? JSON.parse(success) : success;

    toaster.show({
      message: T(success),
      intent: 'success',
      options,
    });
  };

  /**
   * Expose an event based API for notifications (decoupling FTW)
   * @param {Object} notification
   * @param {"success"|"error"} notification.type
   * @param {String} notification.id? - error ID from API
   * @param {String} notification.key? - key for translation system
   * @param {String} notification.message? - error ID from API
   */
  window.addEventListener('cc-notification', ({ detail: notification }) => {
    const message = notification.key != null ? T(notification.key) : T(notification);
    if (notification.type === 'success') {
      $n.displaySuccess({ message });
    }
    if (notification.type === 'error') {
      $n.displayError({ message });
    }
  });

  /**
   * This should be the preferred method to notify a success or an error.
   */
  window.addEventListener('cc:notify', ({ detail: notification }) => {
    toaster.show(notification);
  });

  /**
   * Tries to extract an error message from an HTML string. Sometimes, after a server error, the server returns an HTML
   * instead of a proper JSON. This method tries to find a message by parsing the HTML and getting the text content of the first <h2> heading.
   * @param html
   * @return {string|null}
   */
  function extractMessageFromHTMLError(html) {
    const parser = new DOMParser();

    // sometimes, message a huge HTML.
    const htmlMessageNode = parser.parseFromString(html, 'text/html').querySelector('h1,h2');

    if (htmlMessageNode) {
      return htmlMessageNode.innerText;
    }
    return null;
  }

  return $n;
};
