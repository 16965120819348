module.exports = (function () {
  var $ = require('jquery');
  var Bacon = require('baconjs');
  var _ = require('lodash');

  var $Modal = function (settings) {
    var state = {
      type: settings.type,
      title: settings.title,
      body: settings.body,
      cancelButtonText: settings.cancelButtonText,
      submitButtonText: settings.submitButtonText,
      submitButtonClass: settings.submitButtonClass,
      textCheck: settings.textCheck,
      s_confirm: null,
      s_cancel: null,
      $modal: null,
      handleOutaClick: _.has(settings, 'handleOutaClick') ? settings.handleOutaClick : true,
      customClasses: settings.customClasses || [],

      Templates: settings.Templates,
    };

    return $Modal.init(state);
  };

  $Modal.init = function (state) {
    var $modal = (state.$modal = $(
      state.Templates['modal']({
        body: state.body,
        title: state.title,
        type: state.type,
        cancelButtonText: state.cancelButtonText,
        submitButtonText: state.submitButtonText,
        submitButtonClass: state.submitButtonClass,
        input: state.textCheck ? true : false,
        customClasses: state.customClasses,
      }),
    ));

    var s_click = $modal.find('form').asEventStream('submit').doAction('.preventDefault');

    var s_confirm = s_click.flatMapLatest(function (e) {
      $modal.find('[name="user-input"]').removeAttr('data-status');
      if (state.textCheck) {
        if (state.textCheck(e.target['user-input'].value.trim())) {
          return Bacon.once(e);
        } else {
          $modal.find('[name="user-input"]').attr('data-status', 'error');
          return Bacon.never();
        }
      } else {
        return Bacon.once(e);
      }
    });

    var s_cancel = $modal
      .find('button.cancel, a')
      .asEventStream('click')
      .filter((e) => {
        if (e.target && e.target.target === '_blank') {
          return false;
        }
        return true;
      });

    var s_outClick = !state.handleOutaClick
      ? Bacon.never()
      : $modal.asEventStream('click').filter(function (e) {
          return e.target === $modal.get(0);
        });

    var s_esc = $(document)
      .asEventStream('keydown')
      .filter(function (e) {
        return e.keyCode === 27; // ESC
      });

    // Specific streams
    var s_disablePayment = $modal.find('button.disable-payment').asEventStream('click').map(null);

    $Modal.removeAll();
    $modal.appendTo('body');

    var s_abort = Bacon.mergeAll(s_cancel, s_outClick, s_esc).first();

    s_abort.onValue(_.partial($Modal.remove, state));

    state.s_confirm = s_confirm.merge(s_disablePayment).takeUntil(s_abort);
    state.s_cancel = s_abort;

    state.s_confirm.onValue(function () {
      $modal.find('button.cancel').attr('disabled', 'disabled');
    });

    $modal.find('input[type="text"]').focus();

    return state;
  };

  $Modal.remove = function (state) {
    state.$modal.remove();
  };

  $Modal.removeAll = function () {
    $('#modal').remove();
  };

  $Modal.loadStream = function (state, stream) {
    var $submit = state.$modal.find('[type="submit"]');
    $submit.loadStream(stream);
    stream.onValue(_.partial($Modal.remove, state));
    stream.onError(function () {
      $submit.removeClass('is-loading').removeAttr('disabled');
      state.$modal.find('button.cancel').removeAttr('disabled');
    });
  };

  return $Modal;
})();
