module.exports = (function () {
  var _ = require('lodash');
  var getAppTechno = require('../../helpers/getAppTechno.js');

  var $AddonAppAccess = function (settings) {
    var state = {
      apps: settings.apps,
      addon: settings.addon,
      availableAddons: ['mysql', 'postgresql'],

      Templates: settings.Templates,
    };

    return _.find(state.availableAddons, function (availableAddon) {
      return availableAddon === state.addon.provider.name.toLowerCase();
    })
      ? $AddonAppAccess.init(state)
      : null;
  };

  $AddonAppAccess.init = function (state) {
    return _.chain(state.apps)
      .uniq(function (app) {
        return app.instance.type.toLowerCase();
      })
      .reduce(function (html, app) {
        var appType = getAppTechno(app.instance.type);
        var tpl = state.Templates[appType.realName + '_' + state.addon.provider.name.toLowerCase() + '_addon'];
        html += tpl ? tpl() : '';
        return html;
      }, '')
      .value();
  };

  return $AddonAppAccess;
})();
