module.exports = (function () {
  var $UniformisedDateTime = require('../../src/modules/uniformisedDateTime/main.js');
  var $Modal = require('../../src/modules/modals/main.js');
  var Bacon = require('baconjs');
  var $ = require('jquery');
  var _ = require('lodash');
  var Templates = require('../../generated/templates.js');
  var T = require('../models/technical/translation.js');
  var $Logs = require('../modules/logs/main.js');
  require('@clevercloud/components/dist/cc-datetime-relative.js');
  require('@clevercloud/components/dist/cc-block.js');

  var sp = new (require('./AbstractSP.js'))({
    name: 'AppDeploymentsSP',
  });

  var DEPLOYMENTS_LIMIT = 10;
  var OFFSET_BASE = 0;
  var LIMIT_BASE = DEPLOYMENTS_LIMIT;

  var orgaId;
  var appId;

  sp.getStreams = function (req) {
    orgaId = req.params.oid;
    appId = req.params.appId;

    var s_app = SummaryProxy.fetchOrgaOnce(orgaId).flatMapLatest(function (owner) {
      var app = _.find(owner.applications, function (app) {
        return app.id === appId;
      });

      if (app) {
        return orgaId
          ? API.organisations._.applications._.get().withParams([orgaId, appId]).send()
          : API.self.applications._.get().withParams([appId]).send();
      } else {
        return Bacon.constant(new Bacon.Error({ message: T('APPLICATION_NOT_FOUND') }));
      }
    });

    var s_vhosts = VhostsProxy.fetch(orgaId, appId).map(_.first);

    var s_lastDeployments = SummaryProxy.fetchOrgaOnce(orgaId).flatMapLatest(function (owner) {
      return Bacon.mergeAll(
        sp.fetchDeployments(orgaId, appId, OFFSET_BASE, DEPLOYMENTS_LIMIT),
        DeploymentProxy.fetch(owner.id, appId),
      );
    });

    var s_members = req.params.oid
      ? API.organisations._.members.get().withParams([req.params.oid]).send()
      : API.self
          .get()
          .send()
          .map((user) => {
            return [
              {
                job: null,
                member: { id: user.id, email: user.email, name: user.name },
                role: 'ADMIN',
              },
            ];
          });

    var s_retention = $Logs.getRetentionDays();

    return {
      s_app: s_app,
      s_vhosts: s_vhosts,
      s_lastDeployments: s_lastDeployments,
      s_members: s_members,
      s_retention: s_retention,
    };
  };

  sp.on('onload', function (req, $container, streams) {
    Bacon.onValues(
      streams.s_app,
      streams.s_vhosts,
      streams.s_lastDeployments,
      streams.s_members,
      streams.s_retention,
      function (app, vhosts, lastDeployments, members, retention) {
        sp.displayDeployments(
          $container,
          app,
          vhosts,
          lastDeployments,
          members,
          OFFSET_BASE,
          DEPLOYMENTS_LIMIT,
          retention,
        );
      },
    );
  });

  sp.displayDeploymentCancellation = function ($container, app, deployment) {
    var $cancellationContainer = $container.find('.cancel-deployment');
    if (deployment.state === 'WIP' && deployment.action === 'DEPLOY') {
      $cancellationContainer.html(Templates['AppDeploymentsSP.cancel-deployment']());

      var $abort = $cancellationContainer.find('.abort');
      var s_abortion = $abort.asEventStream('click').flatMapLatest(function () {
        if (Console.LoginAs.isLoggedAsAdmin()) {
          return Bacon.once(new Bacon.Error(new Error("You can't cancel a deployment using the LoginAs feature.")));
        }

        var s_abort = orgaId
          ? API.organisations._.applications._.deployments._.instances
              .delete()
              .withParams([orgaId, appId, deployment.id])
              .send()
          : API.self.applications._.deployments._.instances.delete().withParams([appId, deployment.id]).send();
        $abort.loadStream(s_abort);
        return s_abort;
      });

      s_abortion.onError(function (err) {
        if (err.id === 4101) {
          $Notification.displayError({
            message: T('console.app-deployments.deployment-is-not-wip'),
          });
        } else {
          $Notification.displayError(err);
        }
      });
      s_abortion.onValue(function () {
        $cancellationContainer.empty();
      });
    } else {
      $cancellationContainer.empty();
    }
  };

  sp.displayDeployments = function ($container, app, vhost, deployments, members, offset, limit, logs_retention_days) {
    if (deployments.length > 0) {
      sp.displayDeploymentCancellation($container, app, _.head(deployments));

      var groupDeployments = _.chain(deployments)
        .sort(function (a, b) {
          return b.date - a.date;
        })
        .map(function (deployment) {
          const author = deployment.author.id
            ? members.find((member) => member.member.id === deployment.author.id)
            : {};

          return _.extend({}, deployment, {
            date: $UniformisedDateTime.getWithSeconds(deployment.date),
            timestamp: deployment.date,
            author: author,
          });
        })
        .value();

      var isGITApp = app.deployment.type.toLowerCase() === 'git' ? true : false;

      var lastCommit;

      if (groupDeployments.length < 0 && isGITApp) {
        var lastSuccessDeploy = _.find(groupDeployments, function (d) {
          return d.state === 'OK' ? d.commit : false;
        });
        if (lastSuccessDeploy && lastSuccessDeploy.commit) {
          lastCommit = lastSuccessDeploy.commit;
        } else {
          lastCommit = null;
        }
      } else {
        lastCommit = null;
      }

      var sevenDaysAgo = Date.now() - 3600 * 24 * logs_retention_days * 1000;

      var $deployments = $container.find('.app-activity').html(
        Templates['AppDeploymentsSP.logs']({
          orgaId: orgaId,
          appId: app.id,
          type: app.deployment.type,
          vhost: vhost,
          deployments: groupDeployments,
          lastCommit: lastCommit,
          isGITApp: isGITApp,
          sevenDaysAgo: sevenDaysAgo,
        }),
      );

      if (isGITApp) {
        sp.redeployOldCommit($deployments, lastCommit);
      }
      sp.scroll($container, app, vhost, deployments, members, offset, limit, logs_retention_days);
    } else {
      $container.find('.app-activity').html(Templates['AppDeploymentsSP.no-deployments']());
    }
  };

  sp.redeployOldCommit = function ($deployments, lastCommit) {
    var s_deployButton = $deployments
      .find('a[data-commitid]')
      .asEventStream('click')
      .flatMapLatest(function (e) {
        var commit = $(e.currentTarget).attr('data-commitid');
        if (lastCommit && lastCommit !== commit) {
          var $modal = $Modal({
            type: 'confirmation',
            title: T('console.app-deployments.redeploy-modal-title', {
              commit: commit.substr(0, 8),
            }),
            body: T('console.app-deployments.redeploy-modal-body'),
            submitButtonText: T('console.app-deployments.redeploy-modal-submit'),
            Templates: Templates,
          });

          var s_confirm = $modal.s_confirm;

          s_confirm.onValue(function () {
            $Modal.remove($modal);
          });

          return s_confirm.map(e);
        } else {
          return Bacon.once(e);
        }
      })
      .flatMapLatest(function (e) {
        $('.tipsy').fadeOut();

        var commit = $(e.currentTarget).attr('data-commitid');

        var s_deploy = (
          orgaId
            ? API.organisations._.applications._.instances.post().withParams([orgaId, appId])
            : API.self.applications._.instances.post().withParams([appId])
        )
          .withQuery({ commit: commit })
          .send();

        var $parent = $(e.currentTarget).parent();
        $parent.html(Templates['AppDeploymentSP.deploying-loader']()).find('button').loadStream(s_deploy);

        return s_deploy.map($parent);
      });

    s_deployButton.onValue(function ($parent) {
      var $actions = $parent.html(
        Templates['headbar.readlogs-button']({
          orgaId: orgaId,
          appId: appId,
        }),
      );
    });
    s_deployButton.onError($Notification.displayError);
  };

  sp.fetchDeployments = function (orgaId, appId, offset, limit) {
    var query = {};

    if (offset) {
      query = _.extend({}, query, {
        offset: offset,
      });
    }

    if (limit) {
      query = _.extend({}, query, {
        limit: limit,
      });
    }

    return (
      orgaId
        ? API.organisations._.applications._.deployments.get().withParams([orgaId, appId])
        : API.self.applications._.deployments.get().withParams([appId])
    )
      .withQuery(query)
      .send()
      .flatMapError(function () {
        return sp.fetchDeployments(orgaId, appId, limit, offset);
      })
      .map((deployments) => _.chain(deployments).sortBy('id').reverse().value());
  };

  sp.scroll = function ($container, app, vhost, deployments, members, offset, limit, logs_retention_days) {
    var $mainContainer = $(Console.$panes.slidingPanes).find('main.l-container');
    var nextOffset = offset + DEPLOYMENTS_LIMIT;
    var $more = $container.find('.activity-more-loader');
    var $nomore = $container.find('.activity-no-more');

    var s_scroll = $mainContainer
      .off('scroll')
      .asEventStream('scroll')
      .takeUntil(Console.s_requestUnload)
      .filter(function (e) {
        return $mainContainer.scrollTop() + $mainContainer.height() + 100 >= $container.height();
      })
      .first();

    s_scroll.onValue(function () {
      $more.show();
    });

    var s_fetch = s_scroll.flatMapLatest(function () {
      return sp.fetchDeployments(orgaId, appId, nextOffset, DEPLOYMENTS_LIMIT);
    });

    s_fetch
      .filter(function (oldDeployments) {
        return oldDeployments.length === 0;
      })
      .onValue(function () {
        $more.hide();
        $nomore.show();
      });

    s_fetch
      .filter(function (oldDeployments) {
        return oldDeployments.length > 0;
      })
      .map(function (oldDeployments) {
        return [].concat(deployments).concat(oldDeployments);
      })
      .onValue(function (deploys) {
        sp.displayDeployments(
          $container,
          app,
          vhost,
          deploys,
          members,
          nextOffset,
          DEPLOYMENTS_LIMIT,
          logs_retention_days,
        );
        $more.hide();
      });
  };

  return sp;
})();
