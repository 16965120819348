module.exports = (function () {
  var $ = require('jquery');
  var _ = require('lodash');
  var Bacon = require('baconjs');

  var Templates = require('../../../generated/templates.js');

  var $SSHKeys = function (settings) {
    var state = {
      container: $(settings.selector),
    };

    $SSHKeys.init(state);

    return state;
  };

  $SSHKeys.init = function (state) {
    const s_keys = $SSHKeys.getKeys();

    const s_githubKeys = $SSHKeys.getGithubKeys();

    s_keys.onValue(_.partial($SSHKeys.displayKeys, state));
    s_keys.onError(function (error) {
      var errorFields = _.reduce(
        error.fields,
        function (errors, val, key) {
          var message = key + ': ' + val;
          return errors.concat([message]);
        },
        [],
      );

      $Notification.displayError(
        _.extend({}, error, {
          message: error.message + '. ' + errorFields.join(','),
        }),
      );
    });

    s_githubKeys.onValue(_.partial($SSHKeys.displayGithubKeys, state));
    s_githubKeys.onError(function (error) {
      if (error.id !== 1602) {
        $Notification.displayError(error);
      }
    });

    API.self.get().send().map('.email').onValue(_.partial($SSHKeys.showDocumentation, state));
  };

  $SSHKeys.getKeys = function () {
    return API.self.keys.get().send();
  };

  $SSHKeys.getGithubKeys = function () {
    return API.github.keys.get().send();
  };

  $SSHKeys.addKey = function (key) {
    return API.self.keys._.put()
      .withParams([encodeURIComponent(key.name)])
      .send(JSON.stringify(key.content));
  };

  $SSHKeys.removeKey = function (keyName) {
    return API.self.keys._.delete()
      .withParams([encodeURIComponent(keyName)])
      .send();
  };

  $SSHKeys.displayKeys = function (state, keys) {
    keys = _.sortBy(keys, 'name');

    var $keys = _.reduce(
      keys,
      function ($keys, key) {
        return $keys.append($SSHKeys.displayKey(state, key));
      },
      state.container.find('.ssh-keys').empty(),
    );

    $keys.append($SSHKeys.displayKeyForm(state));
  };

  $SSHKeys.displayKey = function (state, key) {
    var $key = $(Templates['ssh-keys.ssh-key'](key));
    var $button = $key.find('button.remove');

    var s_click = $button.asEventStream('click');
    var s_keyRemoved = s_click.flatMapLatest(function () {
      var s_keyRemoved = $SSHKeys.removeKey(key.name);

      $button.loadStream(s_keyRemoved.mapError().take(1), 1000);

      return s_keyRemoved;
    });

    s_keyRemoved.delay(1000).onValue(function () {
      $SSHKeys.init(state);
    });

    return $key;
  };

  $SSHKeys.displayKeyForm = function (state) {
    var $line = $(Templates['ssh-keys.ssh-key-new']());
    var $form = $line.find('form');

    var s_submit = $form.asEventStream('submit').doAction('.preventDefault');
    var s_keyAdded = s_submit.flatMapLatest(function () {
      state.container.find("[data-status='error']").removeAttr('data-status');

      var form = $form.get(0);
      var nameInput = form.name;
      var sshInput = form.content;

      nameInput.removeAttribute('data-status');
      sshInput.removeAttribute('data-status');

      var key = {
        name: nameInput.value.trim(),
        content: sshInput.value.replace(/\n/g, '').trim(),
      };

      if (key.name === '') {
        $SSHKeys.missingContent(nameInput);
        return Bacon.never();
      } else if (key.content === '') {
        $SSHKeys.missingContent(sshInput);
        return Bacon.never();
      } else {
        var s_keyAdded = $SSHKeys.addKey(key);

        $form.find("[type='submit']").loadStream(s_keyAdded.mapError().take(1), 1000);

        return s_keyAdded;
      }
    });

    s_keyAdded.onValue(function () {
      $SSHKeys.init(state);
    });

    return $line;
  };

  $SSHKeys.displayGithubKeys = function (state, keys) {
    const $container = state.container.find('.github-bloc').show().find('.github-ssh-keys').empty();
    if (keys.length > 0) {
      _.reduce(
        keys,
        function ($keys, key) {
          return $keys.append($SSHKeys.displayGithubKey(state, key));
        },
        $container,
      );
    } else {
      $container.append(Templates['ssh-keys.github-no-keys']);
    }
  };

  $SSHKeys.displayGithubKey = function (state, key) {
    var $key = $(Templates['ssh-keys.github-key'](key));
    var $button = $key.find('.add');

    var s_click = $button.asEventStream('click');
    var s_key = s_click.map(function () {
      return {
        name: key.name,
        content: key.key,
      };
    });

    s_key
      .flatMapLatest(function (key) {
        var s_keyAdded = $SSHKeys.addKey(key);

        $button.loadStream(s_keyAdded.mapError().take(1), 1000);
        return s_keyAdded;
      })
      .onValue(() => $SSHKeys.init(state));

    return $key;
  };

  $SSHKeys.showDocumentation = function (state, userEmail) {
    state.container
      .find('.documentation.ssh-keys-doc')
      .show()
      .html(
        Templates['ssh-keys.documentation']({
          userEmail: userEmail,
        }),
      );
  };

  $SSHKeys.missingContent = function (input) {
    input.setAttribute('data-status', 'error');
  };

  return $SSHKeys;
})();
