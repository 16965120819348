var Bacon = require('baconjs');
var _ = require('lodash');

module.exports = function initializeVhostsProxy(settings) {
  var VhostsProxy = {};

  var API = settings.API;

  var b_fetchers = {};
  var s_fetchers = {};

  var getOwnerKey = function (orgaId) {
    return !orgaId || orgaId.indexOf('user_') > -1 ? 'user' : orgaId;
  };

  var fetchVhosts = function (owner, appId) {
    var s_vhosts =
      owner !== 'user'
        ? API.organisations._.applications._.vhosts.get().withParams([owner, appId]).send()
        : API.self.applications._.vhosts.get().withParams([appId]).send();

    var s_favourite = (
      owner !== 'user'
        ? API.organisations._.applications._.vhosts.favourite.get().withParams([owner, appId]).send()
        : API.self.applications._.vhosts.favourite.get().withParams([appId]).send()
    ).mapError({ fqdn: null });

    return Bacon.combineWith(s_vhosts, s_favourite, function (vhosts, favourite) {
      return _.map(vhosts, function (vhost) {
        return _.extend({}, vhost, {
          favourite: vhost.fqdn === favourite.fqdn,
        });
      });
    }).flatMapError(function (err) {
      if (err.id === 2001) {
        // Not connected
        return Bacon.once(new Bacon.Error(err));
      } else {
        return Bacon.later(5000).flatMapLatest(function () {
          return fetchVhosts(owner, appId);
        });
      }
    });
  };

  VhostsProxy.fetch = function (orgaId, appId) {
    var owner = getOwnerKey(orgaId);

    if (!b_fetchers[owner]) {
      b_fetchers[owner] = {};
      s_fetchers[owner] = {};
    }

    if (!b_fetchers[owner][appId]) {
      b_fetchers[owner][appId] = new Bacon.Bus();
      s_fetchers[owner][appId] = b_fetchers[owner][appId].toProperty();

      b_fetchers[owner][appId].plug(fetchVhosts(owner, appId));
    }

    return s_fetchers[owner][appId].first();
  };

  return VhostsProxy;
};
