module.exports = (() => {
  const $ = require('jquery');
  const _ = require('lodash');
  const Bacon = require('baconjs');

  const LOADABLE_LIBRARIES = [
    {
      name: 'stripe-client',
      onLoad: function () {
        this.reference = window.Stripe(Console.configuration.STRIPE_PUBLISHABLE_KEY);
      },
      getReference: function () {
        return this.reference;
      },
      url: 'https://js.stripe.com/v3/',
    },
  ];
  const LOADED_LIBRARIES = [];

  const loadLibrary = (library) => {
    console.log(`Loading library ${library}`, LOADED_LIBRARIES);
    const lib = _.find(LOADED_LIBRARIES, (lib) => {
      return lib.name === library;
    });

    if (lib) {
      return lib.library.map((l) => l.getReference());
    } else {
      console.log(`Library ${library} not yet loaded, loading...`);
      const loaded = fetchLibrary(library).map((lib) => {
        if (lib.onLoad) {
          lib.onLoad();
        }
        return lib;
      });

      LOADED_LIBRARIES.push({
        name: library,
        library: loaded,
      });

      return loaded.map((lib) => lib.getReference()).toProperty();
    }
  };

  const fetchLibrary = (library) => {
    const loadableLibrary = _.find(LOADABLE_LIBRARIES, (lib) => lib.name === library);

    if (!loadableLibrary) {
      throw new Error(`Library "${library}" is not defined in the LOADABLE_LIBRARIES object`);
    }

    return Bacon.fromBinder((sink) => {
      $.getScript(loadableLibrary.url)
        .done(() => {
          sink(loadableLibrary);
          sink(new Bacon.End());
        })
        .fail((jQXHR, settings, exception) => {
          sink(new Bacon.Error(exception));
          sink(new Bacon.End());
        });
    }).toProperty();
  };

  return loadLibrary;
})();
