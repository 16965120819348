module.exports = (function () {
  var Bacon = require('baconjs');
  var _ = require('lodash');
  var md5 = require('md5');
  var path4js = require('path4js');
  var Yajas = require('yajas');

  var $UserInformation = require('../modules/user-information/view.js');
  var $Avatar = require('../modules/avatar/view.js');
  var T = require('../models/technical/translation.js');
  require('@clevercloud/components/dist/cc-block.js');

  var sp = new (require('./AbstractSP.js'))({
    name: 'UserInformationSP',
  });

  sp.getStreams = function (req) {
    var s_user = API.self.get().send();

    var s_githubUsername = s_user
      .flatMapLatest(function (user) {
        return user.oauthApps.indexOf('github') < 0 ? Bacon.once() : API.github.username.get().send();
      })
      .toProperty();

    var s_githubAvatar = s_githubUsername
      .flatMapLatest(function (githubUsername) {
        return !githubUsername
          ? Bacon.once()
          : Bacon.fromPromise($.get('https://api.github.com/users/' + githubUsername)).map(function (user) {
              return user.avatar_url;
            });
      })
      .toProperty();

    var s_gravatarAvatar = s_user
      .flatMapLatest(function (user) {
        var url = 'https://www.gravatar.com/avatar/' + md5(user.email) + '.jpg?d=404'; // request to have a 404 if no image is found
        var img = new Image();

        var s_imgSuccess = Bacon.fromEventTarget(img, 'load').map(url);
        var s_imgFailure = Bacon.fromEventTarget(img, 'error').map();

        img.src = url;

        return Bacon.mergeAll(s_imgSuccess, s_imgFailure);
      })
      .toProperty();

    return {
      s_user: s_user,
      s_githubUsername: s_githubUsername,
      s_githubAvatar: s_githubAvatar,
      s_gravatarAvatar: s_gravatarAvatar,
    };
  };

  sp.on('onload', function (req, $container, streams) {
    Bacon.onValues(
      streams.s_user,
      streams.s_githubUsername,
      streams.s_githubAvatar,
      streams.s_gravatarAvatar,
      _.partial(sp.displayInformation, $container, req),
    );
  });

  sp.displayInformation = function ($container, req, user, githubUsername, githubAvatar, gravatarAvatar) {
    var informations = $UserInformation({
      selector: $container.find('form.information'),
      user: _.extend({}, user, {
        githubUsername: githubUsername || '',
      }),
      languages: T.getAvailableLanguages(),
      Console: Console,
    });

    var avatar = $Avatar({
      container: $container.find('.avatar-container'),
      owner: user,
      githubAvatar: githubAvatar,
      gravatarAvatar: gravatarAvatar,
    });

    avatar.s_state
      .flatMapLatest(function () {
        return SummaryProxy.updateOrganisation();
      })
      .onValue(function () {
        $Notification.displaySuccess({
          message: T('USER_PIC_UPDATED'),
        });
      });

    avatar.s_state.onError($Notification.displayError);

    informations.user.onValue(function () {
      if (req.search.redirect) {
        Yajas.path4js.launchPath(path4js.Request.fromUri(decodeURIComponent(req.search.redirect)));
      }
    });
  };

  return sp;
})();
