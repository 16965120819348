import { API_HOST, API_CONSUMER_KEY, API_CONSUMER_SECRET, PARTNER_ID } from './configuration.js';
import { fetchAccessToken, fetchRequestToken } from '@clevercloud/client/esm/login.js';
import { prefixUrl } from '@clevercloud/client/esm/prefix-url.js';
import { request } from '@clevercloud/client/esm/request.fetch.js';

function delay(delayInMs) {
  return function (param) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(param), delayInMs);
    });
  };
}

export function login(oauthCallback = location.href) {
  const params = {
    consumerKey: API_CONSUMER_KEY,
    consumerSecret: API_CONSUMER_SECRET,
    oauthCallback,
  };

  fetchRequestToken(params)
    .then(prefixUrl(API_HOST))
    .then(request)
    .then((data) => {
      window.localStorage.setItem('consumer_oauth_token', data.oauth_token);
      window.localStorage.setItem('consumer_oauth_token_secret', data.oauth_token_secret);
      return data;
    })
    .then(delay(1000))
    // A delay(1000) has been added here because for some reason, firefox doesn't have the time to
    // store the localStorage elements if the redirection happens right after
    // which means that our web clients will have a login loop.
    .then((data) => {
      const url = new URL(API_HOST);
      url.pathname = '/v2/oauth/authorize';
      url.searchParams.set('oauth_token', data.oauth_token);
      url.searchParams.set('partner_id', PARTNER_ID);
      window.location = url.toString();
    });
}

export function getAccessToken({ oauth_token, oauth_verifier, consumer_oauth_token_secret }) {
  const params = {
    consumerKey: API_CONSUMER_KEY,
    consumerSecret: API_CONSUMER_SECRET,
    oauthToken: oauth_token,
    oauthVerifier: oauth_verifier,
    tokenSecret: consumer_oauth_token_secret,
  };

  return fetchAccessToken(params)
    .then(prefixUrl(API_HOST))
    .then(request)
    .then((accessTokens) => {
      window.localStorage.setItem('user_oauth_token', accessTokens.oauth_token);
      window.localStorage.setItem('user_oauth_token_secret', accessTokens.oauth_token_secret);
      return accessTokens;
    });
}

export function logout() {
  window.localStorage.removeItem('consumer_oauth_token');
  window.localStorage.removeItem('consumer_oauth_token_secret');
  window.localStorage.removeItem('user_oauth_token');
  window.localStorage.removeItem('user_oauth_token_secret');
}
