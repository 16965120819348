'use strict';

const _ = require('lodash');
const instanceState = require('./instance-state');

const CLEVER_ADDON_PROVIDERS = [
  'addon-matomo',
  'addon-pulsar',
  'artifactory-addon',
  'cellar-addon',
  'config-provider',
  'couchbase-addon',
  'es-addon',
  'es-addon-legacy',
  'fs-bucket',
  'jenkins',
  'kafka',
  'keycloak',
  'kv',
  'metabase',
  'mongodb-addon',
  'mysql-addon',
  'neo4j-addon',
  'otoroshi',
  'postgresql-addon',
  'redis-addon',
  'socks-addon',
  'sprint0',
];

function getInfrastructureStatus(addon, instances) {
  const instance = _.find(instances, (instance) => instance.state === 'UP');

  if (!instance) {
    return 'starting';
  }

  if (instance && instanceState.deploying(instance)) {
    return 'starting';
  }

  if (!instance.ip) {
    return 'starting';
  } else {
    return 'running';
  }
}

function getAddonStatus(addon, instances) {
  const providerId = addon.provider.id.toLowerCase();
  const planName = addon.plan.name.toLowerCase();

  if (!CLEVER_ADDON_PROVIDERS.includes(providerId)) {
    return null;
  }

  const noMigrationNeededAddons = [
    'addon-matomo',
    'addon-pulsar',
    'algoliasearch',
    'artifactory-addon',
    'cellar-addon',
    'config-provider',
    'fs-bucket',
    'keycloak',
    'kv',
    'metabase',
    'otoroshi',
    'socks-addon',
    'sprint0',
  ];

  if (providerId === 'postgresql-addon') {
    if (planName === 'dev' || planName === 's') {
      return 'running';
    }
  } else if (providerId === 'mysql-addon') {
    if (planName === 'dev' || planName === 's') {
      return 'running';
    }
  } else if (providerId === 'mongodb-addon') {
    if (planName === 'peanut' || planName === 'dev') {
      return 'running';
    }
  } else if (noMigrationNeededAddons.indexOf(providerId) >= 0) {
    return 'running';
  }

  if ((!instances || instances.length === 0) && addon.creationDate > new Date().getTime() - 600e3) {
    return 'starting';
  } else {
    return getInfrastructureStatus(addon, instances);
  }
}

module.exports = {
  getAddonStatus,
};
