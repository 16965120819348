export function getFeedbackLink(productId, requestPath) {
  switch (productId) {
    case 'addon-matomo': {
      return 'https://github.com/CleverCloud/Community/discussions/categories/matomo';
    }
    case 'azimutt' : {
      return 'https://github.com/CleverCloud/Community/discussions/categories/dbaas';
    }
    case 'keycloak': {
      return 'https://github.com/CleverCloud/Community/discussions/categories/keycloak';
    }
    case 'kv' : {
      return 'https://github.com/CleverCloud/Community/discussions/categories/materia';
    }
    case 'metabase': {
      return 'https://github.com/CleverCloud/Community/discussions/categories/metabase';
    }
    case 'redis-addon': {
      if (requestPath.endsWith('/redis-explorer')) {
        return 'https://github.com/CleverCloud/Community/discussions/categories/kv-explorer';
      }
    }
  }
  return null;
}
