const instanceState = require('../../helpers/instance-state');

module.exports = (() => {
  const SUPPORTED_ADDONS = [
    {
      providerId: 'postgresql-addon',
      unsupportedPlans: ['DEV', 'S'],
      supportSince: new Date('2018-05-25'),
    },
    {
      providerId: 'mongodb-addon',
      unsupportedPlans: ['PEANUT'],
      supportSince: new Date('2018-09-24'),
    },
    {
      providerId: 'redis-addon',
      unsupportedPlans: [],
      supportSince: new Date('2018-12-12'),
    },
    {
      providerId: 'mysql-addon',
      unsupportedPlans: ['DEV', 'S'],
      supportSince: new Date('2019-02-26'),
    },
    {
      providerId: 'es-addon',
      unsupportedPlans: [],
      supportSince: new Date('2019-10-31'),
    },
    {
      providerId: 'jenkins',
      unsupportedPlans: [],
      supportSince: new Date('2021-09-28'),
    },
  ];

  function addonMetrics(addon) {
    const supported = SUPPORTED_ADDONS.find((a) => a.providerId === addon.provider.id);

    if (!supported) {
      return 'ADDON_NOT_SUPPORTED';
    }

    if (!addon.plan) {
      console.warn('missing .plan in addon structure');
      return 'ADDON_NOT_SUPPORTED';
    }

    const supportedPlan = supported.unsupportedPlans.indexOf(addon.plan.name.toUpperCase());

    if (supportedPlan > -1) {
      return 'ADDON_PLAN_NOT_SUPPORTED';
    }

    const UPInstance = addon.instances.find((instance) => instanceState.deployingOrUp(instance));

    if (!UPInstance) {
      console.warn('No instance available found');
      return 'ADDON_IS_TOO_OLD';
    }

    if (supported.supportSince.getTime() < UPInstance.creationDate) {
      return 'ADDON_IS_SUPPORTED';
    } else {
      return 'ADDON_IS_TOO_OLD';
    }
  }

  return { addonMetrics, SUPPORTED_ADDONS };
})();
