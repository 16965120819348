module.exports = (() => {
  let $ = require('jquery');
  let Bacon = require('baconjs');
  let Yajas = require('yajas');
  let path4js = require('path4js');

  let T = require('../models/technical/translation.js');

  let sp = new (require('./AbstractSP.js'))({
    name: 'AddonProviderCreationSP',
  });

  sp.on('onload', (req, $container) => {
    let $form = $container.find('form');
    let orgaId = req.params.oid;

    let s_create = $form
      .asEventStream('submit')
      .doAction('.preventDefault')
      .map((e) => _.first($form.find('[name="addon-manifest"]').get(0).files))
      .flatMapLatest((file) => {
        return Bacon.fromBinder((sink) => {
          let reader = new FileReader();
          reader.onloadend = function () {
            if (reader.error) {
              sink(new Bacon.Error(new Error(reader.error)));
            } else {
              sink(reader.result);
            }
            sink(new Bacon.End());
          };
          reader.readAsText(file);

          return function () {};
        });
      })
      .flatMapLatest((manifest) => {
        try {
          JSON.parse(manifest);
          return Bacon.once(manifest);
        } catch (e) {
          return Bacon.once(new Bacon.Error(new Error('ENOTJSON')));
        }
      })
      .flatMapLatest((manifest) => {
        let s_call = API.organisations._.addonproviders
          .post()
          .withParams([orgaId])
          .send(manifest)
          .flatMapLatest((addonprovider) => SummaryProxy.update().map(addonprovider));

        $form.find('button[type="submit"]').loadStream(s_call);
        return s_call;
      });

    s_create.onValue((addonprovider) => {
      $Notification.displaySuccess({ message: T('console.addonproviders.created') });

      Yajas.path4js.launchPath(path4js.Request.fromUri('/organisations/' + orgaId + '/providers/' + addonprovider.id));
    });

    s_create.onError((e) => {
      if (e.message === 'ENOTJSON') {
        $Notification.displayError({ message: T('console.addonproviders.create-not-json') });
      } else {
        $Notification.displayError({ message: e.message });
      }
    });
  });

  return sp;
})();
