module.exports = (() => {
  const QueryString = require('../../helpers/querystring.js');
  const _ = require('lodash');
  const { login } = require('../../login-oauth.js');

  let state = {
    loggedAs: null,
    tokens: {},
  };

  let LoginAs = () => {
    const searchParams = _.extend({}, QueryString.parse(window.location.search, true), {
      consumer_oauth_token_secret: localStorage.consumer_oauth_token_secret,
    });

    if (searchParams && searchParams.user_oauth_token && searchParams.user_oauth_token_secret) {
      state.loggedAs = 'admin';
      state.tokens = {
        user_oauth_token: searchParams.user_oauth_token,
        user_oauth_token_secret: searchParams.user_oauth_token_secret,
      };
    } else if (localStorage.user_oauth_token && localStorage.user_oauth_token_secret) {
      state.loggedAs = 'user';
      state.tokens = {
        user_oauth_token: localStorage.user_oauth_token,
        user_oauth_token_secret: localStorage.user_oauth_token_secret,
      };
    } else if (searchParams && searchParams.oauth_token && searchParams.oauth_token_secret) {
      // clevergrid direct login
      state.loggedAs = 'user';
      state.tokens = {
        user_oauth_token: searchParams.oauth_token,
        user_oauth_token_secret: searchParams.oauth_token_secret,
      };

      if (!LoginAs.isPersisted()) {
        LoginAs.persist();
      }
    }
  };

  LoginAs.isLoggedAsAdmin = () => {
    return state.loggedAs === 'admin';
  };

  LoginAs.isLoggedAsUser = () => {
    return state.loggedAs === 'user';
  };

  LoginAs.isLogged = () => {
    return LoginAs.isLoggedAsUser() || LoginAs.isLoggedAsAdmin();
  };

  LoginAs.getTokens = () => {
    return state.tokens;
  };

  LoginAs.setTokens = (tokens) => {
    state.tokens = _.extend({}, state.tokens, {
      user_oauth_token: tokens.user_oauth_token,
      user_oauth_token_secret: tokens.user_oauth_token_secret,
    });

    return LoginAs.getTokens();
  };

  LoginAs.isPersisted = () => {
    return _.has(localStorage, 'user_oauth_token') && _.has(localStorage, 'user_oauth_token_secret');
  };

  LoginAs.persist = () => {
    if (!LoginAs.isLogged()) {
      return null;
    }

    const tokens = LoginAs.getTokens();

    localStorage.setItem('user_oauth_token', tokens.user_oauth_token);
    localStorage.setItem('user_oauth_token_secret', tokens.user_oauth_token_secret);
  };

  LoginAs.clear = () => {
    localStorage.clear();
    state.tokens = {};
  };

  LoginAs.login = () => {
    localStorage.clear();
    var search = QueryString.removeOAuthParameters(window.location.search);
    return login(window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + search);
  };

  return LoginAs;
})();
