require('@clevercloud/components/dist/cc-icon.js');

var $ = require('jquery');
var _ = require('lodash');
var Bacon = require('baconjs');
var moment = require('moment');
var Role = require('../../models/business/role.js');
var Templates = require('../../../generated/templates.js');
var DomEvents = require('../dom-events/main.es6.js');
var GlobalMessage = require('../global-message/main.es6.js');
var initAppsPane = require('../apps-pane/apps-pane.js');
var LogsAddonsHelpers = require('../logs/addons-helper.js');
var MetricsAddonsHelpers = require('../metrics/addons-helper.js');
var BackupsAddonsHelpers = require('../backups/addons-helper.js');
const { getAllUnpaidInvoices } = require('@clevercloud/client/esm/api/v4/billing.js');
const { iconRemixBook_2Fill, iconRemixFeedbackLine } = require('@clevercloud/components/dist/assets/cc-remix.icons.js');
const { getAddonDocumentationInfos, getAppDocumentationInfos } = require('../../helpers/getDocumentationInfos.js');
const { sendToApi } = require('../../send-to-api.js');
const { BILLING_ENABLED, HOMEPAGE_LINK_LABEL, HOMEPAGE_LOGO, ADDON_LOGS_ENABLED, ACCESS_LOGS_ENABLED, GRAFANA_ENABLED, SHARED_SOFTWARES_ENABLED } = require('../../configuration.js');

const { ADDONS_IN_CONSOLE_DASHBOARD, HAS_SERVICE_DEPENDENCIES } = require('../../etc/addon.js');
const { getFeature } = require('../../helpers/featureFlag.js');

const { getFeedbackLink } = require('../../helpers/getFeedbackLink.js');

const KV_EXPLORER_FEATURE = getFeature('KV_EXPLORER')

var $Panes = (module.exports = function (settings) {
  var state = {
    Console: settings.Console,

    orgasPane: $(settings.orgasPane),
    appsPaneSelector: settings.appsPane,
    settingsPane: $(settings.settingsPane),
    slidingPanes: $(settings.settingsPane).parents('.l-slides-for-orgas'),

    noPaneStatepoints: settings.noPaneStatepoints,

    s_user: Bacon.combineWith(addEmailValidatedToUserData, settings.s_user, API.self.get().send().toProperty()),
    s_orgas: settings.s_orgas,
    s_request: settings.s_request,
    s_providers: settings.s_providers,
  };

  $Panes.init(state);

  return state;
});

$Panes.init = function (state) {
  $Panes.buildBottomLinks(state);
  $Panes.handleCloseButton(state);

  // Fetch owners
  var s_owners = Bacon.combineWith(
    _.partial(prepareOwners, state),
    state.s_user,
    state.s_orgas,
    state.s_request,
    state.s_providers,
  ).toProperty();

  // Filter to get only the active owner
  var s_owner = s_owners
    .flatMapLatest(function (owners) {
      var owner = _.find(owners, 'active');
      return owner ? Bacon.once(owner) : Bacon.never();
    })
    .flatMapLatest(function (owner) {
      return state.s_request.first().map(function (req) {
        return _.extend({}, owner, {
          currentAppId: req.params.appId,
          currentAddonId: req.params.addonId,
        });
      });
    })
    .toProperty();

  var s_changeOwner = s_owner.skipDuplicates(function (oldOwner, newOwner) {
    return oldOwner.id === newOwner.id;
  });

  var s_invoices = s_changeOwner.flatMapLatest(function (owner) {
    // owner.role can be undefined if the user just joined an organisation
    // we fetch the organisation after the user loaded the /join pane of the organisation
    // so we don't have the role yet but we will after it has been loaded
    if (owner.id.indexOf('user_') === 0 || (owner.role && Role.canAccessBills(owner.role))) {
      var s_unpaid = Bacon.fromPromise(getAllUnpaidInvoices({ id: owner.id }).then(sendToApi));
      return s_unpaid.takeUntil(s_owner.skip(1).first()).map(function (invoices) {
        return { invoices: invoices, owner: owner };
      });
    } else {
      return Bacon.once({ invoices: [], owner: owner });
    }
  });

  // Check whether apps and settings panes should be displayed
  var s_showPanes = state.s_request
    .map(function (request) {
      return state.noPaneStatepoints.indexOf(request.statepoint.name) < 0;
    })
    .toProperty();

  s_owners.onValue(function (owners) {
    $Panes.buildOwners(state, owners);
  });

  s_owner.onValue(function (owner) {
    // me is the current logged in user
    Bacon.onValues(state.s_request.first(), state.s_user.first(), function (req, me) {
      $Panes.buildSettings(state, owner, req, me);
    });
  });

  initAppsPane(state.appsPaneSelector, {
    s_owner,
    Console: state.Console,
  });

  s_invoices
    .filter(function (obj) {
      return obj.invoices.length >= 1;
    })
    .filter(function (obj) {
      if (obj.invoices.length === 1) {
        var invoice = moment(_.first(obj.invoices).emissionDate);
        // 5 days in the past from now
        var limit = moment(new Date().getTime()).subtract(5, 'days');
        return invoice.valueOf() < limit.valueOf();
      } else {
        return true;
      }
    })
    .onValue(function (obj) {
      var url =
        obj.owner.id.indexOf('user_') === 0 ? '/users/me/invoices' : '/organisations/' + obj.owner.id + '/invoices';

      var message =
        obj.invoices.length >= 2 ? 'console.global-message.unpaid-bills-plur' : 'console.global-message.unpaid-bills';

      GlobalMessage(Console.T(message, { bills: obj.invoices.length, url: url }));
    });

  s_changeOwner.onValue(function () {
    // Hide global message when we change owner
    GlobalMessage.hide();
  });

  s_showPanes.onValue(_.partial($Panes.togglePanes, state));
  s_showPanes.onValue(_.partial($Panes.toggleListeningForPaneOpening, state));
};

// Check whether an owner is a user or not
var isUser = function (owner) {
  return (owner.id || owner).indexOf('user') == 0;
};

// Build an owners list from fetched data
var prepareOwners = function (state, user, orgas, req, addonproviders) {
  var owners = _.mapValues(_.extend({}, orgas, { user: user }), function (owner) {
    var addons = _.map(owner.addons, function (addon) {
      const addonProviderId = _.has(addon, 'providerId') ? addon.providerId : addon.provider && addon.provider.id;

      var addonprovider =
        _.find(addonproviders, function (provider) {
          return provider.id === addonProviderId;
        }) || {};
      return _.extend({}, addon, {
        canUpgrade: addonprovider.canUpgrade,
      });
    });

    var providers = _.map(owner.providers, function (provider) {
      var addonprovider = _.find(addonproviders, { id: provider.id }) || {};
      return _.extend({}, provider, {
        logoUrl: addonprovider.logoUrl,
      });
    });

    return _.extend({}, owner, {
      addons: addons,
      providers: providers,
    });
  });

  var nopane = state.noPaneStatepoints.indexOf(req.statepoint.name) >= 0;

  // Add some properties
  _.each(owners, function (owner) {
    owner.active = (isUser(owner) && !req.params.oid && !nopane) || owner.id == req.params.oid;
    owner.href = isUser(owner) ? '/users/me' : '/organisations/' + owner.id;
    owner.initials = isUser(owner)
      ? 'Me'
      : owner.name.replace(/^(\S+)(\s+(\S+))?.*$/, function (_, w1, __, w2) {
          return w1[0].toUpperCase() + (w2 ? w2[0].toUpperCase() : '');
        });

    if (owner.active) {
      owner.selection =
        (req.params.appId && owner.href + '/applications/' + req.params.appId) ||
        (req.params.addonId && owner.href + '/addons/' + req.params.addonId) ||
        (req.params.consumerKey && owner.href + '/oauth-consumers/' + req.params.consumerKey) ||
        (req.params.providerId && owner.href + '/providers/' + req.params.providerId) ||
        req.path.includes('/create') ||
        (req.path.endsWith('/new') && req.path) ||
        (req.path.endsWith('/ticket-center') && owner.href + '/ticket-center') ||
        owner.href;
    }
  });

  return owners;
};

// Build Crisp, Profile, Docs and Logout buttons
$Panes.buildBottomLinks = function (state) {
  state.orgasPane.find('.bottom-links').html(Templates['orgas.bottom-links']());
};

// Toggle the blue panes when clicking on the arrow button
$Panes.handleCloseButton = function (state) {
  state.settingsPane
    .find('.close')
    .off('click')
    .on('click', function () {
      state.slidingPanes.toggleClass('is-closed');
    });
};

// Build the owner list in the left pane
$Panes.buildOwners = function (state, owners) {
  // Sort owners by name
  var sortedOwners = _.sortBy(owners, function (owner) {
    return isUser(owner) ? '0' : '1' + owner.name.toUpperCase();
  });

  state.orgasPane.find('.logo-link .home-link-label').html(HOMEPAGE_LINK_LABEL);
  state.orgasPane.find('.logo-link .l-orgas-home-icon').attr('src', HOMEPAGE_LOGO);

  state.orgasPane.find('.organisations').html(
    Templates['orgas.list']({
      owners: sortedOwners,
      isEmailValidated: sortedOwners[0].emailValidated,
    }),
  );
};

// Build the settings pane
$Panes.buildSettings = function (state, owner, req, me) {
  if (req.params.appId) {
    $Panes.buildAppSettings(state, owner, req, me);
  } else if (req.params.addonId) {
    $Panes.buildAddonSettings(state, owner, req);
  } else if (req.params.providerId) {
    $Panes.buildProviderSettings(state, owner, req);
  } else if (req.params.consumerKey) {
    $Panes.buildConsumerSettings(state, owner, req);
  } else if (owner.href === owner.selection) {
    $Panes.buildOwnerSettings(state, owner, req);
  } else {
    state.settingsPane.hide();
  }
};

// Build the settings pane for the user and the organisations
$Panes.buildOwnerSettings = function (state, owner, req) {
  var page = req.statepoint.name;

  state.settingsPane.show();
  state.settingsPane.find('.settings').html(
    Templates[isUser(owner) ? 'settings.user' : 'settings.orga']({
      href: isUser(owner) ? '/users/me' : '/organisations/' + owner.id,
      ownerId: owner.id,
      canAccessBills: BILLING_ENABLED && (isUser(owner) || Role.canAccessBills(owner.role || '')),
      canAccessNotifications: isUser(owner) || Role.canAccessRepositories(owner.role || ''),
      canAccessGrafana: GRAFANA_ENABLED,
      canAccessSharedSoftwares: SHARED_SOFTWARES_ENABLED,
      page: page,
    }),
  );
};

// Build the settings pane for an application
$Panes.buildAppSettings = function (state, owner, req, me) {
  var page = req.statepoint.name;
  var app = _.find(owner.applications, function (app) {
    return app.id == req.params.appId;
  });

  // This can happen when we just deleted a PHP FTP app
  // this method is called while the s_request is still pointing to the informations
  // page of the app, for an app that doesn't exist anymore.
  // the s_owner has been updated because we fetched the addons/apps to refresh
  // the summary
  if (!app) {
    return;
  }

  const instanceType = app.instanceType || app.instance.type;
  const variantSlug = app.variantSlug || app.instance.variant.slug;

  state.settingsPane.show();
  state.settingsPane.find('.settings').html(
    Templates['settings.app']({
      href: (isUser(owner) ? '/users/me' : '/organisations/' + owner.id) + '/applications/' + app.id,
      page: page,
      docIcon: JSON.stringify(iconRemixBook_2Fill),
      documentation: getAppDocumentationInfos(`${instanceType}+${variantSlug}`),
      newLogsFeedback: getNewLogsFeedbackLink(req),
      accessLogsEnabled: ACCESS_LOGS_ENABLED,
    }),
  );
};

// Build the settings pane for an add-on
$Panes.buildAddonSettings = function (state, owner, req) {
  const ADDON_PROVIDERS_WITH_LOGS = ['postgresql-addon', 'mysql-addon', 'jenkins'];
  var page = req.statepoint.name;
  var addon = _.find(owner.addons, function (addon) {
    return addon.id === req.params.addonId || addon.realId === req.params.addonId;
  });

  // addon can be undefined if the summary proxy has been
  // refreshed and we are still in the addon's deletion process
  if (!addon) {
    return;
  }

  const addonProviderId = addon.providerId ? addon.providerId : addon.provider && addon.provider.id;

  const hasLogs = ADDON_LOGS_ENABLED && LogsAddonsHelpers.SUPPORTED_ADDONS.find((a) => a.providerId === addonProviderId);
  const hasMetrics = MetricsAddonsHelpers.SUPPORTED_ADDONS.find((a) => a.providerId === addonProviderId);
  const hasBackups = BackupsAddonsHelpers.SUPPORTED_ADDONS.find((a) => a === addonProviderId);

  const feedbackLink = getFeedbackLink(addonProviderId, req.path);
  const hasFeedbackLink = feedbackLink != null;

  let hasUpgrade = _.has(addon, 'canUpgrade') ? addon.canUpgrade : addon.provider && addon.provider.canUpgrade;

  state.settingsPane.show();
  state.settingsPane.find('.settings').html(
    Templates['settings.addon']({
      href: (isUser(owner) ? '/users/me' : '/organisations/' + owner.id) + '/addons/' + addon.id,
    canUpgrade: hasUpgrade,
    page: page,
    hasLogs: hasLogs,
    hasMetrics: hasMetrics,
    hasBackups: hasBackups,
    inConsoleDashboard: ADDONS_IN_CONSOLE_DASHBOARD.includes(addonProviderId),
    hasServiceDependencies: HAS_SERVICE_DEPENDENCIES.includes(addonProviderId),
    docIcon: JSON.stringify(iconRemixBook_2Fill),
    documentation: getAddonDocumentationInfos(addonProviderId),
    hasFeedbackLink,
    feedbackLink,
    feedbackIcon: JSON.stringify(iconRemixFeedbackLine),
    hasRedisExplorer: addonProviderId === 'redis-addon' && KV_EXPLORER_FEATURE,
    hasMateriaExplorer: addonProviderId === 'kv' && KV_EXPLORER_FEATURE,
  }),
  );
};

// Build the settings pane for a provider
$Panes.buildProviderSettings = function (state, owner, req) {
  var page = req.statepoint.name;
  var provider = _.find(owner.providers, function (provider) {
    return provider.id == req.params.providerId;
  });

  // provider can be undefined if the summary proxy has been
  // refreshed and we are still in the provider's deletion process
  if (!provider) {
    return;
  }

  state.settingsPane.show();
  state.settingsPane.find('.settings').html(
    Templates['settings.provider']({
      href: (isUser(owner) ? '/users/me' : '/organisations/' + owner.id) + '/providers/' + provider.id,
      page: page,
    }),
  );
};

$Panes.buildConsumerSettings = function (state, owner, req) {
  var page = req.statepoint.name;
  var consumer = _.find(owner.consumers, function (consumer) {
    return consumer.key === req.params.consumerKey;
  });

  // consumer can be undefined if the summary proxy has been
  // refreshed and we are still in the consumer's deletion process
  if (!consumer) {
    return;
  }

  state.settingsPane.show();
  state.settingsPane.find('.settings').html(
    Templates['settings.consumer']({
      href: (isUser(owner) ? '/users/me' : '/organisations/' + owner.id) + '/oauth-consumers/' + consumer.key,
      page: page,
    }),
  );
};

// Hide the apps pane in some cases
$Panes.togglePanes = function (state, show) {
  state.orgasPane.toggleClass('is-orgas-fixed', !show);
};

// Listen or stop listening to some events depending on the value of showpane
$Panes.toggleListeningForPaneOpening = function (state, showpane) {
  if (showpane) {
    $Panes.startListeningForPaneOpening(state);
  } else {
    $Panes.stopListeningForPaneOpening(state);
  }
};

// Listen to events that will slide the panes
$Panes.startListeningForPaneOpening = function (state) {
  var $pane = state.orgasPane;

  $pane.unbind('click').click(_.partial($Panes.close, state));
  $pane.unbind('mouseenter').mouseenter(_.partial($Panes.open, state));
  $pane.unbind('mouseleave').mouseleave(_.partial($Panes.close, state));
};

// Stop listening to events that will slide the panes
$Panes.stopListeningForPaneOpening = function (state) {
  var $pane = state.orgasPane;

  $pane.unbind('click');
  $pane.unbind('mouseenter');
  $pane.unbind('mouseleave');
};

// Check whether the left panes is open or closed
$Panes.isOpen = function (state) {
  return state.orgasPane.hasClass('is-orgas-open');
};

// Open the left pane if it is closed
$Panes.open = function (state) {
  if (!$Panes.isOpen(state)) {
    state.orgasPane.addClass('is-orgas-open');
  }
};

// Close the left pane if it is open
$Panes.close = function (state) {
  if ($Panes.isOpen(state)) {
    state.orgasPane.removeClass('is-orgas-open');
  }
};

// Remove the panes (because the console is unavailable for example)
$Panes.remove = function (state) {
  $Panes.togglePanes(state, false);

  state.orgasPane.empty();
  $(state.appsPaneSelector).empty();
  state.settingsPane.empty();
};

$Panes.subscribeToTransitions = function (subscribeTo) {
  var events = DomEvents.listenForEvents();
  // close is for browsers not supporting transitionstart event
  var listenDivs = ['l-orgas', 'l-apps', 'l-settings', 'l-slides-for-orgas', 'l-container', 'close'];

  var events = _.map(subscribeTo, function (eventType) {
    if (!_.has(events, eventType)) {
      return Bacon.once(new Bacon.Error(new Error('Event ' + eventType + " doesn't exist")));
    }

    return events[eventType]
      .flatMapError(function (error) {
        return $Panes.polyfill(eventType);
      })
      .filter(function (e) {
        var elem = $(e.target);
        return _.some(listenDivs, function (cssClass) {
          return elem.hasClass(cssClass);
        });
      })
      .map(function (domEvent) {
        return {
          eventType: eventType,
          domEvent: domEvent,
        };
      });
  });

  return Bacon.mergeAll(events);
};

$Panes.polyfill = function (eventType) {
  switch (eventType) {
    case 'transitionstart':
      return $Panes.onTransitionStart();
    default:
      console.warn("Panes: I don't know how to handle", eventType, "event, I don't have a polyfill");
      return Bacon.never();
  }
};

// This polyfill emits an event when we click the arrow pane
$Panes.onTransitionStart = function (state) {
  return $('.pane-bottom button.close').asEventStream('click');
};

$Panes.isLoaded = $Panes
  .subscribeToTransitions(['transitionend'])
  // We can listen to both l-apps or l-settings
  .filter(function (obj) {
    return $(obj.domEvent.target).hasClass('l-orgas');
  })
  .first()
  .map(true)
  .toProperty(false);

$Panes.isLoaded.onValue(function () {
  /* Lazy and we want to be able to check this value anytime */
});

/**
 * Combines user data from the settings and self streams to retrieve
 * the `emailValidated` field.
 * This field is necessary to avoid creating the `Add an organization` entry
 * within the orga list pane (see `buildOwners` within this file & `orgas.list._tmpl.html`).
 *
 * @param {Object} userDataFromSettingsStream
 * @param {Object} userDataFromSelfStream
 * @returns {Object} user data with the emailValidated field
 *
 */
function addEmailValidatedToUserData(userDataFromSettingsStream, userDataFromSelfStream) {
  return {
    ...userDataFromSettingsStream,
    emailValidated: userDataFromSelfStream.emailValidated,
  };
}

function getNewLogsFeedbackLink(req) {
  if (req.path.endsWith('/logs')) {
    return {
      link: 'https://github.com/CleverCloud/Community/discussions/categories/new-logs-interface',
      icon: JSON.stringify(iconRemixFeedbackLine)
    };
  }
}
