module.exports = (function () {
  var $ = require('jquery');
  var Bacon = require('baconjs');
  var _ = require('lodash');

  var Templates = require('../../../generated/templates.js');
  var T = require('../../models/technical/translation.js');
  var $Modal = require('../modals/main.js');
  var LoadLibrary = require('../load-external-libraries/main.es6.js');
  const { createPaymentMethod, getStripePaymentIntent } = require('@clevercloud/client/esm/api/v4/billing.js');
  const { sendToApi } = require('../../send-to-api.js');
  require('@clevercloud/components/dist/cc-block.js');
  require('@clevercloud/components/dist/cc-block-section.js');

  var $Cards = function (settings) {
    var state = {
      container: $(settings.container),
      cards: (settings.cards || []).map((card) => $Cards.formatMethod(card)),
      cardType: settings.cardType || 'CREDITCARD',
      selectCard: settings.selectCard || 'radio',
      selectedCardId: settings.selectedCardId,
      displayCards: _.has(settings, 'displayCards') ? settings.displayCards : true,
      paypalContainer: settings.paypalContainer,
      priceTTC: settings.priceTTC,
      ownerId: settings.ownerId,

      moduleTimestamp: new Date().getTime(),
    };

    state.b_card = new Bacon.Bus();
    var s_filtered = state.b_card.filter((card) => card); // filter so we don't take null cards
    state.s_card = state.selectedCardId
      ? s_filtered.toProperty({ type: 'EXISTING_CARD', token: state.selectedCardId })
      : s_filtered.toProperty();

    state.s_card.onValue(_.partial($Cards.selectCard, state));

    $Cards.init(state);

    return state;
  };

  $Cards.init = (state) => {
    state.container.html(Templates['cards'](state));
    $Cards.displayCreditCards(state);
    $Cards.createNewCardForm(state);
  };

  $Cards.displayCreditCards = function (state) {
    if (state.cards.length === 0) {
      return;
    } else {
      state.container.find('.credit-card-list-container').show().html(Templates['cards-list'](state));

      var s_buttonCards = state.container.find('button[data-token]').asEventStream('click').doAction('.preventDefault');

      var s_radioCards = state.container.find("input[type='radio']").asEventStream('change');

      var s_token = Bacon.mergeAll(s_buttonCards, s_radioCards)
        .map((e) => $(e.currentTarget).attr('data-token'))
        .toProperty();

      s_buttonCards
        .map((e) => $(e.currentTarget).attr('data-token'))
        .onValue((token) => {
          $Cards.displayCreditCards(
            _.extend({}, state, {
              selectedCardId: token,
            }),
          );
        });

      state.b_card.plug(s_token.map(_.partial($Cards.getCardFromToken, state)));
    }
  };

  $Cards.getCardFromToken = function (state, token) {
    var card = _.find(state.cards, function (card) {
      return card.token === token;
    });

    // Device Data would be null for a non paypal payment and not included in the API call
    var ret = card
      ? {
          type: 'EXISTING_CARD',
          token: token,
        }
      : null;

    return ret;
  };

  $Cards.getClientSecret = (ownerId, type) => {
    return $Cards
      .getOwnerId(ownerId)
      .flatMapLatest((ownerId) => {
        return Bacon.fromPromise(getStripePaymentIntent({ id: ownerId, type }).then(sendToApi));
      })
      .map((intent) => intent.clientSecret);
  };

  $Cards.saveCreditCard = function (state, cardElement, stripe) {
    const s_clientSecret = $Cards.getClientSecret(state.ownerId, 'CREDITCARD');

    return s_clientSecret
      .flatMapLatest((clientSecret) => {
        return Bacon.fromBinder((sink) => {
          stripe.handleCardSetup(clientSecret, cardElement, {}).then((result) => {
            sink(result.error ? new Bacon.Error(result.error) : result.setupIntent);
            sink(new Bacon.End());
          });
        });
      })
      .flatMapLatest(_.partial($Cards.saveNewCard, state));
  };

  $Cards.saveNewCard = (state, setupIntent) => {
    const body = JSON.stringify({
      type: 'EXISTING_CARD',
      token: setupIntent.payment_method,
    });

    return $Cards
      .getOwnerId(state.ownerId)
      .flatMapLatest((ownerId) => {
        return Bacon.fromPromise(createPaymentMethod({ id: ownerId }, body).then(sendToApi));
      })
      .map((newCard) => {
        // The ccapi won't fix this :'(
        // the holderName property is missing in the response
        newCard.holderName = null;

        return newCard;
      });
  };

  $Cards.saveSepa = function (state, sourceData, stripe) {
    return $Cards.getClientSecret(state.ownerId, 'SEPA_DEBIT').flatMapLatest((clientSecret) => {
      return Bacon.fromBinder((sink) => {
        stripe.confirmSepaDebitSetup(clientSecret, sourceData).then((result) => {
          sink(result.error ? new Bacon.Error(result.error) : result.setupIntent);
          sink(new Bacon.End());
        });
      }).flatMapLatest((setupIntent) => $Cards.saveNewSepa(state, setupIntent));
    });
  };

  $Cards.saveNewSepa = (state, setupIntent) => {
    const body = JSON.stringify({
      type: 'EXISTING_CARD',
      token: setupIntent.payment_method,
    });

    return $Cards
      .getOwnerId(state.ownerId)
      .flatMapLatest((ownerId) => {
        return Bacon.fromPromise(createPaymentMethod({ id: ownerId }, body).then(sendToApi));
      })
      .map((newSepa) => {
        state.container.find('.submit-sepa').removeAttr('disabled').removeClass('is-loading');
        return newSepa;
      });
  };

  $Cards.selectCard = function (state, card) {
    state.container.find('button[data-token]').removeAttr('disabled');
    state.container.find('button[data-token="' + card.token + '"]').attr('disabled', 'disabled');
  };

  $Cards.askForDefault = (state) => {
    // Don't ask if the user wants to set the card as the default one
    // if there are no cards or if none is set as the default
    const ownerType = state.ownerId.indexOf('orga_') === 0 ? 'orga' : 'user';
    return _.find(state.cards[ownerType], (card) => card.isDefault);
  };

  $Cards.onError = function (error) {
    $Notification.displayError(error);
    console.error(error);
  };

  $Cards.displayNewCreditCardForm = (state, $container, keepLoading = false) => {
    return LoadLibrary('stripe-client').flatMapLatest((stripe) => {
      const elements = stripe.elements();
      const card = elements.create('card', {
        style: {
          base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      });
      card.mount('#card-element');

      const $submitBtn = $container.find('button.submit-payment');
      const s_cardChange = Bacon.fromEvent(card, 'change');
      s_cardChange
        .filter((change) => change.complete)
        .onValue(() => {
          $submitBtn.removeAttr('disabled');
        });

      s_cardChange
        .map((change) => change.error)
        .onValue((error) => {
          $Cards.displayErrorCreditCardForm(error);
          if (error) {
            $submitBtn.attr('disabled', 'disabled');
          }
        });

      return $container
        .find('#payment-form')
        .asEventStream('submit')
        .doAction('.preventDefault')
        .map(() => $Cards.displayErrorCreditCardForm(null))
        .flatMapLatest(() => $Cards.displayCSARegulationModal())
        .flatMapLatest(() => {
          const s_saveCard = $Cards.saveCreditCard(state, card, stripe);
          const $submitButton = $container.find('button.submit-payment');
          if (keepLoading) {
            $submitButton.addClass('is-loading').attr('disabled', 'disabled');
          } else {
            $submitButton.loadStream(s_saveCard);
          }
          return s_saveCard;
        });
    });
  };

  $Cards.displayErrorCreditCardForm = (error) => {
    const $displayError = $('#card-errors');
    if (error && error.message) {
      $displayError.show().text(error.message);
    } else {
      $displayError.hide();
    }
  };

  $Cards.displayErrorSepaForm = (error) => {
    const $displayError = $('#sepa-error-message');
    if (error && error.message) {
      $displayError.show().text(error.message);
    } else {
      $displayError.hide();
    }
  };

  $Cards.createNewCardForm = (state) => {
    let s_newCreditCardForm;
    if (state.cardType === 'CREDITCARD') {
      s_newCreditCardForm = $Cards.displayNewCreditCardForm(state, state.container);
    } else if (state.cardType === 'SEPA_DEBIT') {
      s_newCreditCardForm = $Cards.displayNewSepaForm(state, state.container);
    } else {
      throw new Error(`Unknown ${state.cardType}`);
    }

    s_newCreditCardForm.onValue((newCard) => {
      const newCards = [...state.cards, $Cards.formatMethod(newCard)];
      const newState = _.extend({}, state, {
        cards: newCards,
      });
      $Cards.init(newState);
      // We replace the cards container but some external code relies on the DOM elements
      // the external code also listens to s_card to know when there is a change and render again
      // so we send the last event sent, again, to render the needed HTML
      state.b_card.plug(state.s_card.first());
    });

    s_newCreditCardForm.onError((error) => {
      if (state.cardType === 'CREDITCARD') {
        $Cards.displayErrorCreditCardForm(error);
      } else if (state.cardType === 'SEPA_DEBIT') {
        $Cards.displayErrorSepaForm(error);
      }
    });
  };

  $Cards.displayNewSepaForm = (state, $container) => {
    return LoadLibrary('stripe-client').flatMapLatest((stripe) => {
      const elements = stripe.elements();
      // Custom styling can be passed to options when creating an Element.
      const style = {
        base: {
          // Add your base input styles here. For example:
          fontSize: '16px',
          color: '#32325d',
        },
      };

      const options = {
        style: style,
        supportedCountries: ['SEPA'],
      };

      // Create an instance of the iban Element.
      var iban = elements.create('iban', options);

      // Add an instance of the iban Element into the `iban-element` <div>.
      iban.mount('#iban-element');

      const $submitBtn = $container.find('button.submit-sepa');
      const s_ibanChange = Bacon.fromEvent(iban, 'change');
      s_ibanChange
        .filter((change) => change.complete)
        .onValue(() => {
          $submitBtn.removeAttr('disabled');
        });

      s_ibanChange
        .map((change) => change.error)
        .onValue((error) => {
          $Cards.displayErrorSepaForm(error);
          if (error) {
            $submitBtn.attr('disabled', 'disabled');
          }
        });

      return $container
        .find('#payment-sepa-form')
        .asEventStream('submit')
        .doAction('.preventDefault')
        .map((e) => {
          const $form = $(e.target);
          return {
            payment_method: {
              sepa_debit: iban,
              billing_details: {
                name: $form.find('cc-input-text[name="name"]').val().trim(),
                email: $form.find('cc-input-text[name="email"]').val().trim(),
              },
            },
          };
        })
        .map((sourceData) => {
          $Cards.displayErrorSepaForm(null);
          return sourceData;
        })
        .flatMapLatest((sourceData) => $Cards.displayMandateAcceptance().map(sourceData))
        .flatMapLatest((sourceData) => {
          const s_saveSepa = $Cards.saveSepa(state, sourceData, stripe);
          $submitBtn.loadStream(s_saveSepa);
          return s_saveSepa;
        });
    });
  };

  $Cards.getOwnerId = (orgaId) => {
    return orgaId && orgaId.indexOf('orga_') === 0
      ? Bacon.constant(orgaId)
      : Console.SummaryProxy.fetchUserOnce().map((user) => user.id);
  };

  $Cards.displayCSARegulationModal = () => {
    const $modal = $Modal({
      type: 'confirmation',
      title: T('console.cards.modal-sca-agreement-title'),
      body: T('console.cards.modal-sca-agreement'),
      submitButtonText: T('console.cards.modal-sca-agreement-submit'),
      submitButtonClass: 'btn-green',
      Templates,
    });

    return $modal.s_confirm.map(() => $Modal.remove($modal));
  };

  $Cards.displayMandateAcceptance = () => {
    const $modal = $Modal({
      type: 'confirmation',
      title: T('console.cards.modal-debit-authorization.title'),
      body: T('console.cards.modal-debit-authorization.text'),
      submitButtonText: T('console.cards.modal-debit-authorization.submit'),
      submitButtonClass: 'btn-green',
      customClasses: ['modal-debit-authorization'],
      Templates,
    });

    return $modal.s_confirm.map(() => $Modal.remove($modal));
  };

  $Cards.formatMethod = (method) => {
    if (!method) {
      return null;
    } else if (method.type === 'SEPA_DEBIT') {
      const formattedNumber = `${method.country}XX${method.bankCode}XXXXXX${method.number}`
        .replace(' ', '')
        .replace(/(.{4})/g, '$1 ');
      return _.extend({}, method, { formattedNumber });
    } else {
      return _.extend({}, method, {
        formattedNumber: method.number,
        ...getNetworkLogoInfo(method.preferredNetwork),
      });
    }
  };

  return $Cards;
})();

function getNetworkLogoInfo(network) {
  switch (network) {
    case 'cartes_bancaires':
      return { networkLogo: '/img/payment-methods/cb.svg', networkLogoAlt: 'Cartes Bancaires' };
    case 'visa':
      return { networkLogo: '/img/payment-methods/visa.svg', networkLogoAlt: 'VISA' };
    case 'mastercard':
      return { networkLogo: '/img/payment-methods/mastercard.svg', networkLogoAlt: 'Mastercard' };
    default:
      return {};
  }
}
