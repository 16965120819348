module.exports = (() => {
  const $OwnerNotifications = require('../modules/owner-notifications/main.es6.js');

  const sp = new (require('./AbstractSP.js'))({
    name: 'OwnerNotificationsSP',
  });

  sp.getStreams = (req) => {
    const s_ownerId = req.params.oid ? Bacon.constant(req.params.oid) : SummaryProxy.fetchUserOnce().map('.id');

    return {
      s_ownerId: s_ownerId,
    };
  };

  sp.on('onload', (req, $container, streams) => {
    streams.s_ownerId.onValue((ownerId) => {
      $OwnerNotifications({
        $container: $container.find('.card-container'),
        ownerId: ownerId,
        s_requestUnload: Console.s_requestUnload,
        SummaryProxy: Console.SummaryProxy,
      });
    });
  });

  return sp;
})();
