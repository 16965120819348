var NumberFormat = (module.exports = (function () {
  var nf = {};

  nf.format = function (number, digits) {
    digits = typeof digits === 'number' ? digits : 2;
    var rounded = Math.round(number * Math.pow(10, digits)) / Math.pow(10, digits);
    var intPart = rounded.toString().split('.')[0];
    var decPart = rounded.toString().split('.')[1] || '';

    var missingZeros = function (acc, figures, count) {
      var figure = figures.shift() || '0';
      return count === 0 ? acc : missingZeros(acc + figure, figures, count - 1);
    };

    return digits === 0 ? intPart : intPart + '.' + missingZeros('', decPart.split(''), digits);
  };

  return nf;
})());
