import { prefixUrl } from '@clevercloud/client/esm/prefix-url.js';
import { addOauthHeader } from '@clevercloud/client/esm/oauth.js';
import { request } from '@clevercloud/client/esm/request.fetch.js';
import { getWarp10AccessLogsToken } from '@clevercloud/client/esm/api/v2/warp-10.js';

const WARP_10_EXEC_URL = Console.configuration.WARP_10_HOST + '/api/v0/exec';

let tokenCache = {};
// TODO find a better way to do this
// TODO handle errors
export async function getToken(params, API_HOST, tokens) {
  if (tokenCache[params.orgaId] != null) {
    return tokenCache[params.orgaId];
  }

  const token = await getWarp10AccessLogsToken(params)
    .then(prefixUrl(API_HOST))
    .then(addOauthHeader(tokens))
    .then(request);

  tokenCache[params.orgaId] = token;

  return token;
}

function executeWarp10({ warpscript, slug, timeout = 30000 }) {
  // Prepare to abort/timeout the request after timeout
  const controller = new AbortController();
  const signal = controller.signal;
  const abortTimeoutId = setTimeout(() => {
    console.error(`Warp10 request ${slug} was too long (more than ${timeout}ms).`);
    return controller.abort();
  }, timeout);

  return fetch(WARP_10_EXEC_URL + '?__=' + slug, { method: 'POST', body: warpscript, signal })
    .then(async (r) => {
      if (r.status !== 200) {
        const warp10error = 'warp10 Error: ' + r.headers.get('X-Warp10-Error-Message');
        console.error(warp10error);
        throw new Error(warp10error);
      }
      return r.json();
    })
    .finally(() => clearTimeout(abortTimeoutId));
}

export function getCountryCityAccessLogsFromWarp10({ ownerId, appId, fromDate, toDate, warpToken }) {
  const [granularity, id] = appId != null ? ['app_id', appId] : ['owner_id', ownerId];

  const warpscript = `'${warpToken}' '${granularity}' '${id}' '${fromDate}' '${toDate}' @clevercloud/logs_dotmap_v1`;

  const slug =
    appId != null ? `dotmap__${ownerId.slice(0, 10)}__${appId.slice(0, 10)}` : `dotmap__${ownerId.slice(0, 10)}`;

  return executeWarp10({ warpscript, slug }).then(([results]) => {
    return Object.entries(results).map(([jsonData, count]) => {
      const [lat, lon, country, city] = JSON.parse(jsonData);
      return { lat, lon, country, city, count };
    });
  });
}

export async function getAccessLogs({ orgaId, durationHours }) {
  const warpToken = await getToken({ orgaId });
  return getAccessLogsFromWarp10({ orgaId, durationHours, warpToken });
}

export function getAccessLogsFromWarp10({ ownerId, appId, durationHours = 24, warpToken }) {
  const [granularity, id] = appId != null ? ['app_id', appId] : ['owner_id', ownerId];

  const warpscript = `'${warpToken}' '${granularity}' '${id}' ${durationHours} @clevercloud/logs_heatmap_v1`;

  // TODO: remove slug
  const slug =
    appId != null ? `heatmap__${ownerId.slice(0, 10)}__${appId.slice(0, 10)}` : `heatmap__${ownerId.slice(0, 10)}`;

  return executeWarp10({ warpscript, slug }).then(([results]) => {
    return Object.entries(results)
      .filter(([jsonData]) => jsonData !== '[NaN,NaN]')
      .map(([jsonData, count]) => {
        const [lat, lon] = JSON.parse(jsonData);
        return { lat, lon, count };
      });
  });
}

export function getRequestsFromWarp10({ ownerId, appId, warpToken }) {
  const ONE_HOUR = 1000 * 60 * 60;

  const now = new Date();
  const nowTs = now.getTime();
  const nowRoundedTs = nowTs - (nowTs % ONE_HOUR);
  const endDateMicroSecs = new Date(nowRoundedTs).getTime() * 1000;

  const [granularity, id] = appId != null ? ['app_id', appId] : ['owner_id', ownerId];

  const warpscript = `'${warpToken}' '${granularity}' '${id}' ${endDateMicroSecs} 24 h 1 h @clevercloud/accessLogs_request_count_v1`;

  // TODO: remove slug and use it in a feature flag
  const slug =
    appId != null ? `requests__${ownerId.slice(0, 10)}__${appId.slice(0, 10)}` : `requests__${ownerId.slice(0, 10)}`;

  return executeWarp10({ warpscript, slug }).then(([results]) => {
    // Convert timestamps in ms
    return results.map(([from, to, count]) => [Math.floor(from / 1000), Math.floor(to / 1000), count]);
  });
}
