import Yajas from 'yajas';
import path4js from 'path4js';

export function navigateTo(path) {
  Yajas.path4js.launchPath(path4js.Request.fromUri(path));
}

export function navigateToApplicationOverview(ownerId, appId) {
  navigateTo(`/organisations/${ownerId}/applications/${appId}`);
}

export function navigateToAddonOverview(ownerId, addonId) {
  navigateTo(`/organisations/${ownerId}/addons/${addonId}`);
}

export function navigateToCreationScreen(ownerId) {
  navigateTo(`/organisations/${ownerId}/create`);
}

export function generateRandomName() {
  return Math.random().toString(36).slice(2);
}

export const DICTIONARIES = {
  size: ['huge', 'big', 'medium', 'short', 'tiny'],
  age: ['old', 'adult', 'teenage', 'young'],
  origin: ['medieval', 'renaissance', 'gothic', 'steampunk', 'classical'],
  character: [
    'barbarian',
    'bard',
    'cleric',
    'druid',
    'fighter',
    'monk',
    'paladin',
    'ranger',
    'rogue',
    'sorcerer',
    'warlock',
    'wizard',
  ],
};

export function getRandomItem(array) {
  if (array == null || array.length === 0) {
    return null;
  }
  return array[Math.floor(Math.random() * array.length)];
}

export function generateRandomPassphrase(separator) {
  return [
    getRandomItem(DICTIONARIES.size),
    getRandomItem(DICTIONARIES.age),
    getRandomItem(DICTIONARIES.origin),
    getRandomItem(DICTIONARIES.character),
  ].join(separator ?? '-');
}
