// note: for retrieving links to documentation, please refer to 'src/helpers/getDocumentationInfos.js' instead
var getAppTechno = (module.exports = function (type) {
  var types = {
    node: {
      name: 'Node.Js',
      realName: 'nodejs',
      link: 'nodejs',
    },
    'apache+php55': {
      name: 'PHP',
      realName: 'php',
      link: 'php',
    },
    'apache+php54': {
      name: 'PHP',
      realName: 'php',
      link: 'php',
    },
    php: {
      name: 'PHP',
      realName: 'php',
      link: 'php',
    },
    ruby: {
      name: 'Ruby',
      realName: 'ruby',
      link: 'ruby',
    },
    'java+war': {
      name: 'Java + WAR',
      realName: 'java',
      link: 'java/java-war',
    },
    sbt: {
      name: 'SBT',
      realName: 'scala',
      link: '?search=SBT',
    },
    'java+play1': {
      name: 'Play! 1',
      realName: 'scala',
      link: 'java/play-framework-1',
    },
    docker: {
      name: 'Docker',
      realName: 'docker',
      link: 'docker',
    },
    go: {
      name: 'Golang',
      realName: 'golang',
      link: 'go',
    },
    'java+maven': {
      name: 'Java/Maven',
      realName: 'java',
      link: 'java/java-maven',
    },
    python27: {
      name: 'Python',
      realName: 'python',
      link: 'python',
    },
    static: {},
    'scala+play2': {
      name: 'Play!2',
      realName: 'java+play2',
      link: 'scala/play-framework-2',
    },
    'java+play2': {
      name: 'Play! 2',
      realName: 'java+play2',
      link: 'java/play-framework-2',
    },
    dotnet: {
      name: '.Net',
      realName: 'dotnet',
      link: 'dotnet',
    },
  };

  return types[type.toLowerCase()] || {};
});
